
.full-height {
  height: 100%;
}
.full-width {
  width: 100%;
}
/* --------------------------
    Spacing CSS
-------------------------- */
.lsp-0 {
    letter-spacing: 0;
}
.lsp-1 {
    letter-spacing: 1px;
}
.lsp-2 {
    letter-spacing: 2px;
}
.m-pt-140 {
    padding-top: 140px;
}
.m-pb-140{
    padding-bottom: 140px;
}
.m-pt-40{
    padding-top: 40px;
}
.m-pb-160{
    padding-bottom: 160px;
}
.m-pt-175 {
    padding-top: 175px;
}
.mt--10{
    margin-top: -10px;
}
.m-pb-180{
    padding-bottom: 180px;
}
.m-pt-50{
    padding-top: 50px;
}
.m-mt-30{
    margin-top: 30px;
}
.m-mt-20{
    margin-top: 20px;
}
.m-mtb-20{
    margin-top: 20px;
    margin-bottom: 20px;
}
.m-mb-60{
    margin-bottom: 60px;
}
.m-pb-60{
    padding-bottom: 60px;
}
.m-pt-60{
    padding-top: 60px;
}
.m-mb-70{
    margin-top: 70px;
}
.m-pb-25 {
    padding-bottom: 25px;
}
.m-mt-70 {
    margin-top: 70px;
}
.m-mb-50{
    margin-bottom: 50px;
}
.lt-space {
    letter-spacing: .7px;
}
.pb-80{
    padding-bottom: 80px;
}
.pb-75{
    padding-bottom: 75px;
}
.mb-80{
    margin-bottom: 80px;
}
.mt-80{
    margin-top: 80px;
}
.mb-90{
    margin-bottom: 90px;
}
.mt-90{
    margin-top: 90px;
}
.pt-35 {
    padding-top: 35px;
}
.pb-35 {
    padding-bottom: 35px;
}
.mb-50{
    margin-bottom: 50px;
}
.m-pb-50{
    padding-bottom: 50px;
}
.pl-20 {
    padding-left: 20px;
}
.m-mb-20{
    margin-bottom: 20px;
}

.pl-25 {
    padding-left: 25px;
}

/* monir */
.pt-30{
    padding-top: 30px;
}

/* monir */
.pb-30{
    padding-bottom: 30px;
}

.pt-40 {
    padding-top: 40px;
}

.pb-40 {
    padding-bottom: 40px;
}

.pr-50 {
    padding-right: 50px;
}
.pt-100 {
    padding-top: 100px;
}

.pb-100 {
    padding-bottom: 100px;
}
.m-pb-70{
    padding-bottom: 70px;
}
.m-pt-70{
    padding-top: 70px;
}
.m-pt-275{
    padding-top: 275px;
}
.m-pb-90{
    padding-bottom: 90px;
}
.m-pb-110{
    padding-bottom: 110px;
}
.m-mt-110{
    margin-top: 110px;
}
.m-mt-100{
    margin-top: 100px;
}
.m-mt-120{
    margin-top: 120px;
}
.m-mt-50{
    margin-top: 50px;
}
.m-mt-60{
    margin-top: 60px;
}
.m-mt-40{
    margin-top: 40px;
}
.m-mb-30{
    margin-bottom: 30px;
}
.m-pb-200{
    padding-bottom: 200px;
}
.m-pb-180{
    padding-bottom: 180px;
}
.m-pb-190{
    padding-bottom: 190px;
}
.m-pb-120{
    padding-bottom: 120px;
}
.m-pb-130{
    padding-bottom: 130px;
}
.m-pt-130{
    padding-top: 130px;
}
.m-pt-135{
    padding-top: 135px;
}
.m-pb-135{
    padding-bottom: 135px;
}
.m-mb-40{
    margin-bottom: 40px ;
}
