/*@import url('https://fonts.googleapis.com/css?family=Advent+Pro:400,500,600');*/
/*
Project Name:bcbd;
Date:22-7-18(2.44PM);
Developed BY: KMA SALAM;
Author:BCBD;



    Css Table OF Contents
========================================

Default Settings
    1.Common Css
    2.Typhography
    3.Spacing Css
    4.Button Css
    5.Color css
    6.Common Clsss
Style Css
    1.
    2.
    3.
    4.
    5.
    6.
    7.
    8.
    9.
    10.
    11.
    12.
    13.
    14.
    15.
    16.
    17.







font-family: 'Lato', sans-serif;
font-family: 'Open Sans', sans-serif;

*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/*===================================================
    ==Start Default Settings===
===================================================*/
/*----------------------
    Common Css
----------------------*/
* {
  font-family: 'Exo 2', sans-serif;
}

iframe {
  border: 0;
  border-radius: 15px;
}

body {
  margin: 0;
  padding: 0;
  font-size: 15px;
  position: relative;
  height: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
  transition: all 0.3s cubic-bezier(0.5, 0, 0.5, 1);
}

html,
body {
  height: 100%;
  background: white !important;
}

.bg_8 {
  background: #f79629 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.page_wrapper {
  position: relative;
  overflow: hidden;
}

ul,
ol,
li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

input:focus {
  outline: none;
}

button:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  margin: 0;
  padding: 0;
}

.border-radius-50 {
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  border-radius: 50% !important;
}

a,
a:hover {
  text-decoration: none;
}
.span-color {
  color: #ffb300;
}
a:focus {
  outline: none;
}
.position-none {
  position: unset !important;
}
.p-11px {
  padding: 11px;
}
.w-80px {
  width: 80px;
}
.w-85 {
  width: 85%;
}
.w-100px {
  width: 100px;
}
.w-120px {
  width: 120px !important;
}
.w-150px {
  width: 150px !important;
}
.w-100p {
  width: 100% !important;
}
.w-50px {
  width: 50px;
}
.w-50 {
  width: 50%;
}
.w-22px {
  width: 22px;
}
.w-35px {
  width: 35px;
}
.w-315px {
  width: 315px;
}
.w-330px {
  width: 330px;
}
.w-90p {
  width: 90% !important;
}
.w-90px {
  width: 90px;
}
.w-80p {
  width: 80% !important;
}
.w-65 {
  width: 65% !important;
}
.w-40px {
  width: 40px;
}
.w-290px {
  width: 290px;
}
.w-65p {
  width: 65%;
}
.w-470px {
  width: 470px;
}
.w-200px {
  width: 200px !important;
}
.w-220px {
  width: 220px !important;
}
.w-285px {
  width: 285px;
}
.h-100p img {
  height: 100%;
}
.h-100px {
  height: 100px;
}
.h-120px {
  height: 120px !important;
}
.h-161px {
  height: 161px;
}
.h-130px {
  height: 130px;
}
.h-0 {
  height: 0px;
}
.h-52px {
  height: 52px !important;
}
.h-91vh {
  height: 91vh;
}
.h-200px {
  height: 200px !important;
}
.h-260px {
  height: 260px !important;
}
.h-220px {
  height: 220px !important;
}
.h-290px {
  height: 290px !important;
}
.h-400px {
  height: 400px !important;
}
.h-420px {
  height: 420px !important;
}
.h-50 {
  height: 50%;
}
.h-60 {
  height: 60%;
}
.h-70 {
  height: 70%;
}
.h-500px {
  height: 500px !important;
}
.h-600px {
  height: 600px !important;
}
.h-650px {
  height: 650px !important;
}
.h-115px {
  height: 115px !important;
}
.h-450px {
  height: 450px !important;
}
.h-350px {
  height: 350px !important;
}
.h-345px {
  height: 345px !important;
}
.h-380px {
  height: 380px !important;
}
.h-315px {
  height: 315px !important;
}
.h-290px {
  height: 290px;
}
.h-412px {
  height: 450px !important;
}
.h-55px {
  height: 55px;
}
.h-65vh {
  height: 65vh;
}
.h-60vh {
  height: 60vh;
}
.h-325px {
  height: 325px !important;
}
.h-530px {
  height: 530px !important;
}
.h-45px {
  height: 45px;
}
.h-500px {
  height: 500px !important;
}
.h-535px {
  height: 535px !important;
}
.h-170px {
  height: 170px;
}
.h-80vh {
  height: 80vh;
}
.h-92p {
  height: 92%;
}
.h-77 {
  height: 77%;
}
.f-12px {
  font-size: 12px;
}
.f-13px {
  font-size: 13px;
}
.f-14px {
  font-size: 14px;
}
.f-16px {
  font-size: 16px !important;
}
.f-17px {
  font-size: 17px !important;
}
.f-18px {
  font-size: 18px;
}
.f-19px {
  font-size: 19px;
}
.f-28px {
  font-size: 28px !important;
}
.f-22px {
  font-size: 22px !important;
}
.f-20px {
  font-size: 20px !important;
}
.f-30px {
  font-size: 30px !important;
}
.f-35px {
  font-size: 35px !important;
}
.f-45px {
  font-size: 45px !important;
}
.height-auto {
  height: auto !important;
}
.height-160px {
  height: 160px !important;
}
.height-255px {
  height: 255px;
}
.height-485px {
  height: 485px;
}
.width-60px {
  width: 60px !important;
}
.m-auto {
  margin: auto;
}
.mt-13p {
  margin-top: 13%;
}
.mt-35p {
  margin-top: 35%;
}
.mt-1px {
  margin-top: 1px;
}
.mt--20px {
  margin-top: -20px;
}
.mt--35px {
  margin-top: -35px;
}
.mt--40px {
  margin-top: -40px;
}
.mt--45px {
  margin-top: -45px;
}
.mt-10px {
  margin-top: 10px;
}
.mt-90px {
  margin-top: 90px !important;
}
.mt-95px {
  margin-top: 95px;
}
.mt-85px {
  margin-top: 85px;
}
.mt--10px {
  margin-top: -10px !important;
}
.mt-105px {
  margin-top: 105px;
}
.mt-125px {
  margin-top: 125px;
}
.mt-11 {
  margin-top: 11rem;
}
.mt--14px {
  margin-top: -14px;
}
.mt--10px {
  margin-top: -10px;
}
.ml-32px {
  margin-left: 32px !important;
}
.mx-15px {
  margin: 0 15px !important;
}

.poppins {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
  font-style: normal;
}
.cancel-r-p {
  background: #d8775a;
  padding: 4px 10px;
  margin-top: 5px;
  border-radius: 5px;
  color: white;
  -webkit-transform: 0.2s;
  -moz-transform: 0.2s;
  -ms-transform: 0.2s;
  -o-transform: 0.2s;
  transform: 0.2s;
}
.cancel-r-p:hover {
  background: #ff5722;
  -webkit-box-shadow: 0 0 7px #ff5722;
  -moz-box-shadow: 0 0 7px #ff5722;
  box-shadow: 0 0 7px #ff5722;
  border: #d53300;
}
select:focus {
  outline: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:focus {
  outline: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.only_mob_tab {
  display: none;
}

/*---------------------------
    ==All Transition==
---------------------------*/

.track-btn,
.footer_widget ul li a,
.bcbd_navbar,
.blog_body a,
.btn.btn_tp1::before,
.btn.btn_tp1,
.single_bd_item {
  transition: all 0.4s linear;
  -webkit-transition: all 0.4s linear;
  -moz-transition: all 0.4s linear;
}

.single-dps-table,
.single-core-team,
.blog-navigation .navbar-nav li a,
.post_text a,
.blog_post,
.sl_dps_table .rt_table_btn.bordered,
.sl_tb_block,
.bcbd_btn i,
.bcbd_btn,
.sl img,
.single_radio_field,
.bcbd_lg_btn.t1,
.bcbd_lg_btn.t2,
.sl_tb_block a,
.single_social::after,
.single_social,
.single_pt_slitem,
.banner_tab ul li a,
.bcbd_single_blog,
.blog_meta a,
.blog_meta a h5,
.single_testimonial_slider,
.our_statistics .row div:after {
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
}

.sl_dps_table .lon-grid,
.user_guide_contaienr,
.blog_body a::after {
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

.single_el_ft_item {
  transition: all 150ms linear;
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
}

/*----------------------
    Typhography Css
----------------------*/

p {
  font-size: 15px;
}

h1 {
}

h2 {
}

h3 {
}

h4 {
}

h5 {
}

h6 {
}

/*---------------------------
    ==All Transition==
---------------------------*/
.bcbd_nav li a,
.bcbd_navbar,
.blog_body a,
.btn.btn_tp1::before,
.btn.btn_tp1,
.single_bd_item {
  transition: all 0.4s linear;
  -webkit-transition: all 0.4s linear;
  -moz-transition: all 0.4s linear;
}

.blog_post,
.sl_dps_table .rt_table_btn.bordered,
.sl_tb_block,
.bcbd_btn i,
.bcbd_btn,
.sl img,
.single_radio_field,
.bcbd_lg_btn.t1,
.bcbd_lg_btn.t2,
.sl_tb_block a,
.single_social::after,
.single_social,
.single_pt_slitem,
.banner_tab ul li a,
.bcbd_single_blog,
.blog_meta a,
.blog_meta a h5,
.single_testimonial_slider,
.our_statistics .row div:after {
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
}

.sl_dps_table .lon-grid,
.user_guide_contaienr,
.blog_body a::after {
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

.single_el_ft_item {
  transition: all 150ms linear;
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  width: 100%;
}

.eligibility_ft_slider::before {
  content: '';
  position: absolute;
  left: 17px;
  top: 9px;
  height: calc(100% - 40px);
  width: 5px;
  background: #ececec;
}

/*----------------------------
    ==Button css==
----------------------------*/
.btn {
  display: inline-block;
  position: relative;
  cursor: pointer !important;
}

.btn.btn_tp1 {
  font-size: 18px;
  font-weight: 700;
  border-radius: 30px;
  padding: 10px 40px;
  line-height: inherit;
  box-shadow: 0px 0px 1px rgba(195, 195, 195, 1);
  z-index: 5;
  border: none;
  border: 1px solid #8ccbf5;
}

.new-best-deal .btn.btn_tp1 {
  text-transform: uppercase;
}

.advertisement-slider .single_item:hover .btn_tp1,
.new-best-deal .btn.btn_tp1:hover {
  background: #f4ca1f;
  color: #434343;
  box-shadow: none !important;
}

.client_image_slider {
  padding-top: 20px;
}

.bg-0ab {
  background: #0ab9f2;
}

.bg-f4f4 {
  background: #f4f4f4;
}
.bg-43a873 {
  background: #00acea !important;
}
.col-00ACEA {
  color: #00acea !important;
}

.mb-15 {
  margin-bottom: 15px;
}
.mb-9px {
  margin-bottom: 9px;
}
.mb-4px {
  margin-bottom: 4px;
}
.mb-35 {
  margin-bottom: 35px;
}
.mb--10px {
  margin-bottom: -10px;
}
.mt--10px {
  margin-top: -10px;
}
.bg-f5f5 {
  background: #f5f5f5;
}

.text-5858 {
  color: #585858 !important;
}

.text-0ab {
  color: #0ab9f2;
}

.btn.btn_tp1::before {
  /*content: "";*/
  /*position: absolute;*/
  /*left: 0;*/
  /*top: 0;*/
  /*width: 100%;*/
  /*height: 100%;*/
  /*border-radius: 30px;*/
  /*background: #0ab6e5; !* Old browsers *!*/
  /*background: -moz-linear-gradient(left, #0ab6e5 1%, #1186d4 100%); !* FF3.6-15 *!*/
  /*background: -webkit-linear-gradient(left, #0ab6e5 1%, #1186d4 100%); !* Chrome10-25,Safari5.1-6 *!*/
  /*background: linear-gradient(to right, #0ab6e5 1%, #1186d4 100%); !* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ *!*/
  /*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0ab6e5', endColorstr='#1186d4', GradientType=1); !* IE6-9 *!*/
  /*z-index: -1;*/
  /*opacity: 0;*/
  /*visibility: visible;*/
  /*transform: scale(1.2);*/
}

.btn.btn_tp1:hover {
  color: #fff;
}

/*.btn.btn_tp1:hover::before {*/
/*visibility: visible;*/
/*opacity: 1;*/
/*transform: scale(1);*/
/*}*/

/*-------------------------------
    ==Shadow classes==
-------------------------------*/
.sam_hv_dp_5:hover {
}

/*--------------------------------
    ==Animation css==
--------------------------------*/
@keyframes leftright {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0px);
  }
}

.focusme {
  transition: all 150ms linear;
}

.focusme {
  transform: scale(1.05);
  z-index: 201;
  box-shadow: 0 2px 4px rgba(3, 27, 78, 0.06);
}

/*----------------------
    Spacing Css
----------------------*/
.ptb_60 {
  padding-top: 60px;
  padding-bottom: 60px;
}
.p {
  padding: 1px;
}
.pt_60 {
  padding-top: 60px;
}

.pb_60 {
  padding-bottom: 60px;
}

.mtb_60 {
  margin-top: 60px;
  margin-bottom: 60px;
}

.mt_60 {
  margin-top: 60px;
}

.mb_60 {
  margin-bottom: -60px;
}

/*-------------------------------
    Color css
-------------------------------*/
.bg_secondary {
  background: #f4f9fc;
}

.grad_bg1 {
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #ffffff 0%,
    #ebf3fa 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #ffffff 0%,
    #ebf3fa 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #ffffff 0%,
    #ebf3fa 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ebf3fa', GradientType=0); /* IE6-9 */
}

.grad_bg2 {
  /*    animation: gradbg 10s linear infinite;*/
  background: #0c65b7; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #0c65b7 0%,
    #25a8da 85%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #0c65b7 0%,
    #25a8da 85%
  ); /* Chrome10-25,Safari5.1-6 */
  background: #1b5f8d; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0c65b7', endColorstr='#25a8da', GradientType=1); /* IE6-9 */
}

.sl_tb_block.clr_1:hover {
  background: rgba(97, 190, 147, 0.1);
}

.clr_1 {
  background: rgba(97, 190, 147, 0.1);
}

/*.sl_tb_block.clr_2:hover{*/
/*background: rgba(66, 186, 252, .10);*/
/*}*/
.clr_2 {
  background: rgba(66, 186, 252, 0.1);
}

/*.sl_tb_block.clr_3:hover{*/
/*background: #fcf6eb;*/
/*}*/
.clr_3 {
  background: #fcf6eb;
}

/*.sl_tb_block.clr_4:hover{*/
/*background: #f5f2f6;*/
/*}*/
.clr_4 {
  background: #f5f2f6;
}

/*.sl_tb_block.clr_5:hover{*/
/*background: rgba(20, 177, 178, .10);*/
/*}*/
.clr_5 {
  background: rgba(20, 177, 178, 0.1);
}

/*.sl_tb_block.clr_6:hover{*/
/*background: #e0f4eb;*/
/*}*/
.clr_6 {
  background: #e0f4eb;
}

/*.sl_tb_block.clr_7:hover{*/
/*background: #f5f8e4;*/
/*}*/
.clr_7 {
  background: #f5f8e4;
}

/*.sl_tb_block.clr_7:hover{*/
/*background: #f2eee1;*/
/*}*/
.clr_8 {
  background: #f2eee1;
}

/*----------------------
    Common Css
----------------------*/
.sec_title {
  font-weight: 500;
  color: #3e3c3c;
  padding-bottom: 55px;
  font-size: 35px;
}

.slick-slide > div:focus,
.slick-slide {
  outline: none !important;
}

/*===================================================
    ==End Default Settings===
===================================================*/

/*===================================================
    ==Start Style css===
===================================================*/

/*----------------------------------
    ==Start Navigation css==
----------------------------------*/
.bcbd_navbar {
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0px;
  padding-bottom: 0px;
  z-index: 99;
}

.bcbd_nav {
  padding-left: 40px;
}

.bcbd_nav li:hover > a {
  color: #54c6ff;
}

.bcbd_nav li a {
  color: #585858;
  font-weight: 400;
  font-size: 15px;
  text-transform: capitalize;
  padding: 30px 13px !important;
  position: relative;
}

.navigation_right_area ul li .svg {
  height: 20px;
  fill: #008ecc;
  display: inline-block;
  padding-right: 7px;
}

.navigation_right_area ul li {
  display: inline-block;
  padding-right: 10px;
}

.navigation_right_area ul li:last-child {
  padding-right: 0;
}

.navigation_right_area ul li a {
  color: #fff;
  border-radius: 30px;
}

.bcbd_nav i {
  font-size: 24px;
}

.navigation_right_area ul li:first-child .svg {
  width: 30px;
}

.navigation_right_area ul li:nth-child(2) .svg {
  width: 30px;
}

.btn_type3 {
  background: #dcecf5;
  border-radius: 30px;
  color: #595959 !important;
  font-size: 16px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  z-index: 9;
  border: none !important;
}

.btn_type3::before {
  position: absolute;
  content: '';
  left: 50%;
  top: 0;
  width: 0%;
  height: 100%;
  border-radius: 30px;
  background: #0ab6e5; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #0ab6e5 1%,
    #1186d4 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #0ab6e5 1%,
    #1186d4 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #0ab6e5 1%,
    #1186d4 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0ab6e5', endColorstr='#1186d4', GradientType=1); /* IE6-9 */
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s linear;
}

.btn_type3:hover {
  color: #fff !important;
}

.btn_type3:hover::before {
  left: 0%;
  width: 100%;
  visibility: visible;
  opacity: 1;
}

.btn_type3 .svg {
  fill: #595959 !important;
}

.navigation_right_area .btn_tp1 {
  border: none;
  line-height: inherit;
  font-size: 16px;
  font-weight: 400;
  position: relative;
  letter-spacing: 0.8px;
  box-shadow: none;
  padding: 10px 28px;
  background: #008ecc;
  transition: all 0.05s linear;
}

.navigation_right_area .btn_tp1:hover {
  background: #0ab9f2;
}

/*.navigation_right_area .btn_tp1::before {*/
/*visibility: visible;*/
/*opacity: 1;*/
/*transform: scale(1);*/

/*}*/

.bcbd_navbar.sticky_nav {
  background: #fff;
  z-index: 99;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
  padding-left: 30px;
  padding-right: 30px;
}

.bcbd_navbar.sticky_nav .bcbd_nav li a {
  color: #595959;
  padding: 20px 10px !important;
}

.bcbd_navbar.sticky_nav .bcbd_nav .bcbd_dropdown li a {
  color: #595959;
  padding: 0px 10px !important;
}

.navbar-brand .sl {
  visibility: hidden;
  opacity: 0;
  display: none;
}

.bcbd_navbar.sticky_nav .navbar-brand .nl {
  visibility: hidden;
  opacity: 0;
  display: none;
}

.bcbd_navbar.sticky_nav .navbar-brand .sl {
  visibility: visible;
  opacity: 1;
  display: block;
}

.bcbd_navbar.sticky_nav .navigation_right_area ul li:first-child {
  visibility: hidden;
  opacity: 0;
}

.bcbd_navbar.sticky_nav .btn {
  animation: zoomIn 0.5s linear;
}

.bcbd_navbar.sticky_nav .sl img {
  transform: scale(0.8);
}

.subscribe-area {
  background: url('../images/subscribe-bg.png') no-repeat;
  background-size: inherit;
  background-position: 0 25px;
}

.banner-extra {
  background-size: cover;
  z-index: 0 !important;
  top: -11px;
  left: 18px;
}

.banner-extra .svg {
  height: 120%;
  width: 100%;
  opacity: 0.05;
}

.single-menu-portion a {
  color: #585858;
}

.single-menu-portion h6 {
  color: #4a4a4a;
  font-weight: 400;
}

/*---dropdown----*/
.hs_dropdown {
  position: relative;
}

.bcbd_dropdown {
  position: absolute;
  width: 181px;
  background: #fff;
  border-radius: 5px;
  padding: 10px 0px 10px 0px;
  z-index: 99 !important;
  transform-origin: 0 0 0;
  transition: all 0.3s linear;
  top: 100%;
  opacity: 0;
  visibility: hidden;
  display: none;
}

.bcbd_dropdown li a {
  color: #505050;
  line-height: 31px;
}

.bcbd_nav li:hover > ul {
  visibility: visible;
  opacity: 1;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  display: block;
}

.bcbd_navbar.sticky_nav .bcbd_nav li:hover ul {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.bcbd_dropdown li a {
  display: block;
  padding: 0px 12px !important;
  position: relative;
  transition: all 0.2s linear;
}

.bcbd_dropdown li a:hover {
  color: #1f95d0;
  background: #ecf9ff;
}

.bcbd_dropdown li a.active {
  color: #1f95d0;
  padding-left: 15px !important;
  background: #ecf9ff;
}

.bcbd_dropdown li a::after {
  content: '';
  position: absolute;
  left: -3px;
  top: 0;
  width: 3px;
  background: #1f95d0;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s linear;
}

.bcbd_dropdown li a:hover::after {
  visibility: visible;
  opacity: 1;
  left: 0px;
}

/*.eligibility_tab_content.housetcheck .sl_div:hover span {*/
/*color: #fff;*/
/*}*/

.eligibility_tab_content.housetcheck .eligibility_body .sl_div:hover span,
.eligibility_tab_content.housetcheck
  .eligibility_body
  .sl_div
  input[type='checkbox']:checked
  ~ span {
  color: #fff !important;
}

.hs_dropdown a::after {
  content: '\f107';
  font-family: FontAwesome;
  font-size: 13px;
  position: absolute;
  top: 51%;
  transform: translateY(-50%);
  right: 0px;
  height: inherit;
  transition: all 0.3s linear;
}

.bcbd_dropdown li a::after {
  display: none;
}

.bcbd_dropdown li a:hover::before {
  visibility: visible;
  opacity: 1;
}

.bcbd_dropdown li a::before {
  content: '';
  position: absolute;
  left: 0px;
  top: 0;
  width: 2px;
  background: #48c9ff;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s linear;
}

/*
.bcbd_dropdown li a::after{
    display: none;
}
*/

.eligibility-label {
  color: #696969;
  font-size: 15px;
  display: flex;
  align-items: center;
  height: 100%;
  align-items: start;
  justify-content: start;
  flex-flow: column;
  font-weight: 500;
}

.eligibility-type-2 .eligibility-label {
  line-height: 48px !important;
}

.bcbd_nested_dropdown {
  position: absolute;
}

.css-1hwfws3 {
  color: #696969;
}

.eligibility-footer .btn + .btn::before {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.eligibility-footer .btn + .btn {
  color: #ffffff;
  border-color: #0ab9f2 !important;
  color: #fff !important;
}

.bcbd_dropdown li .bcbd_dropdown {
  left: 100%;
  top: 0;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 5px !important;
}

.hs_dropdown a {
  position: relative;
}

/*
.bcbd_dropdown .hs_dropdown a::after {
    content: "\f107";
    font-family: FontAwesome;
    font-size: 13px;
    position: absolute;
    top: 51%;
    transform: translateY(-50%);
    right: 0px;
    height: inherit;
    transition: all .30s linear;
}
*/
.bcbd_dropdown .hs_dropdown a {
  position: relative;
}

.bcbd_dropdown .hs_dropdown > a::after {
  content: '\f105';
  font-family: FontAwesome;
  font-size: 14px;
  position: absolute;
  right: 8px;
  top: 15px;
  visibility: visible;
  opacity: 1;
  display: block;
  left: inherit;
  background: transparent;
  transition: all 0.3s linear;
}

.bcbd_dropdown .hs_dropdown > a:hover::after {
  visibility: visible;
  opacity: 1;
  left: inherit;
  right: 8px;
}

.bcbd_megamenu .bcbd_dropdown {
  display: block;
  visibility: visible;
  opacity: 1;
  position: relative;
  transform: none;
  float: left;
  border-radius: 0px;
  box-shadow: none !important;
}

.bcbd_megamenu {
  position: absolute;
  width: 363px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  top: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s linear;
  transform-origin: top;
  display: none;
}

.bcbd_nav li:hover > .bcbd_megamenu {
  transform: perspective(500px) rotateX(0deg);
  visibility: visible;
  opacity: 1;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  display: block;
}

.bcbd_megamenu .bcbd_dropdown:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.bcbd_megamenu .bcbd_dropdown:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

/*-----Burger Menu-----*/

.burger_menu {
  position: relative;
  margin-left: auto;
  width: 25px;
  height: 51px;
  background: linear-gradient(to right, #fff 0%, #fff 100%);
  background-size: 50px 2px;
  background-position: center center;
  background-repeat: no-repeat;
  transition: 0.2s background;
  border: none;
  font-size: 0;
  padding: 0;
  outline: none;
  cursor: pointer;
  z-index: 99;
  display: none;
}

.burger_menu:before,
.burger_menu:after {
  content: '';
  position: absolute;
  top: 33px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #fff;
  transition: 0.2s transform;
  transform-origin: 8px 7px;
}

.coApplicant {
  line-height: 30px !important;
}

.burger_menu:after {
  top: 43px;
  transform-origin: 7px -6px;
}

.burger_menu.is-open {
  background-size: 0px 2px;
}

.burger_menu.is-open:before {
  transform: rotate(45deg);
}

.burger_menu.is-open:after {
  transform: rotate(-45deg);
}

.nav_outer .navbar-brand {
  display: none;
}

.lg_cmn {
  display: none;
}

/*-------------------------------
    ==Start MonthlyBenifit css==
-------------------------------*/
.banner_area {
  /*

    background: linear-gradient(270deg, #0d65b8, #26a5d8);
    background-size: 400% 400%;

    -webkit-animation: AnimationName 11s ease infinite;
    animation: AnimationName 11s ease infinite;
    */
}

/*
.cd-headline.clip .cd-words-wrapper{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
*/
.banner_inner_area {
  height: 100vh;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
}

.banner {
  position: relative;
}

/* .banner::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: url('../images/Pattern.png');
    width: 100%;
    height: 100%;
    opacity: .60;
} */

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.banner_content {
  display: block;
  width: 100%;
}

.banner_content h1 {
  text-align: center;
  color: #fff;
  font-size: 57px;
  font-weight: 300;
}

.banner_content h1 .cd-words-wrapper {
  font-weight: 700;
  color: #ffe670;
}

.banner_content h1 .cd-words-wrapper b {
  font-weight: 700;
}

.banner_tab {
  width: 100%;
  margin-top: 60px;
}

.banner_tab ul li {
  float: left;
  width: calc(16.666% - 1.8px);
  margin-right: 2px;
  cursor: pointer;
}

.banner_tab ul li {
  padding: 0;
  background: transparent;
}

.banner_tab ul li,
.banner_tab ul li a {
  padding: 0;
  text-align: center;
  line-height: inherit;
  padding: 14px 0px;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  background: #2ca9e7;
  position: relative;
  z-index: 10;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.banner_content .tab-content {
  background: #fff;
}

.ccol-6 > div {
  width: 16.66%;
  flex: 0 0 16.66%;
}

.ccol-5 > li,
.ccol-5 > div {
  width: 20%;
  flex: 0 0 20%;
}

.ccol-4 > li,
.ccol-4 > div {
  width: 25%;
  flex: 0 0 25%;
}

.ccol-3 > li,
.ccol-3 > div {
  width: 33.33%;
  flex: 0 0 33.33%;
}

.ccol-2 > div {
  width: 50%;
  flex: 0 0 50%;
}

.ccol-1 > div {
  width: 100%;
  flex: 0 0 100%;
}

.sl_tb_block:hover a {
  animation: jello 1s linear;
}

.sl_tb_block {
  float: left;
  position: relative;
}

.sl_tb_block::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
}

.sl_tb_block:last-child::after {
  display: none;
}

.tab_inner {
  background: #fff;
  overflow: hidden;
  display: flex;
}

/*
.tab_inner  .sl_tb_block{
    transition: 1s linear;
}
.sl_tb_block:first-child{
    animation-delay: .10s;
    -webkit-animation-delay: .10s;
}
.sl_tb_block:nth-child(2){
    animation-delay: .20s;
    -webkit-animation-delay: .20s;
}
.sl_tb_block:nth-child(3){
    animation-delay: .30s;
    -webkit-animation-delay: .30s;
}
.sl_tb_block:nth-child(4){
    animation-delay: .40s;
    -webkit-animation-delay: .40s;
}
.sl_tb_block:nth-child(5){
    animation-delay: .50s;
    -webkit-animation-delay: .50s;
}
.sl_tb_block:nth-child(6){
    animation-delay: .60s;
    -webkit-animation-delay: .60s;
}
.sl_tb_block:nth-child(7){
    animation-delay: .70s;
    -webkit-animation-delay: .70s;
}
.sl_tb_block:nth-child(8){
    animation-delay: .80s;
    -webkit-animation-delay: .80s;
}
*/

.sl_tb_block {
  visibility: visible;
  opacity: 1;
  animation: zoomIn 0.5s linear;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 15px;
}

.sl_tb_block a:hover {
  color: #1984d4;
}

.sl_tb_block a img {
  margin-bottom: 18px;
  object-fit: contain;
}

.react-tabs__tab.react-tabs__tab--selected {
  background: #fff;
  color: #1984d4;
}

.banner_tab ul li:last-child {
  margin-right: 0;
}

.sl_tb_block a {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.sl_tb_block a img {
  margin-bottom: 0;
}

.sl_tb_block::after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  width: 1px;
  background: #d1e5f1;
  height: 85px;
  transform: translateY(-50%);
}

.bcbd_ac_title.collapsed {
  border-bottom-left-radius: 5px !important;
}

.bcbd_ac_title {
  border-bottom-left-radius: 0 !important;
}

.bcbd_ac_title:not(.collapsed) {
  background: #2ca9e7 !important;
}

.bcbd_ac_title {
  border: none !important;
  padding: 13px 20px;
  background: #fff !important;
  outline: none;
  -webkit-appearance: none !important;
  cursor: pointer;
  color: #fff;
  font-size: 15px;
  border-radius: 5px;
}

.bcbd_acd .card {
  border: none !important;
  background: transparent;
  margin-bottom: 4px;
  border-radius: 5px;
}

.bn_accordion_inner {
  font-size: 14px;
  padding: 8px 10px;
}

.bcbd_acd .card div {
  background: #fff;
}

.bcbd_ac_title.collapsed {
  background: #fff !important;
  color: #6d6d6d;
  font-weight: 400;
  text-transform: capitalize;
  position: relative;
}

.bcbd_ac_title.collapsed::after {
  content: '\f107';
  font-family: FontAwesome;
  position: absolute;
  top: 18px;
  right: 14px;
  font-size: 19px;
}

.bcbd_ac_title::after {
  content: '\f106';
  font-family: FontAwesome;
  position: absolute;
  top: 18px;
  right: 14px;
  font-size: 19px;
}

.bcbd_acd .card div {
  background: #fff;
}

/*------------------------------
    ==Start Best Deals==
------------------------------*/
.best_deal_area {
  /*background:url(../images/best-deals/best-deals-bg.jpg) no-repeat;*/
  /*background-size: cover;*/
  background: #f9f9f9;
}

.sl_bd_img img {
  max-width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.sl_bd_body h6 {
  font-weight: 700;
  color: #3e3c3c;
  font-size: 16px;
  text-align: left;
}

.sl_bd_body ul li {
  float: none;
  font-weight: 400;
  color: #595959;
  line-height: 29px;
}

.sl_bd_body {
  padding: 0 0px;
  text-align: center;
  padding-top: 0px;
  padding-bottom: 0px;
}

.sl_bd_body ul li i {
  display: inline-block;
}

.sl_bd_body ul li span {
  display: inline-block;
  width: inherit;
  vertical-align: top;
}

.sl_bd_body ul {
  text-align: left;
  padding-bottom: 20px;
  padding-top: 5px;
}

.sl_bd_logo img {
  height: 43px;
}

.new-best-deal .sl_bd_logo {
  padding: 5px 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid #ebebeb;
}

.sl_bd_logo {
  height: 70px;
}

.single_bd_item {
  box-shadow: 0px 0px 0px 1px rgba(231, 231, 231, 1);
  border-radius: 5px;
  background: #fff;
}

.best_deal_slider {
  margin-left: -15px;
  margin-right: -15px;
}

.best_deal_slider {
  position: relative;
}

.best_deal_slider .slick-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -30px;
}

.best_deal_slider .slick-arrow {
  background: transparent;
  border: none;
  cursor: pointer;
}

.best_deal_slider .slick-arrow i {
  color: #fff;
  font-size: 40px;
  text-shadow: 0px 0px 10px rgba(17, 134, 212, 0.5);
}

.best_deal_slider .slick-arrow i:hover {
  color: #207ac0;
  animation: jello 1s linear;
  opacity: 1;
}

.best_deal_slider .slick-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -30px;
}

.best_deal_slider .slick-track {
  padding: 5px 0;
}

/*
.single_bd_item:hover {
    box-shadow: 0px 0px 0px 1px rgba(32,122,192,.70);
}
*/
.single_bd_item:hover {
  box-shadow: 0px 6px 9px 0px rgba(32, 122, 192, 0.2);
  transform: translateY(-7px);
}

/*---------------------------------
    ==Start Statistics==
---------------------------------*/
.our_statistics {
  padding-top: 25px;
  padding-bottom: 25px;
}

.single_statistics {
  position: relative;
}

.our_statistics .row div:before {
  content: '';
  height: 100%;
  width: 1px;
  background: #ddd;
  position: absolute;
  top: 0;
  right: 0;
}

.our_statistics .row div:after {
  content: '';
  height: 0%;
  width: 1px;
  background: #0bb7e5;
  position: absolute;
  top: 0;
  right: 0;
}

.our_statistics .row div:hover:after {
  height: 100%;
}

.our_statistics .row div {
  position: relative;
}

.our_statistics .row > div:last-child:after,
.our_statistics .row > div:last-child:before {
  display: none;
}

.single_statistics {
  font-size: 19px;
  font-weight: 900;
  color: #1c95e6;
}

.single_statistics > div:after,
.single_statistics:after,
.single_statistics > div:before,
.single_statistics::before {
  display: none;
}

.single_statistics p {
  font-weight: 400;
  margin: 0;
}

.our_statistics .row > div:not(:first-child) > div {
  padding-left: 20px;
}

.div_styler {
  height: 5px;
}

.div_styler span {
  height: 100%;
  width: 33.33%;
  float: left;
  display: block;
  position: relative;
}

.div_styler span:first-child {
  background: #2dcaf4;
}

.div_styler span:nth-child(2) {
  background: #0bb7e5;
}

.div_styler span:nth-child(3) {
  background: #1186d4;
}

.single_statistics h5 {
  font-size: 20px;
  font-weight: 400;
}

.our_statistics .row > div:first-child {
  padding-top: 15px;
}

/*------------------------------------
    ==Start Testimonial Css==
------------------------------------*/
.single_testimonial_slider {
  background: #fff;
  box-shadow: 0px 0px 0px 1px rgba(231, 231, 231, 1);
  border-radius: 5px;
  padding: 26px;
}

.single_testimonial_slider p {
  color: #595959;
  font-size: 15px;
  margin: 0;
  font-style: italic;
  line-height: 28px;
  font-weight: 400;
}

.single_testimonial_slider p i {
  font-size: 35px;
  color: #b3cbe1;
  padding-right: 7px;
}

.sl_tm_slider_footer img {
  display: inline-block;
}

.sl_tm_slider_footer .testimonial_slider_designation {
  display: inline-block;
  padding-left: 16px;
}

.sl_tm_slider_footer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 16px;
}

.testimonial_slider_designation h6 {
  color: #1186d4;
  font-weight: 700;
}

.testimonial_slider_designation {
  font-weight: 400;
  color: #595959;
}

.testimonial_slider {
  margin-left: -15px;
  margin-right: -15px;
}

.testimonial_slider {
  position: relative;
}

.testimonial_slider .slick-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -30px;
}

.testimonial_slider .slick-arrow {
  background: transparent;
  border: none;
  cursor: pointer;
}

.testimonial_slider .slick-arrow i {
  color: #fff;
  font-size: 40px;
  text-shadow: 0px 0px 10px rgba(17, 134, 212, 0.5);
}

.testimonial_slider .slick-arrow i:hover {
  color: #207ac0;
  animation: jello 1s linear;
  opacity: 1;
}

.testimonial_slider .slick-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -30px;
}

.testimonial_slider .slick-track {
  padding: 5px 0;
}

.single_testimonial_slider:hover {
  box-shadow: 0px 3px 6px rgba(231, 231, 231, 1) !important;
}

.testimonial-quote {
  top: -44px;
  z-index: 0;
}

/*----------------------------
    ==Testimonial Slider==
----------------------------*/
.testimonial-slider .slick-track {
  display: flex !important;
}

.testimonial-slider .slick-track .slick-slide {
  height: initial !important;
}

.testimonial-slider .slick-track .slick-slide > div,
.testimonial-slider .slick-track .slick-slide .single_testimonial_slider {
  height: 100%;
}

.single_testimonial_slider {
  display: inline-flex !important;
  align-items: flex-start;
  justify-content: space-between;
  flex-flow: column;
}

/*-------------------------------------
    ==Eligibility Features==
-------------------------------------*/
.features-carousel .slick-slide {
  padding: 10px 0px;
}

.single_el_ft_item {
  position: relative;
  margin-left: 40px;
  padding: 15px 30px 15px 10px;
  margin-bottom: 0px;
  border-radius: 5px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.single_el_ft_item:focus {
  outline: none;
}

.single_el_ft_item h6 {
  font-weight: 700;
  color: #595959;
  font-size: 15px;
  padding-bottom: 5px;
  cursor: pointer;
}

.single_el_ft_item p {
  color: #595959;
}

.single_el_ft_item {
}

.single_el_ft_disc {
  height: 25px;
  width: 25px;
  background: #fff;
  border-radius: 50%;
  left: -34px;
  position: absolute;
  top: 9px;
  -webkit-box-shadow: 3px 2px 10px rgba(217, 217, 217, 1);
  box-shadow: 3px 2px 10px rgba(217, 217, 217, 1);
  -webkit-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  transition: all 150ms linear;
}

.terms-content.react-tabs__tab-panel--selected img {
  animation: fadeInUp 0.4s linear;
}

/*.features-carousel .slick-list{*/
/*height: 490px !important;*/
/*}*/

/*
.single_el_ft_item:hover .single_el_ft_disc {
    left: -26px;
}
*/
.eligibility_ft_slider {
  position: relative;
}

.submission-con {
  color: #fff;
  margin-top: 0 !important;
  font-size: 13px;
}

.submission-con .bcbd_check {
  margin-right: 3px;
}

.submission-con .my-label {
  border-color: #4f4f4f;
}

.my-select__menu {
  z-index: 999 !important;
}

.features-carousel {
  position: relative;
}

.features-carousel::before {
  content: '';
  position: absolute;
  left: 19px;
  top: 22px;
  height: calc(100% - 45px);
  width: 5px;
  background: #ececec;
}

.eligibility_ft_slider .slick-track {
  padding: 5px 0;
}

.single_el_ft_item.slick-active {
  box-shadow: 0px 0px 6px rgba(215, 215, 215, 1);
}

.bounceInUp {
}

.single_el_ft_item.slick-active h6 {
  color: #1c95e6;
}

.single_el_ft_item.slick-active .single_el_ft_disc {
  background: #1c95e6;
}

.single_el_ft_item.slick-active {
  animation: fadeIn 1s linear !important;
}

/*
.single_el_ft_item:hover {
    box-shadow: 0 2px 4px rgba(3,27,78,.06);
    border: 1px solid rgba(3,27,78,.1);
    transform: scale(1.05);
}
*/

.switch {
  position: relative;
  display: inline-block;
  height: 42px;
  background: #f2f2f2;
  border-radius: 2px;
  margin-bottom: 0;
}

.slider input {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.no {
  /*
        background: linear-gradient(to right, #0ab6e5  1%,#1186d4 100%);
        font-size: 14px;
        color: #fff;
        box-shadow: -4px 0px 2px rgba(0,0,0,.10);
    */
}

.slider {
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  display: inline-block;
  float: left;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
}

.slider span {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider.no {
  border-right: 1px solid #5da0d3;
}

.switch input[type='checkbox']:checked ~ .no {
  background: #f2f2f2;
  color: #555;
}

.yes {
  background: #f2f2f2;
  color: #555;
}

.switch input[type='radio']:checked ~ span {
  background: #0ab6e5;
  color: #fff;
}

/*------------------------------------
    ==Start Investors==
------------------------------------*/
.partners_inner_area {
  position: relative;
}

.partners_inner_area::before {
  content: '';
  position: absolute;
  left: 0;
  width: calc(100% - 1170px);
  background: #1b99e7;
  height: 100%;
}

.our_partners_title {
  height: 100%;
  background: #1b99e7;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.our_partners_title h3 {
  color: #fff;
  font-weight: 400;
}

.our_partners_title::after {
  content: '';
  right: -32px;
  border-left: 32px solid #1a9be8;
  position: absolute;
  border-top: 44px solid transparent;
  border-bottom: 42px solid transparent;
  top: 0;
}

.our_partners_slider {
  padding-left: 50px;
}

.partners_inner_area {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.single_pt_slitem {
  text-align: center;
}

.single_pt_slitem {
  transition: all 0.1s linear;
}

/*---------------------------------------
    ==BLog Area==
---------------------------------------*/
.bcbd_single_blog {
  background: #fff;
  height: 100%;
}

.blog_image a img {
  width: 100%;
  border-radius: 0;
}

.blog_meta ul li {
  display: inline-block;
  padding-right: 7px;
}

.blog_meta a h5 {
  font-size: 18px;
  color: #595959;
  font-weight: 500;
}

.blog_meta ul li a {
  color: #c2c2c2;
  font-size: 14px;
  font-weight: 400;
}

.bcbd_single_blog .blog_body {
  padding: 6px 15px 27px 20px;
}

.bcbd_single_blog .blog_body p {
  color: #595959;
}

.blog_meta {
  padding-top: 0px;
  padding-left: 20px;
}

.blog_body a {
  color: #0ab9f2;
  font-weight: 600;
  font-size: 15px;
  display: inline-block;
  padding-top: 12px;
  letter-spacing: 0.1px;
  padding-right: 0px;
  position: relative;
}

.bcbd_single_blog {
  box-shadow: 0px 0px 0px 1px rgba(231, 231, 231, 1);
}

.bcbd_blog_slider .slick-track {
  padding: 5px 0;
  display: flex !important;
}

.bcbd_blog_slider .slick-track .slick-slide {
  height: initial !important;
}

.bcbd_blog_slider .slick-track .slick-slide > div {
  height: 100%;
}

.blog_image {
  height: 180px;
  padding: 10px;
}

.blog_image img {
  height: 100%;
}

.blog_body a:hover::after {
  width: 100%;
}

.blog_meta a:hover h5,
.blog_meta a:hover {
  color: #0ab9f2;
}

.bcbd_blog_slider {
  position: relative;
}

.bcbd_blog_slider .slick-prev {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: -53px;
}

.bcbd_blog_slider .slick-arrow {
  background: transparent;
  border: none;
  cursor: pointer;
}

.bcbd_blog_slider .slick-arrow i {
  color: #fff;
  font-size: 40px;
  text-shadow: 0px 0px 10px rgba(17, 134, 212, 0.5);
}

.bcbd_blog_slider .slick-arrow i:hover {
  color: #207ac0;
  animation: jello 1s linear;
  opacity: 1;
}

.bcbd_blog_slider .slick-next {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -54px;
}

.bcbd_blog_slider .slick-arrow {
  height: 51px !important;
  width: 51px !important;
  background: #ffffff !important;
  border-radius: 50% !important;
  box-shadow: 0 0 5px #ebebeb;
}

.bcbd_blog_slider .slick-arrow::before {
  color: #c3bfbf !important;
  text-shadow: none !important;
  box-shadow: none !important;
}

.bcbd_single_blog .blog_body a:hover {
  letter-spacing: 0.7px;
}

/*.bcbd_single_blog:hover {
    box-shadow: 0px 6px 9px 0px rgba(32,122,192,.20);
    transform: translateY(-7px);
    background: rgba(255,255,255,.70);
}*/
.bcbd_single_blog:hover {
  box-shadow: 0px 5px 10px 1px rgba(231, 231, 231, 1);
  transform: translateY(-3px);
}

/*-----------------------------
    ==Button Hover Style==
-----------------------------*/
.hover-style1:hover i {
  transform: translateX(10px);
}

.hover-style1 i {
  transition: all 0.3s linear;
  display: inline-block;
}

.hover-style1:hover {
  letter-spacing: 0.5px;
}

.hover-style1 {
  transition: all 0.3s linear;
}

/*----------------------------------
    ==Footer social==
----------------------------------*/
/*footer {*/
/*background: url(../images/footer.jpg) no-repeat;*/
/*background-size: cover;*/
/*}*/

.footer_social {
  display: flex;
  align-items: center;
}

.single_social {
  float: left;
  width: 25%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
}

.footer_middle {
  background: #1c95e6;
}

.single_social i {
  color: #fff;
  font-size: 27px;
}

.single_social span {
  display: inline-block;
  padding-left: 7px;
  font-size: 15px;
  color: #fff;
}

.single_social a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.single_social:last-child:after {
  opacity: 0;
}

.single_social:hover::after {
  visibility: hidden;
}

/*widget*/
.footer_widget ul li .svg {
  height: 19px;
  fill: #fff;
  display: inline-block;
  padding-right: 7px;
  vertical-align: top;
  margin-top: 4px;
}

/*
eligibilityfooter.footer_area {
    background:  #2891d8;
}
*/

.footer_widget ul li a {
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  color: #757575;
}

.footer_widget ul li {
  float: none;
}

.footer_widget ul li a:hover {
  color: #0ab9f2;
}

.footer_widget ul li a span,
.footer_widget ul li p span {
  display: inline-block;
  width: calc(100% - 40px);
}

.sign-in {
  position: relative;
  z-index: 9;
}

.footer_widget h6 {
  color: #fff;
  text-transform: uppercase;
  font-size: 17px;
  padding-bottom: 16px;
  font-weight: 400 !important;
}

.customer-features p {
  font-size: 17px;
  font-family: 'Exo 2', sans-serif;
  line-height: 30px;
}

.footer_widget ul li .svg {
  height: 19px;
  fill: #3e3c3c;
  display: inline-block;
  padding-right: 7px;
  vertical-align: top;
  margin-top: 4px;
}

.footer_top {
  padding-top: 60px;
  padding-bottom: 50px;
}

.footer_widget.ft_info ul li {
  padding-bottom: 9px;
}

.footer_bottom p {
  padding-top: 15px;
  padding-bottom: 15px;
  color: #fff;
}

.footer_bottom p a {
  color: #fff;
}

.single_social:first-child {
  text-align: left;
  justify-content: flex-start;
}

/*eligibilityfooter type2 css*/
footer.footer_type2 {
  background: #f5f5f5;
  padding: 10px 0px;
}

.footer_left {
  height: 100%;
  display: flex;
  align-items: center;
}

.footer_left a {
  color: #1a8acd;
}

.footer_left a + a {
  padding-left: 15px;
  margin-left: 15px;
  position: relative;
}

.footer_left a + a::before {
  content: '';
  position: absolute;
  left: 0;
  height: 100%;
  width: 1px;
  background: #1a8acd;
}

.footer_right {
  text-align: right;
}

.footer_right h6 {
  color: #626262;
  font-size: 14px;
}

/*--------------------------------------
    ==Call to action==
--------------------------------------*/
.call_to_inner {
  /* background: url(assets/images/mail-icon.png) no-repeat; */
  background-size: cover;
  padding-top: 40px;
  padding-bottom: 40px;
}

.call_to_field input {
  line-height: 47px;
  width: calc(100% - 200px);
  display: inline-block;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  border: 2px solid #d7d7d7;
  padding-left: 20px;
  font-weight: 600;
  color: #aaaaaa;
  font-size: 15px;
  vertical-align: top;
}

.clt_btn {
  background: #20a2f9;
  line-height: 51px;
  padding-left: 35px;
  padding-right: 35px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border: none;
  display: inline-block;
  margin-left: -4px;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  vertical-align: top;
}

.clt_btn i {
  display: inline-block;
  padding-left: 5px;
  animation: leftright 1s linear infinite;
}

.call_to_inner h2 {
  font-weight: 500;
  color: #2791d7;
  font-size: 31px;
  padding-top: 12px;
  text-align: right;
  padding-right: 50px;
}

img.callto_image {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 60px;
}

.call_to_field {
  padding-top: 8px;
}

/*------------------------------
    ==Scroll Top==
------------------------------*/
.scroll_top {
  position: fixed;
  right: 30px;
  bottom: 65px;
  background: #0ab9f2;
  height: 65px;
  width: 65px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  box-shadow: 0 1px 4px #00233ecf;
  border-radius: 50%;
  cursor: pointer;
  z-index: 5000;
  color: white;
}

.scroll_top i {
  font-size: 25px;
}

.scroll_top.active {
  animation: jello 1s linear;
}

/*---------------------------------------
    ==Eligibility ==
---------------------------------------*/
/*.eligible_top {*/
/*overflow: hidden;*/
/*}*/

#proprietorship_container,
div#cardNumberContainer {
  width: 100%;
}

.eligibility_area {
  /*    animation: gradbg 10s linear infinite;*/
  background: #0c65b7; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #0c65b7 0%,
    #25a8da 85%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #0c65b7 0%,
    #25a8da 85%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #0c65b7 0%,
    #25a8da 85%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0c65b7', endColorstr='#25a8da', GradientType=1); /* IE6-9 */
}

@keyframes gradbg {
  0% {
    background: #0c65b7; /* Old browsers */
    background: -moz-linear-gradient(
      left,
      #0c65b7 0%,
      #25a8da 85%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      left,
      #0c65b7 0%,
      #25a8da 85%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to right,
      #0c65b7 0%,
      #25a8da 85%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0c65b7', endColorstr='#25a8da', GradientType=1); /* IE6-9 */
  }
  50% {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#25a8da+15,0c65b7+100 */
    background: #25a8da; /* Old browsers */
    background: -moz-linear-gradient(
      left,
      #25a8da 15%,
      #0c65b7 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      left,
      #25a8da 15%,
      #0c65b7 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to right,
      #25a8da 15%,
      #0c65b7 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#25a8da', endColorstr='#0c65b7', GradientType=1); /* IE6-9 */
  }
  100% {
    background: #0c65b7; /* Old browsers */
    background: -moz-linear-gradient(
      left,
      #0c65b7 0%,
      #25a8da 85%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      left,
      #0c65b7 0%,
      #25a8da 85%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to right,
      #0c65b7 0%,
      #25a8da 85%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0c65b7', endColorstr='#25a8da', GradientType=1); /* IE6-9 */
  }
}

.eligiblity_inner h2 {
  color: #fff;
  font-weight: 300;
  font-size: 40px;
  margin-bottom: 30px;
}

.eligiblity_inner h2 span {
  font-weight: 700;
}

.eligibility_wraper {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  padding: 25px 15px 32px 10px;
}

.eligible_top {
  padding: 0px 15px 27px 15px;
}

.eligible_top .sl_div {
  float: left;
  width: 50%;
  padding-left: 15px;
  padding-right: 15px;
}

.block_label {
  display: block;
}

.sl_div .bcbd_label {
  font-size: 18px;
  color: #ffffff;
  padding-bottom: 5px;
}

.eligiblity_inner {
  height: calc(100vh - 76px);
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding-bottom: 5px;
}

.eligible_bottom {
  /*overflow: hidden;*/
  padding: 0 15px;
}

.eligible_bottom .sl_div {
  width: 50%;
  float: left;
  padding: 0 15px;
}

.bcbd_lg_btn {
  display: block;
  width: 100%;
  text-align: center;
  padding: 13px 0;
  border-radius: 5px;
  color: #fff !important;
  font-weight: 400 !important;
  font-size: 19px;
  position: relative;
}

.bcbd_lg_btn.t1 {
  background: #e98612; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #e98612 0%,
    #f3af15 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #e98612 0%,
    #f3af15 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #e98612 0%,
    #f3af15 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e98612', endColorstr='#f3af15', GradientType=1); /* IE6-9 */
}

.bcbd_lg_btn.t1:hover {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e98612+0,f3af15+100 */
  background: #e98612; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #e98612 0%,
    #f3af15 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #e98612 0%,
    #f3af15 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #e98612 0%,
    #f3af15 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e98612', endColorstr='#f3af15', GradientType=0); /* IE6-9 */
}

.bcbd_lg_btn.t2 {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2b8bd9+0,1372c0+100 */
  background: #2b8bd9; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #2b8bd9 0%,
    #1372c0 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #2b8bd9 0%,
    #1372c0 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #2b8bd9 0%,
    #1372c0 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2b8bd9', endColorstr='#1372c0', GradientType=1); /* IE6-9 */
}

.bcbd_lg_btn.t2:hover {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2b8bd9+0,1372c0+100 */
  background: #2b8bd9; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #2b8bd9 0%,
    #1372c0 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #2b8bd9 0%,
    #1372c0 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #2b8bd9 0%,
    #1372c0 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2b8bd9', endColorstr='#1372c0', GradientType=0); /* IE6-9 */
}

.single_radio_field {
  float: left;
  width: calc(33.33% - 10px);
  background: #fff !important;
  margin-right: 15px;
  border-radius: 3px;
  height: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.single_field {
  float: left;
  width: calc(33.33% - 10px);
  background: #fff !important;
  border-radius: 3px;
  height: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.radio-container {
  font-weight: 400;
  color: #555;
  margin-bottom: 0;
  line-height: 50px;
  font-size: 15px;
  cursor: pointer;

  display: block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  height: 100%;
  padding-left: 35px;
  transition: all 0.2s ease;
}

.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-checkmark {
  position: absolute;
  top: 13px;
  left: 5px;
  height: 25px;
  width: 25px;
  border: 2px solid #00bdf2;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.radio-checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
.radio-container input:checked ~ .radio-checkmark:after {
  display: block;
  animation: bounceIn 1s linear;
}
.radio-container .radio-checkmark:after {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #00bdf2;
}
.radio-container:hover input ~ .radio-checkmark {
  background: rgba(255, 255, 255, 0.8);
}
.radio-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.single_radio_field:hover {
  background: rgba(255, 255, 255, 0.8);
}

.single_radio_field:last-child {
  margin-right: 0;
}

.eligiblity_inner h2 span {
  font-weight: 600;
}

.single_radio_field input {
  -webkit-appearance: radio;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.single_radio_field .check {
  height: 25px;
  width: 25px;
  border: 2px solid #00bdf2;
  border-radius: 50%;
  position: absolute;
  top: 24% !important;
  left: 7% !important;
}

.single_radio_field .check::before {
  content: '';
  position: absolute;
  left: 25%;
  height: 10px;
  width: 10px;
  background: #00bdf2;
  border-radius: 50%;
  top: 28%;
  /*transform: translate(-50%, -50%) scale(2);*/
  visibility: hidden;
  opacity: 0;
  transition: 0.3s linear;
}

.single_radio_field label {
  font-weight: 400;
  color: #555;
  margin-bottom: 0;
  line-height: 50px;
  font-size: 15px;
  cursor: pointer;
}

/*.single_radio_field input[type=radio]:checked ~ .check::before {*/
/*visibility: visible;*/
/*opacity: 1;*/

/*}*/

/*.single_radio_field input[type="radio"]:checked, single_radio_field input[type="radio"]:not(:checked) {*/
/*position: absolute;*/
/*left: -9999px;*/
/*}*/
/*.single_radio_field input[type="radio"]:checked + label,*/
/*input[type="radio"]:not(:checked) + label{*/
/*position: relative;*/
/*cursor: pointer;*/
/*line-height: 20px;*/
/*display: flex ;*/
/*justify-content: center;*/
/*align-items: center;*/
/*width: 100%;*/
/*height: 100%;*/
/*}*/

/*.topUp input[type="radio"]:checked + label:before,*/
/*.topUp input[type="radio"]:not(:checked) + label:before {*/
/*content: '';*/
/*position: absolute;*/
/*left: 12%;*/
/*top: 26%;*/
/*width: 25px;*/
/*height: 25px;*/
/*border:2px solid #00bdf2;*/
/*border-radius: 100%;*/
/*background: #fff;*/
/*}*/
/*.single_radio_field input[type="radio"]:checked + label:after,*/
/*.single_radio_field input[type="radio"]:not(:checked) + label:after {*/
/*content: '';*/
/*width: 10px;*/
/*height: 10px;*/
/*background: #00bdf2;*/
/*position: absolute;*/
/*top: 41%;*/
/*left: 18%;*/
/*border-radius: 100%;*/
/*-webkit-transition: all 0.2s ease;*/
/*transition: all 0.2s ease;*/
/*}*/
/*.single_radio_field input[type="radio"]:not(:checked) + label:after {*/
/*opacity: 0;*/
/*-webkit-transform: scale(0);*/
/*transform: scale(0);*/
/*}*/
/*.single_radio_field input[type="radio"]:checked + label:after{*/
/*opacity: 1;*/
/*-webkit-transform: scale(1);*/
/*transform: scale(1);*/
/*}*/

/*.single_radio_field input[type=radio]:checked ~ label {*/
/*animation: bounceIn 1s linear;*/
/*}*/

.bcbd_select {
  line-height: 45px;
  /*height: 45px !important;*/
  height: 42px !important;
  padding-top: 0;
  padding-bottom: 0px;
  background: #fff;
  border: none;
  font-weight: 400;
  color: #595959;
  font-size: 15px;
  width: 100%;
}

.bcbd_select option {
  line-height: 48px !important;
}

.bcbd_select option {
  line-height: 47px;
  padding-top: 0;
  padding-bottom: 0;
}

.eligibility_process {
  /*height: calc(100vh - 144px);*/
  /*min-height: calc(100vh - 133px);*/
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding-top: 0px;
}

.eligibility_tab {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  padding: 0px 14px 0px 0px;
  display: flex;
}

.eligibility_tab ul li {
  float: none;
}

.eligibility_tab > ul {
  width: 150px;
  background: rgba(255, 255, 255, 0.2);
  padding-top: 30px;
  border-radius: 5px;
  border-top-right-radius: 0;
  display: inline-block;
  margin: 0;
  border: none !important;
  border-bottom-right-radius: 0;
}

.eligibility_tab ul li .svg {
  height: 50px;
  width: 50px;
  fill: #fff;
}

.eligibility_tab ul li a {
  background: transparent;
}

li.nav-item {
}

.eligibility_tab ul li a,
.eligibility_tab ul li a.active {
  background: transparent !important;
  border: none !important;
  outline: none;
  display: block;
  padding: 0;
  text-align: center;
  padding-bottom: 50px;
}

.eligibility_tab ul li a.active {
  opacity: 1;
}

.eligibility_tab ul li a {
  opacity: 0.5;
}

.eligibility_tab .tab-content {
  width: calc(100% - 147px);
  display: inline-block;
  padding: 20px;
  height: 100%;
}

.eligibility_tab .tab-pane {
  height: 100%;
}

.eligibility_tab {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  padding: 0px 0px 0px 0px;
  display: flex;
  height: 100%;
}

.eligibility_tab_content {
  height: 100%;
}

.eligibility_tab_content.job_exp .eligibility_body {
  height: calc(100% - 78px);
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: flex-start;
  padding-top: 22px;
}

.input-group span + span {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.elg_radius {
}

.eligibility_body {
  height: calc(100% - 78px);
}

.fssubmit h6 {
  color: #fff;
  line-height: 25px;
  /*padding-bottom: 15px;*/
  font-weight: 400;
}

.eligibility_tab_content.job_exp.fssubmit .eligibility_body {
  height: calc(100% - 105px);
}

.eligibility_gender .sl_div .svg {
  height: 34px;
  fill: #fff;
  padding-right: 4px;
}

.eligibility_gender .sl_div {
  display: inline-block;
}

.eligibility_gender .sl_div .svg input {
  -webkit-appearance: none;
}

.eligibility_gender .sl_div input {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 30;
  cursor: pointer;
  left: -30px;
  opacity: 0;
}

.eligibility_gender .sl_div {
  position: relative;
  display: flex;
  flex-flow: row;
  align-items: center;
  color: #fff;
  padding-right: 20px;
}

.eligibility_gender {
  display: flex;
  flex-flow: row;
  margin-bottom: 20px;
  margin-top: 0;
  justify-content: flex-start;
  width: 100%;
}

.prsnl_dt .eligibility_body {
  /*    height: calc(100% - 78px);*/
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: flex-start;
  padding-top: 22px;
}

.eligibility_body .bcbd_control {
  width: 100%;
  border: none;
  line-height: 45px;
  height: 42px;
  padding-left: 15px;
  font-size: 15px;
}

/*after change*/

.eligibility_tab_content h5 {
  color: #fff;
}

.eligibility_check_footer .bcbd_btn {
  width: calc(50% - 10px);
  float: left;
  border: 1px solid #fff;
  color: #fff;
  font-size: 15px;
  line-height: 44px;
  height: 44px;
  text-align: center;
  border-radius: 5px;
}

.eligibility_check_footer .bcbd_btn:first-child {
  margin-right: 20px;
}

.eligibility_check_footer {
  overflow: hidden;
  padding-top: 10px;
}

.eligibility_entry_log {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  padding: 14px;
  display: flex;
  height: 100%;
  flex-flow: column;
  position: relative;
  padding-right: 0;
  overflow: hidden;
  justify-content: space-between;
}

/*
.eligibility_entry_log::after {
    content: "";
    position: absolute;
    width: 14px;
    height: 57px;
    background: rgba(255,255,255,.70);
    right: 0;
    top: 35%;
}
*/

.eligibility_log_profile .svg {
  fill: #fff;
  height: 40px;
}

.eligibility_log_profile {
  display: flex;
  float: none;
  width: 100%;
}

.eligibility_log_body {
  display: block;
}

.eligibility_log_info h6 {
  color: #fff;
  font-size: 16px;
}

.eligibility_log_info p {
  color: #fff;
  font-size: 15px;
}

.eligibility_label.bcbd_group.eldblw {
  font-size: 13px;
  line-height: 50px;
  height: 42px;
  width: 30% !important;
}

.input_ysn .eligibility_label.bcbd_group.eldblw {
  width: 70% !important;
}

.eligibility_log_info {
  padding-left: 11px;
}

/*
.eligibility_log_body {
    background: #fff;
    border-radius: 5px;
    margin-top: 13px;
    height: calc(100% - 54px);
    overflow: auto;
}
*/
.eligibility_log_body {
  background: #fff;
  border-radius: 5px;
  margin-top: 13px;
  height: 337px;
  overflow: auto;
}

.eligibility_log_body ul li .log_title {
  display: inline-block;
  font-size: 13px;
  color: #4dafdd;
  width: 85px;
  text-align: right;
  padding-left: 10px;
}

.eligibility_log_body ul li .log_content {
  display: inline-block;
  font-size: 15px;
  width: calc(100% - 78px);
  vertical-align: top;
  color: #595959;
  padding-left: 10px;
}

.eligibility_log_body ul li .log_content {
  display: inline-block;
  font-size: 13px;
  width: calc(100% - 94px);
  vertical-align: top;
  color: #595959;
  padding-left: 10px;
  line-height: 26px;
}

.eligibility_log_user {
  height: 50px;
  width: 50px;
  border: 1px solid #0ab9f2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /*    background: #fff;*/
}

/*loan*/
.loan_cmn.loan1 .bcbd_group {
  /*width: calc(33% - 9px);*/
}

.loan_cmn.loan1 .bcbd_group:nth-child(2) {
  margin-right: 15px;
}

.log_bsc_dt {
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #1f9ad3;
}

.eligibility_check_footer .bcbd_btn:hover {
  background: #2e8eb9;
  color: #fff;
  border-color: transparent;
}

.eligibility_tab .select_parent {
  position: relative;
}

/*
.select_fake_plholder {
    position:  absolute;
    left:  0;
    height:  97%;
    width: 100%;
    top: 2%;
    background: #fff;
    border-radius: 5px;
    z-index: 0;
    line-height:  45px;
    padding-left: 15px;
    color: #595959;
    cursor: pointer;
}
.wtFakeover .select2-container--default .select2-selection--single {
    background:  transparent;
}
*/

.eligibility_check_footer .bcbd_btn:first-child:hover i {
  transform: translateX(-5px);
}

.eligibility_check_footer .bcbd_btn:last-child:hover i {
  transform: translateX(5px);
}

.eligibility_label.bcbd_group {
  background: #fff;
  font-size: 14px;
  color: #595959;
  line-height: 45px;
  height: 42px;
  width: 30% !important;
  padding-left: 15px;
}

.eligibility_tab_content.job_exp .input-group {
  margin-bottom: 15px;
}

.eligibility_tab_content.job_exp .bcbd_group {
  width: 35%;
  float: left;
  border-right: 1px solid #badaf1;
  position: relative;
}

.eligibility_tab_content.job_exp .bcbd_group .input_ysn .bcbd_group i,
.eligibility_tab_content.job_exp .bcbd_group i {
  font-size: 11px;
  position: absolute;
  left: 14px;
  top: -14px;
  color: #1187d4;
}

.eligibility_tab_content .bcbd_select {
}

.eligibility_tab_content.job_exp select.bcbd_select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
  padding-left: 10px;
}

.eligibility_tab_content.job_exp
  .input-group
  .bcbd_group:nth-child(2)
  .bcbd_select {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.eligibility_tab_content.job_exp .bcbd_group:last-child {
  border-right: none;
}

.eligibility_tab_content.job_exp .bcbd_control {
  margin-bottom: 0;
}

.tab-pane {
  position: relative;
}

.eligibility_gender {
}

.eligibility_gender input[type='radio']:checked ~ .check {
  border-color: #118ad5;
  border-radius: 50%;
  padding-left: 5px;
  background: rgba(255, 255, 255, 0.9);
}

.eligibility_gender input[type='radio']:checked ~ .check .svg {
  fill: #ffffff;
}

.eligibility_gender .check {
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  transition: all 0.3s linear;
}

.eligibility_gender label {
  margin-top: 10px;
  cursor: pointer;
  transition: all 0.3s linear;
}

.eligibility_gender input[type='radio']:checked ~ label {
  padding-left: 7px;
}

#salary_type_container {
  width: 100%;
}

.rental_check .eligibility_body .sl_div {
  width: calc(50% - 13px);
  float: left;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  cursor: pointer;
}

.sl_div .check .svg {
  width: 40px;
}

.rental_check .eligibility_body {
  display: flex;
  align-items: center;
  flex-flow: row;
  padding-top: 22px;
  padding-bottom: 10px;
}

.rental_check .eligibility_body {
  height: calc(100% - 0px) !important;
  padding-top: 0;
}

.rental_check .eligibility_body .sl_div input {
  position: absolute;
  left: 0;
  height: 100%;
  opacity: 0;
  z-index: 9;
  width: 100%;
  cursor: pointer;
}

.rental_check .eligibility_body .sl_div input[type='radio']:checked ~ .check {
  animation: bounceIn 1s linear;
}

.rental_check
  .eligibility_body
  .sl_div
  input[type='radio']:checked
  ~ .inner_sl {
  background: #00bdf2;
}

.rental_check .eligibility_body .sl_div .inner_sl {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  transition: all 0.2s linear;
  z-index: 0;
  border-radius: 0.25rem !important;
}

.rental_check .eligibility_body .sl_div .check {
  position: relative;
  z-index: 1;
}

.rental_check .eligibility_body .sl_div label {
  position: relative;
  z-index: 1;
  color: #696969;
  padding-bottom: 8px;
  font-size: 20px;
}

.rental_check .eligibility_body .sl_div .check .svg {
  height: 40px;
  fill: #696969;
}

.text-6969 {
  color: #696969;
}
.text-4e {
  color: #3e3c3c;
}
.bg-3e {
  color: #3e3c3c;
}

.rental_check .eligibility_body .sl_div:first-child {
  margin-right: 15px;
}

.rental_check .eligibility_body .sl_div:last-child {
}

.rental_check .eligibility_body .sl_div input[type='radio']:checked ~ label {
  animation: bounceIn 1s linear;
  color: #ffffff;
}

.rental_check
  .eligibility_body
  .sl_div
  input[type='radio']:checked
  ~ .check
  .svg {
  fill: #ffffff !important;
}

.select2-results__option {
  font-size: 13px !important;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: inherit !important;
  overflow-y: auto;
}

span.select2-results {
  max-height: 130px;
  overflow: auto;
}

/*Select House Type*/
.eligibility_tab_content.housetcheck {
}

.eligibility_tab_content.housetcheck .eligibility_body {
  display: flex;
  /* flex: 0  0 50%; */
  flex-wrap: wrap;
  height: calc(100% - 80px);
  padding-top: 22px;
  padding-bottom: 10px;
}

.eligibility_tab_content.housetcheck .eligibility_body .sl_div {
  width: calc(50% - 10px);
  flex-direction: column;
  background: transparent;
  margin-right: 20px;
  margin-bottom: 20px;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.eligibility_tab_content.housetcheck .eligibility_body .sl_div:nth-child(2),
.eligibility_tab_content.housetcheck .eligibility_body .sl_div:nth-child(4) {
  margin-right: 0;
}

.eligibility_tab_content.housetcheck .eligibility_body .sl_div:nth-child(3),
.eligibility_tab_content.housetcheck .eligibility_body .sl_div:nth-child(4) {
  margin-bottom: 0;
}

.eligibility_tab_content.housetcheck .eligibility_body .sl_div input {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 99%;
  background: #fff;
  opacity: 0;
  z-index: 9;
  cursor: pointer;
}

.eligibility_tab_content.housetcheck
  .eligibility_body
  .sl_div
  input[type='checkbox']:checked
  ~ label {
  background: #fff;
}

.eligibility_tab_content.housetcheck .eligibility_body .sl_div label {
  height: 22px;
  width: 22px;
  position: absolute;
  left: 15px;
  top: 15px;
  border: 1px solid #d7d7d7;
  border-radius: 3px;
  background: #fff;
  z-index: 9;
}

.eligibility_tab_content.housetcheck .eligibility_body .sl_div .sl_inner_layer {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 0.25rem !important;
  z-index: 0;
}

.eligibility_tab_content.housetcheck .eligibility_body .sl_div span {
  color: #fff;
  position: relative;
  z-index: 0;
}

.eligibility_tab_content.housetcheck .eligibility_body .sl_div label::before {
  content: '\f00c';
  font-family: FontAwesome;
  left: 0px;
  top: -3px;
  position: absolute;
  font-size: 19px;
  color: #00bef2;
  transform: scale(3);
  transition: all 0.2s linear;
  opacity: 0;
  visibility: hidden;
}

.eligibility_tab_content.housetcheck
  .eligibility_body
  .sl_div
  input[type='checkbox']:checked
  ~ label::before {
  transform: scale(1);
  visibility: visible;
  opacity: 1;
}

.eligibility_tab_content.housetcheck
  .eligibility_body
  .sl_div
  input[type='checkbox']:checked
  ~ .sl_inner_layer,
.eligibility_tab_content.housetcheck
  .eligibility_body
  .sl_div:hover
  .sl_inner_layer {
  background: #00bef2;
}

.eligibility_tab_content.housetcheck
  .eligibility_body
  .sl_div
  input[type='checkbox']:checked
  ~ span,
.eligibility_tab_content.housetcheck
  .eligibility_body
  .sl_div
  input[type='checkbox']:checked
  ~ .sl_inner_layer {
  animation: bounceIn 1s linear;
}

.elg_cmn_wrp {
  height: 100%;
}

.elg_cmn_wrp .eligibility_body {
  display: flex;
  height: calc(100% - 80px);
  padding-top: 22px;
  padding-bottom: 10px;
}

.elg_cmn_wrp.hsc_type1 .eligibility_body {
  flex-flow: column;
}

/*---------------------
    ==job
---------------------*/
.job_cmn .select2,
.job_cmn .select2-selection,
.job_cmn .select2-selection__rendered {
  width: 100% !important;
  line-height: 45px !important;
  height: 45px;
  color: #595959 !important;
  font-size: 15px !important;
  border: none;
}

.job_cmn .select_parent {
  width: 100%;
}

.job_cmn .select2-selection__arrow {
  top: 0px !important;
  position: absolute;
}

.job_cmn .select2-selection__arrow b {
  position: relative !important;
  top: inherit !important;
  border: none !important;
}

.job_cmn .select2-selection__arrow b::after {
  content: '\f107';
  font-family: FontAwesome;
  font-size: 18px;
  position: absolute;
  top: -11px;
  right: -4px;
  height: inherit;
  transition: all 0.3s linear;
}

.job_cmn .select2-container--open .select2-selection__arrow b::after {
  content: '\f106';
  font-family: FontAwesome;
  font-size: 18px;
  position: absolute;
  top: -11px;
  right: -4px;
  height: inherit;
  transition: all 0.3s linear;
}

.select2-dropdown {
  border: 1px solid transparent !important;
}

.job_cmn .input-group {
  margin-bottom: 0px !important;
}

.job_cmn .bcbd_group {
  width: 30%;
  float: left;
  border-right: 1px solid #badaf1;
}

.job_cmn .input-group .bcbd_group:nth-child(2) .select2-selection {
  border-radius: 0;
}

.job_cmn .bcbd_group:last-child {
  border-right: none;
}

.job_cmn .input-group .bcbd_group:last-child .select2-selection {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
  padding-left: 10px;
}

.job_cmn .eligibility_body {
  /*height: calc(100% - 78px);*/
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: flex-start;
  padding-top: 22px;
}

.job_cmn .bcbd_control {
  margin-bottom: 0;
}

.bcbd_select,
.bcbd_control {
  transition: all 0.15s linear;
}

.eligibility_body .bcbd_select,
.eligibility_body .bcbd_control {
  padding-left: 10px;
}

.eligibility_body .bcbd_control input[type='number'] {
}

.eligibility_body input[type='number']::-webkit-inner-spin-button,
.eligibility_body input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/*-------------------------------------
    ==Ramge Slider
-------------------------------------*/
.range_slider_top {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
}

.loan_scale_inner_container {
  background: #fff;
  padding: 30px;
  border-radius: 5px;
}

.loan_scale_inner_container .bcbd_select {
  padding-left: 10px;
  height: 42px !important;
  margin-top: 1px;
}

.range_slider_top .sl_div:nth-child(2) {
  text-align: center;
}

.range_slider_top .sl_div:nth-child(2) input {
  text-align: center;
}

.range_slider_top .sl_div p {
  font-size: 16px;
  color: #717171;
}

/*.loan_scale_inner_container .ui-slider .ui-slider-range {*/
/*position: absolute;*/
/*z-index: 1;*/
/*font-size: .7em;*/
/*display: block;*/
/*border: 0;*/
/*background-position: 0 0;*/
/*background: #02bdf2;*/
/*}*/

.slider_range_outer {
  position: relative;
  padding-left: 0;
  padding-right: 0;
  margin-left: 9px;
  margin-right: 9px;
}

.loan_grid_paren {
  display: flex;
  flex-flow: row;
}

.slider_range_bottom
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #444;
  line-height: 40px;
  font-size: 14px;
  padding-left: 0;
}

.slider_range_bottom {
  padding-top: 20px;
}

.slider_range_bottom .sl_div {
  float: left;
}

.slider_range_bottom .bcbd_label {
  color: #717171;
  font-size: 14px;
  display: block;
  text-align: center;
  margin-bottom: 0;
  opacity: 1;
  padding-bottom: 9px;
}

.bcbd_compare_btn {
  font-size: 16px;
  color: #fff;
  background: #1489d5;
  line-height: 0;
  padding: 20px 30px;
  border-radius: 5px;
  display: inline-block;
  font-weight: 600;
}

.slider_range_bottom .bcbd_compare_btn {
  margin-top: 31px;
}

.loan_scale h2 {
  color: #fff;
  text-align: center;
  font-weight: 300;
  padding-top: 42px;
  padding-bottom: 42px;
}

.loan_scale h2 span {
  font-weight: 600;
}

/*sl_lh_option*/
.sl_lh_option .select2-container,
.slider_range_bottom .select2-container {
  width: 100% !important;
}

.sl_lh_option
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered,
.slider_range_bottom
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #595959;
  line-height: 40px;
  font-size: 14px;
  padding-left: 10px;
}

.sl_lh_option
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b,
.slider_range_bottom
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  top: 75%;
}

.select2-selection--single:focus {
  outline: none;
}

.select2-dropdown {
  border: 1px solid transparent !important;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
}

.sl_lh_option .form-control {
  padding: 0;
}

.single_loan_table.loan_grid_paren .loan-grid {
  border-right: 1px solid #eee;
}

.single_loan_table .lon-grid {
  border-right: 1px solid #ddd;
}

.single_loan_table .lon-grid p {
  color: #555555;
  font-weight: 400;
  font-size: 15px;
}

.mobile_option {
  color: #111;
  font-size: 12px;
}

.dps_banner.credit_card_bnr {
  padding-top: 130px;
  padding-bottom: 80px;
}

.single_loan_table .lon-grid {
  border-right: 1px solid #ddd;
  color: #555;
  font-weight: 400;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table_adremove {
  position: absolute;
  left: 2px;
  top: 50px;
  z-index: 99;
}

.single_loan_table .lon-grid:first-child {
  border-left: 1px solid #ddd;
}

.single_loan_table .lon-grid {
  flex-flow: column;
}

.full-width {
  width: 100%;
}

.cc_full_with_table .lon-grid {
  position: relative;
}

.cc_full_with_table .single_loan_table .cc_sb_cb {
  position: relative;
}

.cc_full_with_table .single_loan_table .cc_sb_cb input {
  z-index: 9;
}

.cc_full_with_table .single_loan_table .cc_sb_cb label::after {
  top: -3px;
}

.rt_table_btn {
  background: #f79629;
  font-size: 12px;
  color: #fff;
  padding: 8px 0px;
  display: inline-block;
  border-radius: 3px;
  width: 130px;
}

.rt_table_btn.bordered {
  background: #fff;
  border: 1px solid #02bdf2;
  color: #02bdf2;
  margin-top: 7px;
}

.single_loan_table.loan_grid_paren .loan-grid p {
  font-size: 13px;
}

.single_rt_table {
  border-bottom: 1px solid #ddd;
  margin-bottom: 35px;
  border-top: 1px solid #ddd;
  position: relative;
}

.loan_grid_paren.single_loan_table .lon-grid p {
  font-size: 13px;
}

.mobile_option {
  display: none;
}

.single_loan_table {
  display: flex;
  flex-flow: row;
  position: relative;
}

/*---------------------------------
    ==ScbSuccessInfo loan table compare==
---------------------------------*/
.loan_table_head.scb_table_head {
  padding-top: 0;
  background: #e7f3fb;
  color: #595959;
}

.loan_table_head.scb_table_head .lon-grid {
  color: #595959;
  font-weight: 400;
}

/*-------------------------------
    ==Application sucess==
-------------------------------*/
.check {
  stroke-dasharray: 130px;
  stroke-dashoffset: 130px;
  -webkit-transition: stroke-dashoffset 4.1s linear 0s;
  transition: stroke-dashoffset 500ms ease-in-out;
}

.success_info_bnr_inr {
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  color: #fff;
  padding-top: 80px;
}

.success_info_bnr_inr h5 {
  padding-top: 25px;
  font-size: 20px;
  padding-bottom: 10px;
}

.success_info_bnr_inr p {
  font-size: 15px;
}

.tick_circle {
  height: 70px;
  width: 70px;
  border: 2px solid #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tick_circle .svg {
  height: 38px;
}

/*-------------------------------------
    ==Application Failure==
-------------------------------------*/
.scb_header {
  background: #fff;
  margin: 0;
  padding: 7px 0;
  border: 0;
}

.success_info_banner.scb_banner .success_info_bnr_inr {
  height: 230px;
  padding-top: 0px;
}

.success_info_banner.scb_banner {
  box-shadow: inset -7px 0px 16px rgba(0, 0, 0, 0.3);
}

/*------------------------------------
    Start Googl Map area
------------------------------------*/
.map_area #google-map {
  width: 100%;
  height: 500px;
}

/*---------------------------
    ==Loan table==
---------------------------*/
.single_rt_dt h6 {
  color: #02bdf2;
  font-weight: 600;
  font-size: 15px;
  padding-bottom: 10px;
}

.single_rt_dt ul li {
  float: none;
  font-size: 14px;
  color: #595959;
  line-height: 26px;
}

.single_rt_dt ul li span {
  color: #f79629;
  position: relative;
  padding-right: 10px;
}

.single_rt_dt ul li span::after {
  content: '-';
  position: absolute;
  right: 2px;
  color: #000;
}

.fake_headoption {
  visibility: hidden;
  opacity: 0;
}

.loan_head_option_parent {
  position: relative;
}

#sticker {
  top: 0;
  background: #24a5d9;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.is_sticky {
  width: 100%;
  background: #fff;
  position: fixed;
  top: 0;
  z-index: 1000;
  left: 0;
  text-align: center;
}

.fadeInUp {
}

.loan_head_option.is_sticky {
  position: fixed !important;
  z-index: 999;
  background: #24a5d9;
  margin-left: -15px;
  animation: fadeInUp 0.4s ease-in;
}

/*
.adsss {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
*/
.spacer {
  height: 700px;
}

.is_stuck {
  position: fixed;
  width: 100%;
  z-index: 999;
}

.head_option_parent {
  position: relative;
}

.single_rt_dt ul {
  padding-top: 10px;
  padding-left: 10px;
}

.rate_table_details {
  padding: 35px 20px 30px 25px;
  border: 1px solid #ddd;
  border-bottom: 1px solid transparent;
}

.single_rt_dt ul li span::before {
  content: '\f00c';
  position: absolute;
  left: -15px;
  top: -3px;
  font-family: FontAwesome;
  font-size: 12px;
}

.quick_dtl_btn {
  font-size: 15px;
  color: #02bdf2;
  border: 1px solid #ddd;
  display: inline-block;
  padding: 5px 20px;
  border-radius: 20px;
  background: #fff;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: -18px;
  cursor: pointer;
}

.quick_dtl_btn i {
  display: inline-block;
  padding-left: 4px;
}

.rate_table_details {
  display: none;
}

.single_rt_table.active .rate_table_details {
  display: block;
}

/*
.spacer{
    height: 700px;
}
.spacer2{
    height: 700px;
}
*/
/*Range slider */
.has_rate_table nav.navbar.bcbd_navbar.sticky_nav {
  opacity: 0;
  visibility: hidden;
}

.loan_scale_inner_container .ui-widget.ui-widget-content {
  border: none;
  background: #e0e0e0;
  height: 20px;
  margin-left: -9px;
  margin-right: -9px;
  position: static;
  border-radius: 3px;
}

.loan_scale_inner_container .ui-widget.ui-widget-content {
  border: none;
  background: #e0e0e0;
  height: 20px;
  border-radius: 3px;
}

.loan_scale_inner_container .ui-state-default,
.loan_scale_inner_container .ui-widget-content .ui-state-default {
  height: 40px;
  width: 26px;
  border: none;
  background: #1489d5;
  margin-top: -7px;
  cursor: pointer;
  z-index: 9;
  border-radius: 3px;
}

.input-group.bcbd_check {
  margin-top: 15px;
}

/*.input-group.bcbd_check */
.apply_output_filds_inner .input-group.bcbd_check span {
  display: inline-block;
  margin-left: 20px;
  line-height: 25px;
  width: calc(100% - 50px);
  color: #687270;
}

.loan_scale_inner_container .ui-state-default,
.ui-widget-content .ui-state-default:focus {
  outline: none;
}

/*-------------------------------------------
    ==Credit Card Virtual Card==
-------------------------------------------*/
.virtual_card_compare_parent {
  background: #e7f3fb;
  padding: 10px 10px;
  width: 925px;
  position: fixed;
  right: 64px;
  bottom: 5px;
  border-radius: 5px;
  /*visibility: hidden;*/
  /*opacity: 0;*/
  transition: all 0.3s linear;
  z-index: 99;
}

.vt_card_compare {
  display: inline-block;
  background: #1489d6;
  color: #fff;
  padding: 8px 20px;
  font-size: 13px;
  border-radius: 5px;
  transition: all 300ms ease-in-out;
  cursor: pointer;
}
.vt_card_compare:hover {
  background: #6c757d;
}
.vt_card_compare.disable {
  background: #d6d6d6;
}

.virtual_card_compare_right {
  position: relative;
  height: 107px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  float: left;
}

.virtual_compare_card_left {
  width: calc(100% - 188px);
  display: flex;
  float: left;
  height: 109px;
  flex-flow: row;
}

.virtual_card_close {
  position: absolute;
  top: 6px;
  right: 10px;
  border: 1px solid #1489d6;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 600;
  color: #1489d6 !important;
  line-height: 20px;
}

/**/

.single_vt_card {
  display: flex;
  padding: 0px 10px;
  -webkit-box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  width: 170px;
  text-align: center;
  z-index: 50;
  position: relative;
  background: #fff;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  align-items: center;
  justify-content: space-between;
  flex-flow: column;
}

.single_vt_card p {
  font-size: 12px;
  padding-top: 4px;
  color: #555;
  text-transform: capitalize;
}

.single_vt_card ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.single_vt_card ul li {
  font-size: 11px;
  color: #555;
}

.card_cancel {
  position: absolute;
  right: 6px;
  top: 5px;
  height: 17px;
  width: 17px;
  border: 1px solid #106ebc;
  color: #106ebc;
  line-height: 14px;
  border-radius: 50%;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.3s linear;
}
.card_cancel:hover {
  background: #106ebc;
  color: #ffffff;
}

.virtual_compare_card_left .single_vt_card p {
  font-size: 12px;
  padding-top: 4px;
  max-height: 36px;
  overflow: hidden;
}

.single_vt_card {
  margin-left: 5px;
  margin-right: 5px;
}

.sl_com_credit_card_top img {
  max-width: 50%;
}

.virtual_compare_card_left div:first-child {
  margin-left: 0;
}

.virtual_compare_card_left div:last-child {
  margin-right: 0;
}

.single_vt_card.hidden_card {
  position: absolute;
  top: 18px;
  left: -5px;
  opacity: 0;
  width: 165px;
  height: 130px;
}

/*--------------------------------
    ==Credit card compre==
--------------------------------*/
.single_com_credit_card {
  background: #fff;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  transition: all 0.3s linear;
  border: 1px solid transparent;
}

.card_campare {
  background: #f8f8f8;
  padding-top: 100px;
  padding-bottom: 100px;
}

.sl_com_credit_card_top {
  text-align: center;
  border-bottom: 1px solid #ddd;
  padding-top: 10px;
  padding-bottom: 10px;
}

.sl_com_credit_card_top p {
  padding-top: 6px;
  color: #555;
}

.sl_com_credit_card_body h6 {
  color: #00c7ff;
  font-size: 15px;
  letter-spacing: 0.3px;
  text-align: left;
  padding: 5px;
}

.sl_com_credit_card_body {
  padding: 20px 0px;
  text-align: center;
}

.sl_com_credit_card_body ul li {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-flow: row;
  flex-flow: row;
  line-height: 25px;
  font-size: 14px;
  color: #555;
  padding: 5px;
}

span.cccompre_price {
  word-break: break-all;
  text-align: left;
}

.cccompre_option {
  display: inline-block;
  width: calc(40% - 10px);
  text-align: left;
  font-weight: 500;
}

.cccompre_price {
  width: calc(60% - 10px);
}

.sl_com_credit_card_body ul li:nth-child(odd) {
  background: #f2f2f2;
}

.sl_com_credit_card_body ul {
  padding: 6px 0;
  border: 1px dashed #ddd;
}

.compare_btn {
  background: #f79629;
  padding: 8px 30px;
  border-radius: 5px;
  color: #fff;
  font-weight: 400;
  display: inline-block;
}

.sl_com_credit_card_body .compare_btn {
  margin-top: 10px;
}

.single_com_credit_card:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
  border-color: #00c7ff;
}

/*---------------------------------
    ==Compare card MonthlyBenifit==
---------------------------------*/
.card_compare_bnr_inner {
  height: 201px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 66px;
  color: #fff;
}

.card_compare_bnr_inner h2 {
  font-size: 36px;
  font-weight: 300;
}

/*---------------------------------
    ==Start CardDetails==
---------------------------------*/

.dps_banner {
  padding-top: 174px;
  padding-bottom: 90px;
}

.dps_inner {
  background: rgba(255, 255, 255, 0.25);
  padding: 30px 30px 45px 30px;
  border-radius: 5px;
}

.dps_inner .bcbd_label {
  display: block;
  text-align: center;
  color: #fff;
  font-size: 15px;
  padding-bottom: 10px;
}

.dps_banner h2 {
  color: #fff;
  font-weight: 300;
  font-size: 26px;
  text-align: center;
  padding-bottom: 40px;
}

.dps_banner h2 span {
  font-weight: 700;
}

.bcbd_inputgrup {
}

.bcbd_inputgrup .input-group-prepend span {
  padding: 0;
  line-height: 45px;
  height: 45px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 21px;
  color: #fff;
  border-color: transparent;
}

.apply_modal_inner .single_option .bcbd_inputgrup input {
  border: none;
}

.bcbd_inputgrup .select2-container,
.bcbd_inputgrup .select2-container {
  width: 100%;
}

.bcbd_inputgrup .select2-container {
  width: 100% !important;
}

.bcbd_inputgrup .form-control span.select2-selection.select2-selection--single,
.bcbd_inputgrup .form-control span.select2-selection.select2-selection--single {
  border-color: transparent;
  height: 43px;
  line-height: 45px;
}

.bcbd_inputgrup
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  top: 75%;
}

.bcbd_inputgrup .form-control {
  padding: 0;
}

.bcbd_inputgrup
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered,
.bcbd_inputgrup
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #595959;
  line-height: 40px;
  font-size: 14px;
  padding-left: 10px;
}

.dps_table_head {
  background: #24a5d9;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

/*TripleMoney table info*/

.sl_dps_table .lon-grid {
  padding-top: 10px;
  padding-bottom: 10px;
}

.sl_dps_table .rt_table_btn.bordered {
  margin-top: 0;
  padding: 8px 14px;
  font-size: 16px;
  font-weight: 400;
  width: 135px;
}

.sl_dps_table .rt_table_btn.bordered:hover {
  background: #24a5d9;
  color: #fff;
}

.top-radius-0 {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.sl_dps_table .lon-grid p {
  font-size: 15px !important;
  font-weight: 500;
}

.dps_table_head .lon-grid {
  font-weight: 400 !important;
}

.single_rt_table.sl_dps_table {
  margin-bottom: 10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.single_rt_table.sl_dps_table:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.dps_tbale_area h2 {
  text-align: center;
  color: #595959;
  font-size: 28px;
  padding-top: 50px;
  padding-bottom: 50px;
  font-weight: 400;
}

.dps_tbale_area h2 span {
  font-weight: 700;
}

/*----------------------------------
    Credit card
----------------------------------*/
.loan_cmn .select2,
.loan_cmn .select2-selection,
.loan_cmn .select2-selection__rendered {
  width: 100% !important;
  line-height: 45px !important;
  height: 45px;
  color: #595959 !important;
  font-size: 15px !important;
  border: none;
}

.loan_cmn .select_parent {
  width: 100%;
}

.loan_cmn .select2-selection__arrow {
  top: 0px !important;
  position: absolute;
}

.loan_cmn .select2-selection__arrow b {
  position: relative !important;
  top: inherit !important;
  border: none !important;
}

.loan_cmn .select2-selection__arrow b::after {
  content: '\f107';
  font-family: FontAwesome;
  font-size: 18px;
  position: absolute;
  top: -11px;
  right: -4px;
  height: inherit;
  transition: all 0.3s linear;
}

.loan_cmn .select2-container--open .select2-selection__arrow b::after {
  content: '\f106';
  font-family: FontAwesome;
  font-size: 18px;
  position: absolute;
  top: -11px;
  right: -4px;
  height: inherit;
  transition: all 0.3s linear;
}

.loan_cmn {
  width: 100%;
}

/*.loan_cmn .bcbd_group {*/
/*width: calc(50% - 8px);*/
/*}*/
.group-bordered .bcbd_group {
  width: calc(50% - 8px) !important;
}

.group-bordered .bcbd_select option {
  color: #40a4d7;
}

.loan_cmn .input-group .bcbd_group:first-child {
  margin-right: 15px;
}

.loan_cmn .input-group {
  margin-bottom: 15px;
}

.loan_cmn .eligibility_body {
  padding-top: 22px;
  /*height: calc(100% - 78px);*/
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: flex-start;
}

.prsnl_dt .input-group {
  margin-bottom: 13px;
}

.slg_stg1 .select2,
.slg_stg1 .select2-selection,
.slg_stg1 .select2-selection__rendered,
.prsnl_dt .select2,
.prsnl_dt .select2-selection,
.prsnl_dt .select2-selection__rendered {
  width: 100% !important;
  line-height: 45px !important;
  height: 45px;
  color: #595959 !important;
  font-size: 15px !important;
  border: none;
}

.prsnl_dt .select_parent {
  width: 100%;
}

.slg_stg1 .select2-selection__arrow,
.prsnl_dt .select2-selection__arrow {
  top: 0px !important;
  background: #fff;
  z-index: 99;
  width: 24px !important;
  height: 44px !important;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: -9px 0px 9px rgba(255, 255, 255, 0.3);
}

.slg_stg1 .select2-selection__arrow b,
.prsnl_dt .select2-selection__arrow b {
  position: relative !important;
  top: inherit !important;
  border: none !important;
}

.slg_stg1 .select2-selection__arrow b::after,
.prsnl_dt .select2-selection__arrow b::after {
  content: '\f107';
  font-family: FontAwesome;
  font-size: 18px;
  position: absolute;
  top: -11px;
  right: -4px;
  height: inherit;
  transition: all 0.3s linear;
}

.prsnl_dt .select2-container--open .select2-selection__arrow b::after {
  content: '\f106';
  font-family: FontAwesome;
  font-size: 18px;
  position: absolute;
  top: -11px;
  right: -4px;
  height: inherit;
  transition: all 0.3s linear;
}

.tab-content h5 {
  color: #fff;
}

/*
.prsnl_dt .input-group .bcbd_group:first-child {
    margin-right: 15px;
}
*/

/**/
.group-bordered .bcbd_select,
.group-bordered input.bcbd_control {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.eligibility_tab_content.prsnl_dt .bcbd_group {
  width: 30%;
  float: left;
  border-right: 1px solid #badaf1;
}

.prsnl_dt .input-group .bcbd_group:nth-child(2) .select2-selection {
  border-radius: 0;
}

.prsnl_dt .input-group .bcbd_group:last-child .select2-selection {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-left: 10px;
}

.prsnl_dt .bcbd_group:last-child {
  border-right: none;
}

/*-----------------------------
    ==Form Procedure==
-----------------------------*/
.sl_div_parents {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
}

.fromProdure {
  background: rgba(255, 255, 255, 0.3);
  padding: 15px 15px 20px 15px;
  border-radius: 5px;
}

.fromProdure.twoHalf {
}

.fromProdure.twoHalf .sl_div:last-child {
  width: calc(20% - 15px);
  margin-left: 15px;
  text-align: right;
  float: left;
}

.fromProdure.twoHalf .sl_div:first-child,
.fromProdure.twoHalf .sl_div:nth-child(2) {
  width: calc(40% - 5px);
  float: left;
}

.fromProdure .bcbd_control {
  line-height: 42px;
  border: none;
  width: 100%;
  background: #fff;
  border-radius: 5px;
  padding-left: 15px;
}

.fromProdure.twoHalf .sl_div:nth-child(2) {
  margin-left: 15px;
}

.bcbd_btn4 {
  background: #1489d6;
  color: #fff !important;
  padding: 10px 35px 10px 35px;
  border-radius: 5px;
  font-size: 18px;
  border: none;
}

/*----------------------------------
    ==Start Loan Guide==
----------------------------------*/
.user_guide_contaienr {
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #fae216;
  width: 165px;
  border-radius: 5px;
}

.user_guide_inr_cont {
  position: relative;
  padding-top: 44px;
  padding-bottom: 16px;
}

.user_guide_bottom {
  position: relative;
  top: 0%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 3px;
  display: none;
  flex-flow: row;
  text-align: center;
}

.lang_choice {
  position: relative;
  top: 0%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 2px;
  flex-flow: row;
  text-align: center;
  display: none;
}

.lang_choice div {
  display: inline-block;
  background: #c7b202;
  padding: 3px 15px 3px 15px;
  border-radius: 5px;
  text-transform: uppercase;
  color: #fff;
  font-size: 10px;
  cursor: pointer;
}

.user_guide_bottom div {
  display: inline-block;
  font-size: 11px;
  font-weight: 400;
  color: #fff;
  background: #c7b202;
  padding-left: 8px;
  padding-right: 8px;
  line-height: 23px;
  border-radius: 5px;
  cursor: pointer;
}

.user_guide_bottom .guide_audio,
.user_guide_bottom .guide_text {
  font-size: 11px;
  font-weight: 400;
  color: #fff;
  background: #c7b202;
  padding-left: 8px;
  padding-right: 8px;
  line-height: 23px;
  border-radius: 5px;
  cursor: pointer;
}

.user_guide_bottom span {
  font-weight: 600;
  font-size: 13px;
}

.user_guide_bottom .guide_audio i,
.user_guide_bottom .guide_text i {
  font-size: 11px;
  padding-right: 2px;
}

.user_guide_bottom {
}

.user_guide_bottom .active {
  background: #c7b202;
}

#guide_arrow {
  position: absolute;
  right: 1px;
  top: 42%;
  transform: translateY(-50%);
  font-size: 18px;
  color: #878678;
  cursor: pointer;
}

.user_guide_top {
  font-size: 13px;
  font-weight: 600;
  color: #282828;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 16px;
  position: absolute;
  top: -15px;
  line-height: 29px;
}

.user_guide_top img {
  padding-right: 10px;
  margin-bottom: 19px;
  object-fit: contain;
}

.user_guide_body {
}

.user_guide_body p {
  padding-left: 8px;
  padding-right: 17px;
  font-size: 12px;
}

.guide_acces {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-around;
  padding-top: 18px;
}

.guide_acces div {
  background: #c7b202;
  padding: 3px 15px 3px 15px;
  border-radius: 5px;
  text-transform: uppercase;
  color: #fff;
  font-size: 10px;
  cursor: pointer;
}

.user_guide_contaienr.hide {
  margin-left: -147px;
}

/*-------------------------------
    ==Credit card right sidebar
-------------------------------*/
.cc_full_with_table .cc_table_left {
  flex: 0 0 20%;
  padding-left: 15px;
  padding-right: 15px;
}

.cc_full_with_table .cc_table_right {
  flex: 0 0 80%;
  padding-left: 15px;
  padding-right: 15px;
}

.single_cc_sdbar_widget h4 {
  color: #404040;
  font-size: 18px;
  background: #e7f3fb;
  display: block;
  padding: 13px 10px;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.single_cc_sdbar_widget ul {
  background: #fff;
  box-shadow: inset 0px -1px 0px 1px rgba(0, 0, 0, 0.1);
  padding: 25px 15px;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  transition: all 0.3s linear;
}

.single_cc_sdbar_widget:hover ul {
  box-shadow: inset 0px -6px 0px 1px rgba(0, 0, 0, 0.1);
}

.single_cc_sdbar_widget {
  margin-bottom: 15px;
  border-radius: 5px;
}

.single_cc_sdbar_widget ul .cc_sb_txt {
  color: #404040;
  font-size: 13px;
  width: calc(100% - 29px);
  display: inline-block;
  vertical-align: top;
}

.cc_sb_cb {
  height: 16px;
  width: 17px;
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
  margin-top: 1px;
}

.cc_sb_cb input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.cc_sb_cb label {
  width: 100%;
  height: 100%;
  border: 1px solid #afabab;
  border-radius: 3px;
  margin-bottom: 0;
  transition: all 0.3s linear;
}

.cc_sb_cb label::after {
  content: '\f00c';
  font-family: FontAwesome;
  font-size: 16px;
  color: #02bdf2;
  position: absolute;
  left: 0;
  top: -3px;
  transform: scale(2);
  transition: all 0.3s linear;
  opacity: 0;
  visibility: hidden;
}

.cc_sb_cb input[type='checkbox']:checked ~ label {
  border-color: #02bdf2;
}

.cc_sb_cb input[type='checkbox']:checked ~ label::after {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.single_cc_sdbar_widget ul li {
  padding-bottom: 10px;
  position: relative;
}

.single_cc_sdbar_widget ul li:last-child {
  padding-bottom: 0;
}

/*----------------------------
    ==Require Documents==
----------------------------*/
.require_doc_body .rq_txt {
  color: #505050;
  font-size: 13px;
  width: calc(100% - 29px);
  display: inline-block;
  vertical-align: top;
}

.rq_ceck {
  height: 27px;
  width: 27px;
  display: inline-block;
  vertical-align: top;
  margin-right: 12px;
  margin-top: 1px;
  background: #fff;
}

.rq_ceck input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.rq_ceck label {
  width: 100%;
  height: 100%;
  border: 1px solid #afabab;
  border-radius: 3px;
  margin-bottom: 0;
  transition: all 0.3s linear;
}

.rq_ceck label::after {
  content: '\f00c';
  font-family: FontAwesome;
  font-size: 22px;
  color: #02bdf2;
  position: absolute;
  left: 2px;
  top: -3px;
  transform: scale(2);
  transition: all 0.3s linear;
  opacity: 0;
  visibility: hidden;
}

.rq_ceck input[type='checkbox']:checked ~ label {
  border-color: #02bdf2;
}

.rq_ceck input[type='checkbox']:checked ~ label::after {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.require_doc_body ul li ul li {
  position: relative;
}

.require_doc_body > ul > li {
  flex: 0 0 33.33%;
  padding: 15px;
}

.require_doc_body > ul {
  display: flex;
  flex-flow: row;
}

.require_doc_top {
  text-align: center;
  border-bottom: 1px solid #ddd;
  padding: 20px 0px;
}

.require_doc_top h5 {
  color: #595959;
  font-size: 20px;
  font-weight: 600;
}

.require_doc_top p {
  color: #f79629;
  font-size: 15px;
}

.require_doc_area {
  padding-top: 50px;
  padding-bottom: 50px;
}

.require_doc_cont {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  padding-bottom: 20px;
}

.require_doc_body > ul {
}

.require_doc_body > ul > li > ul > li {
  padding-bottom: 6px;
  font-size: 15px;
}

.require_doc_footer {
  text-align: center;
  padding-top: 15px;
}

.require_doc_footer .rq_doc_btn {
  background: #f79629;
  color: #fff;
  padding: 7px 50px;
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.3px;
  border-radius: 5px;
}

/*----------------------
    ==Scroll Down==
----------------------*/
.scroll_down {
  height: 51px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  width: 30px;
  border: 1px solid #fff;
  border-radius: 15px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 4px;
}

.scroll_down i {
  font-size: 22px;
  color: #fff;
  animation: updownanim 1s linear infinite;
}

@keyframes updownanim {
  0% {
    transform: translateY(0px);
  }
  33% {
    transform: translateY(7px);
  }
  66% {
    transform: translateY(-4px);
  }
  100% {
    transform: translateY(0px);
  }
}

/*-----------------------------
    ==Start Comming Soon==
-----------------------------*/
.comming_soon_area {
  position: relative;
}

.comming_soon_area .cms_cld_cmn {
  position: absolute;
}

.comming_soon_inner {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.coming_soon_content {
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  color: #fff;
  position: relative;
  z-index: 9;
}

.coming_soon_content h5 {
  color: #e6ecf2;
  font-size: 30px;
}

.bcbd_borderede_btn {
  border: 1px solid #fff;
  border-radius: 5px;
  line-height: 42px;
  height: 42px;
  padding-left: 40px;
  padding-right: 40px;
  color: #fff !important;
}

.coming_soon_content p {
  font-size: 15px;
  color: #e6ecf2;
  padding-top: 17px;
  padding-bottom: 25px;
}

.cms_cloud3 {
  top: 55%;
  left: 0;
  opacity: 0.5;
  animation-name: cloud;
  animation-delay: 0.3s;
  animation-duration: 250s;
  transition-timing-function: linear;
  animation-iteration-count: infinite;
  opacity: 0.2;
}

.cms_cloud2 {
  top: 43%;
  opacity: 0.5;
  left: 0;
  animation-name: cloud;
  animation-delay: 5s;
  animation-duration: 350s;
  transition-timing-function: linear;
  animation-iteration-count: infinite;
  opacity: 0;
}

.cms_cloud1 {
  top: 35%;
  opacity: 0.5;
  left: 0;
  animation-name: cloud;
  animation-delay: 1s;
  animation-duration: 150s;
  transition-timing-function: linear;
  animation-iteration-count: infinite;
  opacity: 0;
}

.comming_soon_right img {
  max-width: 100%;
}

@keyframes cloud {
  0% {
    left: 20%;
    opacity: 0;
  }
  50% {
    left: 100%;
    opacity: 0.5;
  }
  100% {
    left: 0%;
    opacity: 0.5;
  }
}

/*------------------------------------------------
    ==Scale and Feartures options==
------------------------------------------------*/
.featuers_scale {
  padding-top: 85px;
  padding-bottom: 85px;
}

.features_option {
  overflow: hidden;
}

.features_option .sl_features_option {
  width: 25%;
  float: left;
  background: rgba(255, 255, 255, 0.1);
  transition: all 0.3s linear;
  position: relative;
}

.features_option .sl_features_option .svg {
  height: 43px;
  fill: #fff;
  margin-bottom: 13px;
}

.features_option .sl_features_option a {
  display: flex;
  align-items: center;
  flex-flow: column;
  color: #fff;
  padding-top: 25px;
  padding-bottom: 25px;
}

.features_option .sl_features_option:first-child {
  border-top-left-radius: 5px;
}

.features_option .sl_features_option:last-child {
  border-top-right-radius: 5px;
}

.features_option .sl_features_option:hover {
  background: rgba(255, 255, 255, 0.3);
}

.features_option .sl_features_option::before {
  content: '';
  position: absolute;
  right: 0;
  height: 100%;
  width: 1px;
  background: rgba(255, 255, 255, 0.2);
}

.features_option .sl_features_option:last-child::before {
  display: none;
}

.loan_scale_inner_container {
  background: #fff;
}

/*----------------------------------------
        ==Start Loan table==
----------------------------------------*/
.loan_table_head {
  background: #24a5d9;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.loan_table_container {
  padding-top: 50px;
}

.sl_lh_option .input-group-prepend span {
  line-height: 45px;
  padding: 0;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  border-color: transparent;
  background: #02bdf2;
  color: #fff;
  height: 47px;
}

.loan_head_option.loan_grid_paren {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.loan_grid_paren .lon-grid {
  text-align: center;
  color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 600;
}

.gd-7 .lon-grid {
  width: 14.285%;
}

.gd-6 .lon-grid {
  width: 16.666%;
}

.gd-5 .lon-grid {
  width: 20%;
}

.gd-4 .lon-grid {
  width: 25%;
}

.cc_full_with_table .loan_grid_paren .lon-grid {
  padding-top: 17px;
  padding-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
}

.cc_full_with_table
  .sl_lh_option
  .input-group
  .form-control
  span.select2-selection.select2-selection--single {
  border-color: transparent;
  height: 41px;
  line-height: 40px;
}

/*-------------------------------------
    ==Bottom Mobile OPtions--
-------------------------------------*/

.mobile_options {
  position: fixed;
  bottom: 0;
  z-index: 99;
  width: 100%;
}

.mobile_options::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
}

.mobile_option_inne {
  display: flex;
  flex-flow: row;
}

.mobile_option_inne .svg {
  height: 20px;
  fill: #fff;
}

.mobile_option_inne .sl_div {
  /*! width: 50%; */
  float: left;
  flex: 0 0 50%;
  text-align: center;
}

.mobile_option_inne .sl_div:first-child {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.mobile_option_inne .sl_div a {
  display: block;
  padding: 9px 0;
  transition: all 0.3s ease-in;
}

.mobile_option_inne .sl_div a:hover {
  background: rgba(255, 255, 255, 0.3);
}

/*--------------------------------------
        ==Loan Modal==
--------------------------------------*/
.bcbd_modal_lg .modal_header {
  position: relative;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 10px 20px;
  background: #208dd3 !important; /* Old browsers */
  /*background: -moz-linear-gradient(left, #0c65b7 0%, #25a8da 85%); !* FF3.6-15 *!*/
  /*background: -webkit-linear-gradient(left, #0c65b7 0%, #25a8da 85%); !* Chrome10-25,Safari5.1-6 *!*/
  /*background: linear-gradient(to right, #0c65b7 0%, #25a8da 85%); !* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ *!*/
  /*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0c65b7', endColorstr='#25a8da', GradientType=1); !* IE6-9 *!*/
}

.bcbd_modal_lg .modal_header h5 {
  display: inline-block;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
}

.bcbd_modal_lg {
  max-width: 950px;
}

.modal_header .close {
  color: #fff;
}

.modal_left {
  width: 385px;
  float: left;
  border-right: 1px solid #ddd;
}

.modal_right {
  width: calc(100% - 385px);
  float: left;
}

.apply_modal_inner .modal_body {
  display: flex;
}

.apply_modal_inner .modal_body .bank_name {
  text-align: center;
  border-bottom: 1px solid #ddd;
  padding: 12px 0;
}

.apply_option {
  text-align: center;
}

.single_option {
  text-align: left;
  padding: 0 20px;
  margin-bottom: 34px;
}

.single_option .bcbd_label {
  text-align: left;
  color: #747474;
  font-size: 15px;
  margin-bottom: 5px;
}

.apply_option h4 {
  color: #544d4d;
  font-weight: 500;
  padding-top: 15px;
  padding-bottom: 15px;
  font-family: 'Exo 2', sans-serif !important;
}

.loan_bank_option_output ul {
  display: block;
}

.loan_bank_option_output {
  float: none;
  background: #f2fafd;
  z-index: 0;
  position: relative;
}

.loan_bank_option_output ul li {
  width: 33.33%;
  float: left;
  text-align: center;
  padding: 22px 0px;
  position: relative;
}

.loan_bank_option_output ul li h5 {
  font-size: 15px !important;
  font-weight: 400;
  color: #636363 !important;
  font-family: 'Exo 2', sans-serif !important;
}

.loan_bank_option_output ul li h4 {
  color: #24708c;
  font-weight: 600;
  font-size: 17px;
  font-family: 'Exo 2', sans-serif !important;
  line-height: 28px;
}

.loan_bank_option_output ul li::after {
  content: '';
  position: absolute;
  right: 0;
  height: 50px;
  background: #ddd;
  width: 1px;
  top: 50%;
  transform: translateY(-50%);
}

.loan_bank_option_output ul li:last-child::after {
  display: none;
}

.aply_loan_inner > ul > li,
.aply_output_container_all > ul > li {
  width: 50%;
  float: left;
  border-right: 1px solid #ddd;
}

.aply_outptop_title h4 {
  font-weight: 600;
  font-size: 15px;
  color: #24a8d9;
  text-align: center;
}

.aply_outptop_title {
}

.aply_outptop_title {
  overflow: hidden;
  border-bottom: 1px solid #ddd;
  padding: 13px 0px;
}

.aply_loan_inner ul > li h5,
.apl_chrage_cont ul > li h5 {
  font-weight: 400;
  font-size: 15px;
  color: #747474;
}

.aply_loan_inner {
  height: inherit;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.apl_chrage_cont .aply_output_body li {
  padding: 12px 15px;
  border-bottom: 1px solid #ddd;
}

.aply_loan_inner li h6,
.apl_chrage_cont .aply_output_body li h6 {
  font-size: 16px;
  color: #686868;
  padding-top: 5px;
  font-weight: 400;
}

.apply_loan_total h5 {
  color: #48c9ff;
  font-weight: 400;
  font-size: 15px;
}

.apply_loan_total {
  padding: 0px 15px;
}

.apply_loan_total h6 {
  color: #686868;
  font-size: 15px;
  line-height: 25px;
  font-weight: 400;
}

.apl_chrage_cont .apply_loan_total {
  background: #f2fafd;
  padding: 18px 15px;
}

.apl_chrage_cont .aply_output_body {
  overflow: auto;
  height: 201px;
}

.aply_totl_return_amount ul li h5 {
  display: inline-block;
  font-size: 15px;
  color: #797979;
  width: calc(100% - 80px);
  font-weight: 400;
}

.aply_totl_return_amount ul li h6 {
  font-weight: 600;
  color: #686868;
  display: inline-block;
  font-size: 15px;
  width: 70px;
  font-weight: 400;
}

.aply_totl_return_amount ul li {
  text-align: right;
  padding-right: 35px;
  padding-bottom: 12px;
}

.aply_totl_return_amount .aply_output_body {
  padding: 25px 0px;
  border-bottom: 1px solid #ddd;
}

.aply_totl_return_amount .apply_loan_total {
  display: flex;
  flex-flow: column;
  text-align: center;
  color: #48c9ff;
  padding: 33px 0;
}

.aply_totl_return_amount .apply_loan_total h5 {
  color: #48c9ff;
  font-weight: 400;
  line-height: 35px;
}

.aply_totl_return_amount .apply_loan_total h6 {
  color: #48c9ff;
  font-weight: 400;
}

#aplyNextDet,
.apply_modal_btn {
  width: 100%;
  font-size: 19px;
  color: #ffffff;
  border: none;
  padding: 10px 0;
  cursor: pointer;
  background: #00add9 !important; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #0c65b7 0%,
    #25a8da 85%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #0c65b7 0%,
    #25a8da 85%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #0c65b7 0%,
    #25a8da 85%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0c65b7', endColorstr='#25a8da', GradientType=1); /* IE6-9 */
}

#aplyChgBack {
  padding-top: 5px;
  padding-bottom: 5px;
  display: block;
}

#aplyChgBack i {
  display: inline-block;
  padding-right: 5px;
}

.mobile_v_btn_area {
  border-top: 1px solid #ddd;
  padding-left: 15px;
  width: 100%;
  overflow: hidden;
}

/*
#apply_next{
    width: 50%;
}
#aplyChgBack{
    width: 50%;
}
*/
/*
.aply_output_container_all button{
    width: 50%;
}
*/
/*apply modal2*/
.apply_output_filds_inner {
  padding: 15px 15px;
}

.apply_output_fields .input-group {
  margin-bottom: 15px;
}

.apply_output_fields .input-group .form-control {
  border-color: #ddd;
  padding: 0px 15px;
  line-height: 45px;
  height: 45px;
}

.apply_output_filds_inner .input-group .bcbd_group {
  width: calc(50% - 15px);
  position: relative;
}

.apply_output_filds_inner .input-group .bcbd_group .bcbd_label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  font-size: 17px;
  color: #777;
  z-index: 99;
}

.apply_output_filds_inner .input-group .bcbd_group .form-control {
  padding: 0px 20px;
  line-height: 45px;
  height: 45px;
}

.apply_output_filds_inner .bcbd_group .form-control {
  padding: 0px 20px;
}

.apply_output_filds_inner .bcbd_group .hasDatepicker {
  padding: 0px 0px 0px 35px;
  line-height: 45px;
  height: 45px;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #777;
  width: 100%;
}

.apply_output_filds_inner .input-group .bcbd_group:first-child {
  margin-right: 30px;
}

.apply_output_filds_inner
  .bcbd_group
  span.select2-selection.select2-selection--single {
  border-color: transparent;
  height: 43px;
  line-height: 45px;
}

.apply_output_filds_inner .select2-container {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100% !important;
  padding-left: 30px;
}

.apply_output_filds_inner
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #444;
  line-height: 40px;
  font-size: 14px;
}

.apply_output_filds_inner
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  top: 10px !important;
}

.bcbd_check {
  width: 23px;
  display: inline-block;
  position: relative;
  vertical-align: top;
}

.bcbd_check label {
  height: 26px;
  width: 26px;
  border: 1px solid #48c9ff;
  border-radius: 4px;
  margin-bottom: 0;
  cursor: pointer;
  color: #48c9ff;
  margin-right: 10px;
  display: inline-block;
}

.bcbd_check label::before {
  content: '\F00C';
  font-family: FontAwesome;
  font-size: 23px;
  position: absolute;
  left: 2px;
  top: -4px;
  color: #48c9ff;
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  opacity: 0;
  visibility: hidden;
}

.bcbd_check input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.input-group.bcbd_check {
  margin-top: 15px;
  width: 100%;
  margin-bottom: 0;
}

.apply_output_filds_inner {
  padding: 15px 15px 0px 15px;
}

.apply_output_filds_inner .input-group.bcbd_check span {
  display: inline-block;
  margin-left: 12px;
  line-height: 25px;
  width: calc(100% - 46px);
  color: #687270;
  vertical-align: top;
  margin-top: -7px;
}

.bcbd_check input[type='checkbox']:checked ~ label::before {
  transform: scale(1);
  visibility: visible;
  opacity: 1;
}

.apply_output_fields .apply_modal_btn {
  background: #f79629;
}

.single_option .bcbd_inputgrup input {
  padding-left: 15px;
  font-size: 14px;
}

.single_option .bcbd_inputgrup input:focus {
  box-shadow: none;
}

.apply_option .bcbd_inputgrup .input-group-prepend span {
  padding: 0;
  line-height: 45px;
  height: 45px;
  width: 50px;
  font-size: 16px;
}

/*------------------------------
    ==credit card apply loan==
------------------------------*/
.ccard_modal_left p {
  padding-top: 10px;
}

.ccard_modal_left p span {
  color: #24a8d9;
}

.credit_card_add_carousel {
  width: 100%;
  display: block;
}

/*---------------------------------
    ==Mobile call support==
---------------------------------*/
.call_content .svg {
  height: 70px;
  margin-bottom: 15px;
  fill: #0f6cbb;
}

.call_content {
  text-align: center;
}

.call_content h5 {
  font-size: 16px;
  color: #777;
}

.mobile_call_modal .modal-footer {
  padding: 0;
}

.mobile_call_modal .modal-footer a {
  float: left;
  width: 50%;
  margin: 0;
  border-radius: 0;
  background: #fff;
  color: #219ed5;
  border: none;
  font-size: 15px;
  line-height: 30px;
}

.mobile_call_modal .modal-footer a:first-child {
  border-right: 1px solid #ddd;
  background: #f5f5f5;
}

.mobile_call_modal .modal-header {
  padding-top: 10px;
  padding-bottom: 10px;
}

/*---------------------------
    ==Credit Card Quick View
---------------------------*/
.side_stick {
  position: fixed;
  z-index: 100;
  right: -58px;
  display: none;
  transition: all 0.3s linear;
  top: 132px;
}

.side_stick.active {
  right: -102px;
}

.side_stick_inner {
  background: #fff;
  transform: rotate(-90deg);
  border: 1px solid #111;
}

.view_quick_op {
  background: #fff;
  font-weight: 600;
  font-size: 15px;
  display: flex;
  flex-flow: row;
  position: relative;
}

.view_quick_op i {
  display: inline-block;
  margin-left: 10px;
  color: #111;
  font-size: 50px;
}

.gear_box i {
  transform: rotate(90deg);
}

.view_quick_op span {
  display: inline-block;
  padding: 10px;
}

.gear_box {
  padding-left: 0;
  position: absolute;
  left: 50%;
  top: -34px;
  font-size: 36px;
  height: 32px;
  padding: 0;
}

.cc_table_left.active {
  right: 0;
  visibility: visible;
  opacity: 1;
  left: inherit;
}

#leftClose {
  display: none;
}

/*------------------------------
    ==Start Blog Page==
------------------------------*/
.blog-navigation {
  padding-top: 0;
  padding-bottom: 0;
}

.blog-navigation .navbar-nav > li > a {
  color: #595959;
  font-weight: 600;
  font-size: 14px;
  line-height: 34px;
  padding: 0 28px !important;
}

.blog-navigation .navbar-nav > li > a {
  color: #595959;
  font-weight: 600;
  font-size: 15px;
  line-height: 64px;
  padding: 0 28px !important;
}

.blog-navigation .navbar-nav li a:hover {
  background: #f3f6f9;
}

.blog-navigation {
  background: transparent;
  margin-top: -64px;
}

.news-bnr .card_compare_bnr_inner {
  height: 260px;
  padding-top: 0px;
}

.news-bnr .card_compare_bnr_inner h2 {
  font-weight: 700;
  font-size: 48px;
}

.blog-navigation-inner {
  background: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.post_image img {
  width: 100%;
  border-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0;
}

.post_title {
  font-weight: 800;
  font-size: 25px;
  color: #595959;
  line-height: 33px;
  padding-top: 24px;
  padding-bottom: 12px;
  padding-left: 30px;
  display: block;
}

.blog_post {
  background: #fff;
  margin-bottom: 30px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

.enterprener_parent {
  background: #f3f6f9;
  padding-top: 30px;
}

.psot_meta {
  padding-left: 30px;
  font-size: 17px;
  color: #c2c2c2;
  font-weight: 300;
}

.post_text {
  padding: 10px 10px 15px 30px;
  font-size: 15px;
  color: #595959;
  line-height: 26px;
}

.post_text a {
  color: #0ab3e4;
}

.post_text a:hover {
  letter-spacing: 0.7px;
}

.psot_meta i {
  display: inline-block;
  padding: 0 6px;
}

.sidebar_title {
  font-weight: 700;
  font-size: 20px;
  color: #595959;
  padding-bottom: 25px;
  line-height: 20px;
}

.blog-widget {
  background: #fff;
  border-radius: 5px;
  margin-bottom: 30px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
}

.blog-widget img {
  max-width: 100%;
}

.blog-widget .sidebar-post-title {
  font-weight: 600;
  color: #595959;
  font-size: 20px;
  display: block;
  transition: all 0.3s linear;
}

.sidebar-post-title:hover,
.sidebar-post-title {
  line-height: 25px;
  padding: 10px 10px 0px 15px;
  transition: all 0.3s linear;
}

.sidebar-post-meta {
  padding-left: 15px;
  font-size: 15px;
  color: #c2c2c2;
  padding-top: 4px;
  padding-bottom: 12px;
}

.blog_post:hover {
  box-shadow: 0px 11px 5px rgba(0, 0, 0, 0.1);
}

/*===================================================
    ==End Style css===
===================================================*/

/*-----------------------------------
    new style
------------------------------------*/
.elg-progressparent {
  width: calc(100% - 15px);
  margin-top: 13px;
}

.elg-progressbar {
  height: 19px;
  display: block;
  position: relative;
  width: 100%;
  background: #adddf3;
  border-radius: 5px;
}

.elg-prgNumber {
  position: absolute;
  color: #fff;
  z-index: 5;
  left: 50%;
  top: -1px;
  font-size: 13px;
  margin-left: -33px;
}

.elg-prgLayer {
  position: absolute;
  background: #1f97d3;
  height: 100%;
  border-radius: 5px;
}

/* ---------------------------------------
    ==Contact css==
 --------------------------------------*/
.contact-banner-inner {
  padding-top: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.contact-banner-inner ul {
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
}

.contact-banner-inner h2 span {
  font-weight: 600;
}

.single-map-info-parent {
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.single-map-info {
  flex: 0 0 33.33%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  padding: 40px 20px;
  border-right: 1px solid #ddd;
}

section.contact-map {
  position: relative;
}

.map-info {
  position: absolute;
  width: 100%;
  z-index: 5;
  top: -52px;
}

.map-info-right p {
  color: #595959;
  font-size: 15px;
  line-height: 28px;
}

.single-map-info:last-child {
  border-right: 0;
}

.contact-form {
  position: relative;
}

.contact-form input {
}

.contact-form-inner {
  background: #f3f8fa;
  overflow: hidden;
  padding: 50px 0;
  position: relative;
  margin-top: -70px;
  border-radius: 3px;
}

.contact-form input {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #ddd;
  line-height: 45px;
  height: 52px;
  padding-left: 15px;
  font-size: 15px;
  margin-bottom: 15px;
  transition: all 0.3s linear;
}

.contact-form textarea {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #ddd;
  line-height: 45px;
  padding-left: 15px;
  font-size: 15px;
  margin-bottom: 15px;
  height: 150px;
  transition: all 0.3s linear;
}

.contact-form button {
  background: #45c4fb;
  border: none;
  padding: 10px 35px;
  border-radius: 3px;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  transition: all 0.3s linear;
  letter-spacing: 0.5px;
}

.contact-form input:focus,
.contact-form input:hover,
.contact-form textarea:focus,
.contact-form textarea:hover {
  border-color: #45c4fb;
  outline: none;
}

.contact-form button:hover {
  letter-spacing: 1px;
}

.contact-form {
  position: relative;
  padding-bottom: 60px;
}

/* ------------------------------------
    ==Tersms And Condition==
------------------------------------ */
.terms-condition-tab > ul > li {
  display: block;
  width: 100%;
  background: #48c9ff;
  color: #fff;
  font-size: 17px;
  font-weight: 400;
  padding: 10px 12px;
  border-bottom: 1px solid #36bff9;
  position: relative;
  z-index: 5;
  transition: all 0.3s linear;
  cursor: pointer;
}

.terms-content h5 {
  font-size: 20px;
  color: #48c9ff;
  padding-top: 25px;
  padding-bottom: 25px;
}

.terms-content p {
  font-size: 15px;
  line-height: 25px;
  color: #595959;
}

.terms-innter {
  border-top: 2px solid #48c9ff;
}

.terms-condidition-area {
  margin: 50px;
}

.terms-nav a::before {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  top: 0;
  transform: scaleX(1);
  transition: all 0.3s linear;
  z-index: -1;
  opacity: 0;
}

.terms-nav a:hover::before {
  transform: scaleX(1);
  opacity: 1;
}

.terms-nav a:hover {
  color: #48c9ff;
}

.disclaim-content a {
  color: #23a4d8;
}

/*------------------------------
    ==Start Complain Page ==
------------------------------*/
.complains-inner-area {
  border-top: 2px solid #45c4fb;
  padding: 0 30px;
  background: #fff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  padding-bottom: 30px;
}

.complain-area {
  background: #f6f9fb;
  padding: 40px 0;
}

.complains-inner-area h4 {
  padding-top: 23px;
  padding-bottom: 15px;
  color: #595959;
}

.complains-inner-area p {
  color: #595959;
  line-height: 26px;
}

.complains-inner-area ul li {
  line-height: 27px;
  color: #595959;
}

.disclaim-content {
  padding-top: 20px;
}

/* -------------------------------
    ==Start Privacy-Policy==
------------------------------- */
.privacy-content p {
  padding-bottom: 10px;
}

.privacy-content ul {
}

.privacy-content ul li {
  line-height: 30px;
  color: #595959;
  position: relative;
  padding-left: 16px;
}

.privacy-content ul li::before {
  content: '';
  position: absolute;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background: #999393;
  top: 13px;
  left: 0;
  transition: all 0.3s linear;
}

.privacy-content ul li:hover::before {
  background: #33b2e7;
}

/*---------------------------

---------------------------*/
/*.slick-arrow {*/
/*position: absolute;*/
/*}*/
.advertisement-slider {
}

.slick-slide {
  padding: 10px 15px;
}

.partners-slider .slick-slide {
  padding: 0px 15px;
}

.partners-slider .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
}

.partners-slider .slick-track img {
  /*max-width: 70%;*/
  width: initial !important;
  max-height: 80px;
}

.blog_image a img {
  width: 100%;
  object-fit: cover;
}

.client_image_slider {
  padding-left: 0px;
}

.single_el_ft_img {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  top: -100%;
  transition: all 0.3s linear;
  transform: scale(0.8);
}

.slick-current .single_el_ft_img {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  /*background-image: url(../images/down-arrow.png) !important;*/
  background-repeat: no-repeat !important;
  background-position: calc(100% - 10px) 16px !important;
  padding-right: 20px;
}

.eligibility-inner select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(../images/downicon.png) !important;
  background-repeat: no-repeat !important;
  background-position: calc(100% - 10px) 22px !important;
  padding-right: 20px;
}

.eligibility-inner select:focus {
  outline: none !important;
  box-shadow: none;
}

/*input type range*/
input[type='range'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 10px 0;
  width: 100%;
  z-index: 9;
  position: relative;
  background: transparent;
}

input[type='range']:focus {
  outline: none;
}

input[type='range']::-webkit-slider-runnable-track {
  border: none;
  background: 0 0;
  height: 20px;
  cursor: pointer;
  animate: 0.2s;
  -webkit-box-shadow: 0 0 0 #000, 0 0 0 #0d0d0d;
  box-shadow: 0 0 0 #000, 0 0 0 #0d0d0d;
  border: 0 solid #000101;
}

input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border: 0px solid #000000;
  height: 40px;
  width: 20px;
  background: #1489d5;
  cursor: pointer;
  -webkit-appearance: none;
  position: relative;
  z-index: 99;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}

input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border: 0px solid #000000;
  height: 40px;
  width: 20px;
  background: #1489d5;
  cursor: pointer;
  -webkit-appearance: none;
  position: relative;
  z-index: 99;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;

  /* box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border: 0px solid #000000;
  height: 22px;
  width: 22px;
  background: #1489d5;
  cursor: pointer;
  -webkit-appearance: none;
  position: relative;
  z-index: 99;
  border-radius: 0px;
  margin-top: -3px;
  border-radius: 50%; */
}

.sidebar-range-slider input[type='range']::-moz-range-thumb {
  height: 30px;
  width: 15px;
  margin-top: -7px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 25px;
}

.sidebar-range-slider input[type='range']::-webkit-slider-thumb {
  height: 30px;
  width: 15px;
  margin-top: -3px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 25px;
}

input[type='range']::-ms-track {
  width: 100%;
  height: 12.8px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  border-width: 39px 0;
  color: transparent;
}

input[type='range']::-ms-fill-lower {
  background: #ac51b5;
  border: 0px solid #000101;
  border-radius: 50px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}

input[type='range']::-ms-fill-upper {
  background: #ac51b5;
  border: 0px solid #000101;
  border-radius: 50px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}

input[type='range']::-ms-thumb {
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border: 0px solid #000000;
  height: 20px;
  width: 39px;
  border-radius: 7px;
  background: #65001c;
  cursor: pointer;
}

input[type='range']:focus::-ms-fill-lower {
  background: #ac51b5;
}

input[type='range']:focus::-ms-fill-upper {
  background: #ac51b5;
}

.loan_scale_inner_container h5 span {
  border: 0;
  color: #f6931f;
  font-weight: bold;
}

.loan_scale_inner_container .bcbd_select {
  height: 40px !important;
}

/*------------------------------------
    ==Fixed Deposit==
------------------------------------*/
.sinle-fd-account {
  background: rgba(255, 255, 255, 0.25);
  padding: 25px 0;
}

.sinle-fd-account .svg {
  fill: #fff;
  height: 42px;
  width: 58px;
}

.sinle-fd-account span {
  display: block;
  color: #fff;
  font-size: 15px;
  padding-top: 10px;
  letter-spacing: 0.5px;
}

.fixed-deposit-account-type-slider .slick-next {
  right: 0;
}

.fixed-deposit-account-type-slider .slick-arrow {
  position: absolute;
  z-index: 9;
  top: 35%;
  transform: translateY(-50%);
  border: none;
  font-size: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.fixed-deposit-account-type-slider {
  position: relative;
}

.fixed-deposit-account-type-slider .slick-prev {
  left: 0;
}

.fixed-deposit-account-type-slider .slick-slide {
  margin: 0 0;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  padding: 0;
}

.fixed-deposit-account-type-slider .slick-slide:last-child {
  border-right: 0;
}

.fixed-dps-banner-inner {
  height: 211px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
}

.fixed-dps-banner-inner h2 {
  color: #fff;
}

.fixed-dps-banner-inner h2 span {
  font-weight: 700;
}

.fixed-deposit-account-range-slider .slick-slide {
  margin: 0;
}

.fixed-deposit-range-slider .slider_range_bottom .sl_div:not(:last-child) {
  width: 60%;
}

.fixed-deposit-range-slider .slider_range_bottom .sl_div:last-child {
  width: calc(40% - 25px);
  margin-right: 0;
}

.fixed-deposit-range-slider
  .slider_range_bottom
  .sl_div:last-child
  .bcbd_compare_btn {
  width: 100%;
  text-align: center;
  line-height: 4px;
}

.fixed-deposit-range-slider
  .slider_range_bottom
  .sl_div:last-child
  .bcbd_compare_btn:hover {
  color: #ffffff;
}

.loan_scale_inner_container.fixed-deposit-range-slider {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.fixied_deposit_option {
  height: 100vh;
}

/* fixed deposit grid table */
.fd-table-grid .lon-grid:last-child {
  flex: 0 0 10%;
}

.fd-table-grid .lon-grid:last-child {
  flex: 0 0 9% !important;
}

.fd-table-grid .lon-grid:first-child,
.fd-table-grid .lon-grid:last-child {
  flex: 0 0 13.4% !important;
}

.fd-table-grid .lon-grid:nth-child(9) {
  flex: 0 0 10% !important;
}

.fd-table-grid .lon-grid:not(:last-child) {
  flex: 0 0 9%;
}

.loan_head_option.fd-table-grid {
  margin-left: -2px;
}

.fd-table-grid .lon-grid:nth-child(10) {
  flex: 0 0 13.6% !important;
}

.fd-table-grid .rt_table_btn {
  width: 125px;
}

.fd-table-grid .lon-grid {
  font-weight: 400;
  font-size: 13px;
}

.last-checked {
  padding-bottom: 5px;
}

.lon-grid i {
  display: inline-block;
  padding-right: 6px;
  color: #777777;
}

.sinle-fd-account {
  text-align: center;
}

.sinle-fd-account img,
.sinle-fd-account .svg {
  text-align: center;
  display: inline-block;
}

.fixed-deposit-account-range-slider .slick-slide {
  padding: 0;
}

/*double money*/
.double-money-banner {
  padding-top: 150px;
  padding-bottom: 40px;
}

.double-money-banner h2 {
  font-size: 36px;
}

.double_triple_desc {
  color: white;
  text-align: center;
  display: inline-block;
}

.loan_table_container .bcbd_select,
.dps_inner .bcbd_select,
.dps_tbale_area .bcbd_select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-left: 15px;
}

/*.slick-current .sinle-fd-account{*/
/*background: rgba(255,255,255,.50);*/
/*}*/

.eligibility_process {
  height: 100% !important;
  padding-top: 150px;
  padding-bottom: 100px;
}

.success_info_bnr_inr .svg {
  height: 50px;
  width: 50px;
}

/*-------------------------------------
    ==Fixed Deposit Modal==
-------------------------------------*/
.choose-tener-modal .modal-header {
  background: #1e6b9c;
  padding: 10px 10px;
  margin-bottom: 0;
  border-bottom: none;
}

.choose-tener-modal .modal-header h5 {
  font-weight: 400;
  font-size: 20px;
  color: #fff;
}

.choose-tener-modal {
  border: none;
  outline: none;
  border-radius: 0 !important;
}

.choose-tener-modal .modal-dialog,
.choose-tener-modal .modal-dialog .modal-content {
  border-radius: 0;
  border: none;
}

.choose-tener-modal .modal-body {
  padding: 0 0px 30px 0px;
}

.styles_modal__gNwvD {
  padding: 0 !important;
}

.choose-tener-modal > ul > li {
  background: #208dd3;
  width: 33.33% !important;
  color: #fff;
  text-transform: capitalize !important;
  padding: 0.8rem 1rem !important;
  text-align: center;
  cursor: pointer;
}

.choose-tener-modal > ul > li:not(:last-child) {
  border-right: 1px solid #93dfff !important;
}

.single-inner-tab ul {
  padding: 50px 0;
}

.single-inner-tab ul li {
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
  color: #686868;
}

.tab-botom-box {
  background: #fffbd7;
  font-size: 15px;
  color: #686868;
  padding: 5px 30px;
  border: 1px dashed #90893f;
  border-radius: 3px;
}

.single-inner-tab {
  padding: 0 30px 30px 30px;
}

#prematureClosure .single-inner-tab {
  padding-top: 70px;
}

.single-inner-tab p {
  color: #040101;
  line-height: 28px;
  font-family: 'Exo 2', sans-serif;
}

#prematureClosure .tab-botom-box {
  margin-top: 70px;
}

#requireDocuments {
}

#requireDocuments .tab-top {
  padding: 40px 0;
}

#requireDocuments .tab-botom-box {
  margin-top: 40px;
}

/*--------------------------------
    ==Start Choose Tenure Css==
--------------------------------*/
.chose-tenure-table .gd-7 .lon-grid {
  width: 15%;
}

.chose-tenure-table .gd-7 .lon-grid:first-child {
  width: 18% !important;
}

/*-----------------------------
    ==Footer Svg==
-----------------------------*/
.ft_info li a .svg,
.ft_info li p .svg {
  width: 40px;
  height: 20px;
}

/*------------------------
    Advertisement
------------------------*/
.bcbd_blog_slider,
.testimonial-slider,
.advertisement-slider {
  position: relative;
}

.fixed-deposti-slider .slick-next:before,
.bcbd_blog_slider .slick-next:before,
.testimonial-slider .slick-next:before,
.advertisement-slider .slick-next:before {
  content: '\F105' !important;
  font-family: FontAwesome;
  color: #208dd3;
  font-size: 40px;
  text-shadow: 0px 0px 10px rgb(0, 176, 255);
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.fixed-deposti-slider .slick-prev:before,
.bcbd_blog_slider .slick-prev:before,
.testimonial-slider .slick-prev:before,
.advertisement-slider .slick-prev:before {
  content: '\f104' !important;
  font-family: FontAwesome;
  color: #208dd3;
  font-size: 40px;
  text-shadow: 0px 0px 10px rgb(0, 176, 255);
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.bcbd_blog_slider .slick-prev:hover:before,
.bcbd_blog_slider .slick-next:hover:before,
.testimonial-slider .slick-prev:hover:before,
.testimonial-slider .slick-next:hover:before,
.advertisement-slider .slick-prev:hover:before,
.advertisement-slider .slick-next:hover:before {
  color: #207ac0 !important;
  animation: jello 1s linear;
  opacity: 1;
}

.range-fill {
  background: #02bdf2;
  width: 0;
  height: 20px;
  z-index: 1;
  position: absolute;
  top: 14px;
  z-index: 1;
  border-radius: 3px;
}

.slider_range_outer::after {
  content: '';
  position: absolute;
  left: 0;
  background: #e0e0e0;
  width: 100%;
  height: 20px;
  z-index: 0;
  top: 14px;
  border-radius: 3px;
}

.sidebar-range-slider .slider_range_outer::after {
  height: 15px;
  border-radius: 3px;
}

.sidebar-range-slider .range-fill {
  height: 15px;
  border-radius: 3px;
}

/*-----------------------------------
    ==ActiveApplication Us
-----------------------------------*/
.toolkit-inner {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 40px 0;
  background: #fff;
}

.toolkit-inner p {
  color: #22a1d7;
  line-height: 30px;
  text-align: center;
  font-size: 17px;
}

.about-item-image img {
  max-width: inherit;
}

.about-us-toolkit {
  position: relative;
  top: 0;
  margin-top: -50px;
}

.single-about-inner {
  display: flex;
  flex-flow: row;
  padding-bottom: 60px;
  padding-top: 60px;
  align-items: center;
}

.about-item-parent {
}

.single-about-item {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.single-about-item:first-child .about-item-image {
  padding-left: 35px;
}

.about-item-content h4 {
  font-weight: 400;
  color: #6c6d6e;
  margin-bottom: 22px;
}

.about-item-content p {
  line-height: 30px;
  color: #6c6d6e;
}

.single-about-item:nth-child(even) .single-about-inner {
  flex-flow: row-reverse;
}

.single-about-item:nth-child(even) {
  background: #f4f9fc;
}

.single-about-item:nth-child(even) {
  flex-flow: row-reverse;
}

.single-about-item:nth-child(even) .about-item-image {
  padding-right: 80px;
}

.single-about-item:nth-child(even) .about-item-content {
  text-align: right;
}

.single-about-item:nth-child(3) .about-item-image {
  padding-left: 110px;
}

/*----------------------------------
    ==ActiveApplication Team==
----------------------------------*/
.single-core-team {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 60px 0;
  box-shadow: 0px 0px 1px #bdb8b8;
  border-radius: 5px;
  background: #fff;
  text-align: center;
}

.single-core-team:hover {
  transform: translateY(-5px);
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
}

.single-core-team h6 {
  font-weight: 600;
  color: #6c6d6e;
  font-size: 20px;
  line-height: 40px;
}

.single-core-team p {
  color: #686868;
  font-size: 15px;
  line-height: 23px;
}

.core-member-image {
  height: 110px;
  width: 110px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 25px;
}

.inner-title {
  font-size: 30px;
  color: #6c6d6e;
}

.inner-title span {
  font-weight: 600;
}

.single-core-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}

.single-core-info .svg {
  height: 40px;
  width: 40px;
  fill: #fff;
}

.info-logo {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;
}

.info-logo::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  border: 2px dotted #fff;
  border-radius: 50%;
  animation: rotator 2s linear infinite;
}

.single-core-info h6 {
  color: #ffffff;
  font-size: 16px;
}

/*-----------------------------
    statistics
-----------------------------*/

.company-statics-parent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.company-statics-parent .single-statistics {
  flex: 0 0 50%;
}

.single-statistics h1 {
  font-size: 50px;
  color: #1c95e6;
  font-weight: 700;
}

.single-statistics {
  text-align: center;
  position: relative;
}

.single-statistics::after {
  content: '';
  position: absolute;
  height: 85px;
  width: 1px;
  background: #3db6f0;
  right: 0;
  top: 0;
}

.single-statistics {
  color: #1c95e6;
  font-size: 30px;
}

.ptb-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.single-statistics:last-child::after {
  display: none;
}

/* ----------------------------------
    ==Sign in sign up
---------------------------------- */
.sign-area .sl-div {
  flex: 0 0 50%;
  max-width: 50%;
  background: #fff;
}

.sign-area {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  padding-top: 46px;
  overflow: hidden;
}

.sl-sn-option .svg {
  height: 50px;
  width: 50px;
}

.sn-available-option {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
}

.sn-available-option .sl-sn-option {
  flex: 0 0 calc(33.33% - 10px);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  padding: 15px 0;
  text-align: center;
  max-width: 33.33%;
}

.sn-available-option {
  background: #f3f8fc !important;
  border-radius: 5px;
  padding: 15px 15px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.sl-sn-option:first-child {
  background: #9b72b2;
}

.sl-sn-option .svg {
  fill: #fff;
  margin-bottom: 9px;
}

.sl-sn-option p {
  color: #fff;
  font-size: 14px;
}

.sl-sn-option:nth-child(2) {
  background: #4db3e3;
}

.sl-sn-option:not(:last-child):not(:nth-child(3)) {
  margin-right: 15px;
}

.sl-sn-option:nth-child(3) {
  background: #68b8c9;
}

.sl-sn-option:nth-child(4) {
  background: #e28f5e;
}

.sl-sn-option:nth-child(5) {
  background: #e3b151;
}

.sl-sn-option:nth-child(6) {
  background: #a4c173;
}

.mb-15 {
  margin-bottom: 15px;
}

.sign-option-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sign-option-header a {
  flex: 0 0 50%;
  text-align: center;
  background: #3db7f0;
  font-size: 18px;
  color: #fff;
  line-height: 55px;
  font-weight: 600;
}

.sign-option-header a.active {
  background: #fff;
  color: #595959;
}

.sign-area .form-control {
  height: 45px !important;
  color: #333;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.signup-container .form-control {
  margin-bottom: 15px;
}

.sign-container {
  padding: 20px 90px;
}

.sign-banner {
  padding: 100px 0;
}

.sign-area .btn {
  background: #3db7f0 !important;
  border-color: #3db7f0;
  color: #fff;
}

.sign-banner {
  height: 100vh;
}

.sign-banner h2 {
  padding-top: 52px;
}

.sn-field-area {
  padding: 0px 0px 0 15px;
}

.pl-7 {
  padding-left: 7px;
}

.pr-7 {
  padding-right: 7px;
}

.signup-container {
  padding: 15px 22px 15px 0px;
}

.forget-field {
  position: absolute;
  right: 0%;
  height: 100%;
  top: 0;
  background: #fff;
  z-index: 5;
  padding: 0 100px;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  transition: 0.3s linear;
}

.forget-field.active {
  visibility: visible;
  opacity: 1;
}

/* -------------------------------
    ==Advertisement==
------------------------------- */
.advertised-card select {
  font-weight: 600;
  font-size: 15px;
}

.advertised-card {
  background: #fff;
  padding: 15px 15px;
  border-radius: 5px;
}

img {
  max-width: 100%;
}

.advertised-card h3 {
  font-weight: 300;
  color: #686868;
  font-size: 20px;
}

.advertised-card h3 strong {
  font-weight: 700;
}

.card-icon {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.card-icon {
}

.card-icon i {
  font-size: 24px;
}

.leaf-green {
  background: #6db867;
}

.single-ad-card-content h6 {
  font-size: 15px;
  font-weight: 600;
}

.single-ad-card-content p {
  color: #686868;
  font-weight: 400;
}

.card-highlight-features {
  background: rgba(255, 255, 255, 0.15);
  padding: 20px 20px 20px 30px;
  border-radius: 5px;
}

.card-highlight-features p span {
  font-size: 15px;
  color: #ffffff;
  line-height: 32px;
  display: inline-block;
  width: calc(100% - 25px);
}

.card-highlight-features i {
  display: inline-block;
  margin-right: 5px;
}

.card-highlight-features .aply-btn {
  padding: 12px 40px;
  font-weight: 600;
  font-size: 18px;
  border-radius: 3px;
  box-shadow: 0px 4px 0px 0px #cb7712;
  border: none;
  display: inline-block;
  outline: none;
  transition: all 0.3s linear;
}

.card-highlight-features .aply-btn:active {
  box-shadow: 0 0px 0 #cb7712;
  -webkit-transform: translateY(3px);
  transform: translateY(3px);
  color: #fff;
}

.card-highlight-features .aply-btn i {
  display: inline-block;
  padding-left: 5px;
  font-size: 20px;
}

/* ---------------------------------
    ==Advertisement Table Area
--------------------------------- */
.single-info-column {
  padding: 0px 0px;
}

.default-color {
  background: #48c9ff !important;
}

.single-info-column {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  border: 1px solid #ddd;
  margin-top: -1px;
  background: #f9fcfd;
}

.single-info-column h5 {
  line-height: 50px;
  font-size: 18px;
  padding-left: 10px;
}

.single-info-child:first-child {
  flex: 0 0 40%;
  border-right: 1px solid #ddd;
}

.single-info-child:last-child {
  flex: 0 0 59%;
}

.single-info-child {
  padding: 10px 10px;
  background: #f9fcfd;
}

.single-info-child p {
  font-size: 15px;
  color: #686868;
}

.card-information-table.eligibity-info ul li p {
  font-size: 13px;
  color: #686868;
  position: relative;
  line-height: 22px;
}

.card-information-table h6 {
  font-weight: 600;
  font-size: 15px;
  color: #686868;
  margin-bottom: 5px;
  background: #f3eee0;
}

.card-information-table.eligibity-info ul li {
  padding-left: 10px;
  padding-bottom: 3px;
}

.card-information-table.eligibity-info ul li p i {
  position: absolute;
  left: -9px;
  top: 7px;
}

.card-information-table.eligibity-info .single-info-child:first-child {
  flex: 0 0 50%;
  border-right: 1px solid #ddd;
}

.card-information-table.eligibity-info p {
  font-size: 13px;
}

.card-information-table.eligibity-info .single-info-child:last-child {
  flex: 0 0 50%;
}

.text-bcbd-color {
  color: #48c9ff;
}
.text-black {
  color: #333 !important;
}

.card-information-table.eligibity-info ul li p:last-child {
  color: #48c9ff;
}

/*------------------------------
    ==color css===
------------------------------*/
.pbt-60 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.text-a2d0 {
  color: #17a2d0;
}

.text-young-blue {
  color: #23a4d9;
}

.bg-young-blue {
  background: #23a4d9;
}

.bg-light-alert {
  background: #f7941d;
}

.text-light-alert {
  color: #f7941d;
}

.bg-light-purple {
  background: #8781bd;
}

. {
  background: #fff3ef;
}

.text-light-purple {
  color: #8781bd;
}

.text-leaf-green {
  color: #6db867;
}

.bg-leaf-green {
  background: #6db867;
}

.bg_secondary {
  background: #f4f9fc;
}

.bg-light-dark {
  background: #363636;
}

.color-4 {
  background: #3db6f0;
}

.color-3 {
  background: #f4f9fc;
}

/* ----------------------------------
    Sign in sign up
  ---------------------------------- */
.sign-area .sl-div {
  flex: 0 0 50%;
  max-width: 50%;
  background: #fff;
}

.sign-area {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  padding-top: 46px;
  overflow: hidden;
}

.sl-sn-option .svg {
  height: 50px;
  width: 50px;
}

.sn-available-option {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
}

.sn-available-option .sl-sn-option {
  flex: 0 0 calc(33.33% - 10px);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  padding: 15px 0;
  text-align: center;
  max-width: 33.33%;
}

.sn-available-option {
  background: #f3f8fc !important;
  border-radius: 5px;
  padding: 15px 15px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.sl-sn-option:first-child {
  background: #9b72b2;
}

.sl-sn-option .svg {
  fill: #fff;
  margin-bottom: 9px;
}

.sl-sn-option p {
  color: #fff;
  font-size: 14px;
}

.sl-sn-option:nth-child(2) {
  background: #4db3e3;
}

.sl-sn-option:not(:last-child):not(:nth-child(3)) {
  margin-right: 15px;
}

.sl-sn-option:nth-child(3) {
  background: #68b8c9;
}

.sl-sn-option:nth-child(4) {
  background: #e28f5e;
}

.sl-sn-option:nth-child(5) {
  background: #e3b151;
}

.sl-sn-option:nth-child(6) {
  background: #a4c173;
}

.mb-15 {
  margin-bottom: 15px;
}

.sign-option-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sign-option-header a {
  flex: 0 0 50%;
  text-align: center;
  background: #3db7f0;
  font-size: 18px;
  color: #fff;
  line-height: 55px;
  font-weight: 600;
}

.sign-option-header a.active {
  background: #fff;
  color: #595959;
}

.sign-area .form-control {
  height: 45px !important;
  color: #333;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.signup-container .form-control {
  margin-bottom: 15px;
}

.sign-container {
  padding: 20px 90px;
}

.sign-banner {
  padding: 100px 0;
}

.sign-area .btn {
  background: #3db7f0 !important;
  border-color: #3db7f0;
  color: #fff;
}

.sign-banner {
  height: 100vh;
}

.sign-banner h2 {
  padding-top: 52px;
}

.sn-field-area {
  padding: 0px 0px 0 15px;
}

.pl-7 {
  padding-left: 7px;
}

.pr-7 {
  padding-right: 7px;
}

.signup-container {
  padding: 15px 22px 15px 0px;
}

.forget-field {
  position: absolute;
  right: 0%;
  height: 100%;
  top: 0;
  background: #fff;
  z-index: 5;
  padding: 0 100px;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  transition: 0.3s linear;
}

.forget-field.active {
  visibility: visible;
  opacity: 1;
}

/* -------------------------------
    ==Advertisement==
------------------------------- */
.advertised-card select {
  font-weight: 600;
  font-size: 15px;
}

.advertised-card {
  background: #fff;
  padding: 15px 15px;
  border-radius: 5px;
}

img {
  max-width: 100%;
}

.advertised-card h3 {
  font-weight: 300;
  color: #686868;
  font-size: 20px;
}

.advertised-card h3 strong {
  font-weight: 700;
}

.card-icon {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.card-icon {
}

.card-icon i {
  font-size: 24px;
}

.leaf-green {
  background: #6db867;
}

.single-ad-card-content h6 {
  font-size: 15px;
  font-weight: 600;
}

.single-ad-card-content p {
  color: #686868;
  font-weight: 400;
}

.card-highlight-features {
  background: rgba(255, 255, 255, 0.15);
  padding: 20px 20px 20px 30px;
  border-radius: 5px;
}

.card-highlight-features p span {
  font-size: 15px;
  color: #ffffff;
  line-height: 32px;
  display: inline-block;
  width: calc(100% - 25px);
}

.card-highlight-features i {
  display: inline-block;
  margin-right: 5px;
}

.card-highlight-features .aply-btn {
  padding: 12px 40px;
  font-weight: 600;
  font-size: 18px;
  border-radius: 3px;
  box-shadow: 0px 4px 0px 0px #cb7712;
  border: none;
  display: inline-block;
  outline: none;
  transition: all 0.3s linear;
}

.card-highlight-features .aply-btn:active {
  box-shadow: 0 0px 0 #cb7712;
  -webkit-transform: translateY(3px);
  transform: translateY(3px);
  color: #fff;
}

.card-highlight-features .aply-btn i {
  display: inline-block;
  padding-left: 5px;
  font-size: 20px;
}

/* ---------------------------------
    ==Advertisement Table Area
--------------------------------- */
.single-info-column {
  padding: 0px 0px;
}

.default-color {
  background: #48c9ff !important;
}

.single-info-column {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  border: 1px solid #ddd;
  margin-top: -1px;
  background: #f9fcfd;
}

.single-info-column h5 {
  line-height: 50px;
  font-size: 18px;
  padding-left: 10px;
}

.single-info-child:first-child {
  flex: 0 0 40%;
  border-right: 1px solid #ddd;
}

.single-info-child:last-child {
  flex: 0 0 59%;
}

.single-info-child {
  padding: 10px 10px;
  background: #f9fcfd;
}

.single-info-child p {
  font-size: 15px;
  color: #686868;
}

.card-information-table.eligibity-info ul li p {
  font-size: 13px;
  color: #686868;
  position: relative;
  line-height: 22px;
}

.card-information-table h6 {
  font-weight: 600;
  font-size: 15px;
  color: #686868;
  margin-bottom: 5px;
}

.card-information-table.eligibity-info ul li {
  padding-left: 10px;
  padding-bottom: 3px;
}

.card-information-table.eligibity-info ul li p i {
  position: absolute;
  left: -9px;
  top: 7px;
}

.card-information-table.eligibity-info .single-info-child:first-child {
  flex: 0 0 50%;
  border-right: 1px solid #ddd;
}

.card-information-table.eligibity-info p {
  font-size: 13px;
}

.card-information-table.eligibity-info .single-info-child:last-child {
  flex: 0 0 50%;
}

.text-bcbd-color {
  color: #48c9ff;
}

.card-information-table.eligibity-info ul li p:last-child {
  color: #48c9ff;
}

.sign-area .sl-div {
  flex: 0 0 50%;
  max-width: 50%;
  background: #fff;
}

.sign-area {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  padding-top: 46px;
  overflow: hidden;
}

.sl-sn-option .svg {
  height: 50px;
  width: 50px;
}

.sn-available-option {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
}

.sn-available-option .sl-sn-option {
  flex: 0 0 calc(33.33% - 10px);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  padding: 15px 0;
  text-align: center;
  max-width: 33.33%;
}

.sn-available-option {
  background: #f3f8fc !important;
  border-radius: 5px;
  padding: 15px 15px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.sl-sn-option:first-child {
  background: #9b72b2;
}

.sl-sn-option .svg {
  fill: #fff;
  margin-bottom: 9px;
}

.sl-sn-option p {
  color: #fff;
  font-size: 14px;
}

.sl-sn-option:nth-child(2) {
  background: #4db3e3;
}

.sl-sn-option:not(:last-child):not(:nth-child(3)) {
  margin-right: 15px;
}

.sl-sn-option:nth-child(3) {
  background: #68b8c9;
}

.sl-sn-option:nth-child(4) {
  background: #e28f5e;
}

.sl-sn-option:nth-child(5) {
  background: #e3b151;
}

.sl-sn-option:nth-child(6) {
  background: #a4c173;
}

.mb-15 {
  margin-bottom: 15px;
}

.sign-option-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sign-option-header a {
  flex: 0 0 50%;
  text-align: center;
  background: #3db7f0;
  font-size: 18px;
  color: #fff;
  line-height: 55px;
  font-weight: 600;
}

.sign-option-header a.active {
  background: #fff;
  color: #595959;
}

.sign-area .form-control {
  height: 45px !important;
  color: #333;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.signup-container .form-control {
  margin-bottom: 15px;
}

.sign-container {
  padding: 20px 90px;
}

.sign-banner {
  padding: 100px 0;
}

.sign-area .btn {
  background: #3db7f0 !important;
  border-color: #3db7f0;
  color: #fff;
}

.sign-banner {
  height: 100vh;
}

.sign-banner h2 {
  padding-top: 52px;
}

.sn-field-area {
  padding: 0px 0px 0 15px;
}

.pl-7 {
  padding-left: 7px;
}

.pr-7 {
  padding-right: 7px;
}

.signup-container {
  padding: 15px 22px 15px 0px;
}

.forget-field {
  position: absolute;
  right: 0%;
  height: 100%;
  top: 0;
  background: #fff;
  z-index: 5;
  padding: 0 100px;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  transition: 0.3s linear;
}

.forget-field.active {
  visibility: visible;
  opacity: 1;
}

/* -------------------------------
    ==Advertisement==
------------------------------- */
.advertised-card select {
  font-weight: 600;
  font-size: 15px;
}

.advertised-card {
  background: #fff;
  padding: 15px 15px;
  border-radius: 5px;
}

img {
  max-width: 100%;
}

.advertised-card h3 {
  font-weight: 300;
  color: #686868;
  font-size: 20px;
}

.advertised-card h3 strong {
  font-weight: 700;
}

.card-icon {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.card-icon {
}

.card-icon i {
  font-size: 24px;
}

.leaf-green {
  background: #6db867;
}

.single-ad-card-content h6 {
  font-size: 15px;
  font-weight: 600;
}

.single-ad-card-content p {
  color: #686868;
  font-weight: 400;
}

.advertisement-banner {
  height: 200px;
}

.card-highlight-features {
  background: rgba(255, 255, 255, 0.15);
  padding: 20px 20px 20px 30px;
  border-radius: 5px;
}

.card-highlight-features p span {
  font-size: 15px;
  color: #ffffff;
  line-height: 32px;
  display: inline-block;
  width: calc(100% - 25px);
}

.card-highlight-features i {
  display: inline-block;
  margin-right: 5px;
}

.card-highlight-features .aply-btn {
  padding: 12px 40px;
  font-weight: 600;
  font-size: 18px;
  border-radius: 3px;
  box-shadow: 0px 4px 0px 0px #cb7712;
  border: none;
  display: inline-block;
  outline: none;
  transition: all 0.3s linear;
}

.card-highlight-features .aply-btn:active {
  box-shadow: 0 0px 0 #cb7712;
  -webkit-transform: translateY(3px);
  transform: translateY(3px);
  color: #fff;
}

.card-highlight-features .aply-btn i {
  display: inline-block;
  padding-left: 5px;
  font-size: 20px;
}

/* ---------------------------------
    ==Advertisement Table Area
--------------------------------- */
.single-info-column {
  padding: 0px 0px;
}

.default-color {
  background: #48c9ff !important;
}

.single-info-column {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  border: 1px solid #ddd;
  margin-top: -1px;
  background: #f9fcfd;
}

.single-info-column h5 {
  line-height: 50px;
  font-size: 18px;
  padding-left: 10px;
}

.single-info-child:first-child {
  flex: 0 0 40%;
  border-right: 1px solid #ddd;
}

.single-info-child:last-child {
  flex: 0 0 59%;
}

.single-info-child {
  padding: 10px 10px;
  background: #f9fcfd;
}

.single-info-child p {
  font-size: 15px;
  color: #686868;
}

.card-information-table.eligibity-info ul li p {
  font-size: 13px;
  color: #686868;
  position: relative;
  line-height: 22px;
}

.card-information-table h6 {
  font-weight: 600;
  font-size: 15px;
  color: #686868;
  margin-bottom: 5px;
}

.card-information-table.eligibity-info ul li {
  padding-left: 10px;
  padding-bottom: 3px;
}

.card-information-table.eligibity-info ul li p i {
  position: absolute;
  left: -9px;
  top: 7px;
}

.card-information-table.eligibity-info .single-info-child:first-child {
  flex: 0 0 50%;
  border-right: 1px solid #ddd;
}

.card-information-table.eligibity-info p {
  font-size: 13px;
}

.card-information-table.eligibity-info .single-info-child:last-child {
  flex: 0 0 50%;
}

.text-bcbd-color {
  color: #48c9ff;
}

.card-information-table.eligibity-info ul li p:last-child {
  color: #48c9ff;
}

/* ----------------------------------
    ==Track Application Details
---------------------------------- */
.grid-wBorder .lon-grid {
  border: 1px solid #ebebeb;
  border-left: 0;
}

.grid-wBorder .lon-grid:first-child {
  border: 1px solid #ebebeb;
}

.loan_head_option:first-child .lon-grid:first-child {
  border-top-left-radius: 5px;
}

.loan_head_option:first-child .lon-grid:last-child {
  border-top-right-radius: 5px;
}

.application-table-head {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.track-application-table .loan-head {
  margin-top: -1px;
}

.track-application-table .loan_head_option {
  margin-top: -1px;
}

.bcbd-toggleTarget .track-application-table .lon-grid {
  border-bottom: none !important;
}

.bg-cream {
  background: #ecf6fa;
}

.track-application-table .lon-grid {
  padding: 15px 0;
}

.track-application-table p {
  color: #666;
  font-weight: 400;
}

.foldable-table-inner {
  padding: 0px 3px;
  border: 1px solid #ddd;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.bg-5 {
  background: #fafafa !important;
}

.bg-6 {
  background: #f0f0f0 !important;
}

.sl-div {
}

.foldable-table-inner.gr-3 .sl-div {
  flex: 0 0 33.33%;
  /* background: #000; */
  min-height: 1px;
  padding: 10px 7px;
}

.bg-8 {
  background: #f7f7f7;
}

a[aria-expanded='false'] .folded {
  display: none;
}

a[aria-expanded='true'] .folded {
  display: inline-block;
}

a[aria-expanded='true'] .normal {
  display: none;
}

.application-status {
  padding: 4px 15px;
  font-weight: 500;
  border-radius: 5px;
  font-size: 15px;
}

.foldable-application-table .btn {
  border: 1px solid #ddd;
}

.foldable-application-table .btn[aria-expanded='true'] {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-color: transparent;
}

.nav-link {
  transition: all 0.3s linear;
  cursor: pointer;
}

.semi-bold {
  font-weight: 600;
}

.track-application-parent {
  border-radius: 5px;
}

.track-application-parent .nav-link.active {
  background: #f7f7f7 !important;
  color: #555 !important;
}

.track-application-parent .nav-link:first-child {
  border-top-left-radius: 5px;
}

.track-application-parent .nav-link:last-child {
  border-top-right-radius: 5px;
}

a.nav-link.selected {
  color: #007bff !important;
  background: #f7f7f7 !important;
}

/* ------------------------------
    Customer Service Chat
------------------------------ */
.customer-service-manager-tab .svg {
  height: 21px;
  width: 21px;
  fill: #fff;
  margin-right: 6px;
}

.radius-pill {
  border-radius: 25px;
}

.bg-cl7 {
  background: #3db7f0 !important;
}

.s-dp-2 {
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
}

.customer-service-manager-tab a {
  line-height: 30px;
  letter-spacing: 0.7px;
}

.customer-service-manager-tab p {
  line-height: 24px;
}

.customer-service-manager-tab .media {
  height: 50%;
  padding-top: 80px;
  padding-bottom: 70px;
}

.customer-service-manager-tab .media.active {
  background: #edf6fb;
}

.customer-service-manager-tab .media.active a {
  background: #a1c2d3;
}

.text-in-border span {
  top: -17px;
  background: #f7f7f7;
}

/* -----------------------------
    Chat Content
----------------------------- */
.single-chat .media-body {
  background: #f9f9f9;
  border-radius: 5px;
  padding: 10px 12px;
  margin-bottom: 12px;
  text-align: left;
}

.single-chat .media-body p {
  font-size: 13px;
  color: #9a9a9a;
}

.single-chat.reply .media-body {
  background: #edf6fb;
}

.media.single-chat {
  flex-flow: row-reverse;
  max-width: calc(100% - 46px);
}

.media.reply.single-chat {
  flex-flow: row;
}

.media.single-chat img {
  margin-left: 15px;
  margin-right: 0;
}

.media.reply.single-chat img {
  margin-right: 15px;
  margin-left: 0;
}

.messge-field {
  border-top: 1px solid #ddd;
  margin-left: -8px;
}

.service-message-area {
  height: 100%;
}

.messge-field input,
.messge-field button {
  border: none;
}

.messge-field button {
  background: #3cb7f0;
  color: #fff;
  border-radius: 5px !important;
  padding: 8px 20px;
}

.chat-content {
  height: calc(100% - 95px) !important;
}

.track-application-parent .tab-content {
  background: #f7f7f7;
}

/* ---------------------------------
    Calulator
--------------------------------- */
.incomeChart {
  min-height: 300px !important;
}

.chart-legend ul li {
  float: none;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.chart-legend ul li span {
  display: inline-block;
}

.legend-color {
  width: 50px;
  height: 30px;
  border-radius: 5px;
}

.legend-name {
  margin-left: 17px !important;
  margin-right: auto;
  width: 130px;
}

.legend-amount {
  margin-left: 30px;
  margin-right: auto;
}

.chart-legend {
  height: 100%;
}

.bg-clr-8 {
  background: #f8c21d;
}

.bg-clr-9 {
  background: #47cafe;
}

.bg-clr-10 {
  background: #1a8ccb;
}

.bg-clr-11 {
  background: #f8f8f8;
}

.bg-clr-12 {
  background: #02bdf2;
}

.text-clr-8 {
  color: #f8c21d;
}

.text-clr-9 {
  color: #47cafe;
}

.text-clr-10 {
  color: #1a8ccb;
}

.income-card {
  padding: 10px 5px 5px 40px;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  transition: all 0.3s linear;
}

.income-card:hover {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
}

.amount-details-table > span {
  position: absolute;
  left: 15px;
  top: 12px;
}

.amount-details-table {
  padding: 0;
}

.amount-details-table > span i {
  color: #a1a1a1;
  font-size: 28px;
}

.view-hight {
  height: 100vh;
}

/* --------------------------
    ==user Profile Card==
-------------------------- */
.user-profile-menu a .svg {
  height: 46px;
  width: 46px;
  -webkit-text-fill-color: #3db7f0;
  fill: #3db7f0;
  transition: all 0.3s linear;
  margin-right: 15px;
}

.bg-clr-13 {
  background: #edf6fb;
}

.user-profile-menu a {
  margin-bottom: 30px;
  border: 1px solid #3db7f0;
  border-radius: 5px;
  padding: 23px 10px;
  color: #3db7f0;
  font-size: 18px;
  transition: all 0.3s linear;
}

.user-profile-menu {
  padding: 60px 0 40px 0;
  border-radius: 5px;
  width: 100%;
}

.user-profile-menu a:hover,
.user-profile-menu a.active {
  background: #3db7f0;
  color: #fff;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.4px;
}

.user-profile-menu a:hover .svg,
.user-profile-menu a.active .svg {
  -webkit-text-fill-color: #fff;
  fill: #fff;
}

.pie_progress {
  width: 120px;
  margin: 15px auto;
}

.pie_progress__number {
  font-size: 24px;
}

.profile-details-status-head {
  line-height: 45px;
  text-align: center;
}

.profile-details-status {
  border: 1px solid #ddd;
  padding-bottom: 15px;
  border-radius: 5px;
}

@media all and (max-width: 768px) {
  .pie_progress {
    width: 80%;
    max-width: 300px;
  }
}

/* ------------------------------
------------------------------ */
.profile-details-tab > li {
  padding: 10px 0 10px 15px;
  cursor: pointer;
  background: #ecf6fa;
  border-bottom: 1px solid #cfe6ed;
  color: #595959;
  float: none;
  /* border-left: 1px solid #e0e0e0; */
}

.profile-details-tab > li:last-child {
  border-bottom: transparent;
}
.imageComplains {
  position: absolute;
  bottom: 0;
  z-index: -1;
}
.imageComplains2 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.z-index1 {
  z-index: 1;
}
.profile-details-tab > li:first-child {
  border-top-left-radius: 10px;
}

.profile-details-body {
  margin: 12px;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-bottom: 0;
}

.profile-info-parent {
  background: #f7f7f7;
  padding-bottom: 10px;
  border-radius: 5px;
  margin: 50px 0;
  overflow: hidden;
}

.profile-details-tab {
  height: 100%;
  background: #ecf6fa;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-bottom: 170px;
}

.profile-details-info {
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  height: 100%;
}

.card-information-table {
}

.profile-details-info .single-info-column:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
}

.profile-details-info .single-info-column:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
}

.profile-details-info .tab-pane {
  height: 100%;
}

.profile-details-navigation > div {
  line-height: 50px;
  color: #fff;
}

.profile-arrow {
  /* background: #3db7f0; */
  padding: 0 25px;
  transition: all 0.3s linear;
  cursor: pointer;
  font-size: 18px;
}

.profile-details-navigation {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #ecf6fa;
}

.profile-navigation-path {
  margin-right: auto;
  margin-left: 100px;
}

.profile-arrow,
.profile-arrow:hover {
  background: #3db7f0;
  color: #fff !important;
}

.profile-details-tab li.react-tabs__tab--selected {
  background: #fff;
  color: #555555;
}

.bg-13 {
  background: #e1eee0;
}

.card-information-table {
  margin-bottom: 30px;
}

.active-text {
  color: #25a8da !important;
}

.text-underline {
  text-decoration: underline;
}

/*---------------------------
    ==Track Application
---------------------------*/
/* ----------------------------------
    ==Track Application Details
---------------------------------- */
.grid-wBorder .lon-grid {
  border: 1px solid #ebebeb;
  border-left: 0;
}

.grid-wBorder .lon-grid:first-child {
  border: 1px solid #ebebeb;
}

.loan_head_option:first-child .lon-grid:first-child {
  border-top-left-radius: 5px;
}

.loan_head_option:first-child .lon-grid:last-child {
  border-top-right-radius: 5px;
}

.application-table-head {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.track-application-table .loan-head {
  margin-top: -1px;
}

.track-application-table .loan_head_option {
  margin-top: -1px;
}

.bcbd-toggleTarget .track-application-table .lon-grid {
  border-bottom: none !important;
}

.bg-cream {
  background: #ecf6fa;
}

.track-application-table .lon-grid {
  padding: 15px 0;
}

.track-application-table p {
  color: #666;
  font-weight: 400;
}

.foldable-table-inner {
  padding: 0px 3px;
  border: 1px solid #ddd;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.bg-5 {
  background: #fafafa !important;
}

.bg-6 {
  background: #f0f0f0 !important;
}

.sl-div {
}

.foldable-table-inner.gr-3 .sl-div {
  flex: 0 0 33.33%;
  /* background: #000; */
  min-height: 1px;
  padding: 10px 7px;
}

.bg-8 {
  background: #f7f7f7;
}

a[aria-expanded='false'] .folded {
  display: none;
}

a[aria-expanded='true'] .folded {
  display: inline-block;
}

a[aria-expanded='true'] .normal {
  display: none;
}

.application-status {
  padding: 4px 15px;
  font-weight: 500;
  border-radius: 5px;
  font-size: 15px;
}

.foldable-application-table .btn {
  border: 1px solid #ddd;
}

.foldable-application-table .btn[aria-expanded='true'] {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-color: transparent;
}

.nav-link {
  transition: all 0.3s linear;
}

.semi-bold {
  font-weight: 600;
}

.track-application-parent {
  border-radius: 5px;
}

.track-application-parent .nav-link.active {
  background: #f7f7f7 !important;
  color: #555 !important;
}

.track-application-parent .nav-link:first-child {
  border-top-left-radius: 5px;
}

.track-application-parent .nav-link:last-child {
  border-top-right-radius: 5px;
}

/* ------------------------------
    Customer Service Chat
------------------------------ */
.customer-service-manager-tab .svg {
  height: 21px;
  fill: #fff;
  margin-right: 6px;
}

.radius-pill {
  border-radius: 25px;
}

.s-dp-2 {
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
}

.customer-service-manager-tab a {
  line-height: 30px;
  letter-spacing: 0.7px;
}

.customer-service-manager-tab p {
  line-height: 24px;
}

.customer-service-manager-tab .media {
  height: 50%;
  padding-top: 80px;
  padding-bottom: 70px;
}

.customer-service-manager-tab .media.active {
  background: #edf6fb;
}

.customer-service-manager-tab .media.active a {
  background: #a1c2d3;
}

.text-in-border span {
  top: -17px;
  background: #fff;
}

/* -----------------------------
    Chat Content
----------------------------- */
.single-chat .media-body {
  background: #f9f9f9;
  border-radius: 5px;
  padding: 10px 12px;
  margin-bottom: 12px;
  text-align: left;
}

.single-chat .media-body p {
  font-size: 13px;
  color: #9a9a9a;
}

.single-chat.reply .media-body {
  background: #edf6fb;
}

.media.single-chat {
  flex-flow: row-reverse;
  max-width: calc(100% - 46px);
}

.media.reply.single-chat {
  flex-flow: row;
}

.media.single-chat img {
  margin-left: 15px;
  margin-right: 0;
}

.media.reply.single-chat img {
  margin-right: 15px;
  margin-left: 0;
}

.messge-field {
  border-top: 1px solid #ddd;
  margin-left: -8px;
  padding-right: 10px;
}

.service-message-area {
  height: 100%;
}

.messge-field input,
.messge-field button {
  border: none;
}

.messge-field button {
  background: #3cb7f0;
  color: #fff;
  border-radius: 5px !important;
  padding: 8px 20px;
}

.chat-content {
  height: calc(100% - 80px) !important;
}

.service-tab-menu .svg {
  height: 30px;
  width: 30px;
  display: inline-block;
  fill: #595959;
  margin-right: 7px;
}

.service-tab-menu .nav-link {
  color: #595959;
  display: block;
  width: 100%;
  border-bottom: 1px solid #cfe6ed;
  position: relative;
  cursor: pointer;
}

.service-details-content {
  background: #fff;
  border: 1px solid #ebebeb;
  border-radius: 5px;
}

.track-application-parent .tab-pane {
  padding: 13px 13px 13px 13px;
}

.track-application-parent {
  border-radius: 5px;
  background: #f7f7f7;
}

.service-tab-menu .nav-link::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 4px;
  background: #3db7f0;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  visibility: hidden;
  opacity: 0;
}

.service-tab-menu .nav-link.react-tabs__tab--selected::before {
  visibility: visible;
  opacity: 1;
}

.service-tab-menu .nav-link.react-tabs__tab--selected {
  color: #3db7f0 !important;
}

.service-tab-menu .nav-link.react-tabs__tab--selected .svg {
  fill: #3db7f0 !important;
}

/*------------------------------
    SCB card Banner css
------------------------------*/
.scb-card-content span {
  font-weight: 700;
}

.scb-card-content h5 {
  font-size: 20px;
  color: #686868;
}

.scb-eligibility-banner {
  border-radius: 5px;
}

/*modal*/
.styles_modal__gNwvD {
  max-width: 65%;
  width: 65%;
  /*max-height: 550px;*/
}

.styles_modal__gNwvD.iframe-modal {
  max-width: 900px;
  width: 900px;
  padding: 7px 0 !important;
}

.modal-body {
  height: 500px;
  overflow: auto;
}

.modal-content {
  overflow-y: auto;
  overflow-x: hidden;
}

.modal_header {
  background: #208dd3 !important;
  padding: 15px 10px;
  color: #fff;
}

.apply_modal_inner .single_option .bcbd_inputgrup input {
  padding-left: 15px;
  font-size: 14px;
  line-height: 40px;
  height: 45px;
}

.apply_modal_inner .select_parent.form-control {
  height: 43px;
}

.apply_modal_inner .select_parent.form-control .bcbd_select {
  height: 41px !important;
  padding-left: 10px;
}

.bcbd_select {
  padding-left: 10px;
}

.require-doc-inner p {
  line-height: 30px;
}

/*Error Message*/
.bcbd_select {
  text-transform: capitalize;
}

.error-bound {
  position: relative;
}

.error-bound::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #f3a531;
  border-radius: 3px;
}

/*loan box*/
.loan-box {
  border: 2px solid #eaeaea;
  padding: 0;
  border-radius: 3px;
}

.loan-box .bcbd_group {
  border: 3px solid #eaeaea;
}

/*.loan_cmn .input-group .bcbd_group {*/
/*flex: 0 0 calc(50% - 7px);*/
/*margin-bottom: 10px;*/
/*}*/
.input-group.group2 .bcbd_group {
  flex: 0 0 calc(50% - 8px);
  margin-bottom: 10px;
}

.input-group.group2 .bcbd_group:nth-child(2) {
  margin-right: 0;
}

.input-group.group2 .bcbd_group:first-child,
.input-group.group2 .bcbd_group:nth-child(3) {
  margin-right: 15px;
}

.rc-select {
  width: 100% !important;
}

.rc-select-selection--single,
.rc-select-selection--single .rc-select-selection__rendered,
.rc-select-search--inline .rc-select-search__field__wrap,
.rc-select-search--inline .rc-select-search__field {
  height: 40px !important;
  line-height: 40px !important;
}

.rc-select-arrow {
  top: 6px !important;
}

.react-datepicker__header {
  background: #ffffff !important;
}

.react-datepicker__navigation--previous {
  border-right-color: #5f5c5c !important;
}

.react-datepicker__navigation--next {
  border-left-color: #5f5c5c !important;
}

.eligibility_outer .select_parent {
  height: 45px;
}

.select_area_container .slect-divi .rc-select-selection {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}

.select_area_container .slect-area .rc-select-selection {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 1px solid #25a8da;
}

.loan_cont img {
  max-width: 115px;
  max-height: 60px;
  margin-bottom: 5px;
}

/*fixed Deposit Table*/
.fd-table-grid .rt_table_btn {
  width: 150px;
  padding: 14px 15px;
  font-size: 14px;
  font-weight: 500;
  box-shadow: 0px 3px 1px rgba(0, 0, 0, 0.1);
  transition: all 0.3s linear;
}

.fd-table-grid .rt_table_btn:hover {
  background: #000 !important;
  color: #ffffff !important;
}

.fixed-deposit-table .lon-grid,
.fixed-deposit-table .lon-grid p {
  font-size: 15px !important;
}

.gd-7 .loan_cont img {
  max-width: 90px;
  max-height: 90px;
}

/*.select_parent > div > div {*/
/*border: none !important;*/
/*}*/

.select_parent > div > div:first-child {
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd !important;
  margin-left: 0px;
  border-top: 0;
  border-bottom: 0;
}
#loanNumberContainer loan {
  padding: 0px 15px;
}
#loanNumberContainer .select_parent > div > div:first-child {
  margin-left: 0 !important;
}

.css-1hwfws3 {
  height: 42px;
  overflow: initial !important;
}

.single_loan_table small {
  font-size: 14px;
  font-weight: 500;
}

.single_loan_table .lon-grid {
  height: 130px;
  padding: 12px;
}

/*custom container*/
.container-fluid.bcbd-container {
  width: 90% !important;
}

.loan_head_option .lon-grid {
  padding-top: 7px;
}

.choose-tener-modal .react-tabs__tab.react-tabs__tab--selected {
  background: #ffffff !important;
  color: #1e6b9c !important;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sinle-fd-account.current-active {
  background: rgba(255, 255, 255, 0.5);
}

.css-11unzgr {
  max-height: 200px !important;
}

.is_sticky .fd-table-grid {
  padding-top: 10px;
  padding-bottom: 3px;
}

/*.css-10nd86i {*/
/*border: 1px solid #ddd;*/
/*border-top: 0;*/
/*border-bottom: 0;*/
/*border-top-right-radius: 3px;*/
/*border-bottom-right-radius: 3px;*/
/*}*/

.right-log .right-info-card {
  border: 1px solid #e6e6e6;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1);
  animation: zoomIn 0.4s linear;
}

.single-step .step-label {
  text-transform: uppercase;
}

.right-log .right-info-card p:first-child {
  color: #0ab9f2;
}

.right-log .right-info-card p:last-child {
  color: #696969;
}

.eligibility-inner select:focus,
.eligibility-inner input:focus {
  outline: none !important;
  box-shadow: none !important;
}

.css-1uq0kb5 svg,
.css-1ep9fjw svg {
  visibility: hidden !important;
}

.css-1uq0kb5,
.css-1ep9fjw {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(../images/downicon.png) !important;
  background-repeat: no-repeat !important;
  background-position: calc(100% - 10px) 16px !important;
  padding-right: 20px;
}

/*------------------------------------
        Advertisement css
    ------------------------------------*/
.sl_bd_body {
  padding: 0;
  text-align: center;
  padding-top: 0px;
  padding-bottom: 0px;
  height: initial;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  line-height: 28px;
  color: #777;
  transition: all 0.3s linear;
}

.sl_bd_body > p {
  max-height: 200px;
  overflow: hidden;
  margin-bottom: auto;
}

.advertisement-slider .slick-track {
  display: flex;
}

.sl_bd_content {
  height: calc(100% - 90px);
  padding: 0 18px;
  padding-bottom: 20px;
}

.subsribeModal {
  width: 750px;
  height: calc(100% - 10%) !important;
}

.subscribe-content-container {
  height: calc(100% - 44px);
  overflow-y: auto;
  overflow-x: hidden;
}

.advertisement-slider .slick-track .slick-slide {
  height: initial !important;
}

.advertisement-slider .slick-track .slick-slide > div {
  height: 100% !important;
}

.advertisement-slider .slick-track .slick-slide > div > div,
.advertisement-slider .slick-track .slick-slide .single_item {
  height: 100% !important;
}

.advertisement-slider .single_item,
.advertisement-slider .single_bd_item {
  height: 100% !important;
}

.advertisement-slider .sl_bd_header {
  /*height: 185px;*/
}

.advertisement-slider .sl_bd_img {
  height: 156px;
  margin-bottom: 15px;
  border-bottom: 1px solid #ddd;
  padding: 5px;
}
.advertisement-slider .sl_bd_img img {
  border: 1px solid #dddddd;
}

.advertisement-slider .sl_bd_img img {
  object-fit: fill;
  height: 100%;
  width: 100%;
}

.advertisement-slider .sl_bd_body h6 {
  padding-bottom: 20px;
}

.fixableHeight.is_sticky .loan_head_option {
  padding-top: 11px !important;
  padding-bottom: 2px !important;
}

.scb-eligibility-banner {
  border-radius: 5px;
  margin-top: 150px;
  margin-bottom: -360px;
}

.single_card_eligibility_parent .new-eligibility-area {
  padding-top: 67px;
}

.eligibility-card {
  margin-top: -125px;
}

.position-absolute {
  position: absolute !important;
}

.single_card_eligibility_parent .eligibility_process {
  padding-top: 285px;
}

.react-datepicker-wrapper {
  display: block;
  width: 100%;
}

.apply_modal_inner .single_option .bcbd_inputgrup input {
  padding-left: 15px;
  font-size: 14px;
  line-height: 40px;
  height: 45px;
  border-radius: 3px;
}

.apply_modal_inner .single_option .bcbd_inputgrup .form-control {
  border: 1px solid #ddd;
}

.single_option .react-datepicker__input-container {
  width: 100%;
}

.credit_card_details_modal .aply_loan_inner {
  overflow: visible;
  height: 298px;
}

.eligibility_check_footer.landlord-footer {
  margin-top: -60px;
}

.no-data-row img {
  height: 100px;
}

button {
  outline: none;
  border: none;
}

.css-d8oujb {
  display: none;
}

/*-------------------------------
    ==Credit card Compare==
-------------------------------*/
.typeTitle {
  font-size: 5rem;
  font-weight: normal;
  text-align: center;
  display: flex;
  user-select: none;
}

.word {
  color: white;
  position: relative;
}

.word::after {
  content: '';
  position: absolute;
  margin-left: 2px;
  border-right: 2px solid;
  height: 1em;
  animation: tick 1s steps(1) infinite;
}

@keyframes tick {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.css-2o5izw {
  outline: none !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  border: none !important;
}

.credit_card_dt.is_sticky {
  top: -7px;
  padding-bottom: 0px !important;
}

.credit_card_dt.is_sticky .loan_grid_paren {
  padding-top: 2px !important;
  padding-bottom: 12px !important;
  height: 66px;
}

.no-lineheight {
  line-height: 0;
}

/*Sticker*/
#myBtn {
  display: block;
  position: fixed;
  bottom: 227px;
  right: -63px;
  z-index: 99;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.2;
  padding: 18px 17px;
  border-radius: 5px;
  transform: rotate(-90deg);
  -webkit-transform-origin: 50% 51%;
  transform-origin: 50% 51%;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0;
  box-shadow: 0px 0px 12px 1px rgba(255, 255, 255, 0.8);
}

.my-btn-cont a {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.my-btn-cont a i {
  font-size: 28px;
}

#myBtn p {
  position: relative;
  font-size: 14px;
  color: #fff;
  line-height: 16px;
  font-family: unset;
  margin-bottom: 0;
  padding-left: 7px;
  text-align: center;
}

/*--------------------------
    New Color css
--------------------------*/
.bcbd-bg-color {
  background: #24a5d9;
}

.bcbd-text-color {
  color: #1e6b9c !important;
}

.text-small {
  font-size: 11px !important;
}

.loan_grid_paren.single_loan_table .lon-grid .bcbd-text-color {
  font-size: 14px !important;
}

.loan_grid_paren.single_loan_table .lon-grid p {
  font-size: 12px !important;
}

.credit_cartd_table_head .lon-grid {
  padding-top: 10px !important;
  padding-bottom: 3px !important;
}

/* -------------------------------
    Credit Card Process
------------------------------- */
.item-circle {
  height: 165px;
  width: 165px;
  background: rgba(255, 255, 255, 0.25);
  margin-bottom: 25px;
}

.item-circle img {
  height: 96px;
}

.item-circle::before {
  content: '';
  position: absolute;
  left: -8px;
  top: -8px;
  width: calc(100% + 16px);
  height: calc(100% + 16px);
  border: 2px dashed rgba(255, 255, 255, 0.2);
  border-radius: 50%;
}

/* --------------------------------
    Accordion
-------------------------------- */
.question-icon i {
  font-size: 26px;
  color: #25a8da;
}

.answer-details p::first-letter {
  color: #25a8da;
  font-size: 26px;
  font-weight: 600;
}

.answer-details p {
  color: #777;
  line-height: 28px;
}

/*-----------------------------------------
    ==Eligbility card
-----------------------------------------*/
.scb-eligibility-banner img {
  max-width: 263px;
  height: 80px;
}

/*-------------------------
    ==Investors
-------------------------*/
/* ------------------------------
    Investors Area
------------------------------ */
.investors-image {
  height: 265px;
  width: 265px;
  left: -132px;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0px 0px 10px #f3f2f2;
}

.single-partners {
  padding: 30px 100px 80px 165px;
  background: #fff !important;
  margin-bottom: 50px;
}

.single-partners h2,
.single-partners p {
  color: #6c6d6e;
}

.single-partners h2 {
  padding-top: 12px;
  padding-bottom: 10px;
}

.terms-condition-tab {
  border-top: 3px solid #48c9ff;
}

.privacy-content p,
.terms-content p {
  line-height: 30px;
}

.full-width {
  width: 100%;
}

/*---------------------
---------------------*/
.team-slider .single-core-team {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column;
  flex-flow: column;
  -ms-flex-align: center;
  align-items: center;
  padding: 60px 0;
  -webkit-box-shadow: 0px 0px 1px #bdb8b8;
  box-shadow: 0px 0px 1px #bdb8b8;
  border-radius: 5px;
  background: #fff;
  text-align: center;
  height: 100%;
  justify-content: space-between;
}

.team-slider .slick-slide > div {
  height: 100% !important;
}

.team-slider .slick-slide {
  padding: 10px 15px;
  height: initial !important;
}

.team-slider .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  display: flex !important;
}

.eligibility_body .bcbd_select::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: pink !important;
}

.eligibility_body .bcbd_select::-moz-placeholder {
  /* Firefox 19+ */
  color: pink !important;
}

.eligibility_body .bcbd_select:-ms-input-placeholder {
  /* IE 10+ */
  color: pink !important;
}

.eligibility_body .bcbd_select:-moz-placeholder {
  /* Firefox 18- */
  color: pink !important;
}

.css-1492t68 {
  color: #595959 !important;
}

.bcbd_select::-ms-input-placeholder {
  /* Microsoft Edge */
  color: red;
}

.css-19bqh2r {
  fill: #7d7d7d !important;
}

.credit-cardmodal .loan_bank_option_output ul li {
  width: 31%;
}

.credit-cardmodal .modal_left {
  width: 280px;
  float: left;
  border-right: 1px solid #ddd;
}

.credit-cardmodal .modal_right {
  width: calc(100% - 280px);
}

.bg-f6f9 {
  background: #f6f9;
}

.bg-f9f9 {
  background: #f9f9f9;
}

.rounded-5 {
  border-radius: 5px;
}

.rounded-10 {
  border-radius: 10px;
}

.focus-none:focus {
  outline: none;
  box-shadow: none;
}

.css-2o5izw,
.css-2o5izw {
  border: none !important;
}

.css-xp4uvy,
.css-1492t68 {
  color: hsl(0, 0%, 50%);
  margin-left: 2px;
  margin-right: 2px;
  position: absolute;
  box-sizing: border-box;
  top: 0 !important;
  transform: translateY(0%) !important;
  box-sizing: border-box;
  margin-top: 0 !important;
  margin-top: 0px !important;
  top: 0 !important;
  height: 47px;
  display: flex;
  align-items: center;
}

.css-xp4uvy {
  top: 0px !important;
}

.bcbd_nav li a i {
  font-size: 25px;
  display: inline-block;
  margin-right: 5px;
  color: #0ab9f2;
}

.bcbd_nav li a {
  color: #585858;
  font-size: 16px;
  text-transform: capitalize;
  padding: 25px 13px !important;
}

.single-menu-portion h6 {
}

.bg-2748 {
  background: #274868;
}

.menu-sidebar {
  height: 100vh;
  width: 300px;
  right: 0;
  z-index: 999;
}

.single-menu-portion a svg {
  width: 20px;
  height: 24px;
}

.text-c8d8 {
  color: #becfde;
}

.fz-18 {
  font-size: 18px !important;
}

.single-menu-portion a span {
  margin-left: 7px;
  display: inline-block;
}

.border-2b4d {
  border-color: #e2e3e4 !important;
}

.single-menu-portion a:hover svg {
  fill: #80776b !important;
}

/*sidemenu*/

.menu-tab .nav li.react-tabs__tab--selected {
  background: #ffffff !important;
  color: #6b6b6b;
}

.menu-tab .nav .menu-tab .nav li:focus {
  outline: none;
  box-shadow: none;
}

.single-menu-portion a:hover,
.single-menu-portion a:hover svg {
  fill: #80776b !important;
}

.crossbuton {
  background: #ffffff;
  left: -38px;
  height: 60px;
  width: 38px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: #020202;
  font-size: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: -1;
  cursor: pointer;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-transform-origin: right;
  -ms-transform-origin: right;
  transform-origin: right;
  -webkit-transition: 0.5s linear;
  -o-transition: 0.5s linear;
  transition: 0.5s linear;
  box-shadow: -2px 1px 6px rgba(0, 0, 0, 0.4);
}

.tab-content-area {
  height: calc(100% - 60px);
  overflow: auto;
  clear: both;
}

.menu-tab .nav li {
  /*background: #0ab9f2;*/
  font-size: 18px;
  color: #555555;
  line-height: 60px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s linear;
  font-weight: 600;
}

.call-menu {
  animation: jello 1s linear infinite;
}

.commontab-parent {
  height: 378px;
  overflow: auto;
}

.menu-tab {
  height: 100vh;
}

.sidemenu-inner {
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.3);
  perspective: 500px;
}

.menu-sidebar.active .crossbuton {
  transform: rotateY(0deg);
}

.menu-sidebar {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.menu-sidebar.active {
  visibility: visible;
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/*New footer*/
.bg-f7f7 {
  background: #f7f7f7;
}

.single_social:hover span,
.single_social:hover span i {
  color: #fff;
}

.single_social:hover span,
.single_social:hover i {
  color: #fff;
}

.footer_middle {
  background: transparent;
}

.footer_widget ul li a {
  color: #3e3c3c;
}

.single_social a span,
.single_social a i {
  color: #757575;
}

.single_social::after {
  background: #cccccc;
}

.footer_widget h6 {
  color: #3e3c3c;
}

.single_social {
  transition: all 0.2s linear;
}

.footer_bottom p {
  text-align: center;
}

.partners_inner_area::before {
  display: none;
}

.bg-f6f9 {
  background: #f6f9fb;
}

.blog_meta ul li a {
  color: #c2c2c2;
  font-size: 14px;
  font-weight: 500;
}

.bg-0ab {
  background: #0ab9f2;
}

.blog_meta a h5 {
  font-size: 20px;
}

.styles_modal__gNwvD {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-radius: 10px;
}

/*New Footer*/

@media (min-width: 1200px) and (max-width: 1600px) {
  .banner .fz-65 {
    font-size: 45px;
  }

  .banner .pb-5 {
    padding-bottom: 1rem !important;
  }

  .fz-40 {
    font-size: 30px;
  }

  .subscribe-area .subscribebtn {
    padding: 14px 50px;
    font-size: 15px;
  }

  .fz-25 {
    font-size: 20px !important;
  }

  .single-dps-table .p-lg-3 {
    padding: 0.2rem !important;
  }

  .single-dps-table .fz-14 {
    font-size: 12px;
  }

  .single-dps-table .fz-16 {
    font-size: 13px;
  }

  .credit-card-sidebar p {
    font-size: 14px;
  }

  .single-dps-table .bcbd-btn {
    padding: 11px 15px;
    max-width: 125px;
    min-width: 125px;
  }

  .credit-card-selection h2 {
    font-size: 40px !important;
    margin-bottom: 15px !important;
    /* text-align: left !important; */
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .fz-25 {
    font-size: 20px;
  }

  .single-dps-table .fz-14 {
    font-size: 12px;
  }

  .single-dps-table .fz-16 {
    font-size: 13px;
  }

  .credit-card-sidebar p {
    font-size: 14px;
  }

  .single-dps-table .bcbd-btn {
    padding: 11px 15px;
    max-width: 125px;
    min-width: 125px;
  }
}

/*credit card slection*/
.credit-card-selection {
  background: #1b5f8d;
}

.bg-208d {
  background: #208dd3;
}

.credit-card-selection {
  height: calc(100vh - 79px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-50 {
  font-size: 50px;
}

.new-form-control {
  line-height: 35px;
}

.credit-card-selection input,
.credit-card-selection select {
  z-index: 50;
}

.credit-card-selection h2 {
  z-index: 50;
  font-size: 50px;
  /* font-weight: 100 !important; */
}

.bcbd_navbar .card-selection-parent {
  padding: 10px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
}

.card-selection-parent select,
.card-selection-parent input {
  height: 55px !important;
  font-weight: 500 !important;
  font-size: 18px;
  border-radius: 3px;
}

.bg-f6f9 {
  background: #f6f9fb;
}

.credt-card-choose-option img {
  z-index: 0;
}

.credt-card-choose-option {
  padding: 40px 0 20px 20px;
  /*background: rgba(0,0,0,.20);*/
}

.credt-card-choose-option .creditcardoverlay {
  top: 0;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
}

.credt-card-choose-option .creit-card-hand {
  right: -72px;
  z-index: 50;
}

.dps-bankname img {
  max-width: 150px;
  max-height: 100px;
}

.single-card .bcbd-btn {
  padding: 12px 6px !important;
  margin-bottom: 15px;
}

.credit-card-selection .btn {
  z-index: 50;
  min-width: initial;
  max-width: initial;
  padding-left: 40px;
  padding-right: 40px;
}

.single-menu-portion a {
  transition: all 0.2s linear;
}

.credit-card-selection > img {
  width: initial;
  height: initial;
  bottom: 0;
}

.fz-16 {
  font-size: 16px;
}

.bg-ebfa {
  background: #f8ffeb;
}

.text-ebfa {
  background: #ebfaff;
}

.border-ebfa {
  border-color: #ebfaff;
}

.fz-25 {
  font-size: 25px;
}

.full-height {
  height: 100%;
}

/*menu color*/
.text-4e98 {
}

.text-4e98:hover {
  color: #4e9876;
}

.text-1a9d:hover {
  color: #1a9dc0;
}

.text-857a:hover {
  color: #857a6c;
}

.text-8c5e:hover {
  color: #8c5e67;
}

.text-878f:hover {
  color: #878f4d;
}

.text-9e73:hover {
  color: #9e7329;
}

.text-477a:hover {
  color: #477a96;
}

.fw-medium {
  font-weight: 500;
}

.fz-20 {
  font-size: 20px;
}

.text-a389:hover {
  color: #a3894e;
}

.text-4096:hover {
  color: #4096cd;
}

.text-8971:hover {
  color: #89718e;
}

.border-with-1 {
  border-width: 1px !important;
}

.bg-f4ca {
  background: #f4ca1f !important;
}

.border-4343 {
  border-color: #434343;
}

.text-4343 {
  color: #434343;
}

.text-6a69 {
  color: #6a6969;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                     supported by Chrome and Opera */
}

.text-7575 {
  color: #757575;
}

.bg-f0f2 {
  background: #f0f2f5;
}

/*Modal New Style*/
.modal-body {
  height: calc(100% - 71px);
  overflow: auto;
}

.compare-card-image img {
  max-width: 60%;
}

.compare-card-image {
  width: 100%;
  text-align: center;
}

.customModal > div {
  border-radius: 5px;
}

.customModal {
  max-width: calc(100% - 20%) !important;
  width: calc(100% - 20%) !important;
  height: calc(100% - 10%) !important;
}

.subsribeModal > div {
  border-radius: 5px;
}

.subsribeModal {
  width: 750px;
  height: 783px;
}

.border-0ab9 {
  border-color: #0ab9f2 !important;
}

/*success info*/
.bg-f3fc {
  background: #f3fcf1;
}

.bg-fbf7ea {
  background: #fbf7ea;
}

.bg-edf9ee {
  background: #edf9ee;
}

.border-dashed {
  border-style: dashed !important;
}

.text-383838 {
  color: #383838;
}

.border-daead6 {
  border-color: #daead6 !important;
}

.text-646464 {
  color: #646464;
}

.bg-e9f1f9 {
  background: #e9f1f9;
}

.text-6a6969 {
  color: #6a6969;
}

.text-6464 {
  color: #646464;
}

.text-818181 {
  color: #818181;
}

/*profile css*/

.bg-e2ebf5 {
  background: #e2ebf5 !important;
}

.profile-sidebar li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 3px;
  height: 0;
  background: #0ab9f2;
  transition: all 0.3s linear;
}

.profile-sidebar li {
  line-height: 26px;
  position: relative;
  padding-left: 15px;
  cursor: pointer;
}

.profile-sidebar li.react-tabs__tab--selected::before {
  height: 100%;
}

.bg-f3ca20 {
  background: #f3ca20;
}

.text-747474 {
  color: #747474;
}

p.fz-18.text-383838.text-capitalize {
}

.profile-vertical-menu {
}

.profile-vertical-menu li {
  position: relative;
  color: #383838;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  cursor: pointer;
  line-height: 26px;
}

.profile-vertical-menu li::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 0;
  background: #0ab9f2;
  transition: all 0.3s linear;
}

.profile-vertical-menu li.react-tabs__tab--selected {
  color: #0ab9f2;
}

.profile-vertical-menu li.react-tabs__tab--selected::after {
  width: 100%;
}

/**/
.fz-40 {
  font-size: 40px;
}

.text-5555 {
  color: #555555;
}

.custom-accordion {
  padding: 14px 14px 14px 14px;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.text-5f5f5f {
  color: #5f5f5f;
}

.custom-accordion .card-body {
  line-height: 30px;
}

.custom-accordion .fz-40 {
  line-height: 40px;
}

.text-555555 {
  color: #555555;
}

.text-f6f9fb {
  background: #f6f9fb;
}

.circle {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: stroke 2s ease-out forwards;
}

.line {
  fill: none;
  stroke: #000000;
  strokewidth: 0.6rem;
}

svg.my-svg {
  margin: 0px;
  width: 100%;
  height: 2px;
}

.burger-menu-wrapper {
  width: 50px;
  overflow: hidden;
}

.track-btn:hover::before {
  height: 100%;
}

.track-btn {
  overflow: hidden;
  position: relative;
  z-index: 9;
}

@keyframes stroke {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

/*Credit Card Sidebar*/
.credit-card-sidebar .css-vj8t7z {
  border: none !important;
}

/*Button Style */
.more-info-btn {
  position: relative;
  z-index: 5;
  overflow: hidden;
  border: none;
}
.more-info-btn:hover {
  color: #ffffff;
}
.more-info-btn::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 100%;
  left: 0;
  z-index: -1;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  background: #9fbad2;
  z-index: -1;
}

.mb-15 {
  margin-bottom: 15px;
}

.more-info-btn::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 100%;
  left: 0;
  z-index: -1;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  background: #3b6992;
  z-index: -1;
}

.more-info-btn:hover::before,
.more-info-btn:hover::after {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0) !important;
}

.more-info-btn:hover::after {
  -webkit-transition-delay: 0.175s;
  transition-delay: 0.175s;
}

.credit-card-sidebar .css-d8oujb {
  display: none !important;
}

.css-15k3avv {
  z-index: 9 !important;
}

.css-11unzgr,
.css-15k3avv {
  animation: fadeInUp 0.3s linear;
}

/*New Select*/
.custom-select-control {
  padding: 0 !important;
}

.single-dps-table > .row {
  width: 100%;
}

.dps-bankname small {
  line-height: 16px;
}

.single-dps-table {
  height: 170px;
  align-items: center;
  display: flex;
}

.no-more-table,
.table-area > div:first-child {
  height: initial !important;
}

.single-dps-table .css-vj8t7z {
  border: none !important;
}

.single-dps-table .css-d8oujb {
  display: none !important;
}

.custom-select-control {
  height: 45px;
}

.single-dps-table {
  height: 170px;
}

.custom-select-control .css-1492t68 {
  height: 100%;
}

.single-dps-table:hover {
  border-color: #0ab9f2;
}

.my-personal-gutter {
  animation: fadeInUp 0.3s linear;
}

.eligibility-body .css-1g6gooi {
  margin: 0;
  height: 48px;
  margin-top: -4px !important;
}

.bg-1B5F8D {
  background: #1b5f8d;
}

.bg-f0f4f3 {
  background-color: #f0f4f3;
}

.eligibility-bg {
  z-index: 0;
  width: inherit;
  height: inherit;
  opacity: 1;
}

/*Subscribe area*/
.form-control2 {
  border: 1px solid #e1e1e1;
  line-height: 55px;
  height: 55px !important;
  margin: 0 !important;
}

.text-4e4e4e {
  color: #3e3c3c;
}

.fz-35 {
  font-size: 35px;
}

.form-control2:focus {
  outline: none;
}

.subscribe-content h2 {
  padding-bottom: 52px;
}

.custom-select2 .css-1hwfws3 {
  height: 54px;
}

.custom-select2 .css-vj8t7z {
  border: none;
}

.custom-select2 .css-d8oujb {
  display: none;
}

.custom-select2 .css-xp4uvy {
  font-weight: 500;
  color: #6d6d6d;
  font-size: 16px;
}

.bg-f4ca1f {
  background: #f4ca1f;
}

.big-btn {
  padding: 16px 60px;
}

.new-best-deal .single_item:hover {
  box-shadow: 0px 5px 10px 1px rgba(231, 231, 231, 1) !important;
  transform: translateY(-3px);
}

/*Subscribue Area*/

/*Media Quaries*/
@media (min-width: 992px) and (max-width: 1200px) {
  .banner-box-options a,
  .banner-box-options h4 {
    padding-left: 10px !important;
  }

  .banner-box-options a {
    font-size: 14px !important;
    padding: 8px 10px !important;
  }

  .banner-box-options h4 {
    font-size: 18px;
    padding-bottom: 10px !important;
  }

  .banner-inner h1 {
    font-size: 48px;
    padding-bottom: 20px !important;
  }

  .customer-features p {
    font-size: 14px;
    line-height: 25px;
  }

  .blog_meta a h5 {
    font-size: 18px !important;
    padding-bottom: 10px !important;
  }

  .testimonial-quote {
    top: 0px;
    z-index: 0;
    width: 42% !important;
  }
}

/*New Css*/
.header-btn {
  border: 1px solid #008ecc !important;
  padding: 0px 20px !important;
  height: 45px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 50px !important;
  background: #fff !important;
  color: #008ecc !important;
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
  border-radius: 50px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navigation_right_area ul {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navigation_right_area ul li:not(:last-child) {
  margin-right: 19px;
}

.header-btn:hover {
  background: #dbf0ff !important;
}

/*New Icon*/
@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?w4xxzk');
  src: url('fonts/icomoon.eot?w4xxzk#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?w4xxzk') format('truetype'),
    url('fonts/icomoon.woff?w4xxzk') format('woff'),
    url('fonts/icomoon.svg?w4xxzk#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-biology:before {
  content: '\e900';
}
.icon-left-arrow:before {
  content: '\e901';
}
.icon-company:before {
  content: '\e902';
}
.icon-right-arrow:before {
  content: '\e903';
}
.icon-thinking:before {
  content: '\e904';
}
.icon-employee:before {
  content: '\e905';
}
.icon-file:before {
  content: '\e906';
}
.icon-card:before {
  content: '\E926';
  color: #f2815e;
}
.icon-check-mark:before {
  content: '\E923';
  color: #189cb2;
}
.icon-car-insurance:before {
  content: '\e909';
}
.icon-report:before {
  content: '\e90a';
}
.icon-financial .path1:before {
  content: '\e90b';
  color: rgb(0, 204, 179);
}
.icon-financial .path2:before {
  content: '\e90c';
  margin-left: -1em;
  color: rgb(61, 154, 226);
}
.icon-financial .path3:before {
  content: '\e90d';
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-financial .path4:before {
  content: '\e90e';
  margin-left: -1em;
  color: rgb(61, 154, 226);
}
.icon-financial .path5:before {
  content: '\e90f';
  margin-left: -1em;
  color: rgb(30, 129, 206);
}
.icon-financial .path6:before {
  content: '\e910';
  margin-left: -1em;
  color: rgb(255, 218, 170);
}
.icon-financial .path7:before {
  content: '\e911';
  margin-left: -1em;
  color: rgb(255, 212, 34);
}
.icon-financial .path8:before {
  content: '\e912';
  margin-left: -1em;
  color: rgb(255, 152, 17);
}
.icon-financial .path9:before {
  content: '\e913';
  margin-left: -1em;
  color: rgb(255, 98, 67);
}
.icon-financial .path10:before {
  content: '\e914';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-financial .path11:before {
  content: '\e915';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-financial .path12:before {
  content: '\e916';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-financial .path13:before {
  content: '\e917';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-financial .path14:before {
  content: '\e918';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-financial .path15:before {
  content: '\e919';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-financial .path16:before {
  content: '\e91a';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-financial .path17:before {
  content: '\e91b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-investment:before {
  content: '\e91c';
}
.icon-idea:before {
  content: '\e91d';
}
.icon-accounting:before {
  content: '\e91e';
}
.icon-Application:before {
  content: '\e91f';
  color: #62a46d;
}
.icon-briefcase:before {
  content: '\e920';
}
.icon-calender:before {
  content: '\e921';
}
.icon-Car-Loan:before {
  content: '\e922';
  color: #58a07c;
}
.icon-Check-Eligibility:before {
  content: '\e923';
  color: #189cb2;
}
.icon-check-mark1:before {
  content: '\e924';
  color: #31a557;
}
.icon-clock:before {
  content: '\e925';
}
.icon-Credit-Card-Rate:before {
  content: '\e926';
  color: #f2815e;
}
.icon-Credit-Card-Rate-2:before {
  content: '\e926';
  color: #1469f2;
}
.icon-document:before {
  content: '\e927';
  color: #b8b8b8;
}
.icon-Double-Money:before {
  content: '\e928';
  color: #8f9d57;
}
.icon-DPS:before {
  content: '\e929';
  color: #549d9a;
}
.icon-EMI:before {
  content: '\e92a';
  color: #919191;
}
.icon-Expected_Cost:before {
  content: '\e92b';
  color: #5d5d5d;
}
.icon-Fixed-Deposit:before {
  content: '\e92d';
  color: #826d94;
}
.icon-Home-Loan:before {
  content: '\e92e';
  color: #d8842a;
}
.icon-job-designation .path1:before {
  content: '\e92f';
  color: rgb(255, 255, 255);
}
.icon-job-designation .path2:before {
  content: '\e930';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-job-designation .path3:before {
  content: '\e931';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-job-designation .path4:before {
  content: '\e932';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-job-exp-location:before {
  content: '\e933';
  color: #5d5d5d;
}
.icon-job-work-at .path1:before {
  content: '\e934';
  color: rgb(255, 255, 255);
}
.icon-job-work-at .path2:before {
  content: '\e935';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-job-work-at .path3:before {
  content: '\e936';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-job-work-at .path4:before {
  content: '\e937';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-job-work-at .path5:before {
  content: '\e938';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-job-work-at .path6:before {
  content: '\e939';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-job-work-at .path7:before {
  content: '\e93a';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-job-work-at .path8:before {
  content: '\e93b';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-job-work-at .path9:before {
  content: '\e93c';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-job-work-at .path10:before {
  content: '\e93d';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-job-work-at .path11:before {
  content: '\e93e';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-job-work-at .path12:before {
  content: '\e93f';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-job-work-at .path13:before {
  content: '\e940';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-job-work-at .path14:before {
  content: '\e941';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-job-work-at .path15:before {
  content: '\e942';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-job-work-at .path16:before {
  content: '\e943';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-job-work-at .path17:before {
  content: '\e944';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-job-work-at .path18:before {
  content: '\e945';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-job-work-at .path19:before {
  content: '\e946';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-Like:before {
  content: '\e947';
  color: #b8b8b8;
}
.icon-Loan:before {
  content: '\e948';
  color: #a2a2a2;
}
.icon-Location:before {
  content: '\e949';
  color: #5d5d5d;
}
.icon-log-amout .path1:before {
  content: '\e94a';
  color: rgb(255, 255, 255);
}
.icon-log-amout .path2:before {
  content: '\e94b';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-amout .path3:before {
  content: '\e94c';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-amout .path4:before {
  content: '\e94d';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-amout .path5:before {
  content: '\e94e';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-bank-name:before {
  content: '\e94f';
  color: #707070;
}
.icon-log-company-name:before {
  content: '\e950';
  color: #a5a5a5;
}
.icon-log-company-type .path1:before {
  content: '\e951';
  color: rgb(255, 255, 255);
}
.icon-log-company-type .path2:before {
  content: '\e952';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path3:before {
  content: '\e953';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path4:before {
  content: '\e954';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path5:before {
  content: '\e955';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path6:before {
  content: '\e956';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path7:before {
  content: '\e957';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path8:before {
  content: '\e958';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path9:before {
  content: '\e959';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path10:before {
  content: '\e95a';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path11:before {
  content: '\e95b';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path12:before {
  content: '\e95c';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path13:before {
  content: '\e95d';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path14:before {
  content: '\e95e';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path15:before {
  content: '\e95f';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path16:before {
  content: '\e960';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path17:before {
  content: '\e961';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path18:before {
  content: '\e962';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path19:before {
  content: '\e963';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path20:before {
  content: '\e964';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path21:before {
  content: '\e965';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path22:before {
  content: '\e966';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path23:before {
  content: '\e967';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path24:before {
  content: '\e968';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path25:before {
  content: '\e969';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path26:before {
  content: '\e96a';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path27:before {
  content: '\e96b';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path28:before {
  content: '\e96c';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path29:before {
  content: '\e96d';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path30:before {
  content: '\e96e';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path31:before {
  content: '\e96f';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path32:before {
  content: '\e970';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path33:before {
  content: '\e971';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path34:before {
  content: '\e972';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path35:before {
  content: '\e973';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path36:before {
  content: '\e974';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path37:before {
  content: '\e975';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path38:before {
  content: '\e976';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path39:before {
  content: '\e977';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path40:before {
  content: '\e978';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path41:before {
  content: '\e979';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path42:before {
  content: '\e97a';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path43:before {
  content: '\e97b';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path44:before {
  content: '\e97c';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path45:before {
  content: '\e97d';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path46:before {
  content: '\e97e';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path47:before {
  content: '\e97f';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path48:before {
  content: '\e980';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path49:before {
  content: '\e981';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path50:before {
  content: '\e982';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path51:before {
  content: '\e983';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path52:before {
  content: '\e984';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path53:before {
  content: '\e985';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path54:before {
  content: '\e986';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path55:before {
  content: '\e987';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path56:before {
  content: '\e988';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path57:before {
  content: '\e989';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-company-type .path58:before {
  content: '\e98a';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-credit-card:before {
  content: '\e98b';
  color: #818181;
}
.icon-log-job-exp .path1:before {
  content: '\e98c';
  color: rgb(255, 255, 255);
}
.icon-log-job-exp .path2:before {
  content: '\e98d';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-job-exp .path3:before {
  content: '\e98e';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-job-exp .path4:before {
  content: '\e98f';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-job-exp .path5:before {
  content: '\e990';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-job-exp .path6:before {
  content: '\e991';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-job-exp .path7:before {
  content: '\e992';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-job-exp .path8:before {
  content: '\e993';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-job-exp .path9:before {
  content: '\e994';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-job-exp .path10:before {
  content: '\e995';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-job-status:before {
  content: '\e996';
  color: #707070;
}
.icon-log-tin:before {
  content: '\e997';
}
.icon-log-total-job-exp .path1:before {
  content: '\e998';
  color: rgb(255, 255, 255);
}
.icon-log-total-job-exp .path2:before {
  content: '\e999';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-total-job-exp .path3:before {
  content: '\e99a';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-total-job-exp .path4:before {
  content: '\e99b';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-total-job-exp .path5:before {
  content: '\e99c';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-total-job-exp .path6:before {
  content: '\e99d';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-total-job-exp .path7:before {
  content: '\e99e';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-total-job-exp .path8:before {
  content: '\e99f';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-total-job-exp .path9:before {
  content: '\e9a0';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-log-tt:before {
  content: '\e9a1';
  color: #7b7b7b;
}
.icon-log-value-apartment:before {
  content: '\e9a2';
  color: #5d5d5d;
}
.icon-Message:before {
  content: '\e9a3';
  color: #00c7de;
}
.icon-Monthly-Benefit:before {
  content: '\e9a4';
  color: #4d98b9;
}
.icon-Motor-Insurance:before {
  content: '\e9a5';
  color: #705a6a;
}
.icon-My_Profile:before {
  content: '\e9a6';
  color: #00add9;
}
.icon-Offers:before {
  content: '\e9a7';
}
.icon-Personal-Loan:before {
  content: '\e9a8';
  color: #24a5c7;
}
.icon-Prize-Bond:before {
  content: '\e9a9';
  color: #597da8;
}
.icon-Profession .path1:before {
  content: '\e9aa';
  color: rgb(255, 255, 255);
}
.icon-Profession .path2:before {
  content: '\e9ab';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-Property:before {
  content: '\e9ac';
  color: #5d5d5d;
}
.icon-Recidence-Type .path1:before {
  content: '\e9ad';
  color: rgb(255, 255, 255);
}
.icon-Recidence-Type .path2:before {
  content: '\e9ae';
  margin-left: -1em;
  color: rgb(112, 112, 112);
}
.icon-Settings:before {
  content: '\e9af';
  color: #6a8eaa;
}
.icon-sign-out:before {
  content: '\e9b0';
  color: #8b9c87;
}
.icon-small-calendar:before {
  content: '\e9b1';
}
.icon-Support:before {
  content: '\e9b2';
}
.icon-Triple-Money:before {
  content: '\e9b3';
  color: #8c7c5a;
}
.icon-user-application:before {
  content: '\e9b4';
  color: #62a46d;
}
.icon-user-message:before {
  content: '\e9b5';
  color: #00c7de;
}
.icon-user-profile:before {
  content: '\e9b7';
  color: #00add9;
}
.icon-user-sing-out:before {
  content: '\e9b8';
  color: #8b9c87;
}
.icon-user-support:before {
  content: '\e9b9';
  color: #9f529a;
}
.icon-usr-settings:before {
  content: '\e9ba';
  color: #6a8eaa;
}

/*------------------------------------------------
    End New Icon
------------------------------------------------*/

/* Sidebar Icon css */
.sidebar-icon .single-menu-portion {
  font-size: 23px !important;
}

.single-menu-portion .sidebar-icon {
  font-size: 24px;
}

.sideico-1 .sidebar-icon {
  font-size: 26px;
}

.sideico-2 .sidebar-icon,
.sideico-3 .sidebar-icon {
  font-size: 21px;
}

.sideico-7 .sidebar-icon {
  font-size: 25px !important;
}

.sideico-9 .sidebar-icon,
.sideico-10 .sidebar-icon {
  font-size: 25px !important;
}

.sideico-1 span:hover::before {
  color: #24a5c7 !important;
}

.sideico-1:hover span {
}
.sideico-1 .sidebar-icon,
.sideico-1:hover span {
  color: #24a5c7 !important;
}
.sideico-2 .sidebar-icon,
.sideico-2:hover span {
  color: #df9a50 !important;
}
.sideico-3 .sidebar-icon,
.sideico-3:hover span {
  color: #58a07c !important;
}
.sideico-4 .sidebar-icon,
.sideico-4:hover span {
  color: #836f95 !important;
}
.sideico-5 .sidebar-icon,
.sideico-5:hover span {
  color: #549d9a;
}
.sideico-6 .sidebar-icon,
.sideico-6:hover span {
  color: #5da1bf !important;
}
.sideico-7 .sidebar-icon,
.sideico-7:hover span {
  color: #b3bc8c !important;
}

.sideico-8 .sidebar-icon,
.sideico-8:hover span {
  color: #8c7c5a !important;
}
.sideico-9 .sidebar-icon,
.sideico-9 .sidebar-icon::before,
.sideico-9:hover span {
  color: #189cb2 !important;
}
.sideico-10 .sidebar-icon,
.sideico-10:hover span {
  color: #f2815e !important;
}
.sideico-11 .sidebar-icon,
.sideico-11 .sidebar-icon::before,
.sideico-11:hover span {
  color: #08aef2 !important;
}

/*-------------------------------------------------------------------
/ Profile Setting Style
/ ---------------------------------------------------------------- */
.profile-setting-outer {
}

.profile-setting-inner {
  padding-top: 20px;
}

.profile-setting-tab {
}

.profile-top {
  text-align: center;
  background: #e2ebf5;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding-bottom: 20px;
}

.profile-top img {
  padding-top: 20px;
}

.profile-top h6 {
  color: #383838;
  font-size: 20px;
  font-weight: 500;

  line-height: 38px;
}

.profile-top p {
  color: #383838;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 12px;
}

.profile-top a {
  color: #fff;
  background: #008ecc;
  font-size: 16px;
  font-weight: 500;
  height: 45px;
  padding: 14px 45px;
  line-height: 47px;
  border-radius: 35px;
}

.profile-unorderlist {
  border-top: 0px;
  border: 1px solid #ebebeb;
}

.profile-unorderlist .nav-item {
  height: 54px;
  background: #ffffff;
}

.profile-unorderlist .nav-item .nav-link {
  color: #767676;
  padding: 16px 15px;
  font-size: 15.19px;
  background: #fff;
  transition: all 1s ease;
  line-height: 24px;
  font-weight: 400;
  text-align: left;
}

.profile-setting-description {
}

.update-generale-outer {
  border-radius: 4px;
  border: 1px solid #ebebeb;
  background: #fff;
}

.update-generale-title {
  padding: 15px 15px 10px 15px;
  border-bottom: 1px solid #ebebeb;
}

.update-generale-title h5 {
  color: #4f4f4f;
  font-size: 20px;
  font-weight: 500;
}

.update-generale-title p {
  color: #707070;
  font-size: 16px;

  line-height: 36px;
}

.update-generale-title i {
  font-size: 35px;
  color: #707070;
  line-height: 53px;
}

.update-generale-input-group .input-group input {
  color: #626262;
  font-size: 16px;
  background: #f2f2f2;
  padding-left: 0px;
  height: 50px;
}

.update-generale-input-group .input-group .input-group-text {
  color: #18b3db;
  background: #f2f2f2;
  padding: 5px 10px;
  font-size: 22px;
}

.update-generale-input-group .input-group .input-group-text i {
  padding-bottom: 4px;
}

.save-button {
  height: 50px;
  color: #fff;
  padding: 0px 40px;
  font-size: 16px;
  border-radius: 4px;
  background: #008ecc;
  border: 0px;
}

.profile-unorderlist .nav-link.active,
.nav-pills .show > .nav-link {
  color: #767676;
  padding: 16px 15px;
  border-radius: 0px;
  -webkit-transition: all 0.3s cubic-bezier(0.5, 0, 0.5, 1);
  -moz-transition: all 0.3s cubic-bezier(0.5, 0, 0.5, 1);
  -ms-transition: all 0.3s cubic-bezier(0.5, 0, 0.5, 1);
  -o-transition: all 0.3s cubic-bezier(0.5, 0, 0.5, 1);
  transition: all 0.3s cubic-bezier(0.5, 0, 0.5, 1);
}

.profile-unorderlist .nav-link.active:hover {
  background-color: #f8f8f8;
}

.bg-f6f9fb {
  background: #f6f9fb;
}

.color-626262 {
  color: #626262 !important;
}

.fs16 {
  font-size: 16px !important;
}

/*.menu-tab .nav li.pro-tb.react-tabs__tab--selected {*/
/*background: #E2EBF5 !important;*/
/*}*/
.profile-unorderlist li.nav-item {
  background: #ffffff !important;
}

.profile-setting-tab .nav-item span {
  display: inline-block;
  margin-right: 10px;
  font-size: 19px;
}

.profile-setting-tab .nav-item .nav-link {
  display: flex;
  align-items: center;
  justify-content: start;
}

.sign-area.sidebar .sign-container {
  padding: 0px 0px;
}

.sign-area.sidebar-sign .sign-container {
  padding: 30px 30px;
  width: 100%;
}

.focus-blue:focus {
  box-shadow: none !important;
  border-color: #3db7f0 !important;
}

.sidebar-top-right.react-tabs__tab-panel--selected {
  height: 100%;
}

.bg-E2EBF5 {
  background: #e2ebf5 !important;
}

.sign-area.sidebar-sign {
  padding: 0;
}

.sign-area.sidebar-sign .forget-btn {
  font-size: 13px !important;
  color: #1b5f8d;
}

.sign-area.sidebar-sign .sign-up {
  color: #1b5f8d;
  font-weight: 600;
}

.left-sidebar-apply {
  padding: 10px 0;
  font-size: 18px;
}

.btn-pointer {
  cursor: pointer;
}

.warningText {
  color: red;
}

.successText {
  color: green;
}

.centerForget {
  text-align: center;
  padding-top: 4%;
}

.colorFixLink {
  color: #628aea;
}

/**/
.sidemenu-inner .menu-tab .nav li::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 0;
  background: #3db7f0;
  height: 1px;
  transition: all 0.2s linear;
  z-index: 5;
}

.sidemenu-inner .menu-tab .nav li.react-tabs__tab--selected::before {
  width: 100%;
}

.menu-tab .nav {
  position: relative;
}

.profile-nav .menu-tab .nav::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  height: calc(100% + 0px);
  width: 1px;
  background: #ccc;
  z-index: 1;
}

.menu-tab .nav {
  box-shadow: 0px 1px 3px #ccc;
}

/*.menu-tab .nav li::before {*/
/*content: "";*/
/*position: absolute;*/
/*left: 0;*/
/*width: 0;*/
/*height: 1px;*/
/*background: #1B5F8D;*/
/*bottom: 0;*/
/*}*/

.menu-tab .nav li.react-tabs__tab--selected::before {
  width: 100%;
}

/*.menu-tab .nav li::before {*/
/*content: "";*/
/*position: absolute;*/
/*left: 0;*/
/*display: block;*/
/*height: 1px;*/
/*width: 100%;*/
/*background: #1B5F8D;*/
/*z-index: 2;*/
/*}*/

.react-select-no-border .css-d8oujb {
  display: none;
}

.react-select-no-border .css-vj8t7z {
  border: none !important;
}

.react-select-no-border .css-xp4uvy,
.css-1492t68 {
  height: 45px !important;
  line-height: 45px !important;
}

/* personal loan css */

/*.person_img {*/
/*position: absolute;*/
/*top: 90px;*/
/*left: 15%;*/
/*}*/

/* car loan css */
/* .page_wrapper1 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
} */

/* .select_ch {
  -webkit-appearance: none;
  background: url(./images/br_down.png) no-repeat right;
  background-position: 98%;
} */

.deposit_amount_section_input {
  border-radius: 4px;
  background-color: #fff;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(3.3rem + 2px);
}

.eligibility_area1 {
  position: relative;
  overflow: hidden;
  background: #1b5f8d;
  height: calc(100vh - 76px);
}

.eligiblity_inner_h2 {
  margin-top: -10px;
}

.eligibility_wraper1 {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  padding: 0px 0px 15px 0px;
  position: relative;
  z-index: 9;
}

.personal_loan_logo {
  height: 46px;
  width: 227px;
}

.bcbd_lg_btn.t3 {
  background: #f4ca1f;
}

.bcbd_lg_btn.t4 {
  background: #3abaf4;
}

.creditcompare_section_input2 {
  border-radius: 4px;
  background-color: #f4ca1f;
  color: #3b3b3b;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 16px;
}

.creditcompare_section_input3 {
  height: 56px;
  border-radius: 4px;
  background-color: #3abaf4;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 16px;
  text-align: center;
}

/* home loan css */

.eligibility_area1_inner {
  position: relative;
}

.eligible_top .sl_div1 {
  float: left;
  width: 33.33%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  z-index: 9;
}

.home_img {
  position: absolute;
  left: -110px;
  bottom: -1px;
  z-index: 0;
}

.sun_img {
  position: absolute;
  top: 55px;
  left: 0px;
}

/* --------------------------------------------------------------------
/  Track App 01 Style
/ ------------------------------------------------------------------ */
.bg-1b5f8d {
  background: #1b5f8d;
}

.track-app-outer {
  height: 100vh;
}

.track-app-inner {
}

.track-app-form {
}

.track-form-title {
  color: #fff;
  font-size: 35px;

  font-weight: 500;
}

.track-form-area {
  margin-top: 12px;
  padding: 30px 95px;
  background: #276793;
}

.track-input-group input {
  height: 55px;
  color: #8e8e8e;
  font-size: 16px;
  font-weight: 500;
}

.forget-id p {
  color: #b5c9d7;
  font-size: 16px;
  padding-bottom: 12px;
}

.forget-id span {
  color: #b5c9d7;
  font-size: 16px;
  padding-bottom: 5px;
}

.forget-id {
  position: relative;
}

.forget-id:before {
  content: '';
  position: absolute;
  top: 40px;
  left: 0px;
  height: 1px;
  width: 45%;
  background: #417ca6;
}

.forget-id:after {
  content: '';
  position: absolute;
  top: 40px;
  right: 0px;
  height: 1px;
  width: 45%;
  background: #417ca6;
}

.track-button {
  height: 55px;
  width: 100%;
  text-transform: uppercase;
  border: 0px;
  border-radius: 4px;
  color: #434343;
  background: #f4ca1f;
  font-size: 16px;
  font-weight: 700;

  cursor: pointer;
}

.track-login {
  height: 55px;
  width: 100%;
  text-transform: uppercase;
  border: 0px;
  border-radius: 4px;
  color: #fff;
  background: #0ab9f2;
  font-size: 16px;
  font-weight: 700;

  cursor: pointer;
}

.css-15k3avv {
  z-index: 9999 !important;
}

/*.new-selction-style .css-z5z6cw {*/
/*background: #3D779E;*/
/*color: #fff;*/
/*}*/
/*.new-selction-style  .css-z5z6cw:active{*/
/*background: transparent;*/
/*}*/
/*.new-selction-style .css-wqgs6e:active {*/
/*background: #3D779E;*/
/*color: #fff;*/
/*}*/
/*.new-selction-style .css-xp4uvy{*/
/*color: #555555;*/
/*}*/
/*.new-selction-style .css-z5z6cw {*/
/*background: transparent;*/
/*color: #555;*/
/*}*/
/*.more-info-btn:hover{*/
/*color: #ffffff;*/
/*}*/
/*.new-selction-style .css-z5z6cw:hover{*/
/*background: #3D779E !important;*/
/*color: #fff !important;*/
/*}*/

.track-form-title {
  color: #fff;
  font-size: 35px;

  font-weight: 500;
}

.fz-15 {
  font-size: 15px;
}

.track-form-area {
  margin-top: 12px;
  padding: 30px 95px;
  background: #276793;
}

.track-input-group {
  height: 55px;
  color: #8e8e8e;
  font-size: 16px;
  font-weight: 500;
}

/*Loan Rate Table Css*/
.single-card .card-image {
  width: 148px !important;
  max-width: 150px !important;
  padding-left: 0px !important;
  height: 115px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
}

.single-card .card-box-title {
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 18px;
  color: #7b7b7b !important;
  line-height: 28px;
}

.go-campare {
  padding: 11px 20px;
  font-size: 18px !important;
  font-weight: 500;
  background: #008ecc;
}

/*.sidebar-amount-title {*/
/*line-height: 0px;*/
/*}*/
.carShareParcent > .currency-icon {
  position: absolute !important;
  left: 38px !important;
}

.carShareParcent > .form-control {
  padding-left: 13px !important;
}

/*.credit-card-sidebar .new-selction-style{*/
/*font-weight: 400 !important;*/
/*}*/
.forget-field {
  width: 100%;
  padding: 15px 15px;
  margin-top: 5px;
  position: static;
}

.forget-field input {
  height: 50px !important;
  margin-bottom: 15px;
}

/*Eligibility css*/
.eligibility-top-message-box {
  background: #f5f5f5;
  padding: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-bottom: -5px;
}

.new-eligibility-area.eligibility-type-2 {
  max-height: calc(100vh - 67px);
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  height: calc(100vh - 62px);
}
bcbd_group
  gridViewIncome2
  .single_card_eligibility_parent
  .new-eligibility-area {
  padding-top: 25px !important;
}

.eligibility-top-message-box .media {
  background: #008ecc;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  align-items: center;
  padding: 9px 12px;
}

.eligibility-top-message-box .media h5 {
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  text-shadow: 0px 4px 0px rgba(0, 0, 0, 0.1);
}

.modal-type1 .modal-body {
  height: 500px !important;
  border-radius: 5px !important;
}

/*.eligibility-type-2 .new-selction-style  .css-1492t68 {*/
/*height: 47px !important;*/
/*line-height: 47px !important;*/
/*}*/

.css-11unzgr::-webkit-scrollbar {
  width: 10px !important;
}

/* Track */
.css-11unzgr::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

/* Handle */
.css-11unzgr::-webkit-scrollbar-thumb {
  background: #aaaaaa !important;
}

/* Handle on hover */
.css-11unzgr::-webkit-scrollbar-thumb:hover {
  background: #aaaaaa !important;
}

.eligibility-body .form-control {
  color: #555555 !important;
  font-weight: 500;
}

.border-red {
  border: 1px solid red !important;
}

.footer-logo {
  margin-bottom: 30px;
}

.menu-sidebar .scrollbar-container {
  height: calc(100% - 45px) !important;
  overflow: hidden !important;
}

.modal-area-content {
  border-radius: 10px;
}

.eligibility-modal-footer {
  display: flex;
  height: 100%;
  border-radius: 5px;
  background-color: #f3f8ff;
  z-index: -1;
  align-items: start;
}

.eligibility-modal-header {
  margin-top: 30px;
  margin-left: 35px;
}

.eligibility-modal-footer h4 {
  font-size: 20px;
  color: #434343;
  font-weight: 500;
  padding-top: 35px;
  padding-bottom: 20px;

  line-height: 30px;
}

.eligibility-modal-title {
  font-size: 13px;
  color: #434343;
}

.eligibility-modal-footer p {
  color: #434343;
  font-size: 16px;

  line-height: 22px;
}

.non-eligibility {
  margin: 0px 20px;
  padding-bottom: 70px;
  flex: 1 1 auto;
  padding: 1rem;
}

.non-eligibility ul li {
  margin-left: 20px;
  color: #4c4c4c;
  padding-bottom: 7px;
  font-size: 16px;
  list-style: disc;
  line-height: 30px;
}

.eligibity-top-image img {
  width: 51px;
  height: 50px;
}

.height-515 {
  height: 515px;
}

/*.modal-body {*/
/*height: calc(100% - 100px) !important;*/
/*overflow: auto;*/
/*}*/

/*Eligibity Css*/

.eligibility-step .single-step {
  margin-right: calc(25.6% + 0px) !important;
}

.eligibility-step .single-step:last-child {
  margin-right: 0 !important;
  text-align: right !important;
}

.single-step .step-label {
  font-size: 12px !important;
}

.step-circle {
  height: 25px !important;
  width: 25px !important;
  border: 2px solid #b8b8b8 !important;
}

.single-step.active .step-circle::after {
  width: 209px !important;
}

.step-circle::before {
  height: 3px !important;
  width: 209px !important;
}

.step-circle::after {
  height: 3px !important;
}

.tick-icon {
  font-size: 17px !important;
  top: -2px !important;
  left: 2px !important;
}

.step-circle::after {
  height: 3px;
  width: 0px;
  top: 50%;
}

.step-circle {
  height: 25px !important;
  width: 25px !important;
  border: 2px solid #b8b8b8 !important;
}

.single-step.active .step-circle {
  border-color: #0ab9f2 !important;
}

.eligibility-inner {
  padding-top: 8px !important;
}

/*.eligibility-label {*/
/*    color: #444444;*/
/*    font-size: 16.5px;*/
/*    font-family: 'PT Serif', serif;*/
/*}*/

.yesNo2 .rental_check .eligibility_body .sl_div {
  height: 100%;
}

/**/
.takeoverbox p {
  line-height: 42px;
  color: #3e3c3c;
  font-size: 15px;
  font-weight: 500;
}

.takeoverbox .form-group {
  height: 30px;
  background: #fff;
  width: 30px;
  position: relative;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.takeoverbox .form-group input {
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  left: 0;
  opacity: 0;
  z-index: 9;
}

.takeoverbox .form-group label {
  font-size: 26px;
  position: absolute;
  left: 4px;
  top: 0;
  line-height: 24px;
  color: #0ab9f2;
  transition: all 0.3s linear;
}

.takeoverbox .form-group input label {
  position: absolute;
  left: 0;
  top: 0;
}

.takeoverbox .form-group label {
  font-size: 26px;
  line-height: 24px;
  position: absolute;
  color: #0ab9f2;
  opacity: 0;
}

.takeoverbox .form-group input:checked ~ label {
  visibility: visible;
  opacity: 1;
}

/*Rate Table*/
.single-card .card-image img {
  max-width: 100%;
  max-height: 100%;
  padding-bottom: 10px;
}

.apply_modal_inner .bank_name img {
  width: 100px;
  height: 100%;
}
.apply_modal_inner .bank_name img {
  width: 150px;
  height: 100px;
}

/**/
.compare-card-image img {
  max-width: 135px;
  max-width: 135px;
}

/*updated*/
.compare-card-image {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.single_vt_img_parent {
  height: 52px;
  max-width: 130px;
  max-height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.single_vt_card.lCard {
  display: flex;
  align-items: center;
  justify-content: center;
}

.single_vt_card.lCard p.bcbd-text {
  font-weight: 400 !important;
}

.single_vt_card.lCard .single_vt_img_parent p {
  font-size: 12px;
}

.single_vt_card.lCard p {
  font-size: 12px !important;
  line-height: 12px;
}

.loanCompareModal .fz-16 {
  font-size: 14px !important;
}

/*Log Status Icon*/
.right-log-body i::before {
  color: #fff !important;
}

.no-selection {
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}
.right-log-body {
  height: 470px !important;
}

.no-visibility {
  visibility: hidden;
}

.virtual-disable {
  height: 100%;
  width: 100%;
  background: transparent;
  z-index: 999;
}

.apply_modal_inner .single_option .bcbd_inputgrup .form-control.no-border {
  border: none !important;
}

/*LOader Style*/
.full-page-view {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(255, 255, 255, 0.2);
}

/*check border type*/
.bcbd_check .border-red label {
  border-color: red !important;
}

.bcbd_check .border-red {
  border: none !important;
}

/*MOdal*/
.website-construct img {
  width: 390px;
}

/*New Select2 Style*/
.new-select2-style .css-1szy77t-control {
  height: 50px;
}

.new-select2-style .css-1szy77t-control {
  border-color: transparent;
  box-shadow: none !important;
  font-weight: 500;
  color: #555;
}

.new-select2-style {
  padding: 0px !important;
  font-weight: 400;
  font-size: 15px;
  color: #444;
  margin: 1px 1px 1px 0px;
}
.new-select2-style-loan {
  padding: 0px !important;
  font-weight: 400;
  font-size: 15px;
  color: #444;
  margin: 0px 1px 0px 0px;
}

.new-select2-style .css-dvua67-singleValue,
.new-select2-style .css-151xaom-placeholder {
  color: #555;
}

.new-select2-style .css-bg1rzq-control {
  height: 50px;
}
.new-select2-style .css-bgvzuu-indicatorSeparator,
.new-select2-style .my-select__indicator-separator {
  display: none !important;
}
.new-select2-style .css-fk865s-option:active,
.new-select2-style .css-xo7z33-option {
  background: #fff !important;
  color: #555 !important;
}
.new-select2-style .css-xo7z33-option:hover,
.new-select2-style .css-fk865s-option:hover,
.new-select2-style .my-select__option--is-selected:hover,
.new-select2-style .css-dpec0i-option {
  background: #1b5f8d !important;
  color: #fff !important;
}
.mobile-react-select .css-xo7z33-option:hover,
.mobile-react-select .my-select__option--is-selected:hover,
.mobile-react-select .css-dpec0i-option {
  background: transparent !important;
  color: #333 !important;
}

.new-select2-style .my-select__menu-list {
  color: #555;
}
.new-select2-style .css-1szy77t-control,
.new-select2-style .css-bg1rzq-control,
.eligibility-type-2 .new-select2-style .css-bg1rzq-control {
  border: none !important;
}
.new-select2-style:focus {
  outline: none;
}
.eligibility-type-2 .new-select2-style .css-1szy77t-control:hover {
  border-color: transparent !important;
}
.new-select2-style .css-kj6f9i-menu {
  z-index: 9999 !important;
}

.single-dps-table .fz-16 {
  font-size: 17px;
}

.single-card.single-card-choosed,
.single-card.single-card-choosed .card {
  background: #e7f7ff;
}
/**/
.single-loan-card .card-box-content {
  font-size: 17px;
}
.single-loan-card .card-box-title,
.text-555 {
  color: #444 !important;
}
.text-1e6 {
  color: #1e6b9c;
}

/*Single card Close */

.virtual_card_close {
  border-color: #ff6b6b;
  color: #ff6b6b !important;
  top: -14px;
  background: #fff;
  border-radius: 50%;
  right: -10px;
  cursor: pointer;
  height: 23px;
  width: 23px;
  font-size: 12px;
  transition: all 0.3s linear;
}

.virtual_card_close:hover {
  background: #6f2020;
  color: #ffffff !important;
  animation: jello 0.3s linear;
}

/*Modal Universal Rules*/
.modalCommonRules {
  height: calc(100vh - 20%) !important;
  border-radius: 5px;
  max-width: 75% !important;
  width: 75% !important;
}
.styles_closeButton__20ID4 {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.modalCommonRules .modal-inner-content {
  height: calc(100% - 52px) !important;
}
.overlay-screen {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 100;
  background: rgba(0, 0, 0, 0.7);
  display: none;
}
.overlay-screen.active {
  display: block;
}

/* Responsive Eligibility for 992 */
@media all and (min-width: 992px) and (max-width: 1199.9px) {
  .step-circle::before {
    height: 3px !important;
    width: 175px !important;
  }
  .single-step .step-label {
    white-space: nowrap;
  }
  .eligibility-step .single-step {
    margin-right: calc(24% + 0px) !important;
  }
  .single-step.active .step-circle::after {
    width: 175px !important;
  }
}
.ps__rail-y {
  opacity: 0.9 !important;
  right: 0 !important;
}

/*Loan Details Bank Title*/
.card .no-gutters .col h4 {
  color: #666;
  font-size: 22px;
  text-align: center;
}

/*Input Inside Bar*/
.eligibility-body .css-1g6gooi {
  margin-top: -11px !important;
}
@media screen and (max-width: 1440px) {
  .eligibility-body .css-1g6gooi {
    margin-top: -17px !important;
  }
}
@media screen and (max-width: 1200px) {
  .eligibility-body .css-1g6gooi {
    margin-top: -11px !important;
  }
}

/* Rakib Changes*/
.new-eligibility-area.eligibility-type-2 h5,
.new-eligibility-area.eligibility-type-2,
.new-eligibility-area.eligibility-type-2 .eligibility_gender label,
.new-eligibility-area.eligibility-type-2 .top-select-box *,
.new-eligibility-area.eligibility-type-2,
.new-eligibility-area.eligibility-type-2 .eligibility-label,
.new-eligibility-area.eligibility-type-2 p,
.new-eligibility-area.eligibility-type-2 .new-select2-style,
.new-eligibility-area.eligibility-type-2 .form-control {
  /* font-family: 'Exo 2', sans-serif; */
  font-family: 'Poppins', sans-serif !important;
}

.boldText {
  font-weight: 400;
}
.single-card .row .col .card .card-box-title,
.single-card .row .col .card .card-box-content {
  font-family: 'Exo 2', sans-serif !important;
}
.fz-23 {
  font-size: 23px;
}
.form-group input {
  color: #555;
}
.inputToolTip {
  position: relative;
}
.inputToolTip span {
  visibility: hidden;
}
.inputToolTip:hover span {
  visibility: visible;
}
.rangeValue {
  position: absolute;
  right: -41px;
  padding: 0 5px;
  background: linear-gradient(#1489d5, #02bdf2);
  height: 30px;
  text-align: center;
  color: white;
  border-radius: 5px;
  display: inline-block;
  font: bold 15px/30px Georgia;
  bottom: 195%;
}
.rangeValue::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid #02bdf2;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  margin-top: -1px;
}
.bg-E9EC {
  background: #e9ecef;
}

.single-dps-table .row .border-dashed .align-items-md-center .fz-14 {
  font-family: 'Exo 2', sans-serif;
  font-weight: 600;
}

.top-search span {
  font-family: 'Exo 2', sans-serif;
}

.justify-content-streatch button {
  font-size: 13px !important;
  font-family: 'Exo 2', sans-serif;
}

.slick-track .slick-slide,
h3,
.credit-card-table-area button,
.text-5858,
.text-5d5d,
.form-group,
.col .ff-roboto,
.table-area col,
.row .col .text-normal,
.border-b7b7 .new-select2-style,
.form-control .new-select2-style {
  font-family: 'Exo 2', sans-serif !important;
}

.sidebar-range-slider .text-5d5d,
.card .text-5d5d {
  color: #3e3c3c;
}
/*.col-lg-3 .text-oab{*/
/*font-size: 17px;*/
/*}*/
.pt-5 .col p {
  font-size: 15px;
}

.card .fw-medium,
.material-select .fw-medium {
  font-size: 15px;
}
.p-lg-1 .focus-none {
  padding: 9px 0;
}
.no-border {
  border: none !important;
}
.lightBtn {
  background: #0ab9f2;
  color: #fff;
}
.rdButton {
  font-size: 13px !important;
}
.credt-card-choose-option .card-selection-parent input {
  height: 50px !important;
}
.credt-card-choose-option .bcbd-btn {
  padding: 4px 15px;
}
.eligibility-label.mini-label {
  line-height: 30px !important;
}
.eligibility-type-2 .children-has-border .css-bg1rzq-control {
  border-left: 1px solid #dddddd !important;
}
.subInput {
  width: 680px !important;
}
.marginBottom {
  margin-bottom: 0 !important;
}
.table-area.fullTable {
  max-width: calc(100% - 0%);
}
.loan-range-content {
  width: 455px;
  border: 1px solid #ddd;
  height: 110px;
  background: #fff;
  border-radius: 5px;
  padding: 30px 10px;
  z-index: 9;
  left: 88px;
}
/*=======================================
      === Loan rate table New Css ===
========================================*/
.bg-e2ebf5 {
  background: #e2ebf5;
}
.filter-title {
  font-size: 16px;
  color: #2b2b2b;
  font-weight: 700;
  font-family: 'Exo', sans-serif;
  z-index: 0;
  margin-right: 40px;
  cursor: pointer;
}
.fullScreenCover {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  z-index: 1;
}
/*Rate table*/
.filter-title img {
  margin-right: 5px;
}

.filter-amount {
  font-size: 14px;
  font-weight: 700;
  color: #454545;
  text-transform: uppercase;
  font-family: 'Exo', sans-serif;
  margin-right: 40px;
  cursor: pointer;
}

.filter-amount .filter-amount-digit {
  background: #fff;
  border: 1px solid #cdd6e1;
  padding: 8px 20px;
  width: 150px;
  border-radius: 30px;
  display: inline-block;
  margin-left: 10px;
}

.filter-duration-text {
  font-size: 14px;
  text-transform: uppercase;
  font-family: 'Exo', sans-serif;
  font-weight: 700;
  color: #2b2b2b;
}

.filter-duration-text i {
  display: inline-block;
  margin-right: 4px;
}

.filter-duration-dropdown {
  width: 175px;
}
.filter-duration-dropdown .css-1hwfws3 {
  padding: 2px 15px !important;
}
.filter-duration-dropdown .new-select2-style .css-bg1rzq-control {
  height: 45px;
  border-radius: 30px !important;
  cursor: pointer;
  margin-top: -9px;
}

.filter-duration-dropdown .form-control {
  border-radius: 30px;
  margin-bottom: 0;
}

.filter-duration-dropdown .form-group {
  margin-bottom: 0;
  margin-left: 8px;
}
.filter-duration-dropdown .css-dvua67-singleValue {
  font-family: 'Exo', sans-serif;
  font-weight: 700;
  color: #3b3b3b;
}

.filter-duration-text i {
  font-size: 17px;
  vertical-align: middle;
  padding-bottom: 2px;
}
.loan-range-content .input-range__label--max {
  right: 23px;
  top: -20px;
}
.loan-range-content .input-range__label--min {
  left: 20px;
  background: transparent;
  position: absolute;
  top: -18px;
}
.loan-range-content .input-range__label-container {
  color: #333333;
  font-family: 'Exo', sans-serif;
}
.loan-range-content .input-range__track,
.loan-range-content .input-range__slider-container,
.loan-range-content .input-range__label input-range__label--value,
.loan-range-content .input-range__slider {
  -webkit-transition-property: none;
  -moz-transition-property: none;
  -o-transition-property: none;
  transition-property: none;
}
.loan-range-content .input-range__label--value {
  display: none;
}
.lower-scale {
  width: calc(100% + 0px);
  background: url('../images/slider-scale.png');
  height: 15px;
}

.loan-rate-table-search {
  max-width: 350px;
  float: right;
}
.loan-rate-table-search .css-1wy0on6 {
  display: none;
}
.credit-card-sidebar {
  transition: 0.3s linear;
}
.loan-rate-table-search.input-group > .form-control,
.loan-rate-table-search .new-select2-style .css-bg1rzq-control {
  font-family: 'Exo', sans-serif;
  font-weight: 700;
  color: #2b2b2b;
  padding: 0px 10px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  cursor: pointer;
}
.loan-rate-table-search button {
  padding: 5px 15px;
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}
/*sidebar cancel button*/
.credit-card-sidebar .cancel-class {
  position: absolute;
  top: 0;
  right: 0px;
  font-size: 17px;
  color: #6c6a6a;
  border-radius: 50%;
  z-index: 2;
  height: 22px;
  width: 22px;
  text-align: center;
  line-height: 25px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  /* padding: 4px; */
  cursor: pointer;
  margin: 2px;
}
.credit-card-sidebar .cancel-class:hover {
  background: rgba(0, 0, 0, 0.1);
}
/*.loan-range-content .input-range__label-container{*/
/*display: none;*/
/*}*/

/*Loan rate table new css*/

.loan_p_demo {
  font-size: 14px;
}

.demo_font {
  font-size: 15px;
}

.demo_border {
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  padding: 0px 10px 0px 10px;
}

.demo_btn {
  padding: 0px 10px 0px 10px !important;
}
.demo_footer {
  background-color: #edeeef;
  border-radius: 0 0 2px 2px;
  padding: 10px 10px 3px 10px;
  width: 453px;
  margin-left: -10px;
  height: 48px;
}

/*Loan Rate Table update*/
.loan-range-value {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 6px;
  padding-right: 20px;
  font-weight: 400;
  color: #555;
}

.loan-range-content .input-range__label-container {
  display: none;
}

.loan-range-content::before {
  content: '';
  position: absolute;
  left: 12px;
  top: -8px;
  border-bottom: 9px solid #ffffff;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-radius: 3px;
}
.filter-duration-dropdown .new-select2-style .css-1szy77t-control {
  border-radius: 30px;
}
.profile-setting-description .update-generale-input-group .input-group input {
  height: 52px !important;
}
.profile-setting-description
  .update-generale-input-group
  .input-group
  .input-group-text {
  height: 52px;
}
/*Web*/
.single-sucess-doc label {
  margin-bottom: 0;
  margin-left: 10px;
  position: relative;
  font-size: 16px;
}

.single-sucess-doc {
  margin-bottom: 15px;
}

.single-sucess-doc-inner input {
  height: 15px;
  width: 15px;
  opacity: 0;
  z-index: 10;
  position: absolute;
  top: 0;
  left: -15px !important;
}
.single-sucess-doc .single-sucess-upload {
  width: 64px;
  text-align: center;
}

.single-sucess-doc .single-sucess-doc-inner {
  width: calc(100% - 65px);
  position: relative;
}

.single-sucess-doc .single-sucess-doc-inner label {
  width: calc(100% - 20px);
}

.single-sucess-doc label::before {
  content: '';
  position: absolute;
  left: -26px;
  top: 1px;
  height: 15px;
  width: 15px;
  border: 1px solid #777777;
  transition: all 0.3s linear;
  color: #00b9f0;
}
.single-sucess-doc label::after {
  content: '\F00C';
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  left: -26px;
  position: absolute;
  top: 0px;
  font-size: 16px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s linear;
  color: #00b9f0;
}

.single-sucess-doc input[type='checkbox']:checked ~ label::before {
  border-color: #00b9f0;
}
.single-sucess-doc input[type='checkbox']:checked ~ label::after {
  visibility: visible;
  opacity: 1;
}
.single-sucess-upload input[type='file'] {
  display: block !important;
  cursor: pointer !important;
}
.single-sucess-upload input {
  width: 50px;
  position: absolute;
  opacity: 0;
  cursor: pointer !important;
}

.single-sucess-upload i {
  font-size: 18px;
  cursor: pointer !important;
}
.single-sucess-upload img {
  height: 35px;
  cursor: pointer;
}

.page-item.active-item {
  background: #4f8cb3;
  border-radius: 5px;
  color: #fff;
}

.page-item.active-item .page-link {
  background: #4f8cb3;
  color: #ffffff;
  border-radius: 5px;
}

.page-item.active-item .page-link span {
  color: #1c6897;
}
/*.sheduleSlider .slick-arrow.slick-prev {*/
/*    border: 1px solid #777;*/
/*}*/
/*.sheduleSlider .slick-arrow.slick-next,.sheduleSlider .slick-arrow.slick-prev  {*/
/*    border: 1px solid #777;*/
/*}*/
.sheduleSlider .slick-arrow.slick-prev {
}
.sheduleSlider .slick-arrow.slick-prev::before {
  content: '\f104' !important;
  font: normal normal normal 14px/1 FontAwesome !important;
  text-rendering: auto !important;
  -webkit-font-smoothing: antialiased !important;
  font-size: 30px !important;
}
.sheduleSlider .slick-arrow.slick-next {
}
.sheduleSlider .slick-arrow.slick-next::before {
  content: '\f105' !important;
  font: normal normal normal 14px/1 FontAwesome !important;
  text-rendering: auto !important;
  -webkit-font-smoothing: antialiased !important;
  font-size: 30px !important;
}

.time span {
  color: #403c3c;
  font-size: 15px;
  line-height: 27px;
  font-family: 'roboto';
  font-weight: 500;
}
/*Loan Rate Table*/
.modal-header {
  border-bottom: none;
}

.form-signin-conttent .modal-content {
  height: 515px;
  width: 555px;
}

.modal_h {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 36px;
  z-index: 9;
  color: #515151;
}

.modal_bg_1 {
  border-radius: 10px;
  background: #f3f8ff;
  padding: 35px 30px 30px 35px;
  box-shadow: 0px 3px 6px rgba(207, 207, 207, 0.55);
}

.form-signin-conttent .form-control {
  padding: 0.85rem 0.95rem;
}

.form-signin .modal_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.modal_but {
  height: 50px;
  width: 100%;
  display: block;
  background: #3db7f0;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
}

/*New Css*/
.apply_option {
  text-align: center;
  width: 100%;
}
.warning-message {
  background: #ffe7dc;
  border: 1px dashed #f5e6cb;
  padding: 11px 10px;
  margin: 10px 10px;
  border-bottom-right-radius: 10px;
  font-weight: 700;
  margin-top: 0;
  border-bottom-left-radius: 10px;
}
.success-alert-message {
  background: #d4edda;
  border: 1px dashed #c3e6cb;
  padding: 11px 10px;
  margin: 10px 10px;
  border-bottom-right-radius: 10px;
  font-weight: 700;
  margin-top: 0;
  align-items: center;
  border-bottom-left-radius: 10px;
}
.alert-message {
  background: #fededb;
  border: 1px dashed #f6c4c0;
  padding: 10px 10px;
  border-radius: 5px;
  align-items: center;
  font-weight: 700;
}

.aply_loan_inner {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.loan-inner-div {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.alert-message img,
.warning-message img {
  margin-right: 15px;
}

.apply_option .single_option + .single_option {
  margin-bottom: 0;
}
.apply-loan-modal .modal_left {
  width: 365px;
  float: left;
  border-right: 1px solid #ddd;
  display: flex !important;
  align-items: flex-end !important;
}
.apply_option .bcbd_inputgrup .input-group-prepend span {
  background: #48c9ff;
}
.applyModalStyle > h4 {
  background: #1e6b9c;
}
.apply_modal_inner .single_option .bcbd_inputgrup input {
  padding-left: 15px;
  font-size: 16px;
  line-height: 40px;
  border-radius: 3px;
}
.single_option .bcbd_label {
  text-align: left;
  color: #383737 !important;
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: 400;
  text-transform: capitalize;
  font-family: 'Exo 2', sans-serif !important;
}
.schedule-date-time p {
  font-weight: 600;
  font-size: 15px;
}

.successfull-tabs-inner .nav-item.nav-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 21px;
  border: 1px solid #ddd !important;
  background: #fcfcf1;
  margin-bottom: 10px;
}

.successfull-tabs-inner .nav-item.nav-link span + span {
  font-size: 36px;
}

.doc-accordion doc-ac-body {
  border: 1px solid #ddd;
}

.show.doc-accordion {
}
.successfull-tabs-inner {
  overflow: auto;
  padding: 0 15px;
}
.time span,
.date span {
  color: #504f4f;
}

/*Apply for Loan*/

.single_option .bcbd_inputgrup .input-group-prepend {
  height: 50px;
  line-height: 46px;
  color: #6e6e6e;
  padding-left: 10px;
  background: #f4f4f4;
  position: relative;
  padding-right: 10px;
  border-top-left-radius: 5px;
  font-weight: 600;
  margin-right: unset !important;
  border: 1px solid #cfcfcf;
  border-bottom-left-radius: 5px;
  border-right: 0;
}

.single_option input {
  height: 46px;
}

.single_option input:focus {
  outline: none !important;
}
.single_option .bcbd_inputgrup input {
  height: 48px !important;
}

.single_option .bcbd_inputgrup {
  border-radius: 5px;
}

.single_option .single_option .bcbd_inputgrup input:focus {
  outline: none;
}
.single_option .form-control {
  border: 1px solid #ddd !important;
}
.single_option .single_option .form-control:focus {
  outline: none !important;
}
.aply_loan_inner .input-group.bcbd_check span {
  color: #5a5a5a;
  font-family: 'Exo 2', sans-serif !important;
  text-transform: capitalize;
}

.single_option .bcbd_inputgrup .form-control {
  border: none !important;
}

.apply_modal_inner .single_option .form-control {
}

.single_option .form-control {
  border: 1px solid #ededed !important;
  height: 46px;
}
.apply_modal_inner .single_option .bcbd_inputgrup .form-control {
  border: 0;
}
.apply-big-text2 {
  text-align: center;
  color: #3e3c3c;
  font-size: 25px;
  padding-top: 25px;
}
.single_option {
  text-align: left;
  padding: 0 20px;
  margin-bottom: 10px !important;
}
.modal_left .apply_option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: column;
  height: 100%;
}

.modal_left .apply_option > div {
  width: 100%;
}
/**/
.otp-parent input {
  width: 55px;
  height: 47px !important;
  display: inline-block;
  margin-right: 10px;
  border: 1px solid #d1d1d1;
}

.otp-parent {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

#aply_loan_inner h4 {
  color: #5b5b5b;
}

.otp-parent input:focus {
  outline: none;
  box-shadow: none;
}

.single_option .css-1hwfws3 {
  height: 46px;
}
.single_option .new-select2-style .css-bg1rzq-control {
  height: 46px;
}
.single_option .new-select2-style .css-1szy77t-control {
  height: 46px;
}
.single_option .bcbd_inputgrup .input-group-prepend i {
  font-size: 18px;
  line-height: 46px;
}
.otp-parent input {
  padding: 0;
  text-align: center;
}

/*Success Info Page*/
.date {
  border-right: 1px solid #e8e8d7;
  padding-right: 30px;
  margin-right: 25px;
  margin-top: 0 !important;
}

.time p,
.date p {
  font-size: 16px;
  padding-top: 0 !important;
  line-height: 24px;
  font-family: 'Exo 2', sans-serif !important;
  margin-top: 0 !important;
}

.time span,
.date span {
  font-family: 'Exo 2', sans-serif !important;
  font-size: 16px !important;
}

.successfull-tabs-area .change-schedule-btn {
  padding: 0 35px !important;
  position: relative;
  background: #48c3ef !important;
  color: #fff;
  font-weight: 300;
  letter-spacing: 0.7px;
}

.css-11unzgr::-webkit-scrollbar {
  display: block;
}

.css-11unzgr {
  scrollbar-width: 5px;
}
.credt-card-choose-option .new-form-control {
  line-height: 37px;
}
.creditCardInput::placeholder {
  color: #555;
  font-weight: 600 !important;
  font-size: 15px !important;
}
.placeHolder .new-select2-style .css-151xaom-placeholder {
  color: #6c757d;
  font-size: 15px;
  margin-left: 5px;
  font-weight: 400;
}
.bottom-15 {
  bottom: 15px !important;
}
.f-14 {
  font-size: 14px;
}
.css-bg1rzq-control {
  overflow: hidden;
}
.filter-amount .filter-amount-digit {
  padding: 12px 7px 12px 14px !important;
  text-align: left !important;
  height: 50px !important;
}
.labelPart4 {
  background: #e4e3e3;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}
.height-500 {
  height: 500px;
}
.noEligible li {
  color: #8e0f0f !important;
}
.gridProfile {
  display: grid;
  grid-template-columns: 15% 85%;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.bdt {
  color: #444;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #dedede;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.color444 {
  color: #444 !important;
  font-weight: lighter !important;
}
.straight_top_borders {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.straight_bottom_borders {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.straight_left_borders {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.titleText {
  font-family: 'Exo 2', sans-serif;
  color: #5f5f5f;
}
.divCenter {
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.w-140 {
  width: 140px !important;
}
.h-auto {
  height: auto !important;
}
.css-1hwfws3 {
  position: relative;
}
.css-1g6gooi input {
  position: absolute;
  top: 5%;
}
.inquiriesSelect .css-bg1rzq-control {
  height: 45px !important;
  border: 1px solid #ddd;
  border-radius: 2px !important;
}
.inquiriesSelect .css-1szy77t-control {
  height: 45px !important;
  border: 1px solid #ddd;
  box-shadow: none !important;
  border-radius: 2px !important;
}
.inquiriesSelect .css-1szy77t-control:hover {
  border-color: #45c4fb;
}
.inquiriesSelect .css-bg1rzq-control:hover {
  border-color: #45c4fb;
}
.mobileEligibility .eligibility-body {
  height: auto !important;
}
.gridSuccess {
  display: grid !important;
  grid-template-columns: 95% 5%;
  -ms-flex-pack: center;
  height: 60px;
  font-size: 16px;
  -ms-flex-align: center;
  align-items: center;
}
.bottom-auto {
  bottom: auto !important;
}
.bottom-0 {
  bottom: 0 !important;
}
.bg-Eligibility {
  background: #effaff;
}
.file-document:before {
  content: '\F1C6';
  color: gray;
}
.single-loan-area-creditCard {
  background-color: #fdf2e4 !important;
}
.single-loan-area-deposit {
  background-color: #edf1ea !important;
}
.bg-credit-card {
  background: #f8e0c2 !important;
}
.bg-loan {
  background: #c7edf6 !important;
}
.font-weight-600 {
  font-weight: 600;
}
.height-100vh {
  height: 100vh !important;
}
.deposit-bg {
  background: #cfe3c6 !important;
}
.diposit-slider {
  background: #70a45b !important;
}
.fixed-deposit-thumb::-webkit-slider-thumb {
  background: #577e48 !important;
}
.deposit_info_img1 {
  width: 100px !important;
  height: auto !important;
}

.bannerLoanSlider .slick-prev:before,
.bannerLoanSlider .slick-next:before {
  font-size: 17px !important;
  opacity: 1 !important;
  color: #459bb1 !important;
}
.bannerLoanSlider .slick-next {
  right: -13px;
}
.bannerLoanSlider .slick-prev {
  left: -15px;
  background: transparent;
  z-index: 9;
}
@media only screen and (max-width: 480px) {
  select option {
    width: 40px !important;
    color: #434343;
  }
}
.topFixed {
  position: fixed;
  z-index: 15;
  width: 100%;
  transition: 0.5s;
  top: 0;
  animation: moveTop 0.5s;
}
@keyframes moveTop {
  0% {
    top: 14px;
  }
  100% {
    top: 0;
  }
}
.frontCard {
  position: relative;
  display: grid;
  grid-template-rows: 40% 60%;
}
.single-loan-area-mobile svg {
  height: 100% !important;
}
.banner-home {
  display: grid;
  grid-template-rows: 30% 70%;
}
.mobile-logo svg {
  width: 157px;
}
.new-xmlclass .color1 {
  stop-color: #50c6f1;
}
.new-xmlclass .color2 {
  stop-color: #3d8ecc;
}
.new-xmlclass .color3 {
  stop-color: #336fb7;
}
.bannerDepositSlider .slick-prev:before,
.bannerDepositSlider .slick-next:before {
  color: #80ae6e !important;
}

.mobileCustomgutter {
  height: initial !important;
}
.new-select2-style input {
  background: transparent !important;
}
.dps-grid {
  display: grid;
  grid-template-columns: 40% 60%;
  align-items: center;
}
.bg-566674 {
  background: #566674;
}
.loan-choice-button {
  color: #444 !important;
  padding: 5px 7px;
  min-width: 130px;
  text-align: left;
  border-radius: 4px;
  background-color: #748798;
  border: 0px;
  line-height: 15px;
}
.dps select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  -o-text-overflow: '';
  text-overflow: '';
}
.no-info-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.deposit_info_img1 {
  margin-top: 8px !important;
}
.eligibility-body.elig-submit {
  height: 370px !important;
}
.new-select2-style input {
  margin-top: 7px !important;
}

.fixed-deposit-grid {
  display: grid;
  grid-template-columns: auto auto;
}
.sliderFilter {
  position: fixed;
  width: 100%;
  transition: 0.5s;
  top: 57px;
  z-index: 15;
}
.creditcrdbg {
  background-color: #f1f3f5;
}
.loan-filter-area {
  height: calc(100vh - 112px);
}
.bg-23c3ec {
  background: #23c3ec;
}
.loan-filter-button {
  height: 56px;
  font-size: 16px;
  line-height: 1.56;
  color: #ffffff;
  border: 0px;
}
.bg-df7c48 {
  background: #df7c48;
}
.loanSliderDeposit select {
  background-image: none !important;
  height: 45px;
}
.filter-grid {
  display: grid;
  grid-template-columns: 80% 12% 8%;
  justify-content: center;
  align-items: center;
}
.slick-slider.banner .slick-slide {
  padding: 0px 0px !important;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.top-filter-grid {
  display: grid;
  grid-template-columns: 31% 35% 32%;
}
.top-slider {
  position: fixed;
  transition: 0.5s;
  z-index: 10;
}
.person-position {
  position: absolute;
  width: 100%;
  left: -14%;
}
.landing-pattern {
  position: absolute;
  width: 100%;
  opacity: 0.1;
  top: -11px;
}
.py-8px {
  padding: 8px 0px;
}
.loan-choice-button {
  height: 39px !important;
}
.custom-height-50-loanSlider {
  background: #44505b !important;
  height: 50px !important;
  color: #ffffff !important;
  font-size: 16px;
}
.loan-footer {
  padding: 0 15px;
  margin-top: 27px;
}
.w-95 {
  width: 95%;
}
.w-75 {
  width: 75% !important;
}
.w-98 {
  width: 98% !important;
}
.range-activate {
  border: 2px solid #f4ca1f !important;
  color: #f4ca1f !important;
  background: #566674 !important;
  border-radius: 8px !important;
}
.w-45px {
  width: 45px;
}
.w-45 {
  width: 45%;
}
.w-345px {
  width: 345px !important;
}
.web-svg-area {
  width: 60px;
  height: 50px;
  margin-right: 15px;
}
.web-svg-area svg {
  height: 100%;
}
.exo-font {
  font-family: 'Exo 2', sans-serif !important;
}
.check-mobile-size {
  height: 20px !important;
  width: 20px !important;
}
.h-50px {
  height: 50px !important;
}
.h-5vmax {
  height: 5vmax;
}
.h-3vmax {
  height: 3vmax;
}
.h-80px {
  height: 80px;
}
.mobile-padding-gender {
  padding: 13px 7px 13px 10px !important;
}
.check-mobile-size::before {
  left: 2.5px !important;
  top: 2.5px !important;
}
.e-label-p {
  padding-left: 5px;
  padding-bottom: 5px;
}
.border {
  border: 1px solid #9a9a9a;
}
.roboto-font {
  font-family: 'Roboto', sans-serif;
}
.gridSuccessMessage-mobile {
  display: grid;
  grid-template-rows: 70% 30%;
  justify-items: center;
  align-items: center;
}
.mobile-check label {
  height: 22px !important;
  width: 22px !important;
}
.mobile-check label::before {
  font-size: 20px !important;
  top: -2px !important;
  color: #48c9ff !important;
}
.display-grid-mobile-submit {
  display: grid;
  margin-top: 75px;
  position: relative;
  -ms-flex-align: center;
  align-items: center;
  justify-items: start;
  grid-template-columns: 27% 73%;
}
.gridView-mobile {
  display: grid;
  grid-template-columns: 15% 85%;
}
.gridView-mobile .preTag {
  background: #f0f0f0;
  font-size: 16px;
  font-weight: 500;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mymatches-tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-radius: unset !important;
  color: #ffffff !important;
  padding: 12px !important;
  background: #1e6b9c !important;
  font-size: 15px;
  font-weight: 500 !important;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  border-bottom: 2px solid #00add9 !important;
}
.required-top-bg {
  background: #edeff1;
}
.mymatches-tabs .nav-item {
  font-size: 15px !important;
  letter-spacing: 1.25px;
}
.nav-item .nav-link {
  padding: 12px !important;
}
.transparent-button {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
.loan-grid-top {
  margin-top: 10px;
  display: grid !important;
  grid-template-rows: 60% 40%;
}

.no-padding .nav-link {
  padding: 0 !important;
}
.user-name span {
  color: #8e8e8e;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.15px;
}
.application-grid {
  display: grid;
  grid-template-columns: 90% 10%;
  padding: 10px;
}
.application-grid {
  display: grid;
  grid-template-columns: 70% 15% 15%;
  padding: 10px;
}
.application-button {
  border: 1px solid gainsboro;
  padding: 12px 26px;
  border-radius: 55px;
  color: #616161 !important;
}
.application-button-grid {
  display: grid;
  grid-template-columns: 57% 45%;
  align-items: center;
}
.application-height .active {
  height: 55px !important;
}
.menu-area-content h4 {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  text-align: left;
  color: #6d6d6d;
  padding: 15px 0;
}
.center-all {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.icon-check-contact::before {
  content: '\E926';
  color: #f2815e;
}
.contact-us-button {
  width: 100%;
  height: 60px;
}
.service-contact {
  margin-bottom: 15px;
  border-radius: 5px;
}
.term-side-border-mobile {
  border-left: 1px solid #48c9ff;
  border-right: 1px solid #48c9ff;
}
.notification-hover {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0px;
  color: #03a9f4;
  text-shadow: 0 1px 5px #2196f3;
  padding-right: 5px;
  font-size: 12px;
}
.creditcardp1 {
  text-transform: capitalize;
  font-size: 16px;
  color: #1e6b9c;
  margin-bottom: 10px;
}
.creditcard_info_btn2 {
  height: 40px;
  background: white;
  color: #1e6b9c;
  font-size: 12px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 1.07px;
  border: 1px solid #1e6b9c;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  text-transform: uppercase;
  -webkit-transition: all 1.2s ease-in-out;
  -o-transition: all 1.2s ease-in-out;
  transition: all 1.2s ease-in-out;
  z-index: 1;
}
.creditcard_info_btn1 {
  height: 40px;
  background-color: #1e6b9c;
  color: #ffffff;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}
.css-14ojxsb-control {
  background: white !important;
  border-color: white !important;
}
.select-input .css-1hwfws3 .css-1g6gooi input {
  height: auto !important;
}
.successMsg {
  position: relative;
  width: 100%;
  padding: 10px 15px;
  background: #bcf3c6;
  border-radius: 5px;
  font-size: 15px;
  margin-bottom: 10px;
  color: #494949;
}
.successMsg.close {
  position: absolute;
  right: 0;
  font-size: 14px;
  font-weight: lighter;
}
.custom-scroll::-webkit-scrollbar {
  display: block;
  width: 5px;
}
.calculated-scroll {
  width: calc(100% + 165px);
  overflow-x: scroll;
}
.calculated-scroll-2::-webkit-scrollbar {
  display: block !important;
  width: 6px;
  height: 3px;
  background: #e0e0e0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.calculated-scroll-2::-webkit-scrollbar-thumb {
  background: #999999;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.apply-modal-overflow-height::-webkit-scrollbar {
  display: block !important;
  width: 10px;
  height: 3px;
  background: #e0e0e0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.apply-modal-overflow-height::-webkit-scrollbar-thumb {
  background: #999999;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.modal-footer {
  flex-direction: column;
  text-align: center;
  padding: 0 30px !important;
  border-top: 0px;
  margin: 15px !important;
  border-radius: 5px;
  background-color: #f3f8ff;
}
.modal-list {
  height: 90px;
  overflow: auto;
}
.cross-close {
  font-size: 20px !important;
  padding: 10px !important;
}
.login-msg {
  height: 60%;
  width: 90%;
  margin: 15% auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #87d6ea;
  border-radius: 5px;
  font-size: 30px;
  color: white;
}
.list-style-none {
  list-style: none !important;
}
.top-flat {
  margin-top: -2px !important;
  margin-bottom: 10px !important;
}
.greetings {
  position: absolute;
  width: 60%;
  height: 54%;
}
.apply-now {
  height: 100%;
  position: relative;
}
.bottom-absolute {
  position: absolute;
  bottom: 0;
}
.border-radius-bottom-right-8px {
  border-bottom-right-radius: 8px !important;
}
.modal_right {
  float: left;
  width: 100% !important;
}
.css-1thkkgx-indicatorContainer,
.css-16pqwjk-indicatorContainer {
  padding: 0 !important;
}
.close-cross-position-right-top {
  position: absolute;
  color: #464646 !important;
  z-index: 45;
  top: 5px;
  right: 8px;
  font-size: 16px;
}
.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 100%;
  justify-content: center;
  align-items: center;
}
.grid-20-80 {
  display: grid;
  grid-template-columns: 20% 80%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.grid-25-80 {
  display: grid;
  grid-template-columns: 25% 80%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.border-top-left-radius {
  border-top-left-radius: 5px;
}
.border-bottom-left-radius {
  border-bottom-left-radius: 5px;
}
.border-top-right-radius {
  border-top-right-radius: 5px;
}
.border-bottom-right-radius {
  border-bottom-right-radius: 5px;
}
.ron {
  background: #fdf2f2;
  padding: 0 15px;
  transition: 0.1s;
}
.warning {
  background: #fff3cd;
  padding: 0 15px;
  transition: 0.1s;
}
.ron:hover {
  background: #fbd5d5;
}
.warning:hover {
  background: #fbecbe;
}
.ron_body {
  height: 86%;
  background: #fdf2f2;
}
.warning_body {
  height: 86%;
  background: #fff3cd;
}
.no-arrow select {
  background-image: none !important;
}
.col-lg-3 .input-group > .input-group-prepend > .btn {
  border-top-left-radius: 25px !important;
  border-bottom-left-radius: 25px !important;
}
.sortOption .new-select2-style .css-bg1rzq-control,
.sortOption .new-select2-style .css-1szy77t-control,
.sortOption > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 33px !important;
  border-bottom-right-radius: 25px !important;
}
.select-round {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh !important;
  background-color: rgba(0, 0, 0, 0.65);
}
.fixed-top-right {
  position: absolute;
  right: 8px;
  top: 5px;
}
.support-modal {
  animation: animateSupport 0.5s;
}
.loan-choice-button {
  color: #444;
  padding: 5px 7px;
  height: 39px;
  min-width: 130px;
  text-align: left;
  border-radius: 4px;
  background-color: #748798;
  border: 0px;
  line-height: 15px;
}
.top-right {
  position: absolute;
  top: 5px;
  right: 8px;
  font-size: 18px;
  color: white;
}
.input_grid {
  background: white;
  display: grid;
  grid-template-columns: 7% 88%;
  -ms-flex-pack: center;
  justify-content: center;
  color: #7b7b7b;
  border-radius: 5px;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
  height: 48px !important;
  border: 1px #afafaf solid;
}
.warning-msg {
  text-align: left;
  position: relative;
  top: -10px;
  color: #ff4444;
}
.no-select-background select {
  background-image: none !important;
}
.form-control {
  height: 50px;
}

.eligibility-body .form-control {
  height: 50px;
}

.loan-choice-button {
  line-height: 30px !important;
}
.top-section {
  margin: 4px;
  padding: 20px 0px;
  background: #1e6b9c;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.change-schedule-title {
  color: #fff;
  padding: 0px 20px 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 25px;
  text-align: center;
  font-weight: 500;
}
.top-filter-apply {
  height: 45px;
  display: grid;
  grid-template-columns: 47% 47%;
  justify-content: space-around;
}
.loan-amout-grid {
  padding: 2px;
  display: grid;
  background: white;
  text-align: center;
  border-radius: 5px;
  align-items: center;
  grid-template-columns: 15% 85%;
}
.loan-amout-grid p {
  height: 100%;
  display: flex;
  color: #1b5f8d;
  font-weight: 600;
  background: #d9e5ee;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.loan-amout-grid .css-bg1rzq-control {
  border: none !important;
}
.loan-amout-grid .css-1szy77t-control:hover {
  border: none !important;
}
.loan-amout-grid .css-1szy77t-control {
  border: none !important;
  box-shadow: none !important;
}
.loan-amout-grid .css-bgvzuu-indicatorSeparator {
  background-color: transparent !important;
}
.loan-amout-grid.duration-grid {
  grid-template-columns: 20% 78%;
}
.bg-48c9ff {
  background: #48c9ff !important;
}
.center-all-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.aply_output_container_all button {
  width: 24%;
  margin-top: 2%;
  border-radius: 50px;
}
.select-style {
  height: 100%;
  border: none;
  margin-left: 12px;
}
.deposit_info {
  margin: 5px;
  border-radius: 5px;
  padding-bottom: 15px;
  background-color: #ffffff;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
}
.deposit_btn {
  height: 40px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background-color: #1e6b9c;
}
.show-more-deposit {
  height: 50px;
  display: grid;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);
}
.choose-tener-modal .mobile-tab-menu li {
  font-size: 12px;
  width: inherit !important;
  font-weight: 500;
  line-height: 24px;
  padding: 1px 7px !important;
}
.mobileViewModal .mobile-tab-menu {
  background: #1e6b9c;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-bottom: 15px;
}
.react-tabs__tab--selected {
  background: #fff !important;
  color: #1984d4 !important;
}
#prematureClosure .single-inner-tab {
  padding-top: 15px !important;
}
#prematureClosure .tab-botom-box {
  margin-top: 15px !important;
}
.lh-15px {
  line-height: 15px !important;
}
.mobileViewModal .styles_closeButton__20ID4 {
  cursor: pointer;
  position: absolute !important;
  right: -15px !important;
  top: -18px !important;
  border: 1px solid #ddd !important;
  border-radius: 50%;
  width: 30px;
  padding: 5px;
  background: rgba(255, 255, 255, 0.3);
  height: 30px;
}
.mobileViewModal .styles_closeButton__20ID4 svg {
  height: 18px;
  width: 18px;
}
.bg-table1 {
  color: white;
  background: #488fc0;
}
.bg-disabled {
  background: #efefef;
}
.bg-table2 {
  color: #434343;
}
.table2 {
  background: #b3e8e5;
  padding: 2px;
  margin-bottom: -2px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.bg-table2 .text {
  background: white;
  margin: 0;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding: 0;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bg-table2 thead,
.bg-table2 tbody tr th,
.bg-table2 thead tr th,
.bg-table2 tbody tr td {
  color: #434343 !important;
}
.bg-table2 tbody {
  color: #434343 !important;
  background: #d3faf7;
}
.page_wrapper .container {
  padding: 2px;
}
.white-border-bottom {
  border-bottom: 1px solid white;
}
.bg-bbedec {
  background: #bbedec !important;
}
.bg-93deda {
  background: #93deda !important;
}
.bg-d3faf7 {
  background: #d3faf7 !important;
}
.login-grid {
  display: grid !important;
  grid-template-columns: 15% 85%;
}
.side-img {
  width: 45px;
  height: 45px;
}
.grid-row-2 {
  display: grid;
  grid-template-rows: 20% 80%;
}
.apply-modal-overflow-height {
  height: 360px;
}
.regSucc {
  position: relative;
  color: #353535;
  background: #87d6ea;
  padding: 10px 15px;
  font-size: 15px;
  border-radius: 5px;
  font-weight: bold;
  width: 80%;
  margin: 15px auto 0;
}
.popClose {
  top: 32%;
  right: 10px;
  position: absolute;
}
.log-circle svg {
  width: 26px !important;
  height: 40px;
  fill: white;
  margin-left: 3px;
}
.log-circle svg path {
  fill: white !important;
}
.log-circle .st0 {
  fill: #ffffff;
}
#master {
  background: #ffffff;
}
.check-icon-mobile::before,
.check-icon-mobile::after,
.check-icon-mobile .icon-fix {
  background: transparent !important;
}
.mobile-options {
  padding: 10px;
  background: #1e6b9c;
}
.apply-modal-overflow-height-mobile {
  height: 440px;
  overflow: auto;
}
.mobile-apply-now {
  width: 81%;
  font-size: 16px;
  color: white;
  height: 50px;
  border-radius: 5px;
  bottom: 10px;
  margin-bottom: 10px;
  background: #23bff2;
}
.styles_closeButton__20ID4 {
  cursor: pointer;
  position: absolute !important;
  right: -15px !important;
  top: -15px !important;
  border: 1px solid #ddd !important;
  border-radius: 69%;
  width: 30px;
  padding: 2px;
  background: rgba(219, 230, 236, 0.74);
}
.bg-success {
  background: #e2ffe2 !important;
}
.warning-message ul {
  line-height: 50px;
}
.line-height20px {
  line-height: 20px !important;
}
.line-height30px {
  line-height: 30px !important;
}
.close-btn {
  position: absolute;
  top: -5px;
  right: -5px;
  z-index: 5;
}
.grid-30-70 {
  display: grid;
  grid-template-columns: 30% 70%;
  height: 100%;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}
.input-group-prepend {
  margin-right: 0 !important;
}
.help-desk svg {
  width: 200px;
  height: 200px;
  margin-bottom: 25px;
}
.choose-aamar-section {
  padding: 3px 0px 13px 0px;
  background-color: #44505b;
}
.single-choose .media-body h6 {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.choose-aamar-section h2 {
  color: #ffffff;
  padding: 20px 0px 8px 0px;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}
.single-choose .media-body p {
  color: #cecece;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.single-choose svg {
  padding-top: 5px;
  height: auto;
}
.bg-fffdf9 {
  background: #fffdf9;
}
.top-right-black {
  color: white;
  background: transparent;
}
.mobile-home-container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.calc-height {
  height: calc(100% - 100px) !important;
}
.mobile-applynow-modal {
  width: 100%;
  background: white;
}
.mobile-top-grid {
  display: grid;
  grid-template-columns: 10% 90%;
  color: white;
  padding-top: 20px;
  width: 100%;
  justify-items: center;
  -ms-flex-align: center;
  background: #36353b;
  align-items: center;
}
.mobile-top-grid h4 {
  margin-bottom: 0px !important;
}
.mobile-footer ul,
.mobile-footer li {
  list-style-type: disc;
  line-height: 30px;
}
.top-input {
  background: #f0f0f0;
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 15;
  left: 0;
  top: 0;
}
.grid-10-90 {
  display: grid;
  grid-template-columns: 10% 90%;
}
.grid-10-75-15 {
  color: white;
  height: 50px;
  display: grid;
  text-align: center;
  align-items: center;
  background: #1b5f8d;
  -ms-flex-pack: center;
  -ms-flex-align: center;
  justify-content: center;
  grid-template-columns: 10% 75% 15%;
}
.border-none {
  border: none !important;
}
.input-cancle {
  background: #efefef;
  color: #333;
  border-radius: 2px;
  margin: 0 6px;
  text-align: center;
}
.company-name-list {
  background: white;
  padding: 10px 15px;
  margin: 1px 0;
}
.grid-10-75-15 input::placeholder {
  color: #ddd;
}
.mobileShare input::placeholder {
  font-size: 15px;
}
.dob-personal-details {
  width: 99%;
  margin-left: 2px;
}
.scroll-none::-webkit-scrollbar {
  display: none;
}
.grid-5-95 {
  display: grid;
  grid-template-columns: 5% 95%;
}
.grid-62-16-16 {
  grid-template-columns: 62% 16% 16% !important;
}
.bg-e6f7fe {
  background: #e6f7fe;
}
.border-round-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.border-round-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.text-3c3b3b {
  color: #3c3b3b;
}
.features-art::before {
  content: '';
  position: absolute;
  width: 25px;
  height: 7px;
  background: #1e6b9c;
  border-radius: 5px;
  left: 2px;
  top: 8px;
}
.error-msg {
  width: 93%;
  padding: 5px;
  color: white;
  position: relative;
  background: #ce5858;
  border-radius: 3px;
}
.error-msg::before {
  width: 15px;
  content: '';
  height: 15px;
  position: absolute;
  z-index: -1;
  top: -1px;
  background: #ce5858;
  transform: rotate(45deg);
  position: absolute;
}
.schedule-container {
  width: 710px;
}
.schedule-grid {
  display: flex !important;
  align-items: center;
}
.schedule-loan .date-pagination .page-link {
  font-size: 25px !important;
}
.schedule-loan .date-pagination .page-link span {
  font-size: 12px !important;
}
.schedule-loan .sheduleSlider .slick-arrow.slick-next::before,
.schedule-loan .sheduleSlider .slick-arrow.slick-prev::before {
  font-size: 33px !important;
}
.schedule-loan .sheduleSlider .slick-arrow.slick-next,
.schedule-loan .sheduleSlider .slick-arrow.slick-prev {
  margin-top: 0 !important;
}
.schedule-grid set-time h5 {
  font-size: 18px;
  line-height: 45px;
}
.schedule-grid button {
  color: transparent !important;
  justify-self: center;
}
.gridViewIncome {
  height: 52px !important;
  display: grid;
  grid-template-columns: 85% 15%;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 99.5%;
}
.labelPart {
  color: #434343;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e4e3e3;
  font-size: 13px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 100%;
}
.long-area {
  height: 350px;
  margin-top: 1%;
}
.applyLoanModal {
  height: 100vh;
}
.sliderCompare {
  position: fixed;
  top: 0;
  bottom: 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  z-index: 30;
  background: #44505b;
  color: white;
}
.mobile-credit-card-slide .card {
  background-color: #44505b;
}
.bg-f4f7f8 {
  background-color: #f4f7f8;
}
.slider-submit {
  overflow: hidden;
  margin: auto;
  display: grid;
  justify-content: center;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.set-calling-time {
  width: 335px;
  margin: auto;
}
.dob-personal-details2 {
  width: 98%;
  margin-left: 10px;
}
.grid-row-3 {
  display: grid;
  grid-template-rows: 40% 60%;
}
.date-pagination .page-link-mobile {
  border: 1px solid #3b98d3;
  color: #1c6897;
  font-size: 22px;
  background: transparent;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0px 4px;
  line-height: 28px;
  border-radius: 6px;
  height: 95px;
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.8s ease;
  -o-transition: all 0.8s ease;
  transition: all 0.8s ease;
}
.active-item-mobile .page-link-mobile {
  background: #3b98d3;
  color: white !important;
}
.apply-bottom-mobile {
  position: absolute;
  width: 100%;
  bottom: -3px;
}
.gridViewIncome input {
  height: 100% !important;
}
.set-schedule-height {
  line-height: 25px;
  margin-bottom: 6px;
}
.gridViewIncome2 {
  display: grid;
  grid-template-columns: 85% 15%;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  height: 50px !important;
  width: 100%;
}
.gridViewIncome2 input {
  height: 100% !important;
}
/*Styling for word to text*/
#words {
  color: #1e6b9c;
  font-family: 'Exo 2' !important;
  font-size: 15px;
  height: 100%;
  font-weight: 400;
}

.warningToolTip {
  color: white;
  z-index: 1;
  margin-top: 5px;
  padding: 2px 10px;
  position: relative;
  border-radius: 2px;
  background: #dd3b4b;
}
.warningToolTip::before {
  content: '';
  top: -2px;
  z-index: -1;
  border-radius: 2px;
  width: 20px;
  height: 20px;
  background: #dd3b4b;
  position: absolute;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.wrongText {
  background: #dd3b4b;
  color: white;
  padding: 2px 5px;
  border-radius: 4px;
}
.grid-90-10 {
  display: grid;
  grid-template-columns: 90% 10%;
  align-items: center;
}
.bg-e5e5e5 {
  background: #e5e5e5;
}
.backHeight {
  content: '';
  position: absolute;
  top: 15px;
  left: 36px;
  width: 2px;
  background: #d9d9d9;
  z-index: 1;
}
.new-application-area {
  padding-top: 15px;
  margin-bottom: -15px;
  position: relative;
}
.date-pagination .page-link {
  border: 0px;
  color: #1c6897;
  font-size: 20px;
  background: #eff4f6;
  letter-spacing: 1px;
  line-height: 23px;
  border-radius: 6px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.8s ease;
}
.applynow-date-mobile {
  margin: auto;
  overflow: hidden;
}
.applynow-date-mobile .sheduleSlider .slick-arrow.slick-next {
  right: -15px;
}
.applynow-date-mobile .sheduleSlider .slick-arrow {
  background: transparent !important;
}
.applynow-date-mobile .sheduleSlider .slick-arrow.slick-prev {
  left: -18px;
}
.applynow-date-mobile .slick-list {
  width: 315px;
}
.applynow-date-mobile .slick-track {
  margin: 0px 0px 0px 77px;
}
.mobile-backHeight {
  content: '';
  position: absolute;
  top: 35px;
  left: 35px;
  width: 1px;
  background: #d9d9d9;
  z-index: 1;
}
.eligibility-component-title {
  padding-bottom: 10px;
  font-size: 16px;
  color: #1b5f8d;
}
.backButton {
  transition: 0.5s;
}
.backButton:hover {
  color: #3897d6 !important;
}

.separateBg {
  background-color: #e7f2f7 !important;
  padding: 7px;
  margin-bottom: 5px;
  border-radius: 0.25rem !important;
}
.changeFont .nav-item a {
  font-size: 14px !important;
}

.borderrightFix {
  border-right: 1px solid #effaff;
  padding-right: 10px;
}

/*==========================================*/
/*IFRAME MAPS */
/*=========================================*/
.gmapContainer {
  width: 100%;
}
.mapouter {
  position: relative;
  text-align: right;
  height: 200px;
  width: 100%;
}
#gmap_canvas {
  width: 100%;
  height: 200px;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 200px;
  width: 100%;
}
.contactBg {
  background-color: #e7f2f7 !important;
}
.contact-header {
  color: #595959;
}
.Notify {
  position: relative;
}
.Notify.bell-btn {
  color: #eee;
}
.Notify.bell-btn.active {
  color: #03a9f4 !important;
}

.drp-menu-bg {
  position: fixed;
  display: none;
  top: 56px;
  right: 0px;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  z-index: 10;
  transition: all 300ms ease-in-out;
}
.drp-menu-bg.active {
  opacity: 1;
  display: block;
}

.Dropdown-Menu {
  opacity: 0;
  z-index: 15;
  margin: auto;
  right: 0;
  padding: 0;
  top: -150px;
  width: 90%;
  position: absolute;
  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.8);
  z-index: 15;
  border-radius: 5px !important;
  background: #eee;
  padding-bottom: 10px;
  height: 500px;
  max-height: 45vmax;
  /*transition: all 500ms ease-in-out;*/
}
.Dropdown-Menu.active {
  top: 58px;
  right: 2px;
  animation: animate-notification 0.4s;
  opacity: 1;
}
.Dropdown-Menu-header {
  position: relative;
  background: #43b7e9;
  color: #fff;
  padding: 10px;
  text-align: left;
  border-radius: 5px 5px 0px 0px !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#x {
  position: relative;
  float: right;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 50%;
  color: white;
  top: -25px;
  right: -12px;
  width: 25px;
  height: 25px;
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}
.Dropdown-Menu-header h3 {
  font-weight: lighter;
  font-size: 18px;
}
.Dropdown-Menu-header p {
}
.drp-item {
  padding: 12px 10px;
  background: #eee;
}
.drp-item h4 {
  font-size: 13px;
}
.card-title {
  color: #53586d !important;
  height: 55px;
  background-color: #156ca4;
}
.error-msg {
  margin: 5px 0;
  padding: 5px 15px;
  background: #802121;
  width: 100%;
  border-radius: 5px;
  display: grid;
  color: white;
  grid-template-columns: 99% 2%;
  align-items: center;
}
.error-msg-without-tooltip {
  margin: 5px 0;
  padding: 5px 15px;
  background: #802121;
  width: 100%;
  border-radius: 5px;
  display: grid;
  color: white;
  grid-template-columns: 99% 2%;
  align-items: center;
}
.success-msg {
  margin: 5px 0;
  padding: 5px 15px;
  background: #128021;
  width: 100%;
  border-radius: 5px;
  display: grid;
  color: white;
  grid-template-columns: 99% 2%;
  align-items: center;
}
@keyframes animate-notification {
  from {
    top: 48px;
    opacity: 0;
  }
  to {
    top: 58px;
    opacity: 1;
  }
}
.changeSvg.active svg {
  display: none;
}
.eligibility-warning-body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  width: 55%;
  margin: 20px auto;
}
.eligibility-warning-header {
  display: grid;
  width: 200px;
  grid-template-columns: 30% 70%;
  align-items: center;
  margin-bottom: 15px;
  align-self: center;
}
.eligibility-warning-header .header {
  font-size: 22px;
  color: #e17e32;
  font-weight: bold;
}
.eligibility-warning-body li {
  list-style-type: disc !important;
}
.eligibility-warning-body button {
  background: #03bdf4;
  color: white;
  padding: 5px 20px;
  border-radius: 55px;
  font-size: 16px;
  margin-top: 25px;
}
.eligibility-modal-top {
  display: grid;
  grid-template-columns: 20% 80%;
  align-items: center;
  border-bottom: 1px solid gainsboro;
  height: 90px;
  overflow: hidden;
}
.eligibility-modal-top p {
  margin-left: 10px;
  font-weight: 600 !important;
}

.justify-self-end {
  justify-self: end;
}
.eligibility-modal-top p {
  font-size: 20px;
  font-weight: 500;
  color: #555;
}
.customModalHeight {
  width: 700px !important;
}
.text-d28871 {
  color: #a4492d;
}
.schedule-container .date-pagination .page-link {
  border: 0px;
  color: #1c6897;
  font-size: 30px;
  background: #ffffff;
  letter-spacing: 1px;
  line-height: 28px;
  border: 1px solid #1c6897;
  border-radius: 6px;
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.8s ease;
  -o-transition: all 0.8s ease;
  transition: all 0.8s ease;
}

.page-link-mobile.page-item.active-item-mobile {
  color: #fff !important;
  background: #4f8cb3;
}

.page-link.page-item.active-item {
  color: #fff !important;
  background: #3b98d3;
}

.page-link-mobile.page-item.active-item-mobile span {
  color: #fff !important;
  background: #4f8cb3;
}

.page-link.page-item.active-item span {
  color: #fff !important;
  background: transparent;
}

.page-link.page-item {
  font-size: 25px;
}

.titleText {
  font-weight: 600 !important;
}
.titleText span {
  font-weight: 600 !important;
}

.slider.no {
  border-right: none;
  width: 100%;
}
.slider.yes {
  width: 100%;
}
.border-raidus-8 {
  border-radius: 8.25rem !important;
}
.border-raidus-8 .css-bg1rzq-control,
.border-raidus-8 .css-1szy77t-control {
  background: transparent !important;
}

.bg-eff4f6 {
  background: #eff4f6 !important;
}

.login-grid-loan {
  display: grid;
  grid-template-columns: 22% 78%;
}
.text-d8775a {
  color: #d8775a;
}
.hello-msg-eligibility {
  font-size: 22px;
  font-weight: 500;
  color: #555;
}
.progress-msg-eligibility {
  font-weight: 500;
  margin-top: 8px;
}
.active-applicaiton {
  width: 100%;
  margin: auto;
  background: #f7f0d8;
  border: 1px dashed #ccc4a9;
  border-radius: 5px;
  padding: 10px;
  text-align: left;
}
.cancle-button-active-application {
  background: #d8775a;
  color: white;
  margin-top: 5px;
  border-radius: 5px;
  padding: 4px 10px;
}
.cancle-button-active-application-mobile {
  background: #ffffff;
  color: #f44336;
  font-size: 13px;
  margin-top: 5px;
  border-radius: 24px;
  padding: 0px 14px;
  height: 39px;
  box-shadow: 0 0 5px #ffcfc0;
  width: auto;
}
.cancle-button-active-application:hover {
  background: #fff;
  color: #d8775a;
}
.text-6a6a6a {
  color: #6a6a6a;
  font-weight: 600;
}
.footer-active-app {
  position: relative;
  left: -10px;
  width: 103%;
  bottom: -11px;
  background: #d8775a;
  color: white;
  display: grid;
  grid-template-columns: 65% 16% 16%;
  padding: 5px;
  -ms-flex-align: center;
  align-items: center;
}
.footer-active-app-other {
  width: 107.5%;
  padding: 5px;
  bottom: -11px;
  background: #d8775a;
  color: white;
  display: grid;
  grid-template-columns: 65% 16% 16%;
  -ms-flex-align: center;
  align-items: center;
}
.common-active-app-btn {
  background: #f7ad96;
  margin: 0 3px;
  border-radius: 5px;
  color: #434343;
  padding: 4px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.common-active-app-btn:hover {
  background: #f6cfc3;
}
.return-doc:hover {
  background: #a0b3de !important;
}
.active-application-submit {
  background: #03a9f4;
  color: white;
  font-size: 17px;
  padding: 7px 30px;
  border-radius: 25px;
  margin-top: 10px;
}
.succed-msg {
  color: #5d5d5d;
  font-size: 20px;
  margin: 19px 0;
}
.success-button-final {
  background: #43a873;
  color: white;
  border-radius: 55px;
  font-size: 19px;
  margin-top: 25px;
  width: 11vmax;
  height: 55px;
  margin-bottom: 20px;
}
.modal-bg {
  content: '';
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background: #000000a3;
  z-index: 5000;
}
.mainBody {
  position: relative;
  background: white;
  z-index: 6000;
  width: 60%;
  height: 100%;
  margin: auto;
  border-radius: 10px;
}
.mainBody-mobile {
  position: relative;
  background: white;
  z-index: 6000;
  width: 90%;
  margin: 35% auto;
  border-radius: 10px;
}
.top-successinfo {
  border-bottom: 1px solid gainsboro;
  margin-bottom: 10px;
}
.congrats-success {
  text-transform: uppercase;
  font-weight: 500;
  color: #08bbf1;
}

.close-btn-success {
  position: absolute;
  right: -10px;
  top: -10px;
  background: #dedede;
  width: 25px;
  border: 2px solid #555555;
  height: 25px;
  text-align: center;
  border-radius: 25px;
  display: flex;
  color: #363636;
  justify-content: center;
  align-items: center;
}
.offer-logo div {
  width: 20px;
  height: 27px;
  display: flex;
  align-items: center;
  margin: 0 auto;
}
offer-logo-mobile {
  display: flex;
  justify-content: center;
  align-content: end;
  height: 21px;
}
.offer-logo-mobile-active svg path {
  fill: #00add9;
}

.success-page-modal {
  position: fixed;
  width: 100%;
  z-index: 500;
  height: 70%;
}
.loan-duration-eligibility {
  height: 50px;
  line-height: 46px;
  color: #6e6e6e;
  padding-left: 10px;
  background: #f4f4f4;
  position: relative;
  padding-right: 10px;
  border-top-left-radius: 5px;
  font-weight: 600;
  margin-right: unset !important;
  border: 1px solid #cfcfcf;
  border-bottom-left-radius: 5px;
}
.application-cancle-grid {
  display: grid;
  grid-template-columns: 47% 47%;
  justify-content: space-between;
}
.password-visible {
  position: absolute;
  right: 10px;
  top: 38%;
  color: #424242;
}
.view_details {
  color: #1e6b9c !important;
  font-size: 17px;
  text-align: center;
  margin-top: 6px;
}
.bg-a0b3de {
  background: #a0b3de !important;
}

/*====================================================================================*/
/*OFFER PAGE */
/*====================================================================================*/
.trends_banner {
  background: #1b5f8e;
  height: 250px;
}
.trend-header {
  background: #fbfbfb;
  border-bottom: 1px solid #dfdfdf;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  height: 50px;
}
.trend-header .slick-slider {
  height: 50px;
}
.trendSlider {
  width: 95%;
  margin: auto;
}
.trendSlider a {
  color: #434343;
}
.trendSlider .slick-arrow.slick-prev,
.trendSlider .slick-arrow.slick-next {
  padding-bottom: 30px;
}

.trendSlider .slick-arrow.slick-prev {
}
.trendSlider .slick-arrow.slick-prev::before {
  content: '\f104' !important;
  font: normal normal normal 14px/1 FontAwesome !important;
  text-rendering: auto !important;
  -webkit-font-smoothing: antialiased !important;
  font-size: 30px !important;
  color: #1b5f8e;
}
.trendSlider .slick-arrow.slick-next {
}
.trendSlider .slick-arrow.slick-next::before {
  content: '\f105' !important;
  font: normal normal normal 14px/1 FontAwesome !important;
  text-rendering: auto !important;
  -webkit-font-smoothing: antialiased !important;
  font-size: 30px !important;
  color: #1b5f8e;
}
.trendSlider .slick-slide {
  padding: 0px !important;
}
.trends-data-wrapper {
  background: #fdfdfd;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: start;
  justify-content: start;
  -ms-flex-align: center;
  align-items: center;
  border-bottom-left-radius: 5px;
  height: 65vh;
  padding-bottom: 10px;
  overflow-y: auto;
  border: 1px solid #ececec !important;
  border-bottom-right-radius: 5px;
}
.trend-header-item {
  cursor: pointer;
  background: transparent;
  color: #434343;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  height: 50px;
  transition: all 100ms ease-in;
  padding: 8px 15px;
}
.trend-header-item:hover {
  color: #08bbf1;
  border-bottom: 3px solid #08bbf1;
}
.trend-header-item.isactive {
  color: #08bbf1;
  background: transparent;
  border-bottom: 3px solid #08bbf1;
}
.trend-header-item.isactive a {
  color: #08bbf1;
}
.trends-wrapper {
  width: 97%;
  padding: 0 10px 10px;
  background: #fff;
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
  border: 1px solid #ececec !important;
}

.trends-sections-grid {
  display: grid;
  grid-template-columns: 15% 65% 20%;
  justify-content: center;
  align-items: center;
}
.trends-sections-grid.isactive {
  border-bottom: none !important;
}
.trends-section-image {
  padding: 15px;
}
.trends-section-main {
  display: flex;
  flex-direction: column;
  align-self: normal;
  justify-content: space-between;
  padding-top: 15px;
}

.trends-section-main p {
  color: #a19eb2;
}
.trends-section-main .here {
  line-height: 10px;
}

.trends-section-main h4 {
  font-weight: 600;
  color: #0d435d;
}
.try-other-time {
  color: #e2e2e2 !important;
}
.details-link-mobile {
  background: #f7f7f7;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-shadow-white {
  box-shadow: 0 0 8px #37373730;
}
.trends-section-end {
  display: flex;
  flex-direction: column;
  align-self: normal;
  justify-content: space-between;
  text-align: right;
}

.trends-section-end .new-badge {
  font-size: 15px;
  padding-top: 10px;
  color: #c63e15;
  font-weight: 500;
}
.trends-section-end .quickApply {
  padding-bottom: 10px;
}
.trends-section-main .details .details-link {
  display: contents;
  cursor: pointer;
  font-size: 14px;
  color: #0e6c88 !important;
  font-weight: 600;
  transition: all 300ms ease-in-out;
  animation: animate-glow 1s infinite;
}
.trends-section-main .details .details-link.isactive i {
  color: #a3a4a6;
}
.trends-section-end p {
  position: relative;
}
.trends-section-end .badge {
  text-transform: uppercase;
  padding: 12px;
  font-size: 15px;
  background: #f4ca1e;
  border-radius: 0 !important;
}

/*.trends-section-end .badge:before {*/
/*    content: "";*/
/*    height: 0;*/
/*    width: 0;*/
/*    display: block;*/
/*    position: absolute;*/
/*    top: 3px;*/
/*    left: 0;*/
/*    border-top: 18px solid rgba(0, 0, 0, .3);*/
/*    border-bottom: 18px solid rgba(0, 0, 0, .3);*/
/*    border-right: 18px solid rgba(0, 0, 0, .3);*/
/*    border-left: 18px solid transparent;*/
/*}*/

/*.trends-section-end .badge:after {*/
/*    content: "";*/
/*    height: 3px;*/
/*    background: rgba(0, 0, 0, .3);*/
/*    display: block;*/
/*    position: absolute;*/
/*    bottom: -3px;*/
/*    left: 58px;*/
/*    right: 3px;*/
/*}*/

.trends-slide-down {
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: all 300ms cubic-bezier(0, 1, 0.5, 1);
}

.trends-slide-down.isactive {
  opacity: 1;
  height: auto;
  margin-top: 20px;
  position: relative;
  display: grid;
  grid-template-columns: 60% 40%;
  -ms-flex-pack: start;
  justify-content: start;
  -ms-flex-align: center;
  align-items: center;
  padding: 5px;
  border-top: 1px dashed #dadada;
}
/*.trend-lists ul li:before{*/
/*    content:'' !important;*/
/*    color: #848484 !important;*/
/*    background: none !important*/
/*}*/
.trend-lists ul li {
  margin-left: 45px;
  color: #6e6e6e;
  font-size: 15px;
  margin-bottom: 5px;
  position: relative;
}
.trend-lists ul li::before {
  content: '';
  width: 20px;
  height: 6px;
  position: absolute;
  left: -30px;
  margin-top: 8px;
  z-index: 1;
  background: #50c6f1 !important;
  border-radius: 25px;
}
.trends-sections-grid.empty {
  height: 80%;
  margin-top: 15px;
  border-radius: 5px;
  background: #08bbf1;
  color: white;
}
.trends-sections-grid .trends-section-main p {
  font-weight: 500;
  color: #0e2542;
}

.trends-list-end .req-link {
  width: 100%;
  cursor: pointer;
  color: #08bbf1;
  font-weight: 400;
  transition: all 300ms ease-in-out;
  padding: 10px;
}

.trends-list-end .req-link:hover {
  border: 0.5px solid #08bbf1;
}

.trends-list-end .badge {
  cursor: pointer;
  background: #f4ca1e;
  margin-left: auto;
  width: 60%;
  padding: 12px;
  font-size: 15px;
  margin-bottom: 10px;
}
.succ-modal {
  z-index: 50000;
  color: #95deff;
  top: 0;
  font-size: 26px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000000cf !important;
}
.succ-modal .close-modal {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 24px;
  color: #ffffff;
}
.upload-doc-suc {
  display: grid;
  grid-template-columns: 15% 85%;
  align-items: center;
}
.successfull-banner {
  background-color: #00add9;
  color: #fff !important;
  padding: 10px;
}
.badge::before {
  content: '';
}
.badge::after {
  content: '';
}
.quick-aply {
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
  padding: 8px 25px;
  line-height: 1.5;
  text-transform: uppercase !important;
  color: #434343 !important;
  background: #f4ca1f !important;
  letter-spacing: 1px;
  cursor: pointer;
}
.quick-aply-mobile {
  font-size: 13px;
  font-weight: 600;
  border-radius: 4px;
  padding: 13px;
  line-height: 1.5;
  text-transform: uppercase !important;
  color: #434343 !important;
  background: #f4ca1f !important;
  letter-spacing: 1px;
  cursor: pointer;
}
.check-eligibility-offer {
  color: #00a4ce;
  transition: 0.5s;
  padding: 8px 13px;
  border: 1px solid;
  border-radius: 5px;
}
.check-eligibility-offer:hover {
  background: #00a4ce;
  color: white;
}
.down-arrow-offer {
  align-self: flex-end;
  top: 9px;
  padding: 4px 5px;
}

.new-badge-mobile {
  align-self: start;
  padding: 5px;
  font-size: 13px;
  font-weight: 600;
  position: absolute;
  z-index: 1;
  top: -0.5px;
  right: -6px;
  color: #d66a49;
}

/*.new-badge::before{*/
/*content: '';*/
/*width: 30px;*/
/*height: 30px;*/
/*position: absolute;*/
/*z-index: -1;*/
/*transform: skewX(-48deg) rotate(90deg);*/
/*background: #e6af0d;*/
/*left: -5px;*/
/*bottom: .5px;*/
/*}*/
/*.new-badge::after{*/
/*content: '';*/
/*width: 30px;*/
/*height: 30px;*/
/*position: absolute;*/
/*z-index: -1;*/
/*transform: skewX(48deg) rotate(90deg);*/
/*background: #e6af0d;*/
/*left: -5px;*/
/*top: 0px;*/
/*}*/
.p-offer {
  padding: 0 5px 10px;
}
.text-08bbf1 {
  color: #08bbf1;
}
.text-E8793F {
  color: #e8793f !important;
}

.single-catagory-application span {
  color: #434343;
  font-size: 12px;
}
.successfull-schedule p span {
  color: #735808;
  font-family: Roboto;
  font-size: 8px;
  line-height: 15px;
  text-transform: uppercase;
}
.date span:nth-child(1) {
  color: #735808;
  font-size: 8px;
  line-height: 10px;
  text-transform: uppercase;
}
.single-catagory-application p {
  color: #00416d;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 14px;
}
.text-e36727 {
  color: #e36727;
}
.appl_option_output .cancel-notApproved {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  text-align: center;
  color: #d5941c;
  height: 100%;
  margin-top: -28px;
}

.addToCompare {
  background: #fff;
  color: #208dd3;
  border: 1px solid #208dd3;
}

.addToCompareactive {
  background: #208dd3;
  color: white;
}
.eligibility-label.lineHeightFix2 {
  line-height: 20px !important;
}
.cardlimit {
  padding: 15px !important;
}
.mb-custom {
  margin-bottom: 1.5px !important;
}
.mt-custom {
  margin-top: 50px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.customEligibleColor {
  color: #28855e !important;
}

.single-catagory-document {
  font-size: 17.5px !important;
}
#master-mobile .wrapper-full {
  min-height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: start;
  background: #f8f8f8;
}

.wrapper-full {
  min-height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.wrapper-upper {
}
.wrapper-lower {
  flex-grow: 2;
  min-height: 100% !important;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.wrapper-sliders {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.home3-tabs {
  flex-grow: 2;
}
.successfull-tabs-area-parent {
  min-height: 100% !important;
}
/*.slick-slider.successfull-tabs-area *:not(svg) {*/
/*height: 100%;*/
/*}*/
.slick-slider.successfull-tabs-area {
  height: 100%;
}
.trends-sections-grid-empty {
  margin-top: 10px;
  width: 97%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent !important;
  color: #212529;
}
.new-select2-style2 {
  /*0 padding: 0px !important; */
  font-weight: 400;
  font-size: 15px;
  color: #444;
  margin: 1px 1px 1px 0px;
}
.grid-45-55 {
  display: grid;
  grid-template-columns: 45% 55%;
  height: 100%;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}
.grid-65-35 {
  display: grid !important;
  grid-template-columns: 65% 35%;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}
.changeModal {
  width: 55% !important;
  max-width: 75% !important;
}

.requiredModal {
  width: 55% !important;
  max-width: 75% !important;
}
.navbar-brand {
  padding-top: 2px !important;
}
.shadow {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
}

.btn {
  font-size: 18px !important;
}
.changes {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace;
  font-size: 32px;
}
.footer-color {
  color: #fff;
  background: #0ab9f2;
}
.footer-text {
  color: #fff !important;
}
.footer-nav-text {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace;
  font-size: 20px !important;
}
.font-size-22 {
  font-size: 22px !important;
}
.font-size-21 {
  font-size: 21px !important;
}
.fz-19 {
  font-size: 19px !important;
}

.fp-14 {
  font-size: 14px !important;
}

.bg-blue {
  background: #208dd3 !important;
}
.text-3e {
  color: #3e3c3c !important;
}
.bg-facebook {
  color: #4267b2 !important;
}
.bg-twitter {
  color: #55aded !important;
}
.bg-linkedin {
  color: #0077b5 !important;
}
.bg-instagram {
  color: #7c3bc3 !important;
}
.border-blue {
  border: 1px solid #208dd3;
}
.custom-shadow {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2) !important;
}

.absolute_center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.center-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.left-5px {
  left: 5px;
}
.padding-right-1 {
  padding-right: 1px !important;
}
.bg-whitesmoke {
  background: whitesmoke;
}
.text-gray {
  color: #686868;
}

.check-web-sq {
  width: 140px;
  height: 150px;
  background: #0ab9f2;
  border: 4px solid #0095c6;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.check-web-sq .check path {
  fill: none;
  stroke: white;
  stroke-miterlimit: 10;
  stroke-width: 20px;
  margin: 40px;
  stroke-dasharray: 500;
  stroke-dashoffset: 500;
  animation: draw-check-web-sq 0.5s linear forwards;
}

.check-web-sq svg {
  overflow: visible;
}

@keyframes draw-check-web-sq {
  from {
    stroke-linecap: round;
  }
  to {
    stroke-linecap: round;
    stroke-dashoffset: 0;
  }
}
/*}*/
/*=======================================*/
/*Sucess page*/
/*=======================================*/

.home-page-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
}
.mb-18p {
  margin-bottom: 18%;
}
.mb-14p {
  margin-bottom: 14%;
}
.grid-50-50 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.top-card {
  padding: 12px 8px;
  border-radius: 5px;
  box-shadow: 0 0 5px #c5c5c5;
}
.home-font-color {
  color: #434343 !important;
  font-size: 14px;
}
.upload-document-outer {
  background: #f5fbfe;
}
.upload-document-header-wrapper {
  border-top-left-radius: 0.4rem !important;
  border-top-right-radius: 0.4rem !important;
  padding: 24px 15px;
  background: #35abe6;
  color: #fff;
  text-align: left;
}
.upload-document-header {
  padding-bottom: 10px;
}
.upload-document-header h3 {
  font-size: 20px;
  font-weight: 500;
}

.gridSuccessWeb {
  display: grid !important;
  grid-template-columns: 1fr 3fr;
  -ms-flex-pack: center;
  font-size: 16px;
  -ms-flex-align: center;
  align-items: center;
}
.sucess-header {
  font-size: 16px !important;
  font-weight: 600;
  letter-spacing: 0.7;
  padding-bottom: 5px;
}

.doc-right {
  display: flex;
  background: #1687bf;
  border-radius: 0.25rem !important;
  padding: 10px;
}
.doc-right-mobile {
  display: flex;
  background: #007998;
  border-radius: 1.5rem !important;
  padding: 5px;
  justify-content: center;
  align-items: center;
  width: 85%;
}
.doc-right-mobile p {
  margin-right: 5px;
}
.date-main {
  font-size: 35px;
  height: 100%;
  line-height: 30px;
}
.date-main-mobile {
  font-size: 15px !important;
  font-weight: 500 !important;
  height: 100%;
}
.mod {
  font-size: 14px;
  font-weight: 600;
  height: 100%;
}
.unmod {
  font-size: 12px !important;
  font-weight: 100;
}

.date-subs {
  display: flex;
  flex-direction: column;
  align-items: end;
}
.date-subs p {
  font-size: 14px;
  line-height: 16px;
  margin-left: 5px;
}

.doc-left {
  padding-left: 15px;
}
.time-main-mobile {
  padding-top: 5px;
  color: #fff;
}
.time-main-mobile p {
  font-size: 16px;
  font-weight: 500;
  height: 100%;
  line-height: 30px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: 0px;
}
.time-main-mobile span {
  color: #fff !important;
  margin-left: 5px !important;
  font-weight: 200 !important;
  margin-right: 5px !important;
  font-size: 13px !important;
  line-height: 10px !important;
}
.time-main-mobile .dash {
  color: #fff;
  font-size: 16px !important;
  font-weight: 900 !important;
}

.time-main p {
  font-size: 35px;
  height: 100%;
  line-height: 30px;
  display: flex;
  align-items: center;
}
.time-main span {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 12px;
  line-height: 16px;
}
.dash {
  font-size: 25px;
  font-weight: 900;
}
.mb-5px {
  margin-bottom: 5px;
}

.successfull-tabs-area {
}
.successful-middle-part {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding: 0 15px;
}

.successful-middle-part h3 {
  padding: 30px 0px;
  font-size: 20px !important;
  font-weight: 600 !important;
  width: 75%;
  line-height: 1.2;
}
.success-cancal-btn {
  padding-top: 10px;
  background: #fff;
  color: orangered;
  border: 1px solid #ffc9c9;
  border-radius: 20px;
  width: 38%;
  padding: 8px 12px;
  font-weight: 600;
  transition: 0.3s all;
}
.success-cancal-btn:hover {
  background: #ff000014;
  color: orangered;
}
.successfull-tabs-inner .nav-tabs {
  border: none;
}
.successfull-tabs-inner-inner {
  border-radius: 0.4rem !important;
  border: 1px dashed #dee2e6;
  background: #fff;
  margin-bottom: 20px;
}
.bord {
  border-bottom: 1px dotted #dee2e6 !important;
}

.doc-accordion {
  background: #fff;
  /*border: 1px dotted #dee2e6 !important;*/
  /*border-top: none;*/
  /*border-radius: 0.4rem !important;*/
}
.doc-accordion .nav-tabs {
  border: none;
}
.sucess-title {
  font-size: 18px !important;
  font-weight: 500;
}
.change-schedule-button {
  width:100%;
  font-size: 16px;
  padding: 0px 25px;
  margin-top: 20px;
  line-height: 28px;
  height: 45px;
  border: 1px solid #35abe6;
  border-radius: 9px;
  background: #35abe6;
  color: #fff !important;
  transition: 0.3s all;
}
.change-schedule-button:hover {
  border: 1px solid #35abe6;
  color: #35abe6!important;
  background: #fff !important;
}
.single-application .media .svg::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border: 2px dashed #afafaf !important;
  border-radius: 50%;
  background: #afafaf !important;
  z-index: 0;
}
.single-application .media .svg span::before {
  color: #ffff !important;
}
.fs-web {
  position: relative;
  z-index: 41;
  color: #fff;
  font-size: 36px !important;
}
.fs-check {
  position: relative;
  z-index: 41;
  color: #fff;

  font-size: 36px !important;
}

.single-application .checked .svg::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border: 2px dashed #35abe6 !important;
  border-radius: 50%;
  background: #35abe6 !important;
  z-index: 0;
}
.application-top .media-body p {
  color: #5d5d5d;
  font-size: 22px !important ;
  font-weight: 500;
  line-height: 29.99px;
}
.single-application .media .media-body p {
  font-weight: 600;
}
.svg-custom {
  z-index: 100;
}
.active-application-success {
  width: 100%;
  margin: auto;
  background: #d2e9f5;
  border: 1px dashed #036292;
  border-radius: 5px;
  padding: 10px;
  text-align: left;
}
.active-application-success-mobile {
  width: 100%;
  margin: auto;
  background: #d2e9f5;
  border: 1px dashed #036292;
  border-radius: 5px;
  text-align: left;
}
.footer-active-app-success {
  position: relative;
  left: -10px;
  width: 104%;
  bottom: -11px;
  background: #1884b9;
  color: white;
  display: grid;
  grid-template-columns: 65% 16% 16%;
  padding: 5px;
  -ms-flex-align: center;
  align-items: center;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.footer-active-app-success-mobile {
  position: relative;
  width: 100%;
  background: #1884b9;
  color: white;
  display: grid;
  grid-template-columns: 65% 17.5% 17.5%;
  padding: 5px;
  -ms-flex-align: center;
  align-items: center;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.common-active-app-btn-success {
  background: #a8d7ee;
  margin: 0 3px;
  border-radius: 5px;
  color: #434343;
  padding: 4px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.common-active-app-btn-success:hover {
  background: #5fcaff;
}
.active-application-submit-success {
  background: #1687bf;
  color: white;
  font-size: 17px;
  padding: 7px 30px;
  border-radius: 25px;
  margin-top: 10px;
}
.comp_p_q {
  font-size: 16px;
  color: #44505b;
  line-height: 57px;
}

.comp_p_q .icon_arrow {
  color: #44505b;
}
.single-catagory-application {
  margin-top: 5px;
  color: #fff;
}
.single-catagory-application .header {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}
.single-catagory-application .status {
  color: #fff;
  font-size: 14px !important;
  margin-top: 13px;
}
.single-catagory-application .status i {
  margin-right: 5px;
}
.single-application .media svg {
  z-index: 5;
  height: 75px;
  width: 75px;
}
.status i {
  color: #007998;
}
.hug svg {
  z-index: 5;
  height: 40px !important;
  width: 40px !important;
}

.single-application-success svg {
  height: 30px !important;
}
.side-bar-success {
  position: relative;
}
.side-bar-success::after {
/*   content: '';
  width: 2px;
  height: 75%;
  background: #ffffff;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 12px; */
}

.deposit-module {
  width: 100%;
  height: 100%;
  background: #000000a1;
  position: fixed;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 500;
}
.deposit-module .deposit-module-inner {
  position: relative;
  width: 50%;
  height: 55%;
  display: grid;
  background: white;
  border-radius: 5px;
}
.bb-1 {
  border-bottom: 1px solid #e6e6e6;
}
.grid-20-80-normal {
  display: grid;
  grid-template-columns: 20% 80%;
}
.deposit-module .top-text {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  color: #676767;
}
.modal-close {
  /* background: #272727; */
  position: absolute;
  z-index: 5;
  width: 30px;
  height: 30px;
  background: #fff;
  color: white;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  border: 1px solid #999 !important;
  top: -15px;
  right: -15px;
}

.grid-15-85-normal {
  grid-template-rows: 15% 85%;
}
.grid-22-78-normal {
  display: grid;
  grid-template-columns: 22% 78%;
}
.orange-button {
  font-size: 20px;
  width: 145px;
  background: #fcc645;
  border-radius: 30px;
  padding: 10px 0;
  margin-top: 15px;
}
.credit-rating {
  position: absolute;
  z-index: 5;
  width: 170px;
  padding: 1px 5px;
  top: 0;
  left: 0;
  background: #49af9f;
  color: white;
  border-bottom-right-radius: 25px;
  border-top-left-radius: 3px;
}
.alert-fail {
  position: relative;
  color: #353535;
  background: #ffd2d2;
  padding: 10px 15px;
  font-size: 30px;
  border-radius: 5px;
  font-weight: bold;
  width: 100%;
  margin: 15px auto;
  display: flex;
  flex-direction: row;
}

.alert-scess {
  position: relative;
  color: #353535;
  background: #def6e9;
  padding: 10px 15px;
  font-size: 30px;
  border-radius: 5px;
  font-weight: bold;
  width: 100%;
  margin: 15px auto;
  display: flex;
  flex-direction: row;
}
.alert-scess .alert-icon {
  background: #5b9978;
}
.alert-fail .alert-icon {
  background: #9a4141;
}
.alert-icon {
  border-radius: 10%;
  width: 20%;
  color: #fff;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 10px;
}
.alert-scess .icon span {
  font-size: 40px;
}
.alert-main {
  color: #333333;
  padding-left: 10px;
  width: 80%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.creditcompare-text {
  font-size: 20px !important;
}
.invisible-footer {
  width: 100%;
  height: 65px;
}
.mobile-top-banner .slick-slide {
  transform: scale(0.94);
  opacity: 0.7;
}
.mobile-top-banner .slick-slide.slick-current {
  transform: scale(1);
  opacity: 1;
}
.rating-mobile {
  padding-top: 25px;
}
.check-web-sq-nr {
  width: 90px;
  height: 95px;
  background: #0ab9f2;
  border: 3px solid #0095c6;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  animation: bounceInGlow 1s linear;
}
.check-web-sq-nr svg {
  overflow: visible;
}
.check-web-sq-nr .check path {
  fill: none;
  stroke: white;
  stroke-miterlimit: 10;
  stroke-width: 20px;
  margin: 40px;
  stroke-dasharray: 500;
  stroke-dashoffset: 500;
  animation: draw-check-web-sq 0.5s linear forwards;
}
.footer-active-app-rate {
  position: relative;
  left: -10px;
  width: 102.4%;
  bottom: -11px;
  background: #d8775a;
  color: white;
  display: grid;
  grid-template-columns: 65% 16% 16%;
  padding: 5px;
  -ms-flex-align: center;
  align-items: center;
}
.icon_arrow {
  color: #5c5c5c;
}
.grid-2-row {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
}
.aamartaka-orange-bg {
  background-color: #fcc645 !important;
}
.orange-button-mobile {
  font-size: 18px;
  width: 88%;
  background: #fcc645;
  border-radius: 5px;
  padding: 10px 0;
  margin-top: 15px;
}
.offer-logo-mobile svg path {
  fill: #838383;
}

.success-cancal-btn-mobile {
  margin-top: 12px;
  background: #fff;
  color: #f58c78;
  border-radius: 25px;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.2);
  width: 100%;
  padding: 7px;
  font-weight: 300;
}

#master-mobile {
  color: #434343 !important;
  height: 100%;
  background: #f8f8f8;
}

#master-mobile .new-select2-style .css-dvua67-singleValue,
#master-mobile .new-select2-style .css-151xaom-placeholder {
  color: #434343 !important;
}

#master-mobile .eligibility-label {
  color: #434343 !important;
}

#master-mobile .border {
  border: 1px solid #d1d1d1;
}
#master-mobile .border-right {
  border-right: 1px solid #bababa;
}
#master-mobile .footer-menu-active {
  color: #00add9 !important;
}
#master-mobile .not-active {
  color: #434343 !important;
}
#master-mobile .form-control {
  color: #434343 !important;
}
.trends-section-main > p {
  margin: 3px 0;
}
.trends-section-main > h4 {
  margin-bottom: 3px;
}
.rotate-180 {
  transform: rotateX(-180deg);
}
#master-mobile .start-header p {
  color: #434343;
}
.comp_p i {
  margin-right: 5px !important;
}
.bg-aamartaka {
  background: #08bbf1 !important;
}
#master-mobile .single_option .bcbd_inputgrup input {
  color: #434343 !important;
}
#master-mobile .css-bg1rzq-control {
  border: none !important;
}
fp-20 {
  font-size: 20px !important;
}

.single_option .bcbd_inputgrup input {
  font-family: 'Exo 2', sans-serif !important;
}
.css-xo7z33-option li {
  background: #009cc4 !important;
}
.icofont-ui-settings {
  color: var(--purple);
}
.pass-change-suc {
  padding: 10px;
  background: #008ecc;
  color: white;
  font-size: 16px;
  transition: 0.3s;
}
.bg-oab {
  background: #208dd3 !important;
}
.btn.focus,
.btn:focus {
  box-shadow: none !important;
}
.question-title > h4 {
  font-size: 19px;
}
.btn {
  font-size: 16px !important;
}
.nav-link1 {
  font-size: 14px !important;
}

.creditcard_info_btn_plus {
  width: 40px;
  height: 44px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background-color: #ffffff;
  margin-left: 0px;
  margin-top: 0px;
}
.creditcard_info_btn_plus:focus {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.css-bg1rz {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.css-1szy77t-control {
  box-shadow: none !important;
  border-color: hsl(0, 0%, 80%) !important;
}
.css-1szy77t-control:hover {
  border-color: hsl(0, 0%, 80%) !important;
}
.css-xo7z33-option {
  background: #1b5f8d !important;
}
.css-xo7z33-option:hover {
  background: #1b5f8d !important;
}
.css-11unzgr div:hover {
  background: #1b5f8d !important;
  color: #fff !important;
}
.submission-grid {
  display: grid;
  grid-template-columns: 10% 90%;
}
.nav-pills {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.nav-pills .nav-item:nth-child(1):hover,
.nav-pills .nav-item:nth-child(2):hover,
.nav-pills .nav-item:nth-child(3):hover,
.nav-pills .nav-item:nth-child(4):hover {
  border-left: 3px solid #64a56f;
}

.ui-mobile-btn {
  background: #c7f4ff !important;
  color: #7a7a7a;
  border-color: transparent;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  font-size: 16px;
  padding: 13px 40px;
  background: rgba(255, 255, 255, 0.3);
  z-index: 0;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  text-transform: uppercase;
  font-weight: 700;
  width: 50%;
}
.subscribe-mail img {
  width: 20%;
}
.border-left-24a5c7:hover {
  border-left: 2px solid #24a5c7;
}
.ui-preloader-aamartaka {
  position: relative;
  z-index: 1;
}
.ui-preloader-aamartaka::before {
  content: '';
  width: calc(100% + 6px);
  height: calc(100% + 6px);
  position: absolute;
  border-radius: 50%;
  z-index: -1;
  top: -3px;
  left: -3px;
  -webkit-animation: animate-aamartaka 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation: animate-aamartaka linear 5s infinite;
  border: 2px dashed #105263;
}
.ui-preloader-aamartaka > img {
  animation: animate-aamartaka-logo linear 1.1s infinite;
}
@keyframes animate-aamartaka {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes animate-aamartaka-logo {
  0% {
    transform: scale(0.9);
    opacity: 0.6;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0.6;
  }
}
.top-filter-home-loan {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
}
.top-filter-home-loan {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
}
.top-filter-indi {
  color: #444;
  padding: 5px 7px;
  height: 39px;
  text-align: left;
  border-radius: 4px;
  background-color: #748798;
  border: 0px;
  line-height: 15px;
  width: 80%;
}
.pdl-10 {
  padding-left: 10px !important;
}
.eligibility-footer .btn {
  color: white;
}
.bg-208dd3 {
  background: #208dd3;
}
.text-3e3c3c {
  color: #3e3c3c;
}
.eligibility-body .form-control {
  font-weight: 300;
}
.font-change-label {
  font-size: 15px;
  font-weight: 600;
}
.font-change-label-bdt {
  font-size: 14px;
  font-weight: 600;
}

.btn-adv {
  cursor: pointer;
  border: none !important;
  font-weight: 500;
  font-size: 16px !important;
  margin-top: 10px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;
  border-radius: 30px;
  padding: 10px 40px;
  line-height: inherit;
  z-index: 5;
  color: #fff !important;
  background: #208dd3 !important;
}
.advertisement-slider .single_item:hover .btn_tp1,
.new-best-deal .btn-adv:hover {
  background: #fcc645 !important;
  box-shadow: 0px 0px 1px rgba(195, 195, 195, 1) !important;
  color: #434343 !important;
}

.bg-quickapply {
  background: #fcc645 !important;
}

.styles_modal__gNwvD.applyModalStyleMobile {
  width: 100% !important;
}

.single_vt_img_parent img,
.credit-card-compare-img {
  width: 95px;
  height: 75px;
  max-width: 150px;
}
.center-select .css-1hwfws3 {
  justify-content: center;
}
.tol-tip:hover .ui-tol-tip {
  opacity: 1;
}
.ui-tol-tip {
  position: relative;
  background: white;
  color: black;
  border-radius: 5px;
  padding: 5px 8px;
  width: 14vmax;
  -webkit-box-shadow: 0 0 10px #707070;
  box-shadow: 0 0 10px #707070;
  bottom: 27px;
  left: -3px;
  z-index: 1;
  text-align: center;
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.ui-tol-tip::before {
  content: '';
  width: 20px;
  height: 20px;
  background: white;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  z-index: -1;
  bottom: -4px;
  border-radius: 2px;
  left: 6px;
}

.opacity-1 {
  opacity: 1 !important;
}
.rnded {
  border-radius: 10px !important;
}
.bold {
  font-weight: 600;
}
.center-select.css-1hwfws3 {
  justify-content: center;
}
.border {
  border: 1px solid #bababa;
}
.color-208dd3 {
  color: #208dd3;
}

.profile-detials .nav-item:nth-child(1):hover,
.profile-detials .nav-item:nth-child(2):hover,
.profile-detials .nav-item:nth-child(3):hover,
.profile-detials .nav-item:nth-child(4):hover {
  border-left: none !important;
}

.btn-danger.focus,
.btn-danger:focus {
  box-shadow: none !important;
}

.trendSlider .slick-slide {
  padding: 0px 15px !important;
}

.creditcard_section_info {
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.15) !important;
}
.ui-card-type {
  color: #4e522a;
  font-size: 14px !important;
}
.alert-fail-mobile {
  position: relative;
  color: #ffffff;
  background: #ff5858;
  padding: 4px 13px;
  font-size: 15px;
  font-weight: bold;
  width: 100%;
  margin: 15px auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
}
.down-arrow-select {
  position: absolute;
  right: 5px;
  top: 15%;
}

.creditcard_info_part h2 {
  color: #545454;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  margin-top: 0px;
}

.application_media1_p {
  font-size: 14px;
  font-weight: normal;
  color: #3e3c3c;
}

.mb-3p {
  margin-bottom: 3%;
}

.application_section_btn1 {
  height: 45px;
  width: 150px;
  color: #626262;
  font-size: 16px;
  font-weight: 500;
  font-family: 'roboto', sans-serif;
  padding: 0px 26px;
  background: #fff;
  border-radius: 35px;
  border: 1px solid #ebebeb;
  transition: all 1s ease;
}

.custom-cursore {
  transition: all 0.1s linear;
  content: '';
  width: 25px;
  height: 25px;
  position: absolute;
  z-index: 500;
  background: red;
}
.ui-custom-ul-tag {
  position: relative;
}
.ui-custom-ul-tag ul li {
  margin-left: 25px;
}
.ui-custom-ul-tag ul li::before {
  content: '';
  position: absolute;
  left: 3px;
  width: 15px;
  height: 5px;
  border-radius: 5px;
  background: #1e6b9c;
  z-index: 1;
  margin-top: 12px;
}

.ui-adv-title {
  color: #0d435d !important;
}
.center-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ammartaka-preloader-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100vh;
  position: fixed;
  z-index: 500;
  width: 100%;
  background: white;
  top: 0;
  left: 0;
  right: 0;
}

.sorry-msg {
  -webkit-box-shadow: 0 0 5px #c5c5c5;
  box-shadow: 0 3px 10px #a7c2cf;
  background: rgba(0, 126, 255, 0.05);
}

.sorry-msg .fa-frown-open {
  color: #e4b700;
}
.fa-chevron-down {
  color: #333;
}

.fa-frown-open {
  position: relative;
  animation: animateSadFace 0.7s ease-out infinite;
}

.fa-frown-open::after {
  content: '';
  width: 2px;
  height: 4px;
  background: #e4b700;
  z-index: 5;
  position: absolute;
  left: 49.48%;
  border-radius: 56px;
  top: 27px;
  animation: sadFaceT 1.6s ease-out infinite;
}

@keyframes animateSadFace {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sadFaceT {
  0% {
    top: 16px;
  }
  100% {
    top: 27px;
  }
}

@keyframes bounceInGlow {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  20% {
    -webkit-transform: scale3d(1.01, 1.01, 1.01);
    transform: scale3d(1.01, 1.01, 1.01);
  }

  40% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.01, 1.01, 1.01);
    transform: scale3d(1.01, 1.01, 1.01);
  }

  80% {
    -webkit-transform: scale3d(0.98, 0.98, 0.98);
    transform: scale3d(0.98, 0.98, 0.98);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.ui-company-name {
  background: white;
  padding: 5px 0 0;
  border-radius: 5px;
  margin-top: 3px;
  border: 1px solid #ddd;
  height: 150px;
  overflow-y: scroll;
  position: absolute;
  width: 100%;
  z-index: 5;
  box-shadow: 0 0 7px #ddd;
  -webkit-animation: animateCompanyName 0.3s linear;
  -o-animation: animateCompanyName 0.3s linear;
  animation: animateCompanyName 0.3s linear;
}

.ui-company-name::-webkit-scrollbar {
  width: 10px;
}

.ui-company-name::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.ui-company-name::-webkit-scrollbar-thumb {
  background: #888;
}

@keyframes animateCompanyName {
  from {
    margin-top: 15px;
  }
  to {
    margin-top: 3px;
  }
}

.ui-company-name p {
  font-size: 15px;
  padding: 8px 7px;
  color: #444;
  transition: 0.3s;
}

.ui-company-name-icon {
  position: absolute;
  top: 20px;
  right: 7px;
  font-size: 13px;
  color: #848484;
}

.bg-fffdf4 {
  background: #fffdf4;
}

.bg-orange-light {
  background: #fff7de;
}

.ui-ucb-tooltip {
  color: #ff5722;
  cursor: pointer;
  border: 1px solid #ff5722;
  border-radius: 5px;
  padding: 0 3px;
  position: relative;
  font-size: 13px;
  text-align: center;
}

.ui-ucb-tooltip:hover {
  background: #ff5722;
  color: white;
  -webkit-transition: 0.5s time;
  -moz-transition: 0.5s time;
  -ms-transition: 0.5s time;
  -o-transition: 0.5s time;
  transition: 0.5s time;
}

.ui-ucb-tooltip::before {
  content: 'Customer Have to Utilize Minimum 50% of his Credit Limit';
  position: absolute;
  width: 250px;
  display: none;
  color: white;
  background: #ff5722;
  border-radius: 5px;
  right: 0;
  bottom: 24px;
  font-weight: normal;
  padding: 5px;
}

.ui-ucb-tooltip::after {
  content: '';
  display: none;
  width: 10px;
  height: 10px;
  background: #ff5722;
  z-index: 0;
  position: absolute;
  transform: rotate(45deg);
  right: 8px;
  bottom: 21px;
}

.ui-ucb-tooltip:hover::before,
.ui-ucb-tooltip:hover::after {
  display: block;
}

.top-search {
  font-size: 21px;
}

.text-red {
  color: red;
}

.toolTipWarning {
  position: absolute;
  color: #ffffff;
  background: #ff5722;
  padding: 4px;
  border-radius: 3px;
  top: -40px;
  z-index: 5;
  left: 25px;
}

.toolTipWarning::before {
  content: '';
  width: 20px;
  height: 20px;
  background: #ff5722;
  z-index: -1;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  bottom: -2px;
  position: absolute;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.errorEligibility {
  color: white;
  position: fixed;
  background: red;
  z-index: 5;
  top: 15%;
  left: -20%;
  text-align: center;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  border: 2px solid darkred;
  box-shadow: 0 2px 10px darkred;
  animation: congratulationAnimate 6s ease-in-out;
  animation-delay: 2s;
}

.congratulationsEligibility {
  color: white;
  position: fixed;
  background: #37a6ff;
  z-index: 5;
  top: 15%;
  left: -30%;
  text-align: center;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  border: 2px solid #5a88ac;
  box-shadow: 0 2px 10px #166caf;
  animation: congratulationAnimate 6s ease-in-out;
}

@keyframes congratulationAnimate {
  0% {
    left: -20%;
  }
  20% {
    left: 0;
  }
  40% {
    left: 0;
  }
  60% {
    left: 0;
  }
  80% {
    left: 0;
  }
  100% {
    left: -20%;
  }
}

.notification-number {
  background: red;
  color: white;
  width: 30%;
  height: 30%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 13px;
  font-size: 8px;
  bottom: 13px;
  border: 1px solid white;
}

.notification-list {
  max-height: 45vmax;
  overflow: auto;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.notification-list p {
  background: #f6f6f6;
  font-size: 14px;
  padding: 10px 10px;
  border-bottom: 1px solid #6a6a6a;
  color: #01293a;
}

.loan-conversion {
  text-align: center;
  background: #d5f1fd;
  font-weight: 500;
  padding: 5px;
}

.smoothScroll {
  scroll-behavior: smooth;
}

#master-mobile .compare-section {
  text-align: center;
  display: block;
}

#master-mobile .compare-section h1 {
  font-family: 'Exo 2';
  font-size: 22px !important;
  color: #000;
  font-weight: 500;
  line-height: 32px;
  margin: 10px 0 10px;
  text-align: center;
  width: 98%;
}

#master-mobile .compare-section h1 span {
  font-size: 27px;
  color: #ec5d01;
}

#master-mobile .compare-section h3 {
  color: #2b3357;
  position: relative;
  z-index: 9;
  margin-top: 35px;
  font-size: 22px;
  margin-bottom: 15px;
  text-align: center;
  font-weight: 500;
  line-height: 1.2;
}

#master-mobile .compare-section h3 span {
  font-size: 24px;
  color: #ed5148;
}

#master-mobile .white-bg {
  background-color: #fff;
  box-shadow: 1px 4px 7px 0 #e9e9e9;
  margin-bottom: 20px;
  padding: 1px;
  height: auto;
}
#master-mobile .col-sm-4.col-xs-4 {
  float: left;
  width: 33.3%;
  padding: 3px;
}

#master-mobile .bod-bot-left:before,
.bod-bot-right:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 1px;
  bottom: -3px;
  left: 0;
}

#master-mobile .bod-bot-left:before {
  border-bottom: 1px solid;
  border-image-source: linear-gradient(90deg, #fff, #c6c7c7);
  border-image-slice: 1;
}

#master-mobile .other-service {
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}

#master-mobile .other-service img {
  height: 50px;
}

#master-mobile .bod-right {
  border-right: 1px solid;
  border-image-source: linear-gradient(35deg, #c6c7c7, #fff);
  border-image-slice: 1;
}

#master-mobile .ml-20per {
  margin-left: 15%;
  margin-top: 4px;
}

#master-mobile .bod-right-bot {
  border-right: 1px solid;
  border-image-source: linear-gradient(180deg, #d5d6d6, #fff);
  border-image-slice: 1;
}

#master-mobile .bod-bot:before {
  position: absolute;
  border-bottom: 1px solid #ddd;
  content: '';
  width: 100%;
  height: 1px;
  bottom: -3px;
  left: 0;
}

#master-mobile .bod-bot-left:before,
.bod-bot-right:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 1px;
  bottom: -3px;
  left: 0;
}

#master-mobile .bod-bot-right:before {
  border-bottom: 1px solid;
  border-image-source: linear-gradient(90deg, #c6c7c7, #fff);
  border-image-slice: 1;
}

#master-mobile .Transparent-sec {
  text-align: center;
  position: relative;
}

#master-mobile .Transparent-sec svg {
  margin: 11px 15px 13px;
  height: 43px;
}

#master-mobile .Transparent-sec p {
  color: #000;
  font-size: 12px;
  margin-bottom: 20px;
  line-height: 14px;
}

#master-mobile .Transparent-sec h5 {
  color: #1a5aa9;
  font-size: 13px;
}

#master-mobile .Transparent-sec .sec {
  padding-right: 11px;
  padding-left: 11px;
}
#master-mobile .btn.btn-default {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 10px 30px;
  border-radius: 50px;
  margin-top: 10px;
  font-size: 16px;
  border: 1px solid #ed5148;
  background: #ed5148;
  color: #fff;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#master-mobile .finddeal-sec {
  /* padding: 0 0 50px; */
  background-color: #f8f8f8;
  width: 100%;
  float: left;
  background-position: 100% 100%;
  padding-top: 20px;
}

#master-mobile .offers {
  background: #f8faf9;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#fff),
    color-stop(52%, #fff)
  );
  background: linear-gradient(90deg, #fff, #fff 52%);
  padding: 0;
  border-radius: 0;
}

#master-mobile #offers .owl-carousel .owl-item img {
  display: block;
  width: 100%;
  border-radius: 0 50px;
  box-shadow: -5px 4px 14px #c5c3c3;
}

#master-mobile .finddeal-sec h3 {
  font-size: 22px;
  margin-bottom: 15px;
  text-align: center;
}

#master-mobile .finddeal-sec h3 span {
  color: #ed5148;
}

#master-mobile .no-padding {
  padding: 0 !important;
}

#master-mobile #offers .owl-carousel.owl-drag .owl-item.active {
  margin: 0 20px 0 -60px;
  width: 245px !important;
  float: left;
  background: #f8f8f8;
  padding-bottom: 15px;
}

#master-mobile #offers .owl-carousel.owl-drag .owl-item {
  padding: 10px 17px;
}

#master-mobile .usp-section {
  margin: 0 0 50px;
  float: left;
  padding-top: 20px !important;
}

#master-mobile .bot-boxs {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

#master-mobile .bot-boxs > div:not(:last-child) {
  margin-right: 9px;
}
#master-mobile .bot-box {
  flex-basis: 0;
  flex-grow: 1;
  background-color: #fff;
  border-radius: 15px;
  padding: 0px 9px;
  /* min-height: 95px; */
  text-align: center;
  box-shadow: 2px 1px 7px 0 #ccc9c9;
}

#master-mobile .undline {
  width: 81%;
  margin-left: 6%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  position: absolute;
  top: 33px;
}

.dps-right-options {
  height: 100%;
}

.b-font {
  font-family: 'Hind Siliguri', sans-serif !important;
}

.fixed-deposit-thumb {
  height: 20px !important;
}

.navbar-toggler svg {
  font-size: 35px;
  margin-top: -3px;
  color: #d3531a;
}

.initial-input {
  height: 50px !important;
}

.m-tin-container {
  height: 30px;
  display: flex;
  justify-content: flex-end;
  padding: 0 8px;
  align-items: center;
}

.m-tin-container p {
  margin-right: 5px;
}

.choose-cc-card {
  /* background: rgba(255, 255, 255, 0.15); */
  background: rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  /* z-index: 9; */
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  color: #fff;
}

.choose-cc-card h1 {
  font-size: 25px;
  font-weight: 600;
}

.choose-cc-card a.btn {
  border: 2px solid #fff;
  border-radius: 50px;
  color: #fff;
  font-weight: 600;
  font-size: 15px !important;
  background: rgba(255, 255, 255, 0.35);
  padding: 10px 55px;
}

.choose-cc-card-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.15);
  padding: 10px 5px;
  border-radius: 4px;
}
.choose-cc-card-mobile h3 {
  font-size: 20px;
  /* font-weight: 600; */
}
.choose-cc-card-mobile a {
  border: 1px solid #666;
  border-radius: 50px;
  color: #666;
  font-weight: 500;
  font-size: 14px !important;
  background: rgba(255, 255, 255, 0.35);
  padding: 8px 35px;
  margin-top: 10px;
  text-transform: uppercase;
}
