* {
  margin: 0;
  padding: 0;
}

body{

}
a:focus{
  text-decoration: none;
}
a:hover{
  text-decoration: none;
}

p {
  margin-bottom: 0 !important;
}

input:not[type='range'], select{
  color: #585858 !important;
  background: #fff !important;
  border: 0px;
}
.input-group-text{
  color: #585858 !important;
}
.form-control:focus{
  border: 1px solid #ced4da;
  box-shadow: none;
}
.custom-control-input:focus~.custom-control-label::before {
    box-shadow: none;
}
.ff-roboto{

}
.cursor-pointer{
  cursor: pointer;
}
.focus-none {
   outline: none !important;
   box-shadow: none !important;
}
/* header Css */


/* Footer Css */
