/* eligibility css */


.eligibility_section {
  height: 75px;
  background-color: #f3f3f3;
  border: 1px solid #f1f3f5;;
}

.eligibility_h5 {
  color: #ffffff;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px
}

.eligibility_smp {
  color: #ffffff;
  font-family: Roboto;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: 15px;
}

.eligibility_p{
color: #787878;
font-family: Roboto;
font-size: 16px;
font-weight: 400;
letter-spacing: 0.15px;
}

.eligibility_p_col{
    color: #2bb8f4;
}



/* eligibility icon css */

.icon_fix7{
width: 54px;
height: 54px;
}

.icon_fix8{
width: 39px;
height: 39px;
}
