/*
    ==Details of Media Index large to small respectively==
*/

/*@media (min-width:1140px) and (max-width:1300px){*/

@media (min-width: 1367px) and (max-width: 1600px) {
  .banner-extra .svg {
    height: 142%;
    width: 100%;
    opacity: 0.05;
  }

  .banner-extra {
    background-size: cover;
    z-index: 0 !important;
    top: 2px;
    left: 8px;
  }
}
@media (min-width: 1300px) and (max-width: 1700px) {
  .single-card .card-box-title {
    font-size: 16px;
    line-height: 24px;
  }
  .single-card h4 {
    font-size: 20px;
    padding-bottom: 12px !important;
  }

  .single-card .text-normal-res.fz-18 {
    font-size: 16px !important;
  }

  /**/
  .new-eligibility-area.eligibility-type-2 {
    height: calc(100vh - -116px);
  }
}
@media (min-width: 992px) {
  .appl_option_output {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }

  .apply_modal_inner .modal_body {
    display: flex;
    height: 355px;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  /*Eligibility New*/
  .applyModalStyle.styles_modal__gNwvD {
    max-width: 1200px !important;
    width: 860px !important;
  }
  .new-select2-style .css-bg1rzq-control {
    height: 50px;
  }
  .applyModalStyle.styles_modal__gNwvD {
    max-width: 1200px !important;
    width: 860px !important;
  }
  .new-select2-style .css-1szy77t-control {
    height: 50px;
  }
  /**/
  .single-dps-table .fz-16 {
    font-size: 15px;
  }
  .single-dps-table .fz-16.more-info-btn {
    font-size: 14px !important;
  }
  .home_img img {
    width: 450px;
  }

  .sun_img img {
    width: 110px;
  }

  /*home loan car loan*/
  .single-card .card-box-title {
    font-size: 16px;
    line-height: 24px;
  }

  .single-card h4 {
    font-size: 18px;
    padding-bottom: 12px !important;
  }

  .single-card .text-normal-res.fz-18 {
    font-size: 15px !important;
  }

  /**/
  .banner-extra .svg {
    height: 139%;
    width: 96%;
    opacity: 0.1;
  }

  .banner-extra {
    top: -86px;
    left: 18px;
  }

  .bcbd_blog_slider .slick-next {
    right: -32px;
  }

  .bcbd_blog_slider .slick-prev {
    left: -30px;
    z-index: 9;
  }

  /*Eligibility Css*/
  /*.css-xp4uvy, .css-1492t68 {*/
  /*height: 37px;*/
  /*}*/
  .eligibility-type-2 .new-selction-style .css-1492t68 {
    height: 42px !important;
    line-height: 42px !important;
  }

  .eligibility-body {
    height: 320px !important;
  }

  .eligibility-type-2 .css-1hwfws3 {
    height: 42px !important;
    font-weight: 400;
  }

  .eligibility-type-2 .css-vj8t7z {
    border: none !important;
    height: 42px;
  }

  .single-dps-table .fz-14,
  .single-dps-table .fz-14 p {
    font-size: 12px;
  }

  /*.eligibility-type-2 .eligibility-body .css-1hwfws3 {*/
  /*height: 35px !important;*/
  /*padding-top: 0;*/
  /*padding-bottom: 0;*/
  /*}*/
  .eligibility-type-2 .eligibility_gender .check {
    height: 25px;
    width: 25px;
    border: 2px solid #00bdf2;
    border-radius: 50%;
    position: relative;
  }

  .eligibility_gender .sl_div input {
    width: 100%;
    height: 100% !important;
    z-index: 30;
    left: 0 !important;
    top: 0;
  }

  .eligibility_gender .check::before {
    content: '';
    position: absolute;
    left: 5.5px !important;
    top: 5.5px !important;
    height: 10px !important;
    width: 10px !important;
  }
  .eligibility_gender label {
    font-size: 15px;
  }

  /*New css*/
  .css-1492t68,
  .css-xp4uvy {
    height: 40px !important;
  }

  .eligibility-body .css-1g6gooi {
    height: 40px !important;
  }

  .eligibility-type-2 .css-vj8t7z {
    border: none !important;
    height: 40px !important;
  }

  .eligibility-type-2 .css-1hwfws3 {
    height: 40px !important;
  }

  .eligibility-type-2 .eligibility-body .css-1hwfws3 {
    height: 40px !important;
  }

  .eligibility-body {
    height: 320px !important;
  }

  .eligibility-footer .btn {
    padding: 10px 34px !important;
  }

  .eligibility-type-2 .css-1hwfws3,
  .eligibility-type-2 input {
    height: 50px;
    line-height: 40px;
  }
  .eligibility-type-2 .eligibility-body input.form-control {
    height: 52px !important;
    line-height: 42px;
  }
  .eligibility_gender .sl_div {
    padding: 12px 10px !important;
  }
  .right-log-body {
    height: 400px !important;
    overflow: auto;
  }
  .single-card .bcbd-btn {
    font-size: 13px !important;
  }
  /*Credit Card Table*/
  .single-card .card-image {
    padding-left: 0px !important;
    height: 100px !important;
    margin-top: 40px;
  }
  .table-grid-1 {
    max-width: 150px !important;
    width: 150px !important;
  }
  .single-card .bcbd-btn {
    padding: 11px 4px !important;
    max-width: 135px !important;
    min-width: 135px !important;
  }
  .single-card .text-oab {
    font-size: 15px;
  }
  .card .fw-medium {
    font-size: 16px;
  }
}

@media (min-width: 1301px) {
  .partners_inner_area::before {
    content: '';
    position: absolute;
    left: 0;
    width: calc(100% - 1263px);
    background: #1b99e7;
    height: 100%;
  }
}

@media (max-width: 1400px) {
  .single-card .card-image img {
    width: calc(100% - 10px) !important;
    height: 92px !important;
    max-height: 100px !important;
  }
}
@media (min-width: 1200px) and (max-width: 1300px) {
  .header-btn {
    padding: 0px 30px !important;
    height: 40px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 40px !important;
  }

  .navigation_right_area ul li:not(:last-child) {
    margin-right: 5px !important;
  }

  .navbar-brand img {
    max-width: 100%;
    width: 140px;
  }

  .btn.btn_tp1 {
    font-size: 14px;
    padding: 10px 20px !important;
  }

  .bcbd_nav {
    padding-left: 10px;
  }

  .bcbd_nav span {
    font-size: 14px;
  }

  .bcbd_nav img {
    height: 20px;
  }
  .card .no-gutters .col h4 {
    font-size: 19px;
  }
  /**/
  .banner-extra {
    top: -86px;
    left: -10px;
  }

  .banner-extra .svg {
    height: 123%;
    width: 107%;
    opacity: 0.1;
  }

  .testimonial-quote {
    width: 30% !important;
  }

  /*new Responsive css*/
  .top-search,
  .top-search span {
    font-size: 16px !important;
  }

  /*New Responsive Css*/
  .bcbd_navbar {
    padding-left: 30px;
    padding-right: 30px;
  }

  .sticky_nav.bcbd_navbar {
    padding-left: 30px;
    padding-right: 30px;
  }

  .navigation_right_area .btn_tp1 {
    font-size: 13px;
  }

  .bcbd_nav {
    padding-left: 22px;
  }

  .bcbd_nav li a {
    font-size: 12px;
    padding: 30px 10px !important;
    padding-right: 5px !important;
  }

  .navigation_right_area ul li a {
    font-size: 14px;
  }

  .sticky_nav .bcbd_nav {
    padding-left: 0px;
  }

  .sticky_nav.bcbd_navbar {
    padding-left: 20px;
    padding-right: 20px;
  }

  .banner_content h1 {
    font-size: 40px;
  }

  .banner_tab ul li a,
  body {
    font-size: 14px;
  }

  .btn.btn_tp1 {
    font-size: 14px;
    padding: 8px 40px;
    border: 1px solid #8ccbf5;
  }

  .single_el_ft_item {
    padding-right: 30px;
    padding: 10px 30px 10px 10px;
  }

  .sl_el_ftimg_inner img {
    max-width: 100%;
  }

  .single_testimonial_slider p {
    font-size: 14px;
    line-height: 26px;
  }

  .partners_inner_area::before {
    width: calc(100% - 1000px);
    height: 100%;
  }

  .blog_meta a h5 {
    font-size: 16px;
  }

  p {
    font-size: 14px;
  }

  img.callto_image {
    width: 115%;
  }

  .call_to_inner h2 {
    font-size: 26px;
  }

  .call_to_inner {
    padding-top: 30px;
  }

  .footer_widget ul li a {
    font-size: 13px;
  }

  .cd-headline.clip span {
    padding: 0.1em 0;
  }

  /*Eligibility*/
  .eligibility_process {
    padding-left: 50px;
    padding-right: 50px;
    /*
        transform: scale(.7);
        transform: perspective(1px) translateZ(0);
        backface-visibility: hidden;
*/
  }

  .eligibility_tab ul li a,
  .eligibility_tab ul li a.active {
    padding-bottom: 35px;
  }

  .eligibility_label.bcbd_group {
    font-size: 13px;
  }

  .eligibility_log_body ul li .log_content {
    font-size: 12px;
  }

  .eligibility_log_body ul li .log_title {
    font-size: 13px;
  }

  .eligibility_tab_content h5 {
    color: #fff;
    font-size: 16px;
  }

  .eligibility_body .bcbd_control {
    line-height: 40px;
    height: 40px;
  }

  .eligibility_label.bcbd_group {
    line-height: 35px;
    height: 35px;
  }

  .prsnl_dt .input-group {
    margin-bottom: 10px;
  }

  .prsnl_dt .select2,
  .prsnl_dt .select2-selection,
  .prsnl_dt .select2-selection__rendered {
    line-height: 35px !important;
    height: 35px;
  }

  .eligibility_body .bcbd_control {
    line-height: 35px;
    height: 35px;
  }

  .eligibility_gender {
    margin-bottom: 12px;
    margin-top: 0px;
  }

  .eligibility_gender .check {
    height: 25px;
    width: 25px;
  }

  .eligibility_check_footer .bcbd_btn {
    font-size: 14px;
    line-height: 35px;
    height: 35px;
    border-radius: 5px;
  }

  .eligibility_body .bcbd_control {
    font-size: 13px;
  }

  .prsnl_dt .select2,
  .prsnl_dt .select2-selection,
  .prsnl_dt .select2-selection__rendered {
    font-size: 13px !important;
  }

  .eligibility_tab_content.job_exp .eligibility_body {
    padding-top: 14px;
  }

  .loan_cmn .select2,
  .loan_cmn .select2-selection,
  .loan_cmn .select2-selection__rendered,
  .job_cmn .select2,
  .job_cmn .select2-selection,
  .job_cmn .select2-selection__rendered {
    line-height: 35px !important;
    height: 35px;
    font-size: 13px !important;
  }

  .slg_stg1 .select2-selection__arrow,
  .prsnl_dt .select2-selection__arrow {
    top: 2px !important;
  }

  .eligibility_tab ul li .svg {
    height: 42px;
  }

  /*Navigation*/
  .bcbd_nav li a {
    font-size: 13px;
    padding: 30px 18px !important;
    padding-right: 5px !important;
  }

  .hs_dropdown a::after {
    right: -5px;
  }

  .bcbd_dropdown li a {
    padding: 0px 12px !important;
    font-size: 12px;
  }

  .btn.btn_tp1 {
    border-color: transparent;
  }

  .slg_stg1 .select2-selection__arrow,
  .prsnl_dt .select2-selection__arrow {
    width: 24px !important;
    height: 32px !important;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /*new Responsive css*/
  .top-search,
  .top-search span {
    font-size: 18px !important;
  }

  /*New Responsive Css*/
  .slg_stg1 .select2-selection__arrow,
  .prsnl_dt .select2-selection__arrow {
    width: 24px !important;
    height: 32px !important;
  }

  .fixed-dps-banner-inner h2 {
    font-size: 20px;
  }

  .fixied_deposit_option {
    height: 680px;
  }

  .bcbd_navbar {
    padding-left: 30px;
    padding-right: 30px;
  }

  .sticky_nav.bcbd_navbar {
    padding-left: 30px;
    padding-right: 30px;
  }

  .navigation_right_area .btn_tp1 {
    font-size: 13px;
  }

  .bcbd_nav {
    padding-left: 22px;
  }

  .bcbd_nav li a {
    font-size: 12px;
    padding: 30px 18px !important;
    padding-right: 5px !important;
  }

  .hs_dropdown a::after {
    right: -5px;
  }
  .card .no-gutters .col h4 {
    font-size: 18px;
  }

  .navigation_right_area ul li a {
    font-size: 12px;
  }

  .sticky_nav .bcbd_nav {
    padding-left: 0px;
  }

  .sticky_nav.bcbd_navbar {
    padding-left: 20px;
    padding-right: 20px;
  }

  .navigation_right_area .btn_type3 {
    padding-left: 10px;
    padding-right: 10px;
    border: none !important;
  }

  .navigation_right_area .btn_type3 .svg {
    display: none !important;
  }

  .banner_content h1 {
    font-size: 40px;
  }

  .navigation_right_area .btn_tp1 {
    padding: 6px 15px 6px 15px !important;
    font-size: 11px;
  }

  p,
  body {
    font-size: 14px;
  }

  .btn.btn_tp1 {
    font-size: 14px;
    padding: 8px 24px;
    border: 1px solid #8ccbf5;
  }

  .single_el_ft_item {
    padding-right: 30px;
    padding: 10px 30px 10px 10px;
  }

  .sl_el_ftimg_inner img {
    max-width: 100%;
  }

  .single_testimonial_slider p {
    font-size: 14px;
    line-height: 26px;
  }

  .partners_inner_area::before {
    width: calc(100% - 950px);
  }

  .blog_meta a h5 {
    font-size: 16px;
  }

  img.callto_image {
    width: 102%;
  }

  .call_to_inner h2 {
    padding-top: 20px;
    padding-right: 0px;
    font-size: 26px;
  }

  .call_to_inner {
    padding-top: 20px;
  }

  .footer_widget ul li a {
    font-size: 13px;
  }

  .cd-headline.clip span {
    padding: 0.1em 0;
  }

  .scroll_top {
    right: 10px;
  }

  a.navbar-brand img {
    max-width: 100%;
  }

  .navbar-brand {
    width: 17%;
  }

  .banner_tab ul li a {
    font-size: 14px;
    padding: 10px 0;
  }

  .sec_title {
    padding-bottom: 40px;
    font-size: 28px;
  }

  .single_social i {
    font-size: 22px;
  }

  .single_social span {
    padding-left: 7px;
    font-size: 14px;
  }

  .navigation_right_area .btn.btn_tp1 {
    border: none !important;
  }

  .banner_tab {
    margin-top: 25px;
  }

  .testimonial_slider .slick-next {
    right: -6px;
  }

  .testimonial_slider .slick-prev {
    left: -6px;
  }

  .bcbd_dropdown li a {
    padding: 0px 12px !important;
  }

  /*slick dots*/
  .testimonial_slider .slick-dots li {
    display: inline-block;
    height: 13px;
    width: 13px;
    margin-right: 5px;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #1186d4;
    cursor: pointer;
  }

  .testimonial_slider .slick-dots {
    text-align: center;
    padding-top: 30px;
  }

  .testimonial_slider .slick-dots li button {
    opacity: 0;
  }

  .testimonial_slider .slick-dots li.slick-active {
    background: #1186d4;
  }

  .sl_tb_block a img {
    height: 44px !important;
    padding-bottom: 10px !important;
  }

  .sl_tb_block a {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  /*Eligibility check*/
  .single_radio_field label {
    padding-left: 30px;
    font-size: 14px;
    font-weight: 500;
  }

  .person-image {
    bottom: 0;
    height: 400px;
    left: -56px;
    max-width: inherit;
  }

  .eligiblity_inner h2 {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .slg_stg1 .select2,
  .slg_stg1 .select2-selection,
  .slg_stg1 .select2-selection__rendered,
  .prsnl_dt .select2 {
    font-size: 13px !important;
  }

  /*eligibility check*/
  .eligibility_tab ul li a,
  .eligibility_tab ul li a.active {
    padding: 0;
    padding-bottom: 15px;
  }

  .eligibility_tab > ul {
    width: 100%;
    padding-top: 13px;
    display: flex;
    margin: 0;
    flex-flow: row;
    justify-content: space-around;
  }

  .eligibility_tab ul li .svg {
    height: 28px;
  }

  .eligibility_tab_content h5 {
    font-size: 14px;
  }

  .eligibility_gender .check {
    height: 25px;
    width: 25px;
  }

  .eligibility_gender .sl_div .svg {
    height: 24px;
    padding-right: 4px;
  }

  .eligibility_gender label {
    margin-top: 3px;
    font-size: 15px;
  }

  .loan_cmn .select2,
  .loan_cmn .select2-selection,
  .loan_cmn .select2-selection__rendered,
  .job_cmn .select2,
  .job_cmn .select2-selection,
  .job_cmn .select2-selection__rendered,
  .eligibility_body .bcbd_control,
  .select2-selection__rendered,
  .prsnl_dt .select2,
  .prsnl_dt .select2-selection,
  .prsnl_dt .select2-selection__rendered,
  .eligibility_label.bcbd_group {
    font-size: 15px !important;
    line-height: 35px !important;
    height: 35px;
  }

  .loan_cmn .eligibility_body {
    padding-top: 12px;
  }

  .eligibility_tab_content.job_exp .eligibility_body {
    padding-top: 12px;
  }

  .prsnl_dt .select2-selection__arrow {
    top: 2px !important;
  }

  .eligibility_check_footer .bcbd_btn {
    font-size: 12px;
    line-height: 35px;
    height: 35px;
  }

  .eligibility_log_profile .svg {
    height: 30px;
  }

  .eligibility_log_user {
    height: 40px;
    width: 40px;
  }

  .eligibility_log_info h6 {
    font-size: 14px;
  }

  .eligibility_log_info p {
    font-size: 13px;
  }

  .eligibility_log_body ul li .log_title {
    font-size: 13px;
    width: 85px;
  }

  .eligibility_log_body ul li .log_content {
    font-size: 12px;
    width: calc(100% - 94px);
  }

  .eligibility_tab {
    display: flex;
    flex-flow: column;
  }

  .eligibility_tab .tab-content {
    width: calc(100% - 0px);
    padding: 15px;
  }

  .prsnl_dt .input-group {
    margin-bottom: 10px;
  }

  .eligibility_gender {
    margin-bottom: 12px;
    margin-top: 6px;
  }

  .rt_table_btn {
    font-size: 11px;
    padding: 8px 0px;
    width: 131px;
  }

  /*loan scale*/
  .loan_scale h2 {
    padding-top: 19px;
    padding-bottom: 26px;
    font-size: 25px;
  }

  .sl_lh_option
    .input-group
    .form-control
    span.select2-selection.select2-selection--single {
    border-color: transparent;
    height: 40px;
    line-height: 40px;
  }

  .slider_range_bottom
    .input-group
    .form-control
    span.select2-selection.select2-selection--single {
    border-color: transparent;
    height: 31px;
    line-height: 37px;
    margin-top: -11px;
  }

  .slider_range_bottom
    .select2-container--default
    .select2-selection--single
    .select2-selection__arrow
    b {
    top: 33%;
  }

  .slider_range_bottom .input-group .form-control {
    height: 40px;
    line-height: 40px;
  }

  .slider_range_bottom .input-group .form-control {
    height: 40px !important;
    line-height: 40px;
  }

  .bcbd_select {
    line-height: 40px;
  }

  .loan_scale_inner_container .bcbd_select {
    height: 38px !important;
  }

  .slider_range_bottom .input-group-prepend span {
    padding: 0;
    line-height: 40px;
    height: 40px;
    width: 50px;
    font-size: 15px;
  }

  .sl_lh_option .input-group-prepend span {
    font-size: 18px;
  }

  .loan_grid_paren .lon-grid {
    font-weight: 400;
  }

  /*Dps*/
  .sl_dps_table .rt_table_btn.bordered {
    margin-top: 0;
    padding: 8px 10px;
    font-size: 13px;
    width: 110px;
  }
  /**/

  .loan_cont img {
    max-width: 110px;
  }

  /*New css*/
  .single-loan-card .card-box-title {
    font-size: 14px !important;
    left: 0 !important;
    white-space: nowrap;
  }
  .single-loan-card p {
    font-size: 16px !important;
  }
  .bcbd-btn {
    max-width: 130px !important;
    min-width: 130px !important;
    font-size: 12px !important;
  }
  .table-grid-1 {
    max-width: 145px !important;
    width: 145px !important;
  }
  .single-card .bcbd-btn {
    padding: 12px 4px !important;
  }
}

/*--768px*/

@media (min-width: 768px) and (max-width: 991px) {
  .eligibility_tab_content .bcbd_select {
    height: 35px !important;
    line-height: 36px !important;
  }

  .bcbd_select {
    line-height: 40px;
  }

  .sinle-fd-account span {
    font-size: 11px;
    letter-spacing: 0px;
  }

  .loan_cont a + a {
    margin-left: 5px;
  }

  .loan_scale_inner_container .bcbd_select {
    height: 38px !important;
  }

  .blog-widget .sidebar-post-title {
    font-size: 12px;
  }

  .sidebar_title {
    font-size: 16px;
    padding-bottom: 17px;
    line-height: 17px;
  }

  .blog-widget {
    margin-bottom: 20px;
  }

  .post_title {
    font-size: 18px;
    line-height: 28px;
    padding-top: 16px;
    padding-bottom: 8px;
    padding-left: 30px;
  }

  .slg_stg1 .select2-selection__arrow,
  .prsnl_dt .select2-selection__arrow {
    width: 24px !important;
    height: 32px !important;
  }

  .bcbd_navbar {
    padding-left: 30px;
    padding-right: 30px;
  }

  .sticky_nav.bcbd_navbar {
    padding-left: 30px;
    padding-right: 30px;
  }

  .navigation_right_area .btn_tp1 {
    font-size: 13px;
  }

  .navigation_right_area ul li a {
    font-size: 12px;
  }

  .sticky_nav .bcbd_nav {
    padding-left: 0px;
  }

  .sticky_nav.bcbd_navbar {
    padding-left: 20px;
    padding-right: 20px;
  }

  .navigation_right_area .btn_type3 {
    padding-left: 10px;
    padding-right: 10px;
    border: none !important;
  }

  .navigation_right_area .btn_type3 .svg {
    display: none !important;
  }

  .banner_content h1 {
    font-size: 32px;
  }

  .navigation_right_area .btn_tp1 {
    padding: 6px 15px 6px 15px !important;
    font-size: 11px;
  }

  p,
  body {
    font-size: 14px;
  }

  .btn.btn_tp1 {
    font-size: 14px;
    padding: 10px 24px;
    border: 1px solid #8ccbf5;
  }

  .single_el_ft_item {
    padding-right: 30px;
    padding: 10px 30px 10px 10px;
  }

  .sl_el_ftimg_inner img {
    max-width: 100%;
  }

  .single_testimonial_slider p {
    font-size: 14px;
    line-height: 26px;
  }

  .partners_inner_area::before {
    width: calc(100% - 712px);
  }

  .blog_meta a h5 {
    font-size: 16px;
  }

  img.callto_image {
    width: 102%;
    height: 100%;
  }

  .call_to_inner h2 {
    padding-top: 23px;
    padding-right: 0px;
    font-size: 18px;
  }

  .call_to_field input {
    line-height: 47px;
    width: calc(100% - 140px);
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    border: 2px solid #d7d7d7;
    padding-left: 20px;
    font-size: 15px;
    vertical-align: top;
  }

  .call_to_inner {
    padding-top: 20px;
  }

  .footer_widget ul li a {
    font-size: 13px;
  }

  .cd-headline.clip span {
    padding: 0.1em 0;
  }

  .scroll_top {
    right: 10px;
  }

  .navbar-brand {
    width: 25%;
    padding-top: 2px;
  }

  .banner_tab ul li a {
    font-size: 12px;
    padding: 10px 0;
  }

  .sec_title {
    padding-bottom: 20px;
    font-size: 24px;
  }

  .single_social i {
    font-size: 22px;
  }

  .single_social span {
    padding-left: 7px;
    font-size: 11px;
  }

  .navigation_right_area .btn.btn_tp1 {
    border: none !important;
  }

  .banner_tab {
    margin-top: 25px;
  }

  .testimonial_slider .slick-next {
    right: -6px;
  }

  .testimonial_slider .slick-prev {
    left: -6px;
  }

  /*slick dots*/
  .testimonial_slider .slick-dots li {
    display: inline-block;
    height: 13px;
    width: 13px;
    margin-right: 5px;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #1186d4;
    cursor: pointer;
  }

  .testimonial_slider .slick-dots {
    text-align: center;
    padding-top: 30px;
  }

  .testimonial_slider .slick-dots li button {
    opacity: 0;
  }

  .testimonial_slider .slick-dots li.slick-active {
    background: #1186d4;
  }

  .sl_tb_block a img {
    height: 34px !important;
    padding-bottom: 10px !important;
  }

  .sl_tb_block a {
    padding-top: 25px;
    padding-bottom: 25px;
    font-size: 13px;
  }

  /* best deal dots*/
  /*slick dots*/
  .best_deal_slider .slick-dots li {
    display: inline-block;
    height: 13px;
    width: 13px;
    margin-right: 5px;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #1186d4;
    cursor: pointer;
  }

  .best_deal_slider .slick-dots {
    text-align: center;
    padding-top: 15px;
  }

  .best_deal_slider .slick-prev {
    left: -19px;
  }

  .best_deal_slider .slick-next {
    right: -17px;
  }

  .hs_dropdown.opendp a::after,
  .hs_dropdown a::after {
    right: 10px;
  }

  .bcbd_nav li:hover > ul {
    box-shadow: none;
  }

  .best_deal_slider .slick-dots li button {
    opacity: 0;
  }

  .best_deal_slider .slick-dots li.slick-active {
    background: #1186d4;
  }

  .single_statistics h5 {
    font-size: 15px;
  }

  .single_statistics p {
    margin: 0;
    font-size: 13px;
  }

  .single_statistics {
    font-size: 16px;
  }

  .single_el_ft_item p {
    font-size: 13px;
  }

  .single_el_ft_item:hover .single_el_ft_disc {
    left: -34px;
  }

  .our_partners_title h3 {
    font-size: 22px;
  }

  /*slick dots*/
  .bcbd_blog_slider .slick-dots li {
    display: inline-block;
    height: 13px;
    width: 13px;
    margin-right: 5px;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #1186d4;
    cursor: pointer;
  }

  .bcbd_blog_slider .slick-dots {
    text-align: center;
    padding-top: 30px;
  }

  .bcbd_blog_slider .slick-dots li button {
    opacity: 0;
  }

  .bcbd_blog_slider .slick-dots li.slick-active {
    background: #1186d4;
  }

  .call_to_field .clt_btn {
    line-height: 51px;
    padding-left: 25px;
    padding-right: 25px;
    margin-left: -4px;
    font-size: 14px;
  }

  /*social icon*/
  .single_social {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .footer_bottom p {
    font-size: 13px;
  }

  .rightnmobTab {
    margin-left: 50px;
    margin-top: 12px;
  }

  /*Testimonial*/
  .testimonial-quote {
    max-width: 40%;
    top: 45px;
    z-index: 0;
  }

  .customer-features .customer-tab {
    right: -121px;
    bottom: 0;
    width: 80%;
  }

  .single_testimonial_slider {
    padding: 14px;
  }

  .testimonial_area h2 {
    font-size: 27px;
    line-height: 40px;
  }

  /*mobile Menu*/
  .nav_outer .navbar-brand {
    width: 76%;
    z-index: 99;
    position: relative;
    padding-left: 30px;
    padding-bottom: 30px;
  }

  .nav_outer .navbar-brand {
    display: block;
  }

  .bcbd_nav li a {
    font-size: 13px;
    color: #fff;
    padding: 4px 10px !important;
    padding-right: 5px !important;
  }

  .bcbd_dropdown li a {
    color: #fff;
    line-height: 20px;
  }

  .bcbd_dropdown {
    position: static;
    width: 100%;
    padding: 0px 0px 2px 11px;
    transition: none;
    top: 0;
    opacity: 1;
    visibility: visible;
    transform: none;
  }

  .rightnmobTab {
    display: none !important;
  }

  .collapse.bcbd_collpase_nav {
    width: 0%;
    transition: all 0.3s linear;
    display: block;
    opacity: 0;
    visibility: hidden;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0px;
    margin-left: -100%;
    left: 0;
  }

  .show_clmenu.collapse.bcbd_collpase_nav {
    background: rgba(0, 0, 0, 0.7);
    margin-left: 0%;
    display: block;
    visibility: visible;
    opacity: 1;
  }

  .bcbd_megamenu {
    position: static;
    opacity: 1;
    visibility: visible;
    transform: none;
    transition: none;
    transform-origin: top;
    width: 100%;
    display: none;
  }

  .bcbd_megamenu .bcbd_dropdown {
    background: transparent;
    visibility: visible;
    opacity: 1;
    position: static;
    transform: none;
    display: block !important;
    width: 100%;
  }

  .bcbd_megamenu {
    background: transparent;
    box-shadow: none !important;
  }

  .nav_outer {
    background: #1272bf;
    width: 0px;
    position: relative;
    height: 100%;
    left: 0;
    transition: width 0.4s;
    transition-timing-function: cubic-bezier(0.17, 0.67, 0.88, 0.58);
  }

  .bcbd_dropdown {
    background: #1272bf;
  }

  .show_clmenu.collapse.bcbd_collpase_nav .nav_outer {
    width: 250px;
    overflow: auto;
    padding-bottom: 15px;
  }

  .bcbd_dropdown li a {
    padding-left: 0;
  }

  .bcbd_dropdown li a::after {
    display: none;
  }

  .navigation_right_area ul li {
    padding-right: 2px;
  }

  .navigation_right_area ul li:first-child a {
    color: #777;
    font-size: 12px;
    padding-right: 7px;
  }

  .btn_type3 {
    font-size: 10px;
    padding-left: 10px;
    padding-right: 15px;
    position: relative;
  }

  .sl_bd_logo {
    padding: 10px 10px;
  }

  .navigation_right_area .btn_tp1 {
    border: none;
    line-height: inherit;
    padding: 5px 15px 5px 15px !important;
    font-size: 10px;
  }

  .bcbd_collpase_nav .bcbd_nav {
    padding-left: 10px;
    padding-top: 30px;
  }

  .btn.btn_type3 .svg {
    display: none;
  }

  .btn.btn_type3 .svg {
    display: none;
  }

  .btn.btn_type3 {
    /* font-size: 10px; */
  }

  .navigation_right_area ul li:first-child a {
    color: #777;
    font-size: 12px;
    padding-right: 7px;
  }

  .navigation_right_area ul li:first-child a svg {
    display: none;
  }

  .rightnmobTab {
    z-index: 99;
    padding-top: 16px;
    padding-bottom: 21px;
    top: -100px;
    position: absolute;
    display: block !important;
    transition: all 0.4s linear;
  }

  .activeMobileMenu .rightnmobTab {
    display: block !important;
    top: -8px;
  }

  .nav_outer .navbar-brand {
    width: 70%;
    z-index: 99;
    position: relative;
    padding-left: 19px;
    padding-bottom: 0px;
    padding-top: 19px;
  }

  .burger_menu {
    margin-left: auto;
    width: 25px;
    height: 23px;
    padding: 0;
    z-index: 99;
    display: block;
  }

  .burger_menu:before,
  .burger_menu:after {
    top: 3px;
    height: 2px;
    background: #fff;
    transition: 0.2s transform;
    transform-origin: 8px 7px;
  }

  .burger_menu:after {
    top: 19px;
    transform-origin: 7px -6px;
  }

  .burger_menu.is-open {
    top: -3px;
  }

  .nav_outer .navbar-brand {
    display: block;
  }

  .bcbd_navbar.sticky_nav .bcbd_nav li a {
    color: #fff;
    padding: 4px 10px !important;
  }

  .bcbd_navbar.sticky_nav .burger_menu {
    background: linear-gradient(to right, #1d8ecc 0%, #1d8ecc 100%);
    position: relative;
    background-size: 50px 2px;
    background-position: center center;
    transition: 0.2s background;
    background-repeat: no-repeat;
  }

  .bcbd_navbar.sticky_nav .burger_menu.is-open {
    background-size: 0px 2px;
  }

  .bcbd_navbar.sticky_nav .burger_menu.is-open:after {
    transform: rotate(-45deg);
    transform-origin: 21px 2px !important;
  }

  .bcbd_navbar.sticky_nav .burger_menu:before,
  .bcbd_navbar.sticky_nav .burger_menu:after {
    top: 3px;
    height: 2px;
    background: #1d8ecc;
    transition: 0.2s transform;
    transform-origin: 8px 7px;
  }

  .bcbd_navbar.sticky_nav {
    padding-left: 20px;
    padding-right: 20px;
  }

  .bcbd_navbar.sticky_nav .burger_menu:after {
    top: 19px;
  }

  .bcbd_navbar.sticky_nav .burger_menu:after {
    top: 19px;
  }

  .bcbd_navbar.sticky_nav .burger_menu.is-open:after {
    top: 0px;
  }

  .show_clmenu .bcbd_dropdown {
    display: none;
  }

  img.callto_image {
    right: 10px;
  }

  /*Statistics*/
  .single-statistics h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .single-statistics h5 {
    font-size: 14px;
  }

  /*--Mobile Menu animation--*/
  .lg_cmn {
    display: block;
  }

  .lg1_fr_mb {
    position: absolute;
    top: 70%;
    transform: rotate(-35deg);
    opacity: 0.1;
    z-index: 5;
  }

  .lg2_fr_mb {
    position: absolute;
    top: 31%;
    right: -20px;
    transform: rotate(-50deg);
    opacity: 0.1;
  }

  /*Eligibility check*/
  .single_radio_field label {
    padding-left: 30px;
    font-size: 11px;
  }

  .eligiblity_inner h2 {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .slg_stg1 .select2,
  .slg_stg1 .select2-selection,
  .slg_stg1 .select2-selection__rendered,
  .prsnl_dt .select2 {
    font-size: 13px !important;
  }

  /*eligibility check*/
  .eligibility_tab ul li a,
  .eligibility_tab ul li a.active {
    padding: 0;
    padding-bottom: 15px;
  }

  .eligibility_tab > ul {
    width: 100%;
    padding-top: 13px;
    display: flex;
    margin: 0;
    flex-flow: row;
    justify-content: space-around;
  }

  .eligibility_tab ul li .svg {
    height: 28px;
  }

  .eligibility_tab_content h5 {
    font-size: 14px;
  }

  .eligibility_gender .check {
    height: 25px;
    width: 25px;
  }

  .eligibility_gender .sl_div .svg {
    height: 24px;
    padding-right: 4px;
  }

  .eligibility_gender label {
    margin-top: 3px;
    font-size: 15px;
  }

  .eligibility_label.bcbd_group.eldblw,
  .loan_cmn .select2,
  .loan_cmn .select2-selection,
  .loan_cmn .select2-selection__rendered,
  .job_cmn .select2,
  .job_cmn .select2-selection,
  .job_cmn .select2-selection__rendered,
  .eligibility_body .bcbd_control,
  .select2-selection__rendered,
  .prsnl_dt .select2,
  .prsnl_dt .select2-selection,
  .prsnl_dt .select2-selection__rendered,
  .eligibility_label.bcbd_group {
    font-size: 15px !important;
    line-height: 35px !important;
    height: 35px;
  }

  .loan_cmn .eligibility_body {
    padding-top: 12px;
  }

  .eligibility_tab_content.job_exp .eligibility_body {
    padding-top: 12px;
  }

  .prsnl_dt .select2-selection__arrow {
    top: 2px !important;
  }

  .eligibility_check_footer .bcbd_btn {
    font-size: 12px;
    line-height: 35px;
    height: 35px;
  }

  .eligibility_log_profile .svg {
    height: 30px;
  }

  .eligibility_log_user {
    height: 40px;
    width: 40px;
  }

  .eligibility_log_info h6 {
    font-size: 14px;
  }

  .eligibility_log_info p {
    font-size: 13px;
  }

  .eligibility_log_body ul li .log_title {
    font-size: 13px;
    width: 85px;
  }

  .eligibility_log_body ul li .log_content {
    font-size: 12px;
    width: calc(100% - 94px);
  }

  .eligibility_tab {
    display: flex;
    flex-flow: column;
  }

  .eligibility_tab .tab-content {
    width: calc(100% - 0px);
    padding: 15px;
  }

  .prsnl_dt .input-group {
    margin-bottom: 10px;
  }

  .eligibility_gender {
    margin-bottom: 12px;
    margin-top: 6px;
  }

  /*user guide*/
  .user_guide_contaienr {
    display: none;
  }

  /*Comming soon*/
  .coming_soon_content p {
    font-size: 13px;
  }

  .ptb_60 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  /*head option*/
  .loan_head_option {
    display: none !important;
  }

  .loan_table_head {
    padding-bottom: 15px;
  }

  .loan_grid_paren.single_loan_table {
    display: flex;
    flex-flow: column;
    padding-bottom: 30px;
  }

  .loan_grid_paren.single_loan_table .lon-grid {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
    border-left: none;
    border-right: none;
    display: block;
  }

  .single_rt_dt ul li {
    font-size: 13px;
    line-height: 23px;
  }

  .sl_lh_option
    .input-group
    .form-control
    span.select2-selection.select2-selection--single,
  .slider_range_bottom
    .input-group
    .form-control
    span.select2-selection.select2-selection--single {
    border-color: transparent;
    height: 35px;
    line-height: 35px;
  }

  .sl_lh_option .input-group-prepend span {
    font-size: 20px;
  }

  .rate_table_details {
    border-left: 0;
    border-right: 0;
  }

  .loan_grid_paren.single_loan_table div:nth-child(6) {
    border-bottom: none;
  }

  .single_rt_table {
    border-bottom: 1px solid #ddd;
    margin-bottom: 30px;
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }

  .loan_cont,
  .mobile_option {
    display: inline-block;
  }

  .mobile_option {
    width: 200px;
    font-size: 13px;
    text-align: left;
    padding-left: 15px;
  }

  .loan_cont {
    width: calc(100% - 205px);
  }

  .loan_grid_paren.single_loan_table .lon-grid {
    display: block;
  }

  /*scale*/
  .loan_scale h2 {
    padding-top: 24px;
    padding-bottom: 28px;
    font-size: 24px;
  }

  .slider_range_bottom .input-group-prepend span {
    line-height: 40px;
    height: 40px;
    width: 50px;
    font-size: 18px;
  }

  .bcbd_compare_btn {
    font-size: 14px;
    line-height: 0;
    padding: 18px 30px;
  }

  .slider_range_bottom .bcbd_compare_btn {
    margin-top: 32px;
  }

  /*--Start CardDetails--*/
  .dps_tbale_area h2 {
    font-size: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .dps_table_head {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 18px;
  }

  .sl_dps_table .lon-grid:hover {
    background: #f2f2f2;
    transform: scale(1.02);
  }

  .sl_dps_table .single_loan_table {
    padding-bottom: 0px;
  }

  /*Banenr Heading*/
  .banner-inner h1 {
    font-size: 36px !important;
    padding-bottom: 10px !important;
  }

  .advertisement-slider .btn.btn_tp1 {
    font-size: 14px;
    padding: 10px 40px !important;
  }

  .customer-features p {
    font-size: 14px !important;
    line-height: 26px !important;
  }

  /*-------------------------------
        ==Start Modal==
    -----------------------------*/
  .apply_modal_inner .modal_body {
    display: block;
  }

  .modal_left {
    width: 100%;
    float: none;
    border-right: 1px solid #ddd;
    padding-bottom: 0;
  }

  .apply_option h4 {
    color: #797979;
    font-weight: 300;
    padding-top: 25px;
    padding-bottom: 20px;
    font-size: 18px;
  }

  .modal_right {
    width: 100%;
    float: none;
    padding-top: 0;
    padding-bottom: 0;
  }

  .bcbd_modal_lg {
    max-width: 500px;
  }

  .modal_right {
    display: none;
  }

  .apl_chrage_cont ul > li h5 {
    font-size: 13px;
  }

  .apl_chrage_cont .aply_output_body li h6 {
    font-size: 14px;
    padding-top: 5px;
  }

  .apl_chrage_cont .aply_output_body li {
    padding: 8px 15px;
  }

  .loan_bank_option_output ul li {
    padding: 14px 0px;
  }

  .loan_bank_option_output ul li h5 {
    font-size: 13px;
    line-height: 30px;
  }

  .loan_bank_option_output ul li h4 {
    font-size: 15px;
  }

  .apl_chrage_cont .aply_output_body {
    height: 159px;
  }

  .aply_totl_return_amount ul li {
    padding-bottom: 6px;
  }

  .aply_totl_return_amount ul li h5 {
    font-size: 14px;
    width: calc(100% - 80px);
  }

  .aply_totl_return_amount ul li h6 {
    font-size: 13px;
    width: 70px;
  }

  .aply_totl_return_amount .apply_loan_total {
    padding: 23px 0;
  }

  .aply_option_parent {
    overflow: hidden;
  }

  .cccard_name.only_mob_tab {
    display: block;
  }

  .table_adremove {
    left: 14px;
    top: 8px;
  }

  .credit_card_left_sidebar {
    position: fixed;
    background: #fff;
    z-index: 9;
    top: 57px;
    left: 0;
    display: none;
  }

  .cc_full_with_table .cc_table_right {
    flex: 0 0 100%;
    padding-left: 15px;
    padding-right: 15px;
  }

  /* ----------------------------
        ==Fixed Deposit
    ---------------------------- */
  .fixed-dps-banner-inner h2 {
    font-size: 26px;
    text-align: center;
  }

  .fd-table-grid.single_loan_table div:nth-child(6) {
    border-bottom: 1px solid #ddd;
  }

  .fd-table-grid.single_loan_table .mobile_option {
    height: 100%;
  }

  .fd-table-grid.single_loan_table .lon-grid {
    display: flex;
    flex-flow: row;
  }

  .fd-table-grid {
    padding-bottom: 0 !important;
    border-bottom: 0 !important;
  }

  .last-checked,
  .user-rating {
    text-align: left;
  }

  .fixed-deposit-range-slider .sl_div {
    width: 100% !important;
    flex: 0 0 100%;
  }

  .fixed-deposit-range-slider .sl_div a {
    margin-top: 0;
  }

  .fixed-deposit-range-slider .range_slider_top {
    display: none !important;
  }

  .fixed-deposit-range-slider {
    padding: 20px 20px 20px 20px;
  }
}

/*-------------------------------------------
    ==Large Mobile==
------------------------------------*/

@media (max-width: 767px) {
  .loan_bank_option_output ul {
    display: block;
    overflow: hidden;
    width: 100%;
  }
  .warning-message,
  .warning-message ul li {
    font-size: 13px !important;
    font-weight: 500;
  }
  .alert-message img,
  .warning-message img {
    margin-right: 10px;
    height: 24px;
  }
  .apply-loan-modal .modal_left {
    border: none;
    width: 100% !important;
  }
  .apply-loan-modal .modal_body {
    height: initial;
  }
  .applyModalStyle h2 {
    font-size: 16px;
    font-weight: 600;
  }
  .apply-loan-modal .modal_right {
    float: left;
    height: initial;
  }
  .apply-loan-modal .modal_left {
    height: initial !important;
  }
  .applyModalStyle .modal_right {
    display: block !important;
  }
  /*Mobile responsive css*/
  .scb_header {
    text-align: center;
  }

  .scb_header img {
    width: 20%;
  }

  .footer_right {
    text-align: center;
    padding-bottom: 10px;
  }

  .footer_left {
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 22px;
  }

  .post_title {
    font-size: 15px;
    line-height: 25px;
    padding-top: 20px;
    padding-bottom: 7px;
    padding-left: 30px;
  }

  .psot_meta {
    padding-left: 30px;
    font-size: 13px;
  }

  .blog-widget .sidebar-post-title {
    font-size: 16px;
  }

  .blog-widget img {
    max-width: 100%;
    min-width: 100%;
  }

  .single_cc_sdbar_widget h4 {
    background: linear-gradient(to right, #0c65b7 0%, #25a8da 85%);
    color: #fff;
  }

  .table_adremove {
    display: none;
  }

  .loan_scale_inner_container .bcbd_select {
    height: 38px !important;
    line-height: 37px;
  }

  .loan_cont a + a {
    margin-left: 5px;
  }

  .fromProdure.twoHalf .sl_div:last-child {
    margin-left: 15px;
  }

  .fromProdure.twoHalf .sl_div:first-child,
  .fromProdure.twoHalf .sl_div:nth-child(2) {
    width: calc(49% - 5px);
    float: left;
  }

  .bcbd_btn4 {
    padding: 10px 20px 10px 20px;
    font-size: 16px;
  }

  .credit_card_bnr .fromProdure .bcbd_control {
    line-height: 35px;
  }

  .cc_full_with_table .cc_table_right {
    flex: 0 0 100%;
    padding-left: 15px;
    padding-right: 15px;
  }

  .cc_full_with_table .cc_table_left {
    flex: 0 0 80%;
    padding-left: 15px;
    padding-right: 15px;
    width: 80%;
    overflow: auto;
    border-left: 1px solid #ddd;
    right: 0;
    left: inherit;
  }

  .cc_table_left {
    padding-top: 50px;
    position: fixed;
    background: #fff;
    z-index: 100;
    top: 0px;
    left: 0;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s linear;
  }

  .banner_tab .sl_tb_block a {
    height: 65px;
    width: 65px;
    margin-bottom: 10px;
  }

  #leftClose {
    font-size: 19px;
    font-weight: 600;
    display: inline-block;
    position: absolute;
    top: 9px;
    right: 15px;
    border: 1px solid #111;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    text-align: center;
    line-height: 22px;
    z-index: 1200;
  }

  .banner_content h1 {
    font-size: 16px;
    padding-bottom: 20px;
  }

  .banner_tab {
    margin-top: 20px;
  }

  .sl_bd_img img {
    width: 100%;
  }

  .sec_title {
    padding-bottom: 24px;
    font-size: 20px;
    line-height: 28px;
  }

  .ptb_60 {
    padding-top: 38px;
    padding-bottom: 40px;
  }

  /*slick dots*/
  .best_deal_slider .slick-dots li {
    display: inline-block;
    height: 13px;
    width: 13px;
    margin-right: 5px;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #1186d4;
    cursor: pointer;
  }

  .best_deal_slider .slick-dots {
    text-align: center;
    padding-top: 15px;
  }

  .best_deal_slider .slick-dots li button {
    opacity: 0;
  }

  .best_deal_slider .slick-dots li.slick-active {
    background: #1186d4;
  }

  .our_statistics .row > div:not(:first-child) > div {
    padding-left: 0;
  }

  .single_statistics {
    font-size: 16px;
    text-align: center;
    padding-bottom: 9px;
    border-bottom: 1px solid #eee;
    padding-top: 10px;
  }

  .single_statistics p {
    margin: 0;
    font-size: 13px;
  }

  .our_statistics {
    padding-top: 0;
    padding-bottom: 25px;
  }

  .single_el_ft_item p {
    font-size: 13px;
  }

  .single_el_ft_item {
    margin-left: 34px;
    padding-right: 10px;
    padding: 10px 10px 10px 10px;
    margin-bottom: 0px;
    border-radius: 5px;
  }

  .eligibility_ft_slider_parent {
    padding: 10px 10px 10px 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }

  .single_el_ft_disc {
    height: 15px;
    width: 15px;
    left: -28px;
  }

  .single_el_ft_item:hover .single_el_ft_disc {
    left: -28px;
  }

  .eligibility_ft_slider::before {
    width: 3px;
  }

  /*slick dots*/
  .eligibility_ft_slider .slick-dots li {
    display: inline-block;
    height: 13px;
    width: 13px;
    margin-right: 5px;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #1186d4;
    cursor: pointer;
  }

  .eligibility_ft_slider .slick-dots {
    text-align: center;
    padding-top: 15px;
  }

  .eligibility_ft_slider .slick-dots li button {
    opacity: 0;
  }

  .eligibility_ft_slider .slick-dots li.slick-active {
    background: #1186d4;
  }

  /*slick dots*/
  .testimonial_slider .slick-dots li {
    display: inline-block;
    height: 13px;
    width: 13px;
    margin-right: 5px;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #1186d4;
    cursor: pointer;
  }

  .testimonial_slider .slick-dots {
    text-align: center;
    padding-top: 15px;
  }

  .testimonial_slider .slick-dots li button {
    opacity: 0;
  }

  .testimonial_slider .slick-dots li.slick-active {
    background: #1186d4;
  }

  /*slick dots*/
  .bcbd_blog_slider .slick-dots li {
    display: inline-block;
    height: 13px;
    width: 13px;
    margin-right: 5px;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #1186d4;
    cursor: pointer;
  }

  .bcbd_blog_slider .slick-dots {
    text-align: center;
    padding-top: 30px;
  }

  .bcbd_blog_slider .slick-dots li button {
    opacity: 0;
  }

  .bcbd_blog_slider .slick-dots li.slick-active {
    background: #1186d4;
  }

  .call_to_inner {
    padding-top: 26px;
    padding-bottom: 40px;
    border: 2px dashed #6daad2;
    border-radius: 5px;
    background: #f4f8fd;
    text-align: center;
  }

  img.callto_image {
    display: none;
  }

  .call_to_field input {
    line-height: 34px;
    width: calc(100% - 118px);
    border: 2px solid #d7d7d7;
    padding-left: 20px;
    font-size: 12px;
  }

  .blog_meta a h5 {
    font-size: 14px;
    font-weight: 600;
  }

  body,
  p {
    font-size: 14px;
  }

  .call_to_field .clt_btn {
    background: #20a2f9;
    line-height: 38px;
    padding-left: 12px;
    padding-right: 15px;
    margin-left: -5px;
    font-size: 13px;
  }

  .call_to_inner h2 {
    font-size: 18px;
    padding-top: 12px;
    text-align: center;
    padding-right: 0;
  }

  .footer_widget ul li a {
    font-size: 13px;
    line-height: 26px;
  }

  .footer_widget h6 {
    font-size: 15px;
    padding-bottom: 16px;
    padding-top: 17px;
  }

  .single_social span {
    display: none;
  }

  .single_social {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .single_social i {
    font-size: 18px;
  }

  .scroll_top {
    right: 3px;
    bottom: 65px;
    height: 25px;
    width: 25px;
  }

  .scroll_top i {
    font-size: 20px;
  }

  .single_testimonial_slider p {
    line-height: 22px;
    font-size: 13px;
  }

  .footer_bottom p {
    font-size: 10px;
  }

  .btn.btn_tp1 {
    font-size: 12px;
    border-radius: 30px;
    padding: 10px 27px;
    z-index: 5;
  }

  .our_partners_title::after,
  .partners_inner_area::before {
    display: none;
  }

  .our_partners_title h3 {
    color: #1b99e7;
    text-align: center;
  }

  .partners_inner_area {
    padding-top: 15px;
    padding-bottom: 10px;
  }

  .our_partners_title {
    background: #fff;
    justify-content: center;
    padding-bottom: 15px;
  }

  .bcbd_navbar .navbar-brand {
    width: 50%;
  }

  .bcbd_navbar .navbar-brand img {
    width: 100%;
  }

  .bcbd_navbar {
    padding-left: 15px;
    padding-right: 15px;
  }

  /*mobile Menu*/
  .bcbd_nav li a {
    font-size: 13px;
    color: #fff;
    padding: 4px 10px !important;
    padding-right: 5px !important;
  }

  .bcbd_dropdown li a {
    color: #fff;
    line-height: 20px;
  }

  .bcbd_dropdown {
    position: static;
    width: 100%;
    padding: 0px 0px 2px 11px;
    transition: none;
    top: 0;
    opacity: 1;
    visibility: visible;
    transform: none;
  }

  .bcbd_nav li:hover > ul {
    box-shadow: none;
  }

  .rightnmobTab {
    display: none !important;
  }

  .collapse.bcbd_collpase_nav {
    width: 0%;
    transition: all 0.3s linear;
    display: block;
    opacity: 0;
    visibility: hidden;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0px;
    margin-left: -100%;
    left: 0;
  }

  .show_clmenu.collapse.bcbd_collpase_nav {
    background: rgba(0, 0, 0, 0.7);
    margin-left: 0%;
    display: block;
    visibility: visible;
    opacity: 1;
  }

  .nav_outer {
    background: #1272bf;
    width: 0px;
    position: relative;
    height: 100%;
    left: 0;
    transition: width 0.4s;
    transition-timing-function: cubic-bezier(0.17, 0.67, 0.88, 0.58);
  }

  .bcbd_dropdown {
    background: #1272bf;
  }

  .show_clmenu.collapse.bcbd_collpase_nav .nav_outer {
    width: 250px;
    overflow: auto;
    padding-bottom: 15px;
  }

  .bcbd_nav li:hover > a {
    background: #fff;
  }

  .bcbd_dropdown li a {
    padding-left: 0;
  }

  .bcbd_dropdown li a::after {
    display: none;
  }

  .navigation_right_area ul li {
    padding-right: 2px;
  }

  .navigation_right_area ul li:first-child a {
    color: #777;
    font-size: 12px;
    padding-right: 7px;
  }

  .btn_type3 {
    font-size: 10px;
    padding-left: 10px;
    padding-right: 15px;
    position: relative;
  }

  .navigation_right_area .btn_tp1 {
    border: none;
    line-height: inherit;
    padding: 5px 15px 5px 15px !important;
    font-size: 10px;
  }

  .bcbd_collpase_nav .bcbd_nav {
    padding-left: 10px;
    padding-top: 30px;
  }

  .btn.btn_type3 .svg {
    display: none;
  }

  .btn.btn_type3 .svg {
    display: none;
  }

  .btn.btn_type3 {
    /* font-size: 10px; */
  }

  .navigation_right_area ul li:first-child a {
    color: #777;
    font-size: 12px;
    padding-right: 7px;
  }

  .navigation_right_area ul li:first-child a svg {
    display: none;
  }

  .rightnmobTab {
    z-index: 99;
    padding-top: 16px;
    padding-bottom: 21px;
    top: -100px;
    position: absolute;
    display: block !important;
    transition: all 0.4s linear;
  }

  .activeMobileMenu .rightnmobTab {
    display: block !important;
    top: -8px;
  }

  .nav_outer .navbar-brand {
    width: 70% !important;
    z-index: 99;
    position: relative;
    padding-left: 19px;
    padding-bottom: 0px;
    padding-top: 19px;
  }

  .burger_menu {
    margin-left: auto;
    width: 25px;
    height: 23px;
    padding: 0;
    z-index: 99;
    display: block;
  }

  .burger_menu:before,
  .burger_menu:after {
    top: 3px;
    height: 2px;
    background: #fff;
    transition: 0.2s transform;
    transform-origin: 8px 7px;
  }

  .burger_menu:after {
    top: 19px;
    transform-origin: 7px -6px;
  }

  .burger_menu.is-open::before {
    top: 7px;
  }

  .bcbd_navbar.sticky_nav .burger_menu.is-open::before {
    top: 0;
  }

  .burger_menu.is-open {
    top: 3px;
  }

  .nav_outer .navbar-brand {
    display: block;
  }

  .bcbd_navbar.sticky_nav .bcbd_nav li a {
    color: #fff;
    padding: 4px 10px !important;
  }

  .bcbd_navbar.sticky_nav .burger_menu {
    background: linear-gradient(to right, #1d8ecc 0%, #1d8ecc 100%);
    position: relative;
    background-size: 50px 2px;
    background-position: center center;
    transition: 0.2s background;
    background-repeat: no-repeat;
  }

  .bcbd_navbar.sticky_nav .burger_menu.is-open {
    background-size: 0px 2px;
  }

  .bcbd_navbar.sticky_nav .burger_menu.is-open:after {
    transform: rotate(-45deg);
    transform-origin: 21px 2px !important;
  }

  .bcbd_navbar.sticky_nav .burger_menu:before,
  .bcbd_navbar.sticky_nav .burger_menu:after {
    top: 3px;
    height: 2px;
    background: #1d8ecc;
    transition: 0.2s transform;
    transform-origin: 8px 7px;
  }

  .bcbd_navbar.sticky_nav {
    padding-left: 20px;
    padding-right: 20px;
  }

  .bcbd_navbar.sticky_nav .burger_menu:after {
    top: 19px;
  }

  .bcbd_navbar.sticky_nav .burger_menu:after {
    top: 19px;
  }

  .bcbd_navbar.sticky_nav .burger_menu.is-open:after {
    top: 0px;
  }

  .show_clmenu .bcbd_dropdown {
    display: none;
  }

  .best_deal_slider .slick-prev {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 8px;
    z-index: 9;
  }

  .slick-slide img {
    max-width: 100%;
  }

  .best_deal_slider .slick-next {
    position: absolute;
    top: 50%;
    transform: translate(10px, -50%);
    right: 4px;
  }

  /*--Mobile Menu animation--*/
  .elgibility_mobile_img img {
    height: 140px;
    margin-bottom: 15px;
  }

  .elgibility_mobile_img {
    text-align: center;
  }

  .lg_cmn {
    display: block;
  }

  .lg1_fr_mb {
    position: absolute;
    top: 70%;
    transform: rotate(-35deg);
    opacity: 0.1;
    z-index: 5;
  }

  .lg2_fr_mb {
    position: absolute;
    top: 31%;
    right: -60px;
    transform: rotate(-50deg);
    opacity: 0.1;
  }

  .scroll_down {
    display: none;
  }

  .testimonial_slider .slick-prev {
    position: absolute;
    top: 38%;
    transform: translate(-50%, 10px);
    left: 3px;
    z-index: 9;
  }

  .slick-slide img {
    max-width: 100%;
  }

  .testimonial_slider .slick-next {
    position: absolute;
    top: 50%;
    transform: translate(10px, -50%);
    right: 0;
  }

  /*eligibility*/
  .eligiblity_inner h2 {
    font-size: 18px;
  }

  .eligible_top .sl_div {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .eligible_top {
    padding: 0px 0px 27px 0px;
  }

  .single_radio_field .check::before {
    left: 50%;
  }

  .eligible_top .sl_div:first-child {
    padding-bottom: 15px;
  }

  .sl_div .bcbd_label {
    font-size: 12px;
    padding-bottom: 3px;
  }

  .single_radio_field {
    height: 38px;
  }

  .slg_stg1 .select2,
  .slg_stg1 .select2-selection,
  .slg_stg1 .select2-selection__rendered,
  .prsnl_dt .select2 {
    font-size: 13px !important;
  }

  .eligible_bottom {
    padding: 0px;
  }

  .bcbd_lg_btn {
    font-size: 13px;
  }

  .eligible_bottom .sl_div {
    width: calc(50% - 4px);
    padding: 0px;
    margin-right: 7px;
  }

  .eligible_bottom .sl_div:last-child {
    margin-right: 0;
  }

  .eligibility_wraper {
    padding: 25px 15px 32px 15px;
  }

  /*eligibility check*/
  .eligibility_tab ul li a,
  .eligibility_tab ul li a.active {
    padding: 0;
    padding-bottom: 15px;
  }

  .eligibility_tab > ul {
    width: 100%;
    padding-top: 13px;
    display: flex;
    margin: 0;
    flex-flow: row;
    justify-content: space-around;
  }

  .eligibility_tab ul li .svg {
    height: 28px;
  }

  .eligibility_tab_content h5 {
    font-size: 14px;
  }

  .eligibility_gender .check-mobile-size {
    height: 20px !important;
    width: 20px !important;
  }

  .eligibility_gender .sl_div .svg {
    height: 24px;
    padding-right: 4px;
  }

  .eligibility_gender label {
    margin-top: 3px;
    font-size: 15px;
  }

  .loan_cmn .select2,
  .loan_cmn .select2-selection,
  .loan_cmn .select2-selection__rendered,
  .job_cmn .select2,
  .job_cmn .select2-selection,
  .job_cmn .select2-selection__rendered,
  .eligibility_body .bcbd_control,
  .select2-selection__rendered,
  .prsnl_dt .select2,
  .prsnl_dt .select2-selection,
  .prsnl_dt .select2-selection__rendered,
  .eligibility_label.bcbd_group {
    font-size: 13px !important;
    line-height: 40px !important;
    height: 40px;
  }

  .loan_cmn .eligibility_body {
    padding-top: 12px;
  }

  .eligibility_tab_content.job_exp .eligibility_body {
    padding-top: 12px;
  }

  .prsnl_dt .select2-selection__arrow {
    top: 2px !important;
  }

  .eligibility_check_footer .bcbd_btn {
    font-size: 13px;
    line-height: 40px;
    height: 40px;
  }

  .eligibility_log_profile .svg {
    height: 30px;
  }

  .eligibility_log_user {
    height: 40px;
    width: 40px;
  }

  .eligibility_log_info h6 {
    font-size: 14px;
  }

  .eligibility_log_info p {
    font-size: 13px;
  }

  .eligibility_log_body ul li .log_title {
    font-size: 13px;
    width: 85px;
  }

  .eligibility_log_body ul li .log_content {
    font-size: 12px;
    width: calc(100% - 94px);
  }

  .eligibility_tab {
    display: flex;
    flex-flow: column;
  }

  .eligibility_tab .tab-content {
    width: calc(100% - 0px);
    padding: 15px;
  }

  .prsnl_dt .input-group {
    margin-bottom: 10px;
  }

  .eligibility_gender {
    margin-bottom: 12px;
    margin-top: 6px;
  }

  .job_cmn .select2-selection__arrow b::after {
    font-size: 14px !important;
  }

  .slg_stg1 .select2-selection__arrow b::after,
  .prsnl_dt .select2-selection__arrow b::after {
    font-size: 14px;
  }

  /*user guide*/
  .user_guide_contaienr {
    display: none;
  }

  /*megamenucode*/
  .bcbd_megamenu {
    position: static;
    box-shadow: none;
    opacity: 1;
    visibility: visible;
    transform: none;
    transition: none;
    transform-origin: top;
    width: 100%;
    display: none;
  }

  .bcbd_megamenu .bcbd_dropdown {
    background: transparent;
    visibility: visible;
    opacity: 1;
    position: static;
    transform: none;
    display: block !important;
    width: 100%;
  }

  .hs_dropdown.opendp a::after {
    content: '\f106';
    font-family: FontAwesome;
    font-size: 13px;
    position: absolute;
    top: 51%;
    transform: translateY(-50%);
    right: 0px;
    height: inherit;
    transition: all 0.3s linear;
  }

  /*--Mobile Menu Caret--*/
  .hs_dropdown.opendp a::after,
  .hs_dropdown a::after {
    right: 10px;
  }

  .bcbd_megamenu {
    background: transparent;
    box-shadow: none !important;
  }

  .bcbd_dropdown {
    padding-left: 0px;
  }

  /*comming soon*/
  .comming_soon_right {
    position: absolute;
    margin-top: -42%;
    opacity: 0.1;
  }

  /*
    .eligibility_process{
        overflow: auto;
          overscroll-behavior: contain;
    }
*/
  body {
    overscroll-behavior-y: none;
  }

  /*head option*/
  .loan_head_option {
    display: none !important;
  }

  .loan_table_head {
    padding-bottom: 15px;
  }

  .loan_grid_paren.single_loan_table {
    display: flex;
    flex-flow: column;
    padding-bottom: 20px;
  }

  .quick_dtl_btn {
    font-size: 12px;
  }

  .loan_grid_paren.single_loan_table .lon-grid {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
    border-left: none;
    border-right: none;
    display: block;
  }

  .loan_cont,
  .mobile_option {
    display: inline-block;
  }

  .mobile_option {
    width: 150px;
    font-size: 12px;
    text-align: left;
    padding-left: 15px;
  }

  .loan_grid_paren.single_loan_table .lon-grid {
    transition: all 0.2s ease-in;
    cursor: pointer;
  }

  .loan_grid_paren.single_loan_table .lon-grid:hover {
    background: #f2f2f2;
    transform: scale(1.02);
  }

  .loan_cont img {
    max-width: 100%;
    border-radius: 5px;
  }

  .loan_cont {
    width: calc(100% - 155px);
    vertical-align: middle;
  }

  .single_rt_table {
    border-bottom: 1px solid #ddd;
    margin-bottom: 30px;
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }

  .loan_grid_paren.single_loan_table .lon-grid {
    display: block;
  }

  .loan_grid_paren.single_loan_table div:nth-child(6) {
    border-bottom: none;
  }

  .loan_table_head .sl_lh_option {
    margin-bottom: 10px;
  }

  /*features options*/
  .features_option .sl_features_option a {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .sl_features_option {
    flex: 0 0 50%;
    text-align: center;
  }

  .sl_features_option {
    flex: 0 0 33%;
    text-align: center;
  }

  .features_option > div:nth-child(2) {
    display: none;
  }

  .features_option .sl_features_option {
    width: 33%;
  }

  .features_option .sl_features_option .svg {
    height: 22px;
    margin-bottom: 2px;
  }

  .sl_features_option p {
    font-size: 9px;
  }

  .features_option .sl_features_option:nth-child(2)::before {
    display: none;
  }

  .loan_scale h2 {
    padding-top: 25px;
    padding-bottom: 25px;
    font-size: 18px;
    line-height: 24px;
  }

  .loan_btn.loan_cont {
    width: 100% !important;
  }

  .rt_table_btn {
    font-size: 10px;
    padding: 8px 0px;
    border-radius: 3px;
    width: 133px;
  }

  .rate_table_details {
    border-left: 0;
    border-right: 0;
  }

  .single_rt_dt ul {
    padding-top: 0px;
    padding-left: 10px;
    padding-bottom: 18px;
  }

  .single_rt_dt ul li {
    font-size: 13px;
    line-height: 24px;
  }

  /*mobile scale*/
  .slider_range_bottom .sl_div:first-child,
  .slider_range_bottom .sl_div:nth-child(2) {
    width: 100%;
    margin-right: 0;
  }

  .slider_range_bottom .sl_div:last-child {
    width: 100%;
    text-align: center;
  }

  .bcbd_compare_btn {
    font-size: 12px;
    line-height: 0;
    padding: 18px 20px;
  }

  .slider_range_bottom .sl_div:first-child,
  .slider_range_bottom .sl_div:nth-child(2) {
    width: 100%;
    margin-right: 0;
  }

  .slider_range_bottom .bcbd_compare_btn {
    margin-top: 15px;
  }

  .slider_range_bottom .input-group-prepend span {
    line-height: 40px;
    height: 40px;
    width: 50px;
    font-size: 18px;
  }

  .loan_cmn .select2,
  .loan_cmn .select2-selection,
  .loan_cmn .select2-selection__rendered,
  .job_cmn .select2,
  .job_cmn .select2-selection,
  .job_cmn .select2-selection__rendered,
  .eligibility_body .bcbd_control,
  .select2-selection__rendered,
  .prsnl_dt .select2,
  .prsnl_dt .select2-selection,
  .prsnl_dt .select2-selection__rendered,
  .eligibility_label.bcbd_group {
    font-size: 15px !important;
    line-height: 35px !important;
    height: 35px;
  }

  .sl_lh_option
    .input-group
    .form-control
    span.select2-selection.select2-selection--single,
  .slider_range_bottom
    .input-group
    .form-control
    span.select2-selection.select2-selection--single {
    height: 38px;
    line-height: 38px;
  }

  .sl_lh_option .input-group-prepend span {
    line-height: 45px;
    padding: 0;
    width: 50px;
    font-size: 20px;
  }

  .dps_banner h2 {
    font-size: 18px;
    padding-bottom: 40px;
  }

  .dps_banner {
    padding-top: 110px;
    padding-bottom: 70px;
  }

  .dps_tbale_area h2 {
    font-size: 16px;
    padding-top: 30px;
    padding-bottom: 25px;
    line-height: 30px;
  }

  .dps_table_head {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .bcbd_ac_title img {
    display: inline-block;
    padding-right: 5px;
  }

  .eligibility_ft_slider_parent {
    padding: 20px 10px 10px 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }

  /*-------------------------------
        ==Start Modal==
    -----------------------------*/
  .apply_modal_inner .modal_body {
    display: block;
  }

  .modal_left {
    width: 100%;
    float: none;
    border-right: 1px solid #ddd;
    padding-bottom: 0;
    height: 100%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: flex-start;
  }

  .apply_option h4 {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
    color: #111;
  }

  .apply_modal_inner .modal_body .bank_name img {
    height: 75px;
    width: 117px;
  }

  .modal_right {
    width: 100%;
    float: none;
    padding-top: 0;
    padding-bottom: 0;
  }

  .bcbd_modal_lg {
    max-width: 500px;
  }

  .modal_right {
    display: none;
  }

  .apl_chrage_cont ul > li h5 {
    font-size: 13px;
  }

  .apl_chrage_cont .aply_output_body li h6 {
    font-size: 14px;
    padding-top: 5px;
  }

  .apl_chrage_cont .aply_output_body li {
    padding: 9px 15px;
  }

  .loan_bank_option_output ul li {
    padding: 14px 0px;
  }

  .loan_bank_option_output ul li h5 {
    font-size: 13px;
    line-height: 30px;
  }

  .loan_bank_option_output ul li h4 {
    font-size: 15px;
  }

  .apl_chrage_cont .aply_output_body {
    height: 165px;
  }

  .aply_totl_return_amount ul li {
    padding-bottom: 6px;
  }

  .aply_totl_return_amount ul li h5 {
    font-size: 14px;
    width: calc(100% - 80px);
  }

  .aply_totl_return_amount ul li h6 {
    font-size: 13px;
    width: 70px;
  }

  .aply_totl_return_amount .apply_loan_total {
    padding: 8px 0;
  }

  .aply_option_parent {
    overflow: hidden;
  }

  .bcbd_modal_lg .modal_header {
    padding: 15px 20px;
  }

  .apply_modal_inner .modal_body .bank_name {
    padding: 16px 0;
  }

  .single_option .bcbd_label {
    font-size: 18px;
    margin-bottom: 5px;
    color: #111;
  }

  .bcbd_inputgrup .input-group-prepend span {
    padding: 0;
    line-height: 37px;
    height: 37px;
    width: 50px;
  }

  .bcbd_inputgrup
    .form-control
    span.select2-selection.select2-selection--single,
  .bcbd_inputgrup
    .form-control
    span.select2-selection.select2-selection--single {
    border-color: transparent;
    height: 35px;
    line-height: 45px;
  }

  .apply_option {
    padding-bottom: 20px;
    width: 100%;
  }

  .apply_modal1.modal.show .modal-dialog {
    transform: translate(0px, -50%) !important;
    top: 50% !important;
    /*        left: 50% !important;*/
  }

  .modal-dialog {
    margin-left: 20px;
    margin-right: 20px;
  }

  #aplyNextDet,
  .apply_modal_btn {
    width: 100%;
    font-size: 18px;
    padding: 13px 0;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .single_option {
    padding: 0 20px;
    margin-bottom: 20px;
  }

  .bcbd_inputgrup .input-group-prepend span {
    padding: 0;
    line-height: 37px;
    height: 37px;
    width: 50px;
    font-size: 16px;
  }

  .bcbd_modal_lg {
    max-width: 500px;
  }

  .aply_totl_return_amount .apply_loan_total h5 {
    line-height: 27px;
    font-size: 14px;
  }

  .aply_totl_return_amount .apply_loan_total h6 {
    font-size: 14px;
    line-height: 14px;
  }

  .modal_body {
    height: 480px;
    background: #fff;
  }

  #aplyChgBack {
    padding-top: 19px;
  }

  .modal_right {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    height: 100%;
  }

  .apply_output_fields {
    height: 100%;
  }

  .apply_output_filds_inner {
    height: 100%;
    align-items: center;
    justify-content: flex-start;
  }

  .loan_bank_option_output ul li::after {
    top: 55%;
  }

  .bcbd_modal_lg {
    background: transparent;
  }

  .modal_header .close {
    margin-right: -10px;
    margin-top: -10px;
  }

  .modal_header .close span {
    display: inline-block;
    transition: all 0.3s linear;
  }

  .modal_header .close span:hover {
    transform: scale(1.3);
  }

  .apply_option .bcbd_inputgrup .input-group-prepend span {
    padding: 0;
    line-height: 50px;
    height: 50px;
    width: 50px;
    font-size: 24px;
  }

  .single_option .select2-selection__rendered {
    font-size: 18px !important;
    line-height: 45px !important;
    height: 45px;
    color: #333;
  }

  .single_option .bcbd_inputgrup input {
    color: #111;
    font-size: 18px;
  }

  .bcbd_inputgrup
    .select2-container--default
    .select2-selection--single
    .select2-selection__rendered,
  .bcbd_inputgrup
    .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    color: #111;
    font-size: 15px;
  }

  .bcbd_inputgrup input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #111;
  }

  .bcbd_inputgrup input::-moz-placeholder {
    /* Firefox 19+ */
    color: #111;
  }

  .bcbd_inputgrup input:-ms-input-placeholder {
    /* IE 10+ */
    color: #111;
  }

  .bcbd_inputgrup input:-moz-placeholder {
    /* Firefox 18- */
    color: #111;
  }

  .apply_output_fields .input-group .form-control {
    line-height: 50px;
    height: 50px;
    border-color: #777;
  }

  .apply_output_filds_inner .select2-container {
    border: 1px solid #777;
    color: #777;
  }

  .apply_output_filds_inner .bcbd_group .hasDatepicker {
    border: 1px solid #777;
    border-radius: 5px;
    color: #777;
  }

  .apply_output_filds_inner input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #111;
  }

  .apply_output_filds_inner input::-moz-placeholder {
    /* Firefox 19+ */
    color: #111;
  }

  .apply_output_filds_inner input:-ms-input-placeholder {
    /* IE 10+ */
    color: #111;
  }

  .apply_output_filds_inner input:-moz-placeholder {
    /* Firefox 18- */
    color: #111;
  }

  /*credit card compare*/
  .require_doc_body > ul {
    flex-flow: column;
  }

  .require_doc_body > ul > li {
    flex: 0 0 100%;
    padding: 15px;
    padding-bottom: 0;
  }

  .success_info_bnr_inr p {
    font-size: 15px;
    text-align: center;
  }

  .cccard_name.only_mob_tab {
    display: block;
  }

  .table_adremove {
    left: 14px;
    top: 8px;
  }

  .side_stick {
    display: block;
  }

  .mobile_option,
  .loan_grid_paren.single_loan_table .lon-grid p {
    font-size: 13px;
  }

  .slg_stg1 .select2-selection__arrow,
  .prsnl_dt .select2-selection__arrow {
    width: 24px !important;
    height: 32px !important;
  }

  .eligibility_entry_log {
    display: none;
  }

  /* ----------------------------
        ==Fixed Deposit
    ---------------------------- */
  .fixed-dps-banner-inner h2 {
    font-size: 17px;
    text-align: center;
    line-height: 26px;
  }

  .fd-table-grid.single_loan_table div:nth-child(6) {
    border-bottom: 1px solid #ddd;
  }

  .fd-table-grid.single_loan_table .mobile_option {
    height: 100%;
  }

  .fd-table-grid.single_loan_table .lon-grid {
    display: flex;
    flex-flow: row;
  }

  .fd-table-grid {
    padding-bottom: 0 !important;
    border-bottom: 0 !important;
  }

  .last-checked,
  .user-rating {
    text-align: left;
  }

  .fixed-deposit-range-slider .sl_div {
    width: 100% !important;
    flex: 0 0 100%;
  }

  .fixed-deposit-range-slider .sl_div a {
    margin-top: 0;
  }

  .fixed-deposit-range-slider .range_slider_top {
    display: none !important;
  }

  .fixed-deposit-range-slider {
    padding: 20px 20px 20px 20px;
  }
}

/*--------------------------------------
    ==Mobile Screen==
--------------------------------------*/

@media (min-width: 576px) and (max-width: 767px) {
  .banner_tab ul li {
    font-size: 11px;
  }

  .banner_tab
    .react-tabs__tab-panel.react-tabs__tab-panel--selected
    .sl_tb_block {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 11px;
  }

  .sl_tb_block {
    font-size: 11px;
  }

  .sl_tb_block a img {
    height: 23px;
    padding-bottom: 0;
  }

  .bcbd_navbar .navbar-brand {
    width: 30%;
  }

  /*statistics*/
  .single_statistics {
    font-size: 15px;
    border-bottom: none;
    padding-top: 10px;
  }

  .single_statistics h5 {
    font-size: 12px;
    text-align: left;
  }

  .our_statistics .row div:before {
    top: 9px;
  }

  .our_statistics .row div {
    padding-top: 7px;
  }

  .call_to_field input {
    width: calc(100% - 95px);
  }

  /*.features-carousel .slick-list{*/
  /*height: 364px !important;*/
  /*}*/
}

@media (max-width: 575px) {
  .bcbd_select {
    line-height: 35px;
    height: 35px !important;
    border-radius: 5px;
    font-size: 13px;
  }

  .sl_lh_option .input-group-prepend span {
    line-height: 40px;
    height: 40px;
  }

  .sl_tb_block {
    font-size: 13px;
  }

  .sl_div_parents {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    flex: 0 0 50%;
    flex-wrap: wrap;
  }

  .sl_div_parents > div:last-child {
    width: 100% !important;
    text-align: center !important;
    padding-top: 20px;
  }

  .sl_tb_block a {
    padding-top: 24px;
    padding-bottom: 24px;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .sl_tb_block a img {
    height: inherit;
    width: 25px;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }

  .bn_accordion_inner > div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 8px;
    padding-bottom: 8px;
    flex-flow: column;
  }

  .sl_tb_block a {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .tab_inner {
    overflow: hidden;
    display: block;
  }

  .sl_tb_block a img {
    padding-bottom: 0px;
  }

  .tab_inner.ccol-2 .sl_tb_block:nth-child(2)::after {
    display: none;
  }

  .tab_inner.ccol-5 .sl_tb_block {
    width: 50%;
  }

  .tab_inner.ccol-5 .sl_tb_block:nth-child(5)::after,
  .tab_inner.ccol-5 .sl_tb_block:nth-child(4)::after,
  .tab_inner.ccol-5 .sl_tb_block:nth-child(2)::after {
    display: none;
  }

  .tab_inner.ccol-5 .sl_tb_block:nth-child(5) {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .tab_inner.ccol-4 .sl_tb_block:nth-child(4)::after,
  .tab_inner.ccol-4 .sl_tb_block:nth-child(2)::after {
    display: none;
  }

  .banner_inner_area {
    height: 100%;
    padding-top: 85px;
    padding-bottom: 94px;
  }

  /*comming soon*/
  .coming_soon_content {
    align-items: center;
    justify-content: center;
    z-index: 9;
    text-align: center;
  }

  .coming_soon_content p {
    font-size: 13px;
    padding-top: 10px;
    padding-bottom: 19px;
  }

  .coming_soon_content h5 {
    font-size: 22px;
  }

  /*compnay Features*/
  .single_el_ft_item h6 {
    font-size: 13px;
  }

  .single_el_ft_item p {
    font-size: 12px;
  }

  .blog_image {
    height: 130px;
  }

  /*Range slider Top*/
  .range_slider_top {
    display: none !important;
  }

  .loan_btn.loan_cont {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }

  .rt_table_btn {
    font-size: 11px;
    border-radius: 3px;
    width: calc(100% - 50px);
  }

  .apply_modal_inner .single_option .bcbd_inputgrup input {
    padding-left: 15px;
    font-size: 14px;
    line-height: 38px;
    height: 45px;
  }

  .apply_modal_inner .select_parent.form-control .bcbd_select {
    height: 38px !important;
  }

  .apply_option .bcbd_inputgrup .input-group-prepend span {
    line-height: 50px;
    height: 40px;
    width: 40px;
    font-size: 18px;
  }

  .apply_modal_inner .single_option .bcbd_inputgrup input {
    font-size: 14px;
    line-height: 38px;
    height: 50px;
  }

  .single_option .bcbd_label {
    font-size: 13px;
  }

  .apply_modal_inner .modal_body .bank_name {
    padding: 5px 0;
  }
}

/*640px height media query "Rakib"*/
@media screen and (max-height: 640px) {
  .eligibility-body {
    height: 300px !important;
  }
  .eligibility-gender-responsive .sl_div {
    padding: 12px 10px !important;
  }
  .new-eligibility-area.eligibility-type-2 {
    height: 100% !important;
    max-height: none;
  }
  .eligibility-inner {
    padding: 2px 10px 6px !important;
  }
  .eligibility-inner .row .col-md-8 .eligibility-footer {
    padding-top: 0 !important;
    margin-bottom: 5px;
  }
  .eligibility-inner .eligibility-step {
    margin-bottom: 0 !important;
  }
  .eligibility-body .my-personal-gutter {
    height: 56px;
  }
  .eligibility-body .eligibility_gender {
    margin-bottom: 7px !important;
  }
  .eligibility-type-2 .form-group {
    margin-bottom: 3px !important;
  }
  /*Eligibility Form Submission*/
  #Fsubmision .eligibility_body > div > p {
    font-size: 14px;
  }

  #Fsubmision .eligibility_body > div.rounded {
    padding-top: 6px !important;
    padding-bottom: 9px !important;
  }

  #Fsubmision .eligibility_body {
    padding-top: 0 !important;
  }
}

@media screen and (max-width: 1500px) {
  .eligibility-body .css-1g6gooi {
    margin-top: -10px !important;
  }
}

.eligibility-inner {
  background: #effaff;
}
/**/
.sidebarOpen .credit-card-sidebar {
  /*position: initial;*/
  position: relative;
  z-index: 99;
  left: 0px;
  max-width: 340px;
  min-width: 340px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  margin-right: 15px !important;
}

.sidebarOpen .table-area {
  max-width: calc(100% - 0%);
  transition: all 0.3s linear;
}

.sidebarHide .credit-card-sidebar {
  margin-left: calc(-20% + -17px);
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.sidebarHide .table-area {
  max-width: calc(100% - 0%);
  transition: all 0.3s linear;
}

/*Responsive Class for Sidebar*/
@media screen and (min-width: 992px) and (max-width: 1400px) {
  .separateBg {
    background-color: #e7f2f7 !important;
    padding: 2px 2px;
    margin-bottom: 5px;
    border-radius: 0.25rem !important;
  }

  .sidebarOpen .credit-card-sidebar {
    max-width: 20%;
    min-width: 20%;
  }
  .filter-title {
    font-size: 15px;
  }
  .filter-amount {
    font-size: 13px;
  }
  .filter-title {
    font-size: 14px;
    margin-right: 25px;
  }
  .filter-amount {
    margin-right: 25px;
  }
  .filter-amount .filter-amount-digit {
    padding: 5px 20px;
    width: 130px;
    margin-left: 10px;
  }
  .filter-duration-dropdown .new-select2-style .css-bg1rzq-control {
    height: 45px;
  }
  .new-select2-style .css-1szy77t-control {
    height: 50px;
  }
  .filter-duration-dropdown .css-1hwfws3 {
    height: 39px;
  }
  .loan-rate-table-search .new-select2-style .css-bg1rzq-control {
    height: 40px;
    font-size: 13px;
  }
  .loan-rate-table-search button {
    padding: 0px 15px;
  }
  .loan-rate-table-search i {
    font-size: 15px;
    line-height: 40px;
  }
  .credit-card-sidebar .cancel-class {
    font-size: 15px;
    height: 20px;
    width: 20px;
    line-height: 17px;
  }
  .css-1hwfws3 {
    height: 41px;
  }
  .dps-right-options {
    max-width: 270px;
    min-height: 50px;
  }
  .loan-rate-table-search .new-select2-style .css-151xaom-placeholder {
    color: #555;
    font-family: 'Exo', sans-serif;
    font-weight: 700;
    color: #2b2b2b;
    font-size: 13px;
  }
  .new-select2-style .css-dpec0i-option {
    font-size: 14px;
    line-height: 22px;
  }
  .new-select2-style .css-1szy77t-control {
    height: 50px;
  }
  .loan-rate-table-search .new-select2-style .css-bg1rzq-control {
    height: 38px;
    font-size: 13px;
  }
  aside.credit-card-sidebar p {
    font-size: 14px !important;
  }
}
@media (max-height: 650px) {
  .eligibility-body {
    height: 300px !important;
  }
  .eligibility_gender label {
    margin: 0;
  }
  .new-select2-style .css-bg1rzq-control,
  .new-select2-style .css-1szy77t-control {
    height: 50px;
  }
  .eligibility-type-2 .form-group {
    margin-bottom: 5px !important;
  }
  .eligibility-inner {
    padding-bottom: 5px !important;
  }
  .eligibility-type-2 .eligibility_gender .check {
    margin-right: 5px !important;
  }
  .sharePortion {
    padding: 11px 0 !important;
  }
  .credit_card_details_modal .aply_loan_inner {
    height: 250px;
  }
  .mobile-padding-gender {
    padding: 13px 7px 13px 10px !important;
  }
}
@media screen and (max-width: 1000px) {
  .filter-duration-text,
  .gridLoanDuration {
    font-size: 12px !important;
  }
}
.css-1g6gooi input {
  height: 30px !important;
  padding: 0px !important;
}
@media screen and (max-width: 1300px) {
  .personalLoanRadio .single_radio_field .check {
    height: 20px;
    width: 20px;
    border: 2px solid #00bdf2;
    border-radius: 50%;
    position: absolute;
    top: 14px;
    left: 8px;
  }
}
@media screen and (max-width: 1490px) {
  .aply_loan_inner .input-group.bcbd_check span {
    font-size: 12px;
  }
  .deposit-module .deposit-module-inner {
    width: 65%;
    height: 75%;
  }
  .deposit-module .top-text {
    font-size: 21px;
  }
  .fa-frown-open::after {
    left: 49.29%;
  }
  .eligibility_gender .check::before {
    content: '';
    position: absolute;
    left: 5.5px !important;
    top: 5.5px !important;
    height: 10px !important;
    width: 10px !important;
  }
}
@media (max-width: 1920px) and (min-width: 1500px) {
  .styles_modal__gNwvD {
    max-width: 700px;
    width: 700px;
  }

  
  .styles_modal__gNwvD.iframe-modal {
    max-width: 900px;
    width: 900px;
    padding: 7px 0 !important;
  }
}
@media (max-width: 1200px) {
  .lineHeightFix,
  .eligibility-type-2 .lineHeightFix {
    line-height: 23px !important;
  }
}

@media (max-width: 450px) and (max-height: 850px) {
  .nav-link1 svg {
    height: 65px;
  }
}
@media (max-width: 390px) and (max-height: 850px) {
  .nav-link1 svg {
    height: 60px;
  }
}
@media (max-width: 1700px) {
  .m-11 {
    margin: 12rm;
  }
}
@media (max-height: 850px) and (min-height: 800px) {
  .frontCard {
    grid-template-rows: 48% 52%;
  }
}
@media (max-height: 570px) {
  /*.banner-home{*/
  /*    grid-template-rows: 38% 73%;*/
  /*}*/
  .p-top12 {
    padding: 11px 0px 8px 0px !important;
  }
}
@media (max-height: 1190px) {
  .single-dps-table {
    height: auto !important;
  }
  .responsive_padding {
    padding: 0 !important;
  }
}
@media (max-width: 480px) {
  .eligibility-label {
    padding-left: 5px;
    padding-bottom: 5px;
  }
  .mobile-application-area.application-area::before {
    height: 400px !important;
  }
}
@media (max-width: 330px) {
  .eligibilityHeader {
    background: #dbf9ff;
    padding: 0 2px !important;
  }
}
@media (max-height: 550px) {
  .p-top12 {
    padding: 8px 0px 8px 0px !important;
  }
}
@media (max-height: 570px) {
  .mt--35px {
    margin-top: 10px;
  }
  .mt--40px {
    margin-top: 10px;
  }
}
@media (max-height: 593px) {
  .mt--35px {
    margin-top: 20px;
  }
  .mt--40px {
    margin-top: 20px;
  }
}
@media (max-height: 535px) {
  .mt--35px {
    margin-top: 30px;
  }
  .mt--40px {
    margin-top: 30px;
  }
}
@media (max-height: 477px) {
  .banner-home {
    grid-template-rows: 38% 52%;
  }
}
/*========================================================================*/
/* !*to handle all hovers in mobile view *!*/
/*========================================================================*/
@media (pointer: coarse) {
  .eligibility_tab_content.housetcheck
    .eligibility_body
    .sl_div:hover
    .sl_inner_layer {
    background: none;
  }
  .eligibility_tab_content.housetcheck .eligibility_body .sl_div:hover span {
    color: inherit !important;
  }

  .trend-header-item:hover {
    background: #fff;
    color: #434343;
  }
  .trends-section-side .details-link:hover {
    border-bottom: none;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
}

@media (min-width: 320px) and (max-width: 480px) {
  .sheduleSlider .slick-slide {
    padding: 0px !important;
  }
  .applynow-date-mobile .sheduleSlider .slick-arrow.slick-prev {
    left: -30px;
  }
  .applynow-date-mobile .sheduleSlider .slick-arrow.slick-next {
    right: -30px;
  }
  .trend-header {
    background: white;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .trendSlider {
    width: 100%;
  }
  .trends-data-wrapper {
    padding-top: 0px;
    background: #ffffff;
    height: 100vh;
    padding-bottom: 4rem !important;
  }

  .trend-header-item {
    font-size: 14px;
    cursor: pointer;
    background: transparent;
    color: #434343;
    font-weight: 400;
    text-align: center;
    transition: all 300ms ease-in-out;
    padding: 5px;
  }
  .trends-sections-grid-mobile {
    background: #fff;
    display: grid;
    grid-template-columns: 30% 70%;
    justify-content: center;
    align-items: end;
    width: 100%;
    padding-top: 12px !important;
  }
  .trends-section-image {
    padding: 0px 5px;
  }
  .trends-section-main {
    height: 70%;
    padding-top: 0px;
  }
  .trends-section-main h4 {
    font-weight: 600;
    font-size: 15px;
    color: #0d435d;
  }
  .trends-section-main p {
    font-size: 14px;
    color: #343a40;
  }
  .trends-section-end-mobile {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: flex-end;
  }
  .trends-section-end-mobile .details-link {
    width: 100%;
    margin-left: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    color: #08bbf1;
    font-weight: 400;
    transition: all 300ms ease-in-out;
  }

  .grid-60-40 {
    display: grid;
    grid-template-columns: 60% 40%;
    justify-content: center;
    align-items: end;
    padding-bottom: 15px;
  }
  .grid-60-40 .details-link {
    margin-left: 15px;
    cursor: pointer;
    font-size: 13px;
    color: #08bbf1 !important;
    font-weight: 600;
    transition: all 300ms ease-in-out;
    animation: animate-glow 1s infinite;
  }
  .grid-60-40 .details-link i {
    margin-left: 5px;
  }

  .trends-section-end-mobile .details-link.isactive {
    color: #dee2e6;
  }
  .trends-section-end-mobile .details-link i {
    margin-left: 5px;
  }
  .trends-section-end-mobile .badge {
    text-transform: uppercase;
    border-radius: none !important;
    position: absolute;
    top: -67px;
    left: -26px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 11px;
    background: #f4ca1e;
  }
  .trends-slide-down-mobile {
    height: 0px;
    opacity: 0;
    transition: all 400ms ease-in;
  }

  .trends-slide-down-mobile.isactive {
    opacity: 1;
    background: #fff;
    width: 100%;
    height: auto;
    margin-top: 20px;
    position: relative;
    display: flex;
    -ms-flex-pack: start;
    justify-content: start;
    -ms-flex-align: center;
    align-items: center;
    padding: 5px;
    border-top: 1px dashed #dadada;
  }
  .trend-lists ul li {
    margin-left: 35px;
    color: #343a40;
    font-size: 13px;
    margin-bottom: 5px;
  }
  .trend-lists ul li::before {
    content: '';
    width: 20px;
    height: 6px;
    position: absolute;
    left: -30px;
    margin-top: 5px;
    z-index: 1;
    background: #50c6f1 !important;
    border-radius: 25px;
  }
  .trends-list-end {
    display: grid;
    grid-template-rows: 50% 50%;
    height: 100%;
    justify-content: start;
    align-items: center;
    grid-gap: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .trends-list-end .req-link {
    width: 100%;
    cursor: pointer;
    color: #08bbf1;
    font-weight: 600;
    transition: all 300ms ease-in-out;
    border: 1px solid #08bbf1;
    padding: 5px;
    font-size: 12px;
    text-align: center;
  }

  .trends-list-end .badge {
    padding-bottom: 10px;
    padding-top: 10px;
    font-size: 12px;
    text-overflow: ellipsis;
  }
}

@media (min-width: 1281px) {
  .changeModal {
    width: 75% !important;
    max-width: 75% !important;
  }
  .modalCommonRules {
    max-width: 65%;
    width: 75%;
  }
  .single_radio_field .check::before {
    content: '';
    position: absolute;
    left: 25%;
    height: 10px;
    width: 10px;
    background: #00bdf2;
    border-radius: 50%;
    top: 28%;
    /*transform: translate(-50%, -50%) scale(2);*/
    visibility: hidden;
    opacity: 0;
    transition: 0.3s linear;
  }
  .single_radio_field label {
    font-weight: 400;
    color: #555;
    margin-bottom: 0;
    line-height: 50px;
    font-size: 15px;
    cursor: pointer;
  }
  .tick-icon {
    font-size: 17px !important;
    top: -2px !important;
    left: 2px !important;
  }
  .subscribe-mail img {
    width: 20%;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .changeModal {
    width: 90% !important;
    max-width: 90% !important;
  }
  .modalCommonRules {
    max-width: 75% !important;
    width: 75% !important;
  }
  .single_radio_field .check::before {
    content: '';
    position: absolute;
    left: 20%;
    height: 10px;
    width: 10px;
    background: #00bdf2;
    border-radius: 50%;
    top: 20%;
    /*transform: translate(-50%, -50%) scale(2);*/
    visibility: hidden;
    opacity: 0;
    transition: 0.3s linear;
  }
  .single_radio_field label {
    padding-left: 30px;
    line-height: 50px;
  }
  .tick-icon {
    font-size: 17px !important;
    top: -2px !important;
    left: 2px !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .changeModal {
    width: 98% !important;
    max-width: 98% !important;
  }
  .modalCommonRules {
    max-width: 100% !important;
    width: 97% !important;
  }
  .single_radio_field .check {
    top: 30% !important;
    left: 7% !important;
  }
  .single_radio_field .check::before {
    content: '';
    position: absolute;
    left: 20%;
    height: 10px;
    width: 10px;
    background: #00bdf2;
    border-radius: 50%;
    top: 20%;
    /*transform: translate(-50%, -50%) scale(2);*/
    visibility: hidden;
    opacity: 0;
    transition: 0.3s linear;
  }
  .single_radio_field label {
    padding-left: 28px;
    line-height: 50px;
    font-size: 13px;
  }

  .tick-icon {
    font-size: 17px !important;
    top: -2px !important;
    left: 2px !important;
  }
  .time-main p {
    font-size: 22px;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .btn {
    font-size: 14px !important;
  }
  .backHeight {
    top: 20px;
    left: 20px;
  }
  .h-600px {
    height: 500px !important;
  }
  .track-form-area {
    padding: 30px 30px;
  }
  .styles_modal__gNwvD {
    width: 100% !important;
  }
}
@media (min-width: 300px) and (max-width: 410px) {
  .loan-choice-button {
    min-width: 110px;
    width: 110px;
  }
}

@media (max-height: 555px) {
  .deposit-module .deposit-module-inner {
    height: 85%;
  }
}
@media (max-height: 640px) {
  .success-page-modal {
    height: 33vmax;
  }
}
@media only screen and (max-width: 600px) {
  .eligibility-body {
    height: 300px !important;
  }
  .fa-frown-open::after {
    left: 48.5%;
  }
  .display-grid-mobile-submit {
    grid-template-columns: 35% 65%;
  }
  .eligibility_tab_content .height-210 {
    height: 375px;
  }
  .eligibility-step .single-step {
    margin-right: calc(24% + 16px) !important;
  }
  .step-circle::before {
    width: 160px !important;
  }
  .eligibility_gender .sl_div {
    padding: 12px 7px 12px 10px !important;
  }
  .eligibility-footer .btn {
    border-radius: 5px;
    width: 50%;
  }
  .d-grid-2 {
    display: grid !important;
    grid-template-columns: 35% 65%;
    justify-items: flex-end;
    text-align: right;
    align-items: center;
  }
  .eligibility-footer .btn::before {
    border-radius: 0;
  }
  .eligibility-footer .btn:nth-child(1) {
    background: #00add9 !important;
    color: white;
    border-color: transparent;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .eligibility-footer .btn:nth-child(2) {
    background: #00add9 !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .single-step .step-label {
    font-size: 11px !important;
    color: #ffffff !important;
  }
  .single-step.active .step-circle::after {
    width: 160px !important;
  }
  /*input{*/
  /*    height: 50px;*/
  /*}*/
  ::placeholder {
    color: #555;
  }
  .margin-bottom-100 {
    margin-bottom: 80px !important;
  }
  body {
    background: #f5f5f5;
  }
  .height-210 {
    height: 210px;
  }
  .rental_check .eligibility_body .sl_div {
    width: calc(52% - 13px);
  }
  .eligibility-footer .btn::before {
    background: none !important;
  }
  .switch .rounded-left {
    /*width: 50% !important;*/
  }
  .check-web-sq-nr {
    width: 115px;
    height: 120px;
  }
  span.fz-16 {
    width: 100% !important;
  }
  .mobileEligibilityBody {
    border-radius: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .eligibilityHeader {
    background: #dbf9ff;
    padding: 0 15px;
  }
  .step-circle::after {
    background: #46d4f8;
  }
  .step-circle::before {
    background: #ffffff;
  }
  .step-circle {
    border: 2px solid #46d4f8 !important;
  }
  .yourDetails {
    text-align: center;
    background: #00add9;
    border: 2px dotted #ffffff;
    padding: 8px 0;
    font-size: 12px;
    color: #ffffff;
  }
  .loggerComponent {
    position: fixed;
    width: 100%;
    height: 0;
    overflow: hidden;
    z-index: 55;
    transition: 0.5s;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
  .topComponent {
    position: relative;
  }
  .right-log {
    border-radius: 0 !important;
  }
  .switch input[type='radio']:checked ~ span {
    width: 100% !important;
  }
}
@media (max-width: 450px) {
  .calcHeight {
    height: calc(100% - 20px) !important;
  }
  .support-button:hover {
    box-shadow: none;
    -webkit-animation: none;
    -o-animation: none;
    animation: none;
  }
  .ui-ucb-tooltip::before {
    width: 215px;
  }
  .deposit-module .deposit-module-inner {
    width: 90%;
    height: 500px;
  }
  .styles_modal__gNwvD {
    max-width: 100%;
    width: 100%;
    /* max-height: 550px; */
  }
  .track-form-area {
    padding: 30px 12px !important;
  }
  .application-sumary-area h2 {
    font-size: 18px !important;
  }
  .alert-message img,
  .warning-message img {
    margin-right: 10px !important;
    height: 30px !important;
  }
  .aply_option_parent {
    overflow-x: hidden;
  }
  .custom-checkbox label {
    color: white !important;
  }
  .mobile-compare-head a {
    font-size: 13px;
    padding: 8px 12px;
    font-weight: 400;
    line-height: 1;
    width: 125px;
    margin: auto;
  }
  .success-button-final {
    width: 20vmax;
    height: 7vmax;
  }
  .trendSlider .slick-slide {
    padding: 0px 5px !important;
  }
  .d-grid-2 {
    display: grid !important;
    grid-template-columns: 35% 65%;
    justify-items: flex-end;
    text-align: right;
    align-items: center;
  }
  .application_section_btn1 {
    height: 41px;
    width: 110px;
    color: #1e6b9c;
    font-size: 16px;
    font-weight: 300;
    background: #ffffff;
    box-shadow: 0 0 5px #c3e8ff;
  }
  .mb-18p {
    -o-margin-bottom: 14%;
  }
  .fa-frown-open::after {
    left: 47.5%;
  }
  .check-web-sq-nr {
    width: 100px;
    height: 105px;
    border: 2px solid #0095c6;
    border-radius: 20px;
    box-shadow: 0 0 8px #0095c6;
    animation: bounceInGlow 0.8s linear;
  }
  .eligibility_gender .check-mobile-size::before {
    content: '';
    position: absolute;
    left: 4px !important;
    top: 4px !important;
    height: 8px !important;
    width: 8px !important;
  }

  @keyframes bounceInGlow {
    0% {
      opacity: 0;
      -webkit-transform: scale3d(0.97, 0.97, 0.97);
      transform: scale3d(0.97, 0.97, 0.97);
      box-shadow: 0 0 8px #0095c6;
    }

    20% {
      -webkit-transform: scale3d(1.01, 1.01, 1.01);
      transform: scale3d(1.03, 1.03, 1.03);
      box-shadow: 0 0 12px #0095c6;
    }

    40% {
      -webkit-transform: scale3d(0.97, 0.97, 0.97);
      transform: scale3d(0.97, 0.97, 0.97);
      box-shadow: 0 0 14px #0095c6;
    }

    60% {
      opacity: 1;
      -webkit-transform: scale3d(1.01, 1.01, 1.01);
      transform: scale3d(1.03, 1.03, 1.03);
      box-shadow: 0 0 14px #0095c6;
    }

    80% {
      -webkit-transform: scale3d(0.98, 0.98, 0.98);
      transform: scale3d(0.98, 0.98, 0.98);
      box-shadow: 0 0 12px #0095c6;
    }

    100% {
      opacity: 1;
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
      box-shadow: 0 0 8px #0095c6;
    }
  }
}
@media (max-width: 1400px) {
  .scroll_top {
    height: 45px;
    width: 45px;
  }
}
