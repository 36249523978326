.input-range__slider {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #0CA0C5;
  /*border: 1px solid #3f51b5;*/
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 1.2rem;
  margin-left: -0.5rem;
  margin-top: -0.9rem;
  -webkit-transform: scale(1.6);
  -moz-transform: scale(1.6);
  -ms-transform: scale(1.6);
  -o-transform: scale(1.6);
  transform: scale(1.6);
  outline: none;
  position: absolute;
  top: 45%;
  -webkit-transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
  transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
  width: 1.2rem;
}
.input-range__slider:active {
  -webkit-transform: scale(1.3);
  transform: scale(1.3); }
.input-range__slider:focus {
  box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2); }
.input-range--disabled .input-range__slider {
  background: #cccccc;
  border: 1px solid #cccccc;
  box-shadow: none;
  -webkit-transform: none;
  transform: none; }

.input-range__slider-container {
  -webkit-transition: none;
  transition: none;
}

.input-range__label {
  color: #aaaaaa;
  font-family: "Helvetica Neue", san-serif;
  font-size: 0.8rem;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  white-space: nowrap; }

.input-range__label--min,
.input-range__label--max {
  bottom: -1.4rem;
  position: absolute; }

.input-range__label--min {
  left: 0; }

.input-range__label--max {
  right: 0; }

.input-range__label--value {
  position: absolute;
  top: -2.5rem; }

.input-range__label-container {
  left: -50%;
  position: relative; }
.input-range__label--max .input-range__label-container {
  left: 50%; }

.input-range__track {
  background: #eeeeee;
  border-radius: 0.3rem;
  cursor: pointer;
  display: block;
  height: 0.3rem;
  position: relative;
  -webkit-transition: left 0.3s ease-out, width 0.3s ease-out;
  transition: left 0.3s ease-out, width 0.3s ease-out; }
.input-range--disabled .input-range__track {
  background: #eeeeee; }

.input-range__track--background {
  left: 0;
  margin-top: -0.15rem;
  position: absolute;
  right: 0;
  top: 50%;
  height: 10px;
}

.input-range__track--active {
  background: #00ADD9;
  height: 10px;
  transition: none;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}

.input-range {
  height: 1rem;
  position: relative;
  width: 100%; }

/*# sourceMappingURL=react-input-range.css.map*/