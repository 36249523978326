/* Home Page */
.perso {
    color: #509b75;
}

.home {
    color: #26a6c8;
}

.info_para {
    color: #24a5c7;
}

.info_para1 {
    color: #949d65;
}
.info_para2 {
    color: #ff7a2f;
}

.car {
    color: #b7ab9d;
}

.sme {
    color: #F0F1F1;
}

.checkel {
    color: #826d94;
}

.creditcard {
    color: #fa8e5f;
}

.fixeddepo {
    color: #a1a76f;
}

.monthincome {
    color: #538ba5;
}

.doublemoney {
    color: #b29c6b;
}

.triplemoney {
    color: #b7a682;
}

.motorinsurance {
    color: #43ad8d;
}

.prizebond {
    color: #6682a2;
}

/* Home Page */
/* -------------------------------------------------------------
/ Color Css for advertisement-section-2 Style
/ ----------------------------------------------------------- */
.color-00add9{
  color: #00add9;
}
.color-leaf-green {
  color: #6db867;
}

.color-young-blue {
  color: #23a4d9;
}

.color-light-alert {
  color: #f7941d;
}

.color-light-purple {
  color: #8781bd;
}
.bg-44505b{
  height: 56px;
  background: #44505b;
}
.bg-00add9{
  background: #00add9;
}
.bg-2cb17c{
  background-color: #2cb17c;
}
.bg-00a4ce{
  background: #00a4ce;
}
.bg-leaf-green {
  background: #6db867;
}

.bg-young-blue {
  background: #23a4d9;
}

.bg-light-alert {
  background: #f7941d;
}

.bg-light-purple {
  background: #8781bd;
}
